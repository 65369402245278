import React, { FC } from 'react'

interface Props {
  className?: string
}

const Svg: FC<Props> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 73 72" height="72" width="73" className={className}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M60.5 6H12.5C9.2 6 6.5 8.7 6.5 12V66L18.5 54H60.5C63.8 54 66.5 51.3 66.5 48V12C66.5 8.7 63.8 6 60.5 6Z"
      fill="currentColor"
    />
  </svg>
)

export default Svg
