import React, { FC } from 'react'

interface Props {
  className?: string
}

const Svg: FC<Props> = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29 29" width="29" height="29">
      <path
        stroke="currentColor"
        d="M0 0.5h7m1 0h1m1 0h2m1 0h3m1 0h3m2 0h7M0 1.5h1m5 0h1m1 0h3m2 0h1m2 0h1m1 0h1m3 0h1m5 0h1M0 2.5h1m1 0h3m1 0h1m2 0h4m3 0h1m1 0h3m1 0h1m1 0h3m1 0h1M0 3.5h1m1 0h3m1 0h1m1 0h3m3 0h2m1 0h3m2 0h1m1 0h3m1 0h1M0 4.5h1m1 0h3m1 0h1m3 0h1m3 0h1m1 0h4m2 0h1m1 0h3m1 0h1M0 5.5h1m5 0h1m6 0h1m1 0h6m1 0h1m5 0h1M0 6.5h7m1 0h1m1 0h1m1 0h1m1 0h1m1 0h1m1 0h1m1 0h1m1 0h7M8 7.5h4m3 0h3m1 0h1M0 8.5h1m1 0h2m1 0h3m1 0h1m1 0h1m1 0h1m1 0h1m1 0h1m4 0h1m2 0h1m1 0h2M0 9.5h5m4 0h7m3 0h6m3 0h1M1 10.5h1m1 0h2m1 0h4m3 0h1m2 0h3m4 0h2m1 0h2M0 11.5h3m1 0h1m2 0h4m1 0h1m3 0h1m1 0h1m2 0h4m3 0h1M1 12.5h1m2 0h1m1 0h1m1 0h2m4 0h2m1 0h2m6 0h2M0 13.5h1m1 0h1m4 0h1m2 0h1m3 0h1m1 0h4m2 0h2m2 0h3M0 14.5h2m4 0h1m1 0h1m1 0h1m1 0h2m1 0h2m2 0h1m1 0h2m3 0h3M3 15.5h1m3 0h1m2 0h3m1 0h1m1 0h1m1 0h4m2 0h1m2 0h1M3 16.5h2m1 0h3m2 0h1m2 0h3m2 0h3m2 0h2m1 0h1M1 17.5h5m1 0h6m1 0h1m1 0h1m1 0h1m4 0h1m1 0h3M0 18.5h1m1 0h1m2 0h2m2 0h3m1 0h6m2 0h2m1 0h1m1 0h1M3 19.5h1m1 0h1m1 0h2m1 0h2m1 0h2m2 0h1m4 0h2m2 0h1M1 20.5h3m2 0h3m1 0h1m1 0h3m1 0h2m1 0h8M8 21.5h1m1 0h2m3 0h2m2 0h2m3 0h5M0 22.5h7m1 0h3m3 0h2m2 0h3m1 0h1m1 0h2m1 0h1M0 23.5h1m5 0h1m1 0h2m1 0h1m1 0h3m4 0h1m3 0h2M0 24.5h1m1 0h3m1 0h1m2 0h1m1 0h1m5 0h1m2 0h5m1 0h1m1 0h1M0 25.5h1m1 0h3m1 0h1m1 0h1m1 0h4m3 0h3m1 0h1m2 0h2m1 0h1M0 26.5h1m1 0h3m1 0h1m1 0h3m1 0h1m6 0h1m1 0h1m1 0h1m2 0h1m1 0h1M0 27.5h1m5 0h1m3 0h1m3 0h3m2 0h2m2 0h3m1 0h1M0 28.5h7m1 0h1m1 0h1m1 0h1m2 0h1m2 0h4m1 0h1m3 0h1"
      />
    </svg>
  )
}

export default Svg
