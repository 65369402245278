import React, { FC, PropsWithChildren, useMemo } from 'react'
import cx from 'classnames'
import { Link as PrismicLinkData } from 'prismic-reactjs'
import WistiaVideo from 'components/WistiaVideo'

import styles from './styles.module.scss'

const extractWistiaVideoIdFromUrl = (url: string) => {
  const match = url.match(/myrealm.wistia.com\/medias\/(.+)/)
  if (match && match.length > 1) {
    return match[1]
  }

  return null
}

interface Props {
  className?: string
  disclaimer?: string
  media?: PrismicLinkData
}

const Hero: FC<PropsWithChildren<Props>> = ({ children, className, disclaimer, media }) => {
  const videoId = useMemo(() => {
    if (media && media.link_type == 'Web') {
      return extractWistiaVideoIdFromUrl(media.url || '')
    }
  }, [media])

  return (
    <div className={cx(styles.hero, className)}>
      <div className={styles.heroContainer}>
        <div className={styles.content}>{children}</div>
        <div className={styles.mediaContainer}>
          {media && media.link_type === 'Media' && <img alt="" src={media.url} className={styles.image} />}
          {media && media.link_type === 'Web' && videoId && (
            <div className={styles.videoSubContainer}>
              <WistiaVideo videoId={videoId} />
            </div>
          )}
        </div>
      </div>
      {disclaimer && <p className={styles.disclaimer}>{disclaimer}</p>}
    </div>
  )
}

export default Hero
