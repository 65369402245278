import React, { FC } from 'react'

import { ProjectTemplate } from 'recoil/projectTemplates'

import Project from './Project'

import styles from './styles.module.scss'

interface Props {
  selectedProjects: Array<ProjectTemplate>
}

const SelectedProjectList: FC<Props> = ({ selectedProjects }) => (
  <div className={styles.selectedProjectList}>
    {selectedProjects.length == 0 ? (
      <p className={styles.empty}>{'Add renovations to your plan to see totals'}</p>
    ) : (
      <ul className={styles.projects}>
        {selectedProjects.map((project) => (
          <li key={project.id}>
            <Project project={project} small />
          </li>
        ))}
      </ul>
    )}
  </div>
)

export default SelectedProjectList
