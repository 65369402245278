import React, { FC } from 'react'

import { Calendar } from './types'

import ExpiredIcon from 'svgs/event-busy'

import CompletedLayout from 'pages/_serverRendered/VendorSiteVisit/CompletedLayout'

interface Props {
  calendar: Calendar
}
const Declined: FC<Props> = ({ calendar }) => {
  return (
    <CompletedLayout
      calendar={calendar}
      title="Site Visit Declined"
      description={`You declined a site visit${
        calendar.event
          ? ` on ${new Date(calendar.event.updated_at).toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            })}`
          : ''
      }.`}
      type="danger"
      icon={<ExpiredIcon />}
      helpMessage="If you believe there is an error, or would still like to schedule a site visit for this project, contact us."
      screen="declined"
    />
  )
}

export default Declined
