import React, { FC } from 'react'
import cx from 'classnames'

import { Image } from '../../types'

import styles from './styles.module.scss'

interface Props {
  className?: string
  image: Image
}

const Image: FC<Props> = ({ className, image }) => {
  const srcsetDesktop = [
    { img: image, size: '1x' },
    { img: image?.desktop_2x, size: '2x' },
  ]
    .filter((img) => !!img.img)
    .map((img) => `${img.img?.url} ${img.size}`)
    .join(',')
  const srcsetMobile = [
    { img: image?.mobile_1x, size: '1x' },
    { img: image?.mobile_2x, size: '2x' },
  ]
    .filter((img) => !!img.img)
    .map((img) => `${img.img?.url} ${img.size}`)
    .join(',')

  return (
    <div key={image.url} className={cx(className, styles.image)}>
      <img className={cx(styles.desktop)} src={image.url} srcSet={srcsetDesktop} alt={image?.alt || ''} />
      <img className={cx(styles.mobile)} src={image.url} srcSet={srcsetMobile} alt={image?.alt || ''} />
    </div>
  )
}

export default Image
