import ArrowCircleDown from 'svgs/arrow-circle-down'
import { generateIndexedUrlPathForPublicProperty } from 'utils/publicProperty'
import React, { FC, PropsWithChildren } from 'react'
import { formatAddressCasing, formatCurrency, formatOrdinal, formatPublicPropertyAddress2nd } from 'utils/formatting'

import styles from './styles.module.scss'
import { PublicPropertyAvatar } from 'components/PropertyAvatar'

interface ComparisonProps {
  ctaHref: string
  label: 'Value' | 'Potential'
  otherValue: number
  otherTaxAssessor: AttomTaxAssessor
  position: number
}

const Comparison: FC<PropsWithChildren<ComparisonProps>> = ({
  children,
  ctaHref,
  label,
  otherValue,
  otherTaxAssessor,
  position,
}) => {
  return (
    <div className={styles.card}>
      <div>
        <header className={styles.cardHeader}>
          {label === 'Value' ? <h4>{'Current Value'}</h4> : label === 'Potential' ? <h4>{'Potential Value'}</h4> : null}
          <div className={styles.cardSubheader}>
            {'Ranked '}
            <b className={styles.scoreLabel}>{formatOrdinal(position)}</b>
            {' out of '}
            <b>{'100'}</b>
            {' homes'}
          </div>
        </header>
        {children}
      </div>
      <div className={styles.ctaContainer}>
        <a href={ctaHref}>
          {'Learn more about this property'}
          <ArrowCircleDown />
        </a>
      </div>
      <div className={styles.cardHighlight}>
        <header>
          <h5>{`Top ${label} Nearby`}</h5>
          <div>{'1st'}</div>
        </header>
        <a className={styles.cardHighlightHome} href={generateIndexedUrlPathForPublicProperty(otherTaxAssessor)}>
          <PublicPropertyAvatar publicProperty={otherTaxAssessor} width={300} height={200} />
          <div>
            {label === 'Value' || label === 'Potential' ? (
              <div className={styles.valueLabel}>{formatCurrency(otherValue)}</div>
            ) : (
              <div className={styles.scoreLabel}>{otherValue}</div>
            )}
            <div>{formatAddressCasing(otherTaxAssessor.propertyaddressfull)}</div>
            <p>{formatPublicPropertyAddress2nd(otherTaxAssessor)}</p>
          </div>
        </a>
      </div>
    </div>
  )
}

export default Comparison
