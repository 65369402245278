import React, { FC, PropsWithChildren, ReactNode } from 'react'
import cx from 'classnames'

import InfoIcon from 'svgs/info-filled'
import CoreText from './CoreText'

import styles from './CoreInfoLabel.module.scss'

interface Props {
  className?: string
  type?: 'default' | 'info' | 'positive' | 'negative'
  text?: ReactNode
  textWeight?: 'regular' | 'heavy' | 'light'
  icon?: ReactNode
  note?: ReactNode
  noteWeight?: 'regular' | 'heavy' | 'light'
  noteIcon?: ReactNode
  noteInline?: boolean
  element?: keyof JSX.IntrinsicElements
}

const CoreInfoLabel: FC<PropsWithChildren<Props>> = ({
  children, // Use of children overrides text, icon, note and noteIcon
  className,
  type = 'info', // Because this is an InfoLabel, the default is 'info'.
  text,
  textWeight = 'regular',
  icon,
  note,
  noteWeight = 'light',
  noteIcon = <InfoIcon />,
  noteInline = false,
  element: Element = 'div',
}) => {
  return (
    <Element className={cx(styles.infoLabel, { [styles.noteInline]: noteInline, [styles[type]]: true }, className)}>
      {children ? (
        children
      ) : (
        <>
          {text && (
            <div className={styles.textContainer}>
              {icon && <div className={styles.iconWrapper}>{icon}</div>}
              <CoreText.Paragraph className={styles.text} weight={textWeight}>
                {text}
              </CoreText.Paragraph>
            </div>
          )}
          {note && (
            <div className={styles.noteContainer}>
              {noteIcon && <div className={styles.iconWrapper}>{noteIcon}</div>}
              <CoreText.Paragraph weight={noteWeight} className={styles.text}>
                {note}
              </CoreText.Paragraph>
            </div>
          )}
        </>
      )}
    </Element>
  )
}

export default CoreInfoLabel
