import React, { FC, useCallback, useEffect, useRef } from 'react'
import cx from 'classnames'
import { Link as PrismicLinkData } from 'prismic-reactjs'

import { track } from 'utils/analytics'

import { useCTAAdjustment } from '../CtaAdjustment'

import Button from 'components/NikhilButton'

import styles from './styles.module.scss'

interface Props {
  className?: string
  onVisibleChange?: (boolean) => void

  show_cta?: boolean
  cta_text?: string
  cta_referral_utm_params?: boolean
  cta_forward_utm_params?: boolean
  cta_url?: PrismicLinkData
  cta_relative_url?: string
}

const CtaButton: FC<Props> = ({
  className,
  onVisibleChange,
  cta_text,
  cta_referral_utm_params,
  cta_forward_utm_params,
  cta_url,
  cta_relative_url,
}) => {
  const { url } = useCTAAdjustment(cta_url?.url, cta_referral_utm_params, cta_forward_utm_params)
  const ref = useRef<HTMLButtonElement>(null)
  const handleCtaClick = useCallback(async () => {
    await track('click cta', { cta: 'landing-page-hero', module: 'hero' })

    if (!cta_url && !cta_relative_url) return
    if (!url && !cta_relative_url) return

    if (url && cta_url && cta_url.target === '_blank') {
      window.open(url, '_blank')?.focus()
    } else {
      window.location.href = url || cta_relative_url || ''
    }
  }, [cta_url, url, cta_relative_url])

  useEffect(() => {
    if (onVisibleChange != null) {
      if ('IntersectionObserver' in window) {
        const observer = new IntersectionObserver((entries) =>
          entries.forEach((entry) => {
            onVisibleChange(entry.isIntersecting)
          })
        )
        if (ref.current) observer.observe(ref.current)

        return () => observer?.disconnect()
      } else {
        onVisibleChange(true)
      }
    }
  }, [onVisibleChange])

  return (
    <Button className={cx(styles.cta, className)} type="button" onClick={handleCtaClick} ref={ref}>
      {cta_text || 'Get Started'}
    </Button>
  )
}

export default CtaButton
