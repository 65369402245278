import React, { FC, useCallback } from 'react'

import { formatPhoneNumber } from 'utils/formatting'
import { prepare, trackClick as baseTrackClick } from 'utils/analyticsV2'

import { Property } from '../types'

import PersonIcon from 'svgs/person'
import EmailIcon from 'svgs/email-2'
import PhoneIcon from 'svgs/phone'

import CoreText from 'components/Core/CoreText'
import CoreSheetModal from 'components/Core/CoreSheetModal'
import CoreDivider from 'components/Core/CoreDivider'
import InfoList, { InfoListItem } from '../components/InfoList'

import styles from './HomeownerDetails.module.scss'

const trackClick = prepare(
  {
    family: 'vendor-site-visit',
    section: 'household-details-homeowner-details',
  },
  baseTrackClick
)

interface Props {
  property: Property
  screen: string
  onClose: () => void
}
const HomeownerDetails: FC<Props> = ({ property, screen, onClose }) => {
  const handleEmailClick = useCallback(() => {
    trackClick({ code: 'open', screen, feature: 'email' })

    window.open(`mailto:${property.email}`)
  }, [screen, property])

  const handlePhoneClick = useCallback(() => {
    trackClick({ code: 'open', screen, feature: 'phone' })

    let number = property.phone_number as string
    if (!number.startsWith('+')) number = `+${number}`
    window.open(`tel:${number}`)
  }, [screen, property])

  return (
    <CoreSheetModal className={styles.homeownerDetails} title="Homeowner Details" onClose={onClose}>
      <InfoList className={styles.infoList}>
        <InfoListItem icon={<PersonIcon />}>
          <CoreText.Paragraph size="l" color="primaryBlack">
            {property.full_name}
          </CoreText.Paragraph>
        </InfoListItem>
        <li>
          <CoreDivider.Full />
        </li>
        <InfoListItem icon={<EmailIcon />} onClick={handleEmailClick}>
          <CoreText.Paragraph size="l" color="primaryBlack">
            {property.email}
          </CoreText.Paragraph>
        </InfoListItem>
        {property.phone_number && (
          <>
            <li>
              <CoreDivider.Full />
            </li>
            <InfoListItem icon={<PhoneIcon />} onClick={handlePhoneClick}>
              <CoreText.Paragraph size="l" color="primaryBlack">
                {formatPhoneNumber(property.phone_number)}
              </CoreText.Paragraph>
            </InfoListItem>
          </>
        )}
      </InfoList>
    </CoreSheetModal>
  )
}

export default HomeownerDetails
