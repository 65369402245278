import React, { FC, useMemo, useState, useEffect, useRef } from 'react'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

import ArrowIcon from 'svgs/arrow-forward-ios'

import CoreText from 'components/Core/CoreText'

import Time, { Block } from './Time'

import styles from './Period.module.scss'

dayjs.extend(utc)
dayjs.extend(timezone)

type Periods = 'morning' | 'afternoon' | 'evening'
export const PERIODS: Array<Periods> = ['morning', 'afternoon', 'evening']
const PERIOD_NAME_MAP = {
  ['morning']: { title: 'Morning', empty: 'morning' },
  ['afternoon']: { title: 'Afternoon', empty: 'afternoon' },
  ['evening']: { title: 'Evening', empty: 'evening' },
}

const ONE_MINUTE = 60 * 1000
const ONE_HOUR = ONE_MINUTE * 60
function timeFromOffset(offset: number): string {
  const h = Math.floor(offset / ONE_HOUR)
  const sH = h.toString().padStart(2, '0')
  const m = Math.floor((offset - h * ONE_HOUR) / ONE_MINUTE)
  const sM = m.toString().padStart(2, '0')
  // Let's only care up to a minute granularity here.
  return `${sH}:${sM}`
}

function getTimeOfDate(value: Date, timeOffset: number, timezone?: string): Date {
  // In order to get the true time of day, we can't use normal math.
  // We need to reparse based on the time that we want.
  // This is because in situations like DST time switch, the difference between
  // 1AM and 2AM is not 3600000 ms like normal, but 7200000 ms!
  // So we can't just say "add X hour worth of ms to midnight" to get a time.
  // Further, dayjs's 'add' function just adds to the raw time (which it is supposed to),
  // so if we tried to use this, we'd still end up being an hour ahead or behind on
  // certain days.
  let date = dayjs(value)
  if (timezone) date = date.tz(timezone)
  const sDate = date.format('YYYY-MM-DD')
  const time = timeFromOffset(timeOffset)
  return dayjs.tz(`${sDate} ${time}`, timezone).toDate()
}

function getPeriod(time: Date, timezone?: string): Periods {
  const noon = getTimeOfDate(time, 12 * ONE_HOUR, timezone).getTime()
  const five = getTimeOfDate(time, 17 * ONE_HOUR, timezone).getTime()

  const startTime = time.getTime()
  if (startTime < noon) return 'morning'
  else if (startTime < five) return 'afternoon'
  return 'evening'
}

interface Props {
  period: Periods
  availabilities: Array<Block>
  selected?: Block
  disabled: boolean
  timezone?: string
  onChange: (block: Block) => void
}
const Period: FC<Props> = ({ period, availabilities, selected, disabled, timezone, onChange }) => {
  const ref = useRef<HTMLOListElement | null>(null)
  const [hiddenChildren, setHiddenChildren] = useState(0)
  const [childToLeft, setChildToLeft] = useState(false)
  const [childToRight, setChildToRight] = useState(false)

  // Divide them into day periods.
  const localAvailabilities = useMemo(() => {
    return availabilities.filter((block) => getPeriod(block.start, timezone) == period)
  }, [availabilities, period, timezone])

  useEffect(() => {
    if ('IntersectionObserver' in window) {
      const observer = new IntersectionObserver(
        (entries) => {
          if (ref.current) {
            const root = entries[0].rootBounds
            if (!root) return

            let hasLeft = false
            let hasRight = false
            const sum = Array.from(ref.current.children).reduce((sum, entry) => {
              const child = entry.getBoundingClientRect()
              // We need to see at least 25% of the child's right or left to
              // know it is there.
              const childLeft = child.left + child.width * 0.75
              const childRight = child.right - child.width * 0.75
              if (childLeft < root.left) {
                hasLeft = true
                return sum + 1
              } else if (childRight > root.right) {
                hasRight = true
                return sum + 1
              }
              return sum
            }, 0)
            setChildToLeft(hasLeft)
            setChildToRight(hasRight)
            setHiddenChildren(sum)
          }
        },
        { root: ref.current, threshold: 0.25 }
      )
      if (ref.current && localAvailabilities.length > 0) {
        Array.from(ref.current.children).forEach((child) => observer.observe(child))
      } else {
        setChildToLeft(false)
        setChildToRight(false)
        setHiddenChildren(0)
      }
      return () => observer?.disconnect()
    }
  }, [localAvailabilities])

  return (
    <li className={styles.period}>
      <div className={styles.periodTitle}>
        <CoreText.Paragraph color="secondaryBlack" className={styles.periodLabel}>
          {PERIOD_NAME_MAP[period].title}
        </CoreText.Paragraph>
        {hiddenChildren > 0 && (
          <div className={styles.periodMore}>
            {childToLeft && <ArrowIcon className={styles.left} />}
            <CoreText.Paragraph color="secondaryBlack">{`+${hiddenChildren} more`}</CoreText.Paragraph>
            {childToRight && <ArrowIcon />}
          </div>
        )}
      </div>
      {localAvailabilities.length == 0 ? (
        <CoreText.Paragraph
          className={styles.noTimes}
          color="disabledBlack"
          weight="light"
        >{`No available times in the ${PERIOD_NAME_MAP[period].empty} for the selected date`}</CoreText.Paragraph>
      ) : (
        <div className={styles.timesWrapper}>
          <ol className={styles.times} ref={ref}>
            {localAvailabilities.map((block) => (
              <Time
                key={block.start.getTime()}
                block={block}
                selected={selected}
                disabled={disabled}
                timezone={timezone}
                onClick={onChange}
              />
            ))}
          </ol>
        </div>
      )}
    </li>
  )
}

export default Period
