import React, { FC, useMemo } from 'react'
import cx from 'classnames'

import { SliceInfoTable, InfoTableItem } from '../../types'

import PresentRichText from 'pages/_serverRendered/CmsPage/Section/Components/PresentRichText'
import TitleHeader from '../Components/TitleHeader'

import styles from './styles.module.scss'

interface Props {
  className?: string
  slice: SliceInfoTable
}
const InfoTable: FC<Props> = ({ className, slice }) => {
  const columns = slice.primary?.columns || 2
  const rows = useMemo(() => {
    let column = 0
    const ret = (slice.items || []).reduce(
      (rows, item) => {
        if (column == 0) {
          rows.push([item])
        } else {
          rows[rows.length - 1].push(item)
        }
        column = Math.min(column + (item.column_span || 1), columns) % columns
        return rows
      },
      [] as Array<Array<InfoTableItem>>
    )
    // If we have column space left at the end, fill it in with null.
    const remainder = columns - ret[ret.length - 1].reduce((sum, item) => sum + (item.column_span || 1), 0)
    if (remainder > 0) {
      ret[ret.length - 1].push({ content: [], column_span: remainder, content_alignment: 'left' })
    }

    return ret
  }, [slice.items, columns])

  return (
    <section className={cx(styles.infoTable, className)}>
      <TitleHeader className={styles.titleBox} {...slice.primary} />
      <div className={styles.content}>
        <table
          className={cx(styles.table, { [styles.manyColumns]: columns >= 3 })}
          id={slice.primary?.reference_id}
          style={slice.primary?.border_color ? { borderColor: slice.primary.border_color } : {}}
        >
          <tbody>
            {rows.map((row, ri) => (
              <tr key={ri} className={styles.row}>
                {row.map((item, ci) => {
                  const style = {}
                  if (slice.primary?.cell_color) {
                    if (slice.primary?.cell_alt_color && ri % 2 == 1)
                      style['background'] = slice.primary?.cell_alt_color
                    else style['background'] = slice.primary?.cell_color
                  }
                  if (slice.primary?.text_color) style['color'] = slice.primary.text_color
                  if (slice.primary?.border_color) style['borderColor'] = slice.primary.border_color
                  if (item.cell_color) style['background'] = item.cell_color
                  if (item.text_color) style['color'] = item.text_color

                  return (
                    <td
                      key={ci}
                      className={cx(styles.cell, { [styles[item.content_alignment || 'left']]: true })}
                      style={style}
                      colSpan={item.column_span || 1}
                    >
                      <PresentRichText render={item.content} />
                    </td>
                  )
                })}
              </tr>
            ))}
          </tbody>
        </table>
        <div className={styles.notes}>
          <PresentRichText className={styles.notes} render={slice.primary?.table_notes} />
        </div>
      </div>
    </section>
  )
}

export default InfoTable
