import React, { FC } from 'react'

interface Props {
  className?: string
}

const Svg: FC<Props> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49 48" height="48" width="49" className={className}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.7635 12.9089C41.6035 13.6089 42.8235 13.4889 43.5235 12.6689L43.5835 12.6089C44.2835 11.7689 44.1635 10.5489 43.3435 9.84891L37.1435 4.66891C36.3035 3.96891 35.0835 4.08891 34.3835 4.90891L34.3235 4.96891C33.6235 5.80891 33.7435 7.02891 34.5635 7.72891L40.7635 12.9089ZM8.36351 5.36891C7.60351 4.60891 6.36351 4.60891 5.58351 5.36891L5.54351 5.42891C4.76351 6.20891 4.76351 7.44891 5.54351 8.20891L6.90351 9.56891L6.56351 9.84891C5.72351 10.5289 5.62351 11.7689 6.30351 12.6089L6.36351 12.6689C7.06351 13.5089 8.28351 13.6089 9.12351 12.9089L9.74351 12.4089L11.3435 14.0089C8.60351 17.1689 6.94351 21.2889 6.94351 25.7889C6.94351 35.7289 15.0035 43.7889 24.9435 43.7889C29.4435 43.7889 33.5635 42.1289 36.7235 39.3889L39.5435 42.2089C40.3035 42.9689 41.5435 42.9689 42.3235 42.2089L42.3835 42.1489C43.1435 41.3889 43.1435 40.1489 42.3835 39.3689L8.36351 5.36891ZM21.0235 12.3689C22.2635 12.0089 23.5835 11.7889 24.9435 11.7889C32.6635 11.7889 38.9435 18.0689 38.9435 25.7889C38.9435 27.1489 38.7235 28.4689 38.3635 29.7089L41.4835 32.8289C42.4235 30.6689 42.9435 28.2889 42.9435 25.7889C42.9435 15.8489 34.8835 7.78891 24.9435 7.78891C22.4435 7.78891 20.0635 8.30891 17.8835 9.22891L21.0235 12.3689ZM10.9435 25.7889C10.9435 33.5089 17.2235 39.7889 24.9435 39.7889C28.3435 39.7889 31.4635 38.5689 33.8835 36.5489L14.1835 16.8489C12.1635 19.2689 10.9435 22.3889 10.9435 25.7889ZM15.5835 4.96891C16.1035 5.60891 16.1635 6.46891 15.8035 7.14891L13.1235 4.46891C13.9235 4.04891 14.9235 4.18891 15.5235 4.90891L15.5835 4.96891Z"
      fill="currentColor"
    />
  </svg>
)

export default Svg
