import React, { FC, useCallback, useState } from 'react'
import cx from 'classnames'

import { prepare, trackClick as baseTrackClick } from 'utils/analyticsV2'

import { Property } from '../types'

import PersonIcon from 'svgs/person'
import HomeIcon from 'svgs/home'
import HandymanIcon from 'svgs/handyman'

import CoreText from 'components/Core/CoreText'
import CoreSheetModal from 'components/Core/CoreSheetModal'
import InfoList, { InfoListItem } from '../components/InfoList'
import LocalCard from '../components/LocalCard'
import HomeownerDetails from './HomeownerDetails'

import styles from './styles.module.scss'

const trackClick = prepare(
  {
    family: 'vendor-site-visit',
    section: 'household-details',
  },
  baseTrackClick
)

interface Props {
  className?: string
  screen: string
  property: Property
}
const HouseholdDetails: FC<Props> = ({ className, property, screen }) => {
  const [showCustomerDetails, setShowCustomerDetails] = useState(false)
  const [showScopeDetails, setShowScopeDetails] = useState(false)

  const handleCustomerClick = useCallback(() => {
    trackClick({ code: 'open', screen, feature: 'homeowner-details' })
    setShowCustomerDetails(true)
  }, [screen])
  const handleCustomerDetailsClosed = useCallback(() => {
    trackClick({ code: 'close', screen, feature: 'homeowner-details' })
    setShowCustomerDetails(false)
  }, [screen])

  const handleAddressClick = useCallback(() => {
    const addr = [property.delivery_line_1, property.delivery_line_2, property.city, property.state, property.zip5]
      .filter((el) => el != null)
      .join(', ')

    trackClick({ code: 'open', screen, feature: 'address', content_text: addr })

    // Send iOS to apple maps. Everyone else goes to google.
    if (navigator.userAgent && /iPad|iPhone|iPod/.test(navigator.userAgent) && (window as any).MSStream) {
      window.open(`https://map.apple.com/?address=${encodeURIComponent(addr)}`, '_blank')
    } else {
      window.open(`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(addr)}`, '_blank')
    }
  }, [screen, property])

  const handleScopeClick = useCallback(() => {
    trackClick({ code: 'open', screen, feature: 'project-scope' })

    setShowScopeDetails(true)
  }, [screen])
  const handleScopeDetailsClosed = useCallback(() => {
    trackClick({ code: 'close', screen, feature: 'project-scope' })

    setShowScopeDetails(false)
  }, [screen])

  return (
    <LocalCard className={cx(styles.householdDetails, className)}>
      <CoreText.Paragraph className={styles.title} size="m" color="tertiaryBlack">
        {'Household details'}
      </CoreText.Paragraph>
      <InfoList className={styles.infoList}>
        <InfoListItem
          icon={<PersonIcon />}
          onClick={property.email || property.phone_number ? handleCustomerClick : undefined}
        >
          <CoreText.Paragraph size="m" weight="light" color="primaryBlack">
            {property.full_name}
          </CoreText.Paragraph>
        </InfoListItem>
        <InfoListItem icon={<HomeIcon />} onClick={handleAddressClick}>
          <div>
            <CoreText.Paragraph size="m" weight="light" color="primaryBlack">
              {property.delivery_line_1}
            </CoreText.Paragraph>
            {property.delivery_line_2 && (
              <CoreText.Paragraph size="m" weight="light" color="primaryBlack">
                {property.delivery_line_2}
              </CoreText.Paragraph>
            )}
            <CoreText.Paragraph size="m" weight="light" color="primaryBlack">
              {`${property.city}, ${property.state} ${property.zip5}`}
            </CoreText.Paragraph>
          </div>
        </InfoListItem>

        <InfoListItem icon={<HandymanIcon />} onClick={handleScopeClick}>
          <CoreText.Paragraph size="m" weight="light" color="primaryBlack">
            {`Project Scope`}
          </CoreText.Paragraph>
        </InfoListItem>
      </InfoList>
      {showCustomerDetails && (
        <HomeownerDetails screen={screen} onClose={handleCustomerDetailsClosed} property={property} />
      )}
      {showScopeDetails && (
        <CoreSheetModal className={styles.scope} title={`Project Scope`} onClose={handleScopeDetailsClosed}>
          <div className={styles.scopeDescription}>
            <div className={styles.scopeContent}>
              <p dangerouslySetInnerHTML={{ __html: property.scope }} />
            </div>
          </div>
        </CoreSheetModal>
      )}
    </LocalCard>
  )
}

export default HouseholdDetails
