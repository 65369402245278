import { CmsPageData, IntroData, Slice as SliceType } from './types'
import React, { FC, useEffect, useMemo, useState, useRef, useCallback } from 'react'

import AddressAutocomplete from 'components/MarketingSite/Header/AddressAutocomplete'
import Backers from './Section/Backers'
import BlogHighlights from './Section/BlogHighlights'
import Cards from './Section/Cards'
import CmsAccordion from 'components/MarketingAndPropertyPages/Accordion/CmsAccordion'
import Cta from 'components/MarketingAndPropertyPages/Cta'
import CustomerLogos from './Section/CustomerLogos'
import FlippingCards from './Section/FlippingCards'
import Footer from 'components/MarketingSite/Footer'
import FormattedText from './Section/FormattedText'
import GetStarted from 'pages/_serverRendered/Homepage/GetStarted'
import Header from 'components/MarketingSite/Header'
import Hero from './Hero'
import ImageQuotes from './Section/ImageQuotes'
import LandingCards from './Section/LandingCards'
import ListCard from './Section/ListCard'
import Markdown from 'components/Markdown'
import OpenRoles from './Section/OpenRoles'
import PhotoCard from './Section/PhotoCard'
import Press from './Section/Press'
import QuotesCard from './Section/QuotesCard'
import ScrollingCards from './Section/ScrollingCards'
import Team from './Section/Team'
import classnames from 'classnames'
import { colorsToStyle } from './Section/Components/Card'
import { hasTextPresent } from './Section/Components/PresentRichText'
import slugify from 'utils/slugify'
import styles from './styles.module.scss'
import { trackPage } from 'utils/analytics'
import { ConvertAutocompleteRedirectTarget } from 'pages/_serverRendered/CmsPage/utils'
import CustomEmbed from 'pages/_serverRendered/Blog/shared/CustomEmbed'

const Navigation: FC<{ slices: SliceType[] }> = ({ slices }) => {
  const navTitles = useMemo(() => slices.map((slice) => slice.primary?.nav_title).filter((e) => e), [slices])

  return (
    <nav className={styles.nav}>
      {navTitles.map((title, index) => (
        <a
          className={classnames(styles.navItem, { [styles.navItemActive]: index === 0 })}
          href={`#${slugify(title)}`}
          key={slugify(title)}
        >
          <div className={styles.navBullet}>{'→'}</div>
          <div className={styles.navText}>{title}</div>
        </a>
      ))}
    </nav>
  )
}

export const Intro: FC<IntroData> = ({ title, body }) => (
  <div className={styles.intro}>
    {hasTextPresent(title) && <h3 className={styles.introTitle}>{title[0].text}</h3>}
    {hasTextPresent(body) && <p>{body[0].text}</p>}
  </div>
)

const SliceContent: FC<SliceType> = (slice) => {
  switch (slice.slice_type) {
    case 'accordion':
      return <CmsAccordion {...slice} />
    case 'blog_highlights':
      return <BlogHighlights {...slice} />
    case 'cards':
      return <Cards {...slice} />
    case 'landing_cards':
      return <LandingCards {...slice} />
    case 'customer_logos':
      return <CustomerLogos {...slice} />
    case 'flipping_cards':
      return <FlippingCards {...slice} />
    case 'formatted_text':
      return <FormattedText {...slice} />
    case 'photo_card':
      return <PhotoCard {...slice} />
    case 'scrolling_cards':
      return <ScrollingCards {...slice} />
    default:
      return null
  }
}

const Slice: FC<SliceType & { className?: string }> = (props) => {
  const { className, ...slice } = props
  const title = slice.primary?.title?.[0]?.text
  const body = slice.primary?.description?.[0]?.text

  if (
    ['image_quotes', 'list_card', 'quotes_card', 'custom_embed', 'team', 'open_roles', 'backers', 'press'].includes(
      slice.slice_type || ''
    )
  ) {
    return (
      <>
        {slice.primary?.nav_title && (
          <div className={styles.sectionSidebar}>
            <div className={classnames(styles.anchor, styles.navItemActive)}>
              <div className={styles.navBullet}>{'→'}</div>
              <div className={styles.navText}>{slice.primary?.nav_title}</div>
            </div>
          </div>
        )}
        {slice.slice_type === 'image_quotes' ? (
          <ImageQuotes
            slice={slice}
            className={classnames(styles.gridColumn, styles.transparent, styles.overflow)}
            id={slugify(slice.primary?.nav_title)}
          />
        ) : slice.slice_type === 'list_card' ? (
          <ListCard slice={slice} className={styles.gridColumn} id={slugify(slice.primary?.nav_title)} />
        ) : slice.slice_type === 'quotes_card' ? (
          <QuotesCard slice={slice} className={styles.gridColumn} id={slugify(slice.primary?.nav_title)} />
        ) : slice.slice_type == 'custom_embed' ? (
          <section id={slugify(slice.primary?.nav_title)} className={styles.gridColumn}>
            <CustomEmbed slice={slice} />
          </section>
        ) : slice.slice_type === 'team' ? (
          <Team slice={slice} className={styles.gridColumn} id={slugify(slice.primary?.nav_title)} />
        ) : slice.slice_type === 'open_roles' ? (
          <OpenRoles slice={slice} className={styles.gridColumn} id={slugify(slice.primary?.nav_title)} />
        ) : slice.slice_type === 'backers' ? (
          <Backers slice={slice} className={styles.gridColumn} id={slugify(slice.primary?.nav_title)} />
        ) : slice.slice_type === 'press' ? (
          <Press slice={slice} className={styles.gridColumn} id={slugify(slice.primary?.nav_title)} />
        ) : null}
      </>
    )
  }

  return (
    <>
      {slice.primary?.nav_title && (
        <div className={styles.sectionSidebar}>
          <div className={classnames(styles.anchor, styles.navItemActive)}>
            <div className={styles.navBullet}>{'→'}</div>
            <div className={styles.navText}>{slice.primary?.nav_title}</div>
          </div>
        </div>
      )}
      <section
        className={classnames(
          styles.slice,
          {
            [styles.transparent]:
              ['scrolling_cards', 'flipping_cards'].includes(slice.slice_type || '') ||
              slice.primary?.background_color == 'transparent',
          },
          { [styles.overflow]: ['scrolling_cards', 'flipping_cards'].includes(slice.slice_type || '') },
          className
        )}
        id={slugify(slice.primary?.nav_title)}
        style={{
          ...colorsToStyle[slice.primary?.background_color || ''],
        }}
      >
        {title != null && (
          <Markdown options={{ wrapper: 'h2' }} className={styles.sliceTitle}>
            {title}
          </Markdown>
        )}
        {body != null && <p className={styles.sliceBody}>{body}</p>}
        <SliceContent {...slice} />
        {slice.primary?.cta_title && (
          <Cta className={styles.cta} href={slice.primary?.cta_url || ''}>
            {slice.primary?.cta_title}
          </Cta>
        )}
      </section>
    </>
  )
}

const CmsPage: FC<{ data: CmsPageData; pageName: string }> = ({
  data: {
    body,
    get_started,
    hero,
    intro,
    nav_how_it_works,
    nav_why_realm,
    nav_resources,
    nav_about,
    nav_dashboard,
    nav_sign_in,
    nav_get_started,
    autocomplete_redirect_target,
    page_id_for_tracking,
  },
  pageName,
}) => {
  const headerRef = useRef<HTMLElement>(null)
  const [heroInputVisible, setHeroInputVisible] = useState(false)
  const [campaignId, setCampaignId] = useState<string>()
  useEffect(() => {
    trackPage(pageName || page_id_for_tracking)
  }, [pageName, page_id_for_tracking])

  // Ideally, the marketing campaign Id also comes from prismic.
  // Since we only have one campaign right now, we'll fake it here.
  useEffect(() => {
    // Set the campaignId only after the client loads, so we can detect what
    // page we're on and if we should do it. This whole useEffect block
    // won't be necessary if/when we push the campaignId from prismic
    if (window.location.pathname == '/l/giveaway') {
      const url = new (URL || window.URL)(window.location.href)
      if (!url.searchParams.has('mc')) {
        const campaignId = encodeURIComponent(btoa('giveaway-2021-10-18'))
        url.searchParams.append('mc', campaignId)
        window.history.replaceState(null, '', url.toString())
        setCampaignId(campaignId)
      } else {
        setCampaignId(url.searchParams.get('mc') as string)
      }
    }
  }, [])

  const handleRequestHeaderHeight = useCallback(() => {
    if (headerRef.current) return headerRef.current.offsetHeight
  }, [])

  return (
    <div>
      <Header
        ref={headerRef}
        className={styles.header}
        hideHowItWorksButton={nav_how_it_works === false}
        hideWhyRealmButton={nav_why_realm === false}
        hideResourcesButton={nav_resources === false}
        hideAboutButton={nav_about === false}
        hideDashboardButton={nav_dashboard === false}
        hideSignInButton={nav_sign_in === false}
        hideSignupButton={nav_get_started === false}
        campaignId={campaignId}
      >
        {heroInputVisible ? (
          <div />
        ) : (
          <AddressAutocomplete
            className={styles.search}
            positionLabel="header"
            variant={ConvertAutocompleteRedirectTarget(autocomplete_redirect_target)}
          />
        )}
      </Header>
      {hero?.[0] != null && (
        <Hero
          {...hero[0]}
          onInputVisibleChange={setHeroInputVisible}
          autocompleteRedirectTarget={autocomplete_redirect_target}
          onRequestHeaderHeight={handleRequestHeaderHeight}
        />
      )}
      <div className={styles.content}>
        <div className={styles.sidebar}>
          {intro?.[0] != null && <Intro {...intro[0]} />}
          <Navigation slices={body} />
        </div>
        {body.map((slice, index) => (
          <Slice {...slice} key={index} />
        ))}
      </div>
      <GetStarted
        className={styles.getStarted}
        id="getstarted"
        title={get_started?.[0]?.title[0]?.text}
        description={get_started?.[0]?.description[0]?.text}
        data-hide-on="logged-in"
        variant={ConvertAutocompleteRedirectTarget(autocomplete_redirect_target)}
        onRequestHeaderHeight={handleRequestHeaderHeight}
      />
      <Footer />
    </div>
  )
}

export default CmsPage
