import React, { FC } from 'react'

import ZoningRule from './ZoningRule'

import styles from './styles.module.scss'

interface Props {
  sectionTitle: string
  zoningRules: Array<{ ruleType: string; value: string; caveats: string[] }>
}

const ZoningGroup: FC<Props> = ({ sectionTitle, zoningRules }) => {
  return (
    <div>
      <h2 className={styles.sectionHeader}>{sectionTitle}</h2>
      <div>
        <ul className={styles.rules}>
          {zoningRules.map((rule) => {
            return (
              <li key={rule.ruleType}>
                <ZoningRule name={rule.ruleType} value={rule.value} message={rule.caveats} />
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export default ZoningGroup
