import cx from 'classnames'
import Spinner from 'components/Spinner'
import React, { FC, useCallback, useState } from 'react'
import { track } from 'utils/analytics'

import styles from './styles.module.scss'

interface InsightsEmailCtaProps {
  text: string
  path: string
}

const InsightsEmailCta: FC<InsightsEmailCtaProps> = ({ text, path }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isSuccess, setIsSuccess] = useState<boolean>(false)

  const handleClick = useCallback(async () => {
    setIsLoading(true)
    track('click cta', { cta: 'email-insights' })
    try {
      const csrfParam = document.querySelector('meta[name=csrf-param]')?.getAttribute('content')
      const csrfToken = document.querySelector('meta[name=csrf-token]')?.getAttribute('content')
      const response = await fetch(path.replace(/\/landing/, '/subscribe_lead'), {
        method: 'POST',
        credentials: 'same-origin',
        headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest' },
        body: JSON.stringify({ [csrfParam || '']: csrfToken }),
      })
      if (response.ok) {
        setIsSuccess(true)
      } else {
        alert('An unexpected error occured, please try again later')
      }
    } finally {
      setIsLoading(false)
    }
  }, [path])

  if (isSuccess) return <p className="tw-mt-6">{'Added! We’ll be in touch.'}</p>

  if (isLoading) {
    return (
      <div className="tw-pt-6 tw-px-16">
        <Spinner size="lg" />
      </div>
    )
  }

  return (
    <button
      type="button"
      onClick={handleClick}
      className={cx(
        'tw-font-bold tw-rounded-lg tw-p-4 tw-flex tw-items-center tw-justify-center tw-border-base-gray-two tw-border tw-mt-4 tw-text-sm tw-w-full xl:tw-w-auto',
        styles.insightsButton
      )}
    >
      {text}
    </button>
  )
}

export default InsightsEmailCta
