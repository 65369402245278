import classnames from 'classnames'
import React, { FC, Fragment, ReactNode, useMemo } from 'react'

import CtaSmall from 'components/MarketingSite/CtaSmall'
import Section from 'components/MarketingSite/PageContent/Section'
import { MarketingSitePage, NavItem, Intro as IntroType } from 'components/MarketingSite/PageContent/types'

import styles from './styles.module.scss'

interface Props {
  content: MarketingSitePage
  hero?: ReactNode
}

const PageContent: FC<Props> = ({ content, hero }) => {
  const navItems = useMemo(
    () =>
      content.sections.reduce((items: NavItem[], section) => {
        if (section.navItem != null) {
          return [...items, section.navItem]
        }
        return items
      }, []),
    [content.sections]
  )

  return (
    <div className={styles.pageContent}>
      <div className={styles.sidebar}>
        <nav className={styles.nav}>
          {navItems.map((item, index) => (
            <a
              className={classnames(styles.navItem, { [styles.navItemActive]: index === 0 })}
              href={`#${item.slug}`}
              key={item.slug}
            >
              <div className={styles.navBullet}>{'→'}</div>
              <div className={styles.navText}>{item.title}</div>
            </a>
          ))}
        </nav>
        <Intro {...content.intro} />
      </div>
      {hero}
      {content.sections.map((section, index) => (
        <Fragment key={index}>
          {(section.navItem != null || section.intro != null) && index !== 0 && (
            <div className={styles.sectionSidebar}>
              {section.navItem != null && index != 0 && (
                <div className={classnames(styles.anchor, styles.navItemActive)}>
                  <div className={styles.navBullet}>{'→'}</div>
                  <div className={styles.navText}>{section.navItem.title}</div>
                </div>
              )}
              {section.intro != null && <Intro {...section.intro} />}
            </div>
          )}
          <Section className={styles.section} key={section.title} {...section} />
          {section.cardBackgroundColor === 'transparent' &&
          content.sections[index + 1]?.cardBackgroundColor === 'transparent' ? (
            <hr className={styles.hr} />
          ) : null}
        </Fragment>
      ))}
    </div>
  )
}

export const Intro: FC<IntroType> = ({ title, body, cta }) => (
  <div className={styles.intro}>
    <h3 className={styles.introTitle}>{title}</h3>
    {body != null && <p>{body}</p>}
    {cta != null && <CtaSmall href={cta.href}>{cta.title}</CtaSmall>}
  </div>
)

export default PageContent
