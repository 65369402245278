import React, { FC } from 'react'

import styles from './styles.module.scss'

const IntroCopy: FC = () => (
  <div className={styles.introCopy}>
    <h2 className={styles.headline}>{'Realm makes homeownership easy'}</h2>
    <p className={styles.description}>
      {'We get it. Homeownership can be overwhelming, stressful, and confusing. Whether you’re searching for a new home, ' +
        'staying put, or somewhere in between, Realm is here to help. We use reliable, unbiased data to show you not only' +
        ' what a property is worth today, but what it could be worth in years to come. Our free tools give you accurate ' +
        'renovation cost estimates and tell you how much value a project will add. Plus, our insights are customized to ' +
        'each property, so you get trustworthy information you can actually use. Make smart choices for your home with Realm.'}
    </p>
  </div>
)

export default IntroCopy
