import classnames from 'classnames'
import React, { FC } from 'react'

import { ContentBlog, ContentBlogItem } from 'components/MarketingSite/PageContent/types'
import Cta from 'components/MarketingAndPropertyPages/Cta'

import ImgBlogLiz from 'images/learn/blog/liz.jpg'
import ImgWhyData from 'images/learn/blog/why-data-will-change-home-ownership.jpg'

import styles from './styles.module.scss'

const Blog: FC<ContentBlog> = ({ items }) => (
  <div className={styles.blog}>
    {items.map((item) => (
      <BlogItem key={item.title} {...item}></BlogItem>
    ))}
  </div>
)

const BlogItem: FC<ContentBlogItem> = ({ author, title, href, image, body }) => (
  <div className={classnames(styles.item, { [styles.withImage]: image != null })} key={title}>
    <div className={styles.author}>
      <img src={authorImageSrc(author.key)} alt={author.name} />
      <p>
        {author.name}
        <br />
        {author.title}
      </p>
    </div>
    <h4 className={styles.title}>{title}</h4>
    {image != null && <img className={styles.image} src={postImageSrc(image.key)} alt={image.alt} />}
    {body != null && <p className={styles.body}>{body}</p>}
    <Cta href={href} target="_blank">
      {'Read'}
    </Cta>
  </div>
)

const authorImageSrc = (key) => {
  switch (key) {
    case 'liz':
      return ImgBlogLiz
  }
}

const postImageSrc = (key) => {
  switch (key) {
    case 'why-data-will-change-home-ownership':
      return ImgWhyData
  }
}

export default Blog
