import React, { FC } from 'react'

interface Props {
  className?: string
}

const Svg: FC<Props> = ({ className }) => (
  <svg className={className} width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.1065 15.8596L17.5665 15.5696C16.9565 15.4996 16.3565 15.7096 15.9265 16.1396L14.0865 17.9796C11.2565 16.5396 8.93651 14.2296 7.49651 11.3896L9.34651 9.53962C9.77651 9.10962 9.98651 8.50962 9.91651 7.89962L9.62651 5.37962C9.50651 4.36962 8.65651 3.60962 7.63651 3.60962H5.90651C4.77651 3.60962 3.83651 4.54962 3.90651 5.67962C4.43651 14.2196 11.2665 21.0396 19.7965 21.5696C20.9265 21.6396 21.8665 20.6996 21.8665 19.5696V17.8396C21.8765 16.8296 21.1165 15.9796 20.1065 15.8596Z"
      fill="currentColor"
    />
  </svg>
)

export default Svg
