import cx from 'classnames'
import React, { FC, useCallback, useEffect, useState } from 'react'
import Header from 'components/MarketingSite/Header'
import { FooterContainer, SocialLinks } from 'components/MarketingAndPropertyPages/Footer'
import HomepageLinks from 'components/MarketingAndPropertyPages/Footer/HomepageLinks'
import Background from './background'
import ProjectIcon from './project'
import ValueIcon from './value'
import NeighborhoodIcon from './neighborhood'
import SlideButton from './SlideButton'
import InsightsEmailCta from './InsightsEmailCta'
import DownloadIcon from 'svgs/download'
import { track } from 'utils/analytics'
import { LandingPageData } from './types'

import styles from './styles.module.scss'

interface MobileAppPageProps {
  data?: LandingPageData
  email?: string
  path?: string
}

const MobileAppPage: FC<MobileAppPageProps> = ({ data, email, path }) => {
  const [slidesIndex, setSlidesIndex] = useState(0)
  const [clickCounter, setClickCunter] = useState(0)

  useEffect(() => {
    let didCancel = false
    const interval = setInterval(() => {
      if (didCancel) return
      setSlidesIndex((slidesIndex) => (slidesIndex + 1) % 3)
    }, 8000)
    return () => {
      clearInterval(interval)
      didCancel = true
    }
  }, [clickCounter]) // have clicks reset the timer

  const handleClickSlide = useCallback((slide: number) => {
    setClickCunter((count) => count + 1)
    setSlidesIndex(slide)
  }, [])

  const handleTopDownloadApp = useCallback(() => {
    track('click cta', { cta: 'download-app', module: 'top' }) // no await, this is best-effort, as we want it to be fast
    open(data?.download_app_url, '_blank')
  }, [data?.download_app_url])

  const handleBottomDownloadApp = useCallback(() => {
    track('click cta', { cta: 'download-app', module: 'bottom' }) // no await, this is best-effort, as we want it to be fast
    open(data?.download_app_url, '_blank')
  }, [data?.download_app_url])

  if (!data) return null

  return (
    <div className="tw-bg-base-offwhite tw-min-h-screen tw-flex tw-flex-col tw-font-sans-open-sans tw-items-center">
      <Header
        className="tw-w-full"
        logoClassName="tw-text-black"
        hideSignupButton
        showDownloadApp={data?.download_app_url}
      />
      <div
        className={cx(
          'tw-flex tw-flex-col xl:tw-flex-row tw-items-center xl:tw-items-stretch tw-grow tw-pt-2 tw-pb-8 tw-w-full',
          styles.container
        )}
      >
        <div className="tw-flex tw-flex-col tw-items-center xl:tw-w-2/5 xl:tw-items-start">
          <h1 className="tw-font-bold tw-text-center tw-text-2xl tw-px-16 md:tw-text-5xl md:tw-leading-tight xl:tw-pr-0 xl:tw-pl-4 xl:tw-text-left xl:tw-text-6xl">
            {data.title1}
          </h1>
          <div className="tw-flex tw-flex-col tw-items-center xl:tw-w-9/12 xl:tw-items-start">
            <button
              type="button"
              onClick={handleTopDownloadApp}
              className="tw-bg-moss-bright tw-relative tw-z-10 tw-font-bold tw-rounded-lg tw-p-4 tw-flex tw-items-center tw-justify-center tw-gap-2 tw-mt-6 tw-text-sm lg:tw-hidden"
            >
              <DownloadIcon className="tw-w-4 tw-h-4" />
              {data.download_app_cta}
            </button>
            <div
              className={cx(
                'tw-relative xl:tw-hidden tw-overflow-x-hidden tw-flex tw-justify-center tw--mt-2 sm:tw-mt-0 md:tw-mt-2 lg:tw-mt-4 tw-pointer-events-none',
                styles.backgroundContainer
              )}
            >
              <Background
                idPrefix="small"
                className={cx('tw-w-full tw-h-full', styles.background)}
                slidesIndex={slidesIndex}
              />
              {[data.projects_section_image, data.value_section_image, data.alerts_section_image].map(
                (image, index) => (
                  <div
                    className={cx('tw-absolute', styles.sectionImageContainer, styles.sectionImageSmall, {
                      'tw-invisible': slidesIndex != index,
                    })}
                    key={image?.url}
                  >
                    <img
                      className={cx('tw-max-w-none', styles.sectionImage, styles.sectionImageSmall)}
                      alt={image?.alt || undefined}
                      src={image?.url?.replace(/\?.*/, '')}
                    />
                  </div>
                )
              )}
            </div>
            <div className="tw-px-4 xl:tw-pr-0">
              <div className="tw-relative">
                <div
                  className={cx('tw-h-20 tw-bg-base-charcoal tw-absolute', styles.sectionsLine)}
                  style={{ top: slidesIndex * 80 }}
                />
                <ul className="tw-mt-6 tw-border-l-2 tw-border-base-gray-two">
                  <li>
                    <SlideButton onClick={handleClickSlide} slide={0}>
                      <ProjectIcon className="tw-mx-4 tw-shrink-0" />
                      <div>{data.project_callout}</div>
                    </SlideButton>
                  </li>
                  <li>
                    <SlideButton onClick={handleClickSlide} slide={1}>
                      <ValueIcon className="tw-mx-4 tw-shrink-0" />
                      <div>{data.value_callout}</div>
                    </SlideButton>
                  </li>
                  <li>
                    <SlideButton onClick={handleClickSlide} slide={2}>
                      <NeighborhoodIcon className="tw-mx-4 tw-shrink-0" />
                      <div>{data.alerts_callout}</div>
                    </SlideButton>
                  </li>
                </ul>
              </div>
              <div className={cx('tw-w-full', styles.scanToDownload)}>
                <hr className="tw-my-6 tw-w-full" />
                <div className="tw-flex tw-items-center tw-gap-6">
                  <div className="tw-p-2 tw-bg-moss-bright tw-rounded-2xl tw-shrink-0">
                    <img
                      className="tw-w-20 tw-h-20"
                      alt={data.qr_code_image?.alt || undefined}
                      src={`${data.qr_code_image?.url?.replace(/\?.*/, '')}?w=80`}
                      srcSet={`${data.qr_code_image?.url?.replace(
                        /\?.*/,
                        ''
                      )}?w=80&dpr=1 1x, ${data.qr_code_image?.url?.replace(/\?.*/, '')}?w=80&dpr=2 2x`}
                    />
                  </div>
                  <p className="tw-text-lg tw-font-bold">{data.scan_qr_code_cta}</p>
                </div>
              </div>
              <button
                type="button"
                onClick={handleBottomDownloadApp}
                className="tw-bg-moss-bright tw-font-bold tw-rounded-lg tw-p-4 tw-flex tw-items-center tw-justify-center tw-gap-2 tw-mt-6 tw-text-sm tw-w-full lg:tw-hidden"
              >
                <DownloadIcon className="tw-w-4 tw-h-4" />
                {data.download_app_cta}
              </button>
              {email && path && (
                <>
                  <hr className="tw-my-6 tw-w-full" />
                  <div>
                    <span>{data.not_ready}</span> <b>{email}</b>
                  </div>
                  <InsightsEmailCta text={data.insights_email_cta} path={path} />
                </>
              )}
            </div>
          </div>
        </div>
        <div className={cx('tw-relative xl:tw-w-3/5', styles.largeSlide)}>
          <Background idPrefix="big" className="tw-w-full tw-h-full" slidesIndex={slidesIndex} />
          {[data.projects_section_image, data.value_section_image, data.alerts_section_image].map((image, index) => (
            <div
              className={cx('tw-absolute', styles.sectionImageContainer, styles.sectionImageLarge, {
                'tw-invisible': slidesIndex != index,
              })}
              key={image?.url}
            >
              <img
                className={cx(styles.sectionImage, styles.sectionImageLarge)}
                alt={image?.alt || undefined}
                src={image?.url?.replace(/\?.*/, '')}
              />
            </div>
          ))}
        </div>
      </div>
      <FooterContainer>
        <HomepageLinks />
        <SocialLinks />
      </FooterContainer>
    </div>
  )
}

export default MobileAppPage
