import React from 'react'
import classNames from 'classnames'

import Info from 'svgs/info'

import styles from './styles.module.scss'

interface Props {
  className?: string
}

const ToolTip: React.FC<React.PropsWithChildren<Props>> = ({ className, children }) => (
  <span className={classNames(styles.tooltip, className)}>
    <span className={styles.holder}>
      <Info className={styles.icon} />
      <span className={styles.tip}>{children}</span>
    </span>
  </span>
)
export default ToolTip
