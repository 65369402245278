import React, { FC } from 'react'

import SearchIcon from 'svgs/search'

import Input, { InputProps } from 'components/NikhilInput'

import styles from './styles.module.scss'

const Search: FC<InputProps> = ({ ...props }) => (
  <div className={styles.search}>
    <SearchIcon />
    <Input className={styles.input} type="search" {...props} />
  </div>
)

export default Search
