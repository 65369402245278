import React, { FC } from 'react'

import { RichText } from 'prismic-reactjs'
import { SliceCards } from 'pages/_serverRendered/CmsPage/types'
import { hasTextPresent } from 'pages/_serverRendered/CmsPage/Section/Components/PresentRichText'
import styles from './styles.module.scss'
import { thumbnailUrl } from 'pages/_serverRendered/CmsPage/utils'

const Cards: FC<SliceCards> = ({ items }) => (
  <div className={styles.cards}>
    {items.map((card, i) =>
      hasTextPresent(card.body1) ? (
        <div className={styles.body} key={i}>
          <RichText render={card.body1} />
        </div>
      ) : (
        <div className={styles.card} key={i}>
          {hasTextPresent(card.title) && <h4 className={styles.h4}>{card.title[0].text}</h4>}
          {card.image?.url != null && (
            <div className={styles.image}>
              <img src={thumbnailUrl(card.image.url)} alt={card.image.alt || ''} />
            </div>
          )}
        </div>
      )
    )}
  </div>
)

export default Cards
