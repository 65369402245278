import React, { FC, useEffect, createContext, useContext, useMemo } from 'react'
import cx from 'classnames'

import { trackPage, prepare, trackClick as baseTrackClick } from 'utils/analyticsV2'

import { HomepageData, Slice as SliceType } from './types'

import ShowOrHideAdjustBanner from 'non-rendering/ShowOrHideAdjustBanner'

import OverlayMount from 'layouts/components/OverlayMount'

import Hero from './Hero'
import Header from './Header'
import CallToAction from './CallToAction'
import Footer from './Footer'

import CustomerSocialProof from './Sections/CustomerSocialProof'
import AppPreview from './Sections/AppPreview'
import MediaSocialProof from './Sections/MediaSocialProof'
import ImageQuotes from './Sections/ImageQuotes'
import ListCard from './Sections/ListCard'
import PhotoCards from './Sections/PhotoCards'

import styles from './styles.module.scss'

type TrackClickType = (options?: {
  code?: string
  section?: string
  feature?: string
  family?: string
}) => Promise<void>
interface ITrackContext {
  trackClick: TrackClickType
}
const TrackContext = createContext<ITrackContext>({
  trackClick: async () => undefined,
})

export const useTrackClick = (): TrackClickType => {
  return useContext(TrackContext).trackClick
}

const Slice: FC<SliceType> = (props) => {
  const { ...slice } = props
  if (
    ['customersocialproof', 'apppreview', 'mediasocialproof', 'image_quotes', 'list_card', 'photo_cards'].includes(
      slice.slice_type || ''
    )
  ) {
    return (
      <>
        {slice.slice_type === 'customersocialproof' ? (
          <CustomerSocialProof slice={slice} className={styles.section} />
        ) : slice.slice_type === 'apppreview' ? (
          <AppPreview slice={slice} className={styles.section} />
        ) : slice.slice_type === 'mediasocialproof' ? (
          <MediaSocialProof slice={slice} className={styles.section} />
        ) : slice.slice_type === 'image_quotes' ? (
          <ImageQuotes slice={slice} className={styles.section} />
        ) : slice.slice_type === 'list_card' ? (
          <ListCard slice={slice} className={styles.section} />
        ) : slice.slice_type === 'photo_cards' ? (
          <PhotoCards slice={slice} className={styles.section} />
        ) : null}
      </>
    )
  }
  return null
}

const Homepage: FC<{ data: HomepageData; webview: boolean; type: string }> = ({ data, type }) => {
  const { body, tracking_page_id } = data

  useEffect(() => {
    trackPage(tracking_page_id || 'homepage')
  }, [tracking_page_id])

  const trackClick = useMemo(() => prepare({ screen: tracking_page_id }, baseTrackClick), [tracking_page_id])

  return (
    <OverlayMount>
      <TrackContext.Provider value={{ trackClick }}>
        <div className={cx(styles.homepage, styles[type])}>
          <Header className={styles.header} {...data} />
          <Hero className={styles.hero} {...data} />
          {body.map((slice, index) => (
            <Slice {...slice} key={index} />
          ))}
          <CallToAction
            className={styles.callToAction}
            background_images={data.call_background_images}
            {...data.call_to_action[0]}
          />
          <Footer className={styles.footer} {...data} />
          <ShowOrHideAdjustBanner pathname={global.window && global.window.location.pathname} />
        </div>
      </TrackContext.Provider>
    </OverlayMount>
  )
}

export default Homepage
