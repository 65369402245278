import { MarketingSitePage } from 'components/MarketingSite/PageContent/types'

export const projectsItems = [
  {
    title: 'Add more space',
    image: {
      key: 'more-space',
      alt: 'Add more space',
    },
    header: 'Consider a home addition.',
    body: 'Square feet is the number one driver of home value and often the biggest source of potential value. Let us help you find the balance between project cost and estimated value increase.',
    cta: { title: 'Log in to your Project Planner to learn more.', href: '/users/sign_up' },
  },
  {
    title: 'Convert existing space',
    image: {
      key: 'convert-existing',
      alt: 'Convert existing space',
    },
    header: 'Make room for your in-laws.',
    body: 'Converting a garage or attic into liveable space is a great option for a growing family. Adding 1 bed / 1 bath to your home increases property value by $49,500 on average.',
    cta: { title: 'Log in to your Project Planner to learn more.', href: '/users/sign_up' },
  },
  {
    title: 'Build a backyard home',
    image: {
      key: 'backyard-home',
      alt: 'Build a backyard home',
    },
    header: 'Use your extra space to generate rental income.',
    body: 'Many cities allow you to build an Accessory Dwelling Unit (ADU) on your property. We can help you sift through your local regulations and calculate expected rental income from a backyard home.',
    cta: { title: 'Log in to your Project Planner to learn more.', href: '/users/sign_up' },
  },
  {
    title: 'Renovate the kitchen',
    image: {
      key: 'kitchen',
      alt: 'Renovate the kitchen',
    },
    header: 'Paint your cabinets.',
    body: 'Updated kitchens can help you to sell faster, but this can quickly become expensive. You’ll want to detail out a budget, think through where to splurge and where to save, and explore your financing options before getting started.',
    cta: { title: 'Log in to your Project Planner to learn more.', href: '/users/sign_up' },
  },
  {
    title: 'Renovate the bathroom',
    image: {
      key: 'bathroom',
      alt: 'Renovate the bathroom',
    },
    header: 'Update your countertops.',
    body: 'If you’re considering making some cosmetic changes to your bathroom, it’s often one of the cheaper projects to tackle. Plus, our data shows that making cosmetic updates to your bathroom will recoup 75% of the cost in increased value.',
    cta: { title: 'Log in to your Project Planner to learn more.', href: '/users/sign_up' },
  },
  {
    title: 'Add a pool',
    image: {
      key: 'pool',
      alt: 'Add a pool',
    },
    header: 'Host your dream backyard parties.',
    body: 'Pools are fun for parties, but not for your bank account. They have lots of hidden expenses such as energy bills, chemical treatments, and additional insurance costs. Think twice before splurging on this upgrade.',
    cta: { title: 'Log in to your Project Planner to learn more.', href: '/users/sign_up' },
  },
]

const learnContent: MarketingSitePage = {
  intro: {
    title: 'What is property potential?',
    body: 'The maximum value your home could be worth.',
  },
  sections: [
    {
      title: 'What is property potential?',
      body: 'The median home has an additional 2,010 buildable square feet and an untapped $175,900 of potential value. You can tap into that value by understanding:',
      navItem: {
        title: 'What is property potential?',
        slug: 'what-is-property-potential',
      },
      cardBackgroundColor: 'tangerine-fine',
      contentType: 'list',
      content: {
        items: [
          'How many additional square feet are you allowed to add?',
          'How much could you earn from renting out your extra space?',
          'How could you optimize your living space?',
          'How much equity is available for you to access?',
        ],
        footer: {
          title: 'Here’s why it’s a big deal.',
          body: 'Your home is likely your single biggest asset. If you make the right decisions, it can make you happy and make you money. But it all starts with understanding what’s possible.',
        },
      },
    },
    {
      title: 'What impacts potential',
      body: 'When calculating potential, we aggregate publicly available data from past real estate transactions, tax assessments, and building permits. We’ve also researched your local city policies which allows us to map out the specific number of square feet you’re allowed to develop. The data that most impacts potential is:',
      cardBackgroundColor: 'transparent',
      contentType: 'cards',
      content: {
        cards: [
          {
            title: 'Property History',
            body: 'What you paid for the property and things you’ve upgraded since living there.',
          },
          {
            title: 'Neighboring Homes',
            body: 'What your neighbors have done to their properties and how that has impacted their home value.',
          },
          {
            title: 'Possibilities',
            body: 'What projects and improvements you are allowed to do based on your local regulations.',
          },
        ],
      },
    },
    {
      title: 'It’s all designed to help you get more out of your property.',
      navItem: {
        title: 'Strategies',
        slug: 'strategies',
      },
      body: 'But not everyone’s goals are the same. Are you moving soon and want to sell your home for as much as possible? Do you want to make your home more enjoyable? To achieve your goal, you need the right strategy.',
      cta: { title: 'Enter your address to explore these strategies for your property', href: '/users/sign_up' },
      cardBackgroundColor: 'transparent',
      contentType: 'cards',
      content: {
        cards: [
          {
            title: 'Maximize home value',
            image: {
              key: 'maximize',
              alt: 'Maximize home value',
            },
          },
          {
            title: 'Improve living space',
            image: {
              key: 'living-space',
              alt: 'Improve living space',
            },
          },
          {
            title: 'Earn rental income',
            image: {
              key: 'rental',
              alt: 'Earn rental income',
            },
          },
          {
            title: 'Create the best backyard',
            image: {
              key: 'backyard',
              alt: 'Create the best backyard',
            },
          },
        ],
      },
    },
    {
      title: 'Property potential’s best-kept secret: equity.',
      navItem: {
        title: 'Equity',
        slug: 'equity',
      },
      body: 'For most Americans, home equity makes up over half of their total assets. That’s why using your home equity is usually the most affordable way to access funds for projects. ',
      cardBackgroundColor: 'transparent',
      contentType: 'text',
      content: {
        footer: `But what’s the right amount to access without driving up your interest rate? Figuring this out sounds more difficult than it is, so we’re here to help.`,
        cta: {
          title: 'Check out your Project Planner for personalized insights.',
          href: '/users/sign_up',
        },
      },
    },
    {
      title: 'Once you’ve chosen a strategy, it’s time to get specific.',
      navItem: {
        title: 'Projects',
        slug: 'projects',
      },
      cardBackgroundColor: 'transparent',
      contentType: 'accordion',
      content: {
        items: projectsItems,
      },
    },
    {
      title: 'FAQ',
      navItem: {
        title: 'FAQ',
        slug: 'faq',
      },
      cardBackgroundColor: 'transparent',
      contentType: 'faq',
    },
  ],
}

export default learnContent
