import React, { FC } from 'react'

import AddressAutocomplete from 'components/MarketingSite/Header/AddressAutocomplete'

import styles from './styles.module.scss'

const GetRealmScoreCTA: FC = () => (
  <div className={styles.getRealmScoreCTA} data-hide-on="logged-in">
    <div className={styles.details}>
      <h2 className={styles.title}>{'See what your home could be worth'}</h2>
      <p className={styles.note}>{'We currently cover most standalone, single-family homes'}</p>
    </div>
    <AddressAutocomplete className={styles.autocomplete} placeholder="Enter an address" positionLabel="hero" />
  </div>
)

export default GetRealmScoreCTA
