import React, { FC, useCallback, useMemo } from 'react'
import cx from 'classnames'

import { Link as PrismicLink } from 'prismic-reactjs'

import { useTrackClick } from '../../index'

import Arrow from 'svgs/arrow'

import styles from './styles.module.scss'

interface Props {
  className?: string
  text?: string
  targetUrl?: PrismicLink
  targetRelativeUrl?: string
  targetId?: string
  trackingCode?: string
  trackingSection?: string
  trackingFeature?: string
  trackingFamily?: string
}
const NavLink: FC<Props> = ({
  className,
  text,
  targetUrl,
  targetRelativeUrl,
  targetId,
  trackingCode,
  trackingSection,
  trackingFeature,
  trackingFamily,
}) => {
  const href = useMemo(
    () => (targetUrl?.url ? targetUrl.url : targetRelativeUrl ? targetRelativeUrl : targetId ? `#${targetId}` : ''),
    [targetUrl, targetRelativeUrl, targetId]
  )
  const target = useMemo(() => (targetUrl?.url ? targetUrl.target : ''), [targetUrl])

  const trackClick = useTrackClick()
  const handleClick = useCallback(() => {
    // NavLink is always an internal-page fragment link, so we don't need
    // to wait for the tracking to finish, and can let the anchor function
    // normally.
    if (!trackingCode) return
    trackClick({
      code: trackingCode,
      section: trackingSection,
      feature: trackingFeature,
      family: trackingFamily,
    })
  }, [trackClick, trackingCode, trackingSection, trackingFeature, trackingFamily])

  // no render if no href.
  if (!href) {
    return null
  }

  return (
    <div className={cx(styles.navLink, className, { [styles.ctaButton]: targetUrl || targetRelativeUrl })}>
      <a className={styles.link} href={href} target={target} onClick={handleClick}>
        {text && <span className={styles.text}>{text}</span>}
        <Arrow className={styles.arrow} />
      </a>
    </div>
  )
}

export default NavLink
