import classnames from 'classnames'
import React, { FC } from 'react'

import { ContentCards } from 'components/MarketingSite/PageContent/types'

import ImgBackyard from 'images/learn/strategies/backyard.png'
import ImgBackyard2x from 'images/learn/strategies/backyard@2x.png'
import ImgLivingSpace from 'images/learn/strategies/living-space.png'
import ImgLivingSpace2x from 'images/learn/strategies/living-space@2x.png'
import ImgMaximize from 'images/learn/strategies/maximize.png'
import ImgMaximize2x from 'images/learn/strategies/maximize@2x.png'
import ImgRental from 'images/learn/strategies/rental.png'
import ImgRental2x from 'images/learn/strategies/rental@2x.png'

import ImgPotentialValue from 'images/homepage/illustrations/potential-value.png'
import ImgRealScore from 'images/homepage/illustrations/real-score.png'
import ImgPersonalizedAdvice from 'images/homepage/illustrations/personalized-advice.png'

import styles from './styles.module.scss'

const Cards: FC<ContentCards> = ({ cards }) => (
  <div className={classnames(styles.container, { [styles.twoUp]: cards.length % 2 === 0 })}>
    {cards.map((card) => (
      <div className={styles.card} key={card.title}>
        <h4 className={styles.title}>{card.title}</h4>
        {card.image != null && (
          <div className={styles.image}>
            <img
              src={imageSrc(card.image.key)?.[0]}
              alt={card.image.alt}
              srcSet={imageSrc(card.image.key)?.[1] ? `${imageSrc(card.image.key)?.[1]} 2x` : undefined}
            />
          </div>
        )}
        {card.body && <p className={styles.body}>{card.body}</p>}
      </div>
    ))}
  </div>
)

const imageSrc = (image) => {
  switch (image) {
    case 'backyard':
      return [ImgBackyard, ImgBackyard2x]
    case 'living-space':
      return [ImgLivingSpace, ImgLivingSpace2x]
    case 'maximize':
      return [ImgMaximize, ImgMaximize2x]
    case 'rental':
      return [ImgRental, ImgRental2x]
    case 'potential-value':
      return [ImgPotentialValue]
    case 'real-score':
      return [ImgRealScore]
    case 'personalized-advice':
      return [ImgPersonalizedAdvice]
  }
}

export default Cards
