import React, { FC, useMemo } from 'react'

import { MediaTestimonial } from '../../types'

import PresentRichText from 'pages/_serverRendered/CmsPage/Section/Components/PresentRichText'

import Image from './Image'

import styles from './styles.module.scss'

interface Props {
  testimonials: Array<MediaTestimonial>
}
const Testimonials: FC<Props> = ({ testimonials }) => {
  // Split testimonials in two groups (columns)
  const groups = useMemo(() => {
    return testimonials.reduce(
      (acc, item, i) => {
        acc[Math.floor(i / Math.ceil(testimonials.length / 2))].push(item)
        return acc
      },
      [[], []] as [Array<MediaTestimonial>, Array<MediaTestimonial>]
    )
  }, [testimonials])
  return (
    <ul className={styles.testimonials}>
      {groups.map((group, i) => (
        <li key={i} className={styles.group}>
          <ul>
            {group.map((testimonial, i) => (
              <li key={i} className={styles.testimonial}>
                <Image className={styles.image} image={testimonial.logo} />
                <PresentRichText className={styles.quote} render={testimonial.quote} />
              </li>
            ))}
          </ul>
        </li>
      ))}
    </ul>
  )
}

export default Testimonials
