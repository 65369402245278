import React, { FC } from 'react'

import { Advisor } from '../types'

import InfoIcon from 'svgs/info-filled'

import CoreText from 'components/Core/CoreText'
import CoreDivider from 'components/Core/CoreDivider'
import LocalCard from '../components/LocalCard'

import ContactInfo from './ContactInfo'

import styles from './styles.module.scss'

interface Props {
  advisor: Advisor
  screen: string
  message?: string
}
const HouseholdDetails: FC<Props> = ({ advisor, screen, message }) => {
  return (
    <LocalCard className={styles.pointOfContact}>
      {message && (
        <>
          <div className={styles.message}>
            <div className={styles.iconWrapper}>
              <InfoIcon />
            </div>
            <CoreText.Paragraph color="primaryBlack">{message}</CoreText.Paragraph>
          </div>
          <CoreDivider.Full className={styles.divider} />
        </>
      )}
      <CoreText.Paragraph className={styles.title} size="l" weight="heavy" color="primaryBlack">
        {'Realm Point of Contact'}
      </CoreText.Paragraph>
      <ContactInfo
        className={styles.contactInfo}
        advisor={advisor}
        screen={screen}
        section="point-of-contact-details"
      />
    </LocalCard>
  )
}

export default HouseholdDetails
