import classnames from 'classnames'
import React, { FC } from 'react'

import styles from './styles.module.scss'

interface Props {
  children: React.ReactNode
  anchorId?: string
  className?: string
}

const Section: FC<Props> = ({ children, anchorId, className }) => (
  <section id={anchorId} className={classnames(className, styles.section)}>
    {anchorId ? <div className={styles.anchor} id={`${anchorId}_point`} /> : null}
    {children}
  </section>
)

export default Section
