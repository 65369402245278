import React, { FC, useState, useCallback, useEffect } from 'react'
import cx from 'classnames'

import { trackPage, UTM_PARAMS_SESSION_KEY } from 'utils/analytics'
import { titleCase } from 'utils/formatting'
import { ProjectTemplate } from 'recoil/projectTemplates'

import RealmLogo, {
  RealmLogoSmall,
  RealmLogoPride,
  RealmLogoSmallPride,
} from 'components/MarketingSite/Header/RealmLogo'

import ProjectList from './ProjectList'
import SelectedProjectList from './SelectedProjectList'
import CtaSection from './CtaSection'
import Calculations from './Calculations'
import QRCode from './QRCode'

import styles from './styles.module.scss'

interface UtmParams {
  utm_medium?: string
  utm_source?: string
  utm_campaign?: string
  utm_content?: string
}

interface Props {
  property: { address: string; city: string; state: string; zip: string }
  attom_id: string
  project_templates: Array<ProjectTemplate>
}

const ProjectPricing: FC<Props> = ({ property, project_templates: projectTemplates }) => {
  const [selectedProjects, setSelectedProjects] = useState<Array<ProjectTemplate>>([])
  const [utmParams, setUtmParams] = useState<UtmParams | null>(null)

  useEffect(() => {
    const sUtmParams = sessionStorage?.getItem(UTM_PARAMS_SESSION_KEY)
    if (sUtmParams) {
      // We only need medium, if it exists.
      const utmParams = JSON.parse(sUtmParams)
      if (utmParams) {
        setUtmParams(utmParams)
      }
    }
  }, [])

  // Track the page
  useEffect(() => {
    trackPage('project_pricing')
  }, [])

  const handleSelectedProjectsChange = useCallback(
    (selectedIds: Array<number>) => {
      setSelectedProjects(
        selectedIds.reduce((memo, id) => {
          const selProject = projectTemplates.find((project) => project.id == id)
          if (!selProject) return memo
          return memo.concat([selProject])
        }, [] as Array<ProjectTemplate>)
      )
    },
    [projectTemplates]
  )

  const renderQRCode = useCallback(() => <QRCode className={styles.qrCode} />, [])
  let redirectUrl =
    'https://app.adjust.com/uqpj0b8?redirect_macos=https://apps.apple.com/us/app/realm-home/id1589251076&redirect_windows=https://apps.apple.com/us/app/realm-home/id1589251076'
  if (utmParams) {
    if (utmParams.utm_source) redirectUrl += `&adgroup=${encodeURIComponent(utmParams.utm_source)}`
    if (utmParams.utm_campaign) redirectUrl += `&campaign=${encodeURIComponent(utmParams.utm_campaign)}`
    if (utmParams.utm_content) redirectUrl += `&creative=${encodeURIComponent(utmParams.utm_content)}`
  }
  return (
    <div className={styles.projectPricing}>
      <section className={styles.primary}>
        <header>
          {process.env.PRIDE_MODE == 'true' ? (
            <>
              <RealmLogoPride className={cx(styles.logo, styles.pride, styles.desktop)} />
              <RealmLogoSmallPride className={cx(styles.logo, styles.pride, styles.mobile)} />
            </>
          ) : (
            <>
              <RealmLogo className={cx(styles.logo, styles.desktop)} />
              <RealmLogoSmall className={cx(styles.logo, styles.mobile)} />
            </>
          )}
        </header>
        <div className={styles.content}>
          <h1>{'What renovations are you interested in?'}</h1>
          <h3>{`Personalized pricing estimates for ${titleCase(property.address)}`}</h3>
          <ProjectList
            projects={projectTemplates}
            selectedProjects={selectedProjects.map((project) => project.id)}
            onChange={handleSelectedProjectsChange}
          />
        </div>
      </section>
      <section className={styles.calculations}>
        <div className={styles.selectedPlan}>
          <h4>{'Your renovation plan'}</h4>
          <SelectedProjectList selectedProjects={selectedProjects} />
        </div>
        <CtaSection className={styles.cta} redirectUrl={redirectUrl} redirectQR={renderQRCode}>
          {'Customize your renovations for more precise pricing with the Realm app.'}
        </CtaSection>
        <Calculations className={styles.total} selectedProjects={selectedProjects} />
      </section>
    </div>
  )
}

export default ProjectPricing
