import AddressAutocompleteField from 'pages/_serverRendered/CmsPage/AddressAutocompleteField'
import ArrowCircleDown from 'svgs/arrow-circle-down'
import BarChartSimple from 'components/BarChartSimple'
import Comparison from 'components/MarketingAndPropertyPages/Compare/Comparison'
import { formatAddressCasing, formatCurrency, formatPublicPropertyAddress2nd } from 'utils/formatting'
import Footer from 'components/MarketingSite/Footer'
import Header, { calculateNavItems } from 'components/MarketingSite/Header'
import { PublicPropertyPotential, PublicPropertyFullRelativeStats } from 'recoil/publicProperty'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import Modal, { ModalButton } from 'components/NewModal'
import OverlayMount from 'layouts/components/OverlayMount'
import { trackPage } from 'utils/analytics'
import { StyledText } from 'components/MarketingAndPropertyPages/StyledRichText'

import styles from './styles.module.scss'
import { PublicPropertyAvatar } from 'components/PropertyAvatar'
import { GooglePlacesUnavailableResult, searchGooglePlaces } from 'apis/googlePlaces'

interface HomeValuesNearMeProps {
  public_property: {
    avm: AttomAvm
    potential: PublicPropertyPotential
    public_property: AttomTaxAssessor
    full_relative_stats: PublicPropertyFullRelativeStats
  }
  sample: boolean
}

const HomeValuesNearMe: FC<HomeValuesNearMeProps> = ({ public_property: publicProperty, sample: isSample }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [unsupportedResponse, setUnsupportedResponse] = useState<GooglePlacesUnavailableResult>()

  const handleModalClose = useCallback(() => {
    setUnsupportedResponse(undefined)
    setIsLoading(false)
  }, [])

  const handlePredictionSelected = useCallback(async (queryParams: Record<string, string>) => {
    setIsLoading(true)
    const queryString = new URLSearchParams(queryParams).toString()
    const response = await searchGooglePlaces({ queryString })
    if (response.unavailable) {
      setUnsupportedResponse(response)
    } else {
      window.location.href = response.public_property_path
    }
  }, [])

  const modalButtons = useMemo(() => {
    const result: ModalButton[] = [{ name: 'Try another property' }]
    if (unsupportedResponse && unsupportedResponse.public_property_path) {
      result.push({ name: 'View property details', href: unsupportedResponse.public_property_path, secondary: true })
    }
    return result
  }, [unsupportedResponse])

  useEffect(() => {
    trackPage('Compare')
  }, [])

  return (
    <OverlayMount>
      <div className={styles.homeValuesNearMe}>
        <Header activeNavItem={calculateNavItems().Compare} />
        <div className={styles.pageContent}>
          <div className={styles.sidebar}>
            <div className={styles.intro}>
              <h3 className={styles.introTitle}>{'How does your home compare?'}</h3>
              <p>
                {
                  'Use our neighborhood calculator to explore home values near you and see how your current and potential value stacks up to the 100 houses nearest to you.'
                }
              </p>
              <AddressAutocompleteField
                containerClassName={styles.addressAutocompleteFieldContainer}
                className={styles.addressAutocompleteField}
                buttonLabelLong="View property"
                buttonLabelShort="View property"
                onPredictionSelected={handlePredictionSelected}
                placeholder="8100 Maitland Ave"
                positionLabel="sidebar"
                readOnly={isLoading}
                variant="compare"
              />
              <p className={styles.mutedIntro}>
                <span>
                  {
                    'We currently cover standalone, single family homes in all 50 states, but not in every county. Check our '
                  }
                </span>
                <a
                  href="/resources/library/realm-now-available-for-80m-single-family-homes/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {'coverage map'}
                </a>
                <span>{' for more details on this county.'}</span>
              </p>
            </div>
            {isSample && (
              <div className={styles.sampleProperty}>
                <h3>{'Sample Property'}</h3>
                {'Find out how this home compares by entering a property address above.'}
              </div>
            )}
          </div>
          <section className={styles.section}>
            <header className={styles.propertyHeader}>
              <div>
                <h3>{formatAddressCasing(publicProperty.public_property.propertyaddressfull)}</h3>
                <p>{formatPublicPropertyAddress2nd(publicProperty.public_property)}</p>
              </div>
              <PublicPropertyAvatar
                className={styles.cardHighlightHomeImg}
                publicProperty={publicProperty.public_property}
                width={600}
                height={400}
              />
            </header>
            <div className={styles.cardsContainer}>
              {publicProperty.avm.estimatedvalue && publicProperty.potential.potential_value && (
                <>
                  <Comparison
                    ctaHref={
                      isSample ? '/onboarding?n' : `/onboarding?attom_id=${publicProperty.public_property.attom_id}`
                    }
                    label="Value"
                    otherValue={publicProperty.full_relative_stats.top_value || 0}
                    otherTaxAssessor={publicProperty.full_relative_stats.top_value_tax_assessor}
                    position={publicProperty.full_relative_stats.value_position || 0}
                  >
                    <BarChartSimple
                      className={styles.barChartSimple}
                      barClassName={styles.barChartSimpleBar}
                      valueLabel={formatCurrency(publicProperty.avm.estimatedvalue)}
                      value={publicProperty.avm.estimatedvalue}
                      totalLabel=""
                      total={publicProperty.potential.potential_value}
                    />
                    <p>{'Our estimate of the value of this home today based on hundreds of attributes.'}</p>
                  </Comparison>
                  <Comparison
                    ctaHref={
                      isSample ? '/onboarding?n' : `/onboarding?attom_id=${publicProperty.public_property.attom_id}`
                    }
                    label="Potential"
                    otherValue={publicProperty.full_relative_stats.top_potential || 0}
                    otherTaxAssessor={publicProperty.full_relative_stats.top_potential_tax_assessor}
                    position={publicProperty.full_relative_stats.potential_position || 0}
                  >
                    <BarChartSimple
                      className={styles.barChartSimple}
                      barClassName={styles.barChartSimpleBar}
                      valueLabelClassName={styles.potentialValueLabel}
                      totalLabelClassName={styles.blackTotalLabel}
                      valueLabel={
                        publicProperty.potential.potential_value
                          ? formatCurrency(publicProperty.avm.estimatedvalue)
                          : ''
                      }
                      value={100}
                      totalLabel={formatCurrency(publicProperty.potential.potential_value)}
                      total={100}
                    />
                    <p>{'Our estimate of how much this home could be worth with various upgrades.'}</p>
                  </Comparison>
                </>
              )}
              <div className={styles.specialCard}>
                <a
                  href={isSample ? '/onboarding?n' : `/onboarding?attom_id=${publicProperty.public_property.attom_id}`}
                >
                  {'Learn more about this property'}
                  <ArrowCircleDown />
                </a>
              </div>
            </div>
            <p className={styles.rank}>
              {
                'Think you should rank higher? After creating a free account, you can share more details about this property and we will recalculate your ranking. Our data sources are refreshed on a daily basis.'
              }
            </p>
            <StyledText className={styles.copy}>
              <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                <h2 itemProp="name" id="why-do-home-values-near-me-matter">
                  {'Why do home values near me matter?'}
                </h2>
                <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                  <div itemProp="text">
                    <p>
                      {
                        'A home is a huge financial investment and getting the most out of that investment depends on you understanding your home value — and the factors that impact it. Even if you do everything you can to maximize the value of your own home, there are still external factors like location, crime statistics, and yes, your neighbors that can influence how much your home is worth. Yup, that’s right. Home values near you can affect how much your home is worth. '
                      }
                    </p>
                    <p>
                      {
                        'But why do your neighbors’ homes matter? Let’s explore the ways that nearby home values can play into your home’s potential valuation.'
                      }
                    </p>
                  </div>
                </div>
              </div>
              <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                <h3 itemProp="name" id="what-is-home-value">
                  {'What is home value?'}
                </h3>
                <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                  <div itemProp="text">
                    <p>
                      {
                        'Essentially, your home value is how much the property is worth, but this is a flexible and variable figure that changes over time. So how is this number calculated? Of course, certain factors like the house’s age, appearance, location, and amenities all play into figuring out home value. But you also want to keep in mind who exactly is figuring out the value because that can also impact the number. '
                      }
                    </p>
                    <p>
                      {
                        'Home value is typically measured in three different ways. First, there is the fair market value which, simply put, is how much you would get for your home if you sold it on the open market. Secondly comes appraised value, which mortgage lenders use to figure out just how much you can borrow. This is often a lower figure than the fair market value and involves an inspection by a professional appraiser. Lastly, your home’s assessed value is determined by your local tax assessor. This, too, is typically a lower number than the fair market value, and it is used to figure out just how much you’ll owe in property taxes. '
                      }
                    </p>
                    <p>
                      {
                        'But why does all this matter, especially if you aren’t thinking about selling your home? The reality is that for most Americans, their home represents 62% of their total financial assets. Knowing your home value can give you a good idea of how much money you may get if you sell or how much money you might be able to access by refinancing or borrowing against your equity. '
                      }
                    </p>
                    <p>
                      {
                        'Luckily, unlike most large purchases, like a car which rapidly depreciates, homes are likely to appreciate over time. Knowing your home’s value can help you use your asset as a financial tool. For example, say you want to renovate your property. You could '
                      }
                      <a href="https://realmhome.com/resources/library/should-i-refinance-my-mortgage">
                        {'refinance the mortgage'}
                      </a>
                      {
                        ' or obtain a home equity line of credit to secure the funds to do so. This allows you to use the current value of the home to your advantage. Not to mention that a renovation could add value to the home over time — further increasing your asset. Realm’s free dashboard can help you figure out '
                      }
                      <a href="https://realmhome.com/resources/library/home-renovation-costs">
                        {'how much a project will cost'}
                      </a>
                      {' and just '}
                      <a href="https://realmhome.com/resources/library/how-much-is-your-home-really-worth">
                        {'how much value'}
                      </a>
                      {' you would add after the renovation is complete. '}
                    </p>
                  </div>
                </div>
              </div>
              <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                <h3 itemProp="name" id="what-impacts-home-value">
                  {'What impacts home value?'}
                </h3>
                <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                  <div itemProp="text">
                    <p>
                      {
                        'Since purchasing a home is such a significant financial milestone for most people, knowing what will impact the present and future value of your home can give you a good idea of what to expect down the line.'
                      }
                    </p>
                    <p>
                      {
                        'Depending on how long you’ve owned your home, the past resale value might be more or less relevant. If you purchased recently, this might weigh more heavily, but either way, the home’s sale history can give you an idea of how attractive your local real estate market is to buyers. '
                      }
                    </p>
                    <p>
                      {
                        'Within your property, age and condition of the house matter. A home’s layout and design, as well as the quality of materials used in construction, all play a part. Peeling paint, warped siding, and cracked foundations are all issues that can be easily fixed without a massive investment. However, even if a home is somewhat older, a pristine exterior, attractive landscaping, and a modern interior will do quite a bit for the property’s overall value. Staying up to date on basic home maintenance is one way to keep your home’s value rising. '
                      }
                    </p>
                    <p>
                      {
                        'The size of and amenities within the home are also crucial factors. The home’s square footage has a large impact, as does how the space is used. Things like a finished attic or basement can be attractive to buyers and add value to smaller homes that are seeking to maximize their square footage. The home’s appliances are also factored in. If by some miracle your avocado green fridge from the 1970s is still running and you’re looking for ways to boost your home value, it might be time for a newer model. '
                      }
                    </p>
                    <p>
                      {
                        'Outside the property lines, location plays a crucial role in your home value. If you’re living in a large city, you’re likely in a highly desirable market, and your home will be worth more than if it’s in a sparsely populated area. Things like the quality of public transit or ease of access to shopping and entertainment are often better in larger cities. However, if you live in a city that is prone to floods, fires, or other natural disasters, your home values might suffer a bit, even if it’s a popular location. '
                      }
                    </p>
                    <p>
                      {
                        'On a more micro level, the specifics of your neighborhood can also affect home values. What are the crime rates like? Is the school district highly sought after? Taxes can also have a variable effect. Although higher property taxes can indicate a good neighborhood (since the money supports local infrastructure, schools, fire departments, etc.), if the rates get too high, it can send buyers looking for lower-cost options elsewhere. '
                      }
                    </p>
                    <p>
                      {
                        'Finally, the sale prices of similar homes in the area can also increase or decrease your home value. If your home is in a hot real estate market with nearby homes getting snapped up left and right, this is likely to boost your value, while areas with slower selling, less popular properties could see a slight depression. '
                      }
                    </p>
                  </div>
                </div>
              </div>
              <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                <h3 itemProp="name" id="how-do-home-values-near-me-affect-my-home-value">
                  {'How do home values near me affect my home value?'}
                </h3>
                <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                  <div itemProp="text">
                    <p>
                      {
                        'Your neighbors’ home values are important because they impact your own home value. Small things like an unkempt lawn here or there are not likely to cause a long-lasting drop in your home value. But if you’re the only maintained home on the block, that’s likely to have a negative effect on your property’s valuation. Although it may not seem fair, this is due to the external factors that are considered when calculating a home’s value. A run-down neighborhood is not going to be attractive to potential buyers, so the houses will, naturally, score far lower than homes on a manicured cul-de-sac a few short miles away.'
                      }
                    </p>
                    <p>
                      {
                        'Beyond mild disrepair, if your neighbors are suffering financially, you might see your property value take a hit. The surest sign of this is foreclosures. If foreclosures are cropping up in your neighborhood, this probably signals a decline in your home value. If the foreclosed structure is also vacant or abandoned, this will amplify the negative consequences. '
                      }
                    </p>
                    <p>
                      {
                        'Aside from cosmetic and financial problems, if some of your neighbors have criminal backgrounds, it could take a bite out of your home value. Even if your neighbors haven’t run afoul with the law, if they tend to host loud parties and raucous events, this can also damage the resale value of your home. Many neighborhoods have homeowners’ associations (HOAs) to address these issues directly. '
                      }
                    </p>
                  </div>
                </div>
              </div>
              <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                <h3 itemProp="name" id="what-do-comps-reveal-about-home-values-near-me">
                  {'What do comps reveal about home values near me?'}
                </h3>
                <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                  <div itemProp="text">
                    <p>
                      {
                        'When you start exploring your home value, a term that will likely surface is a real estate comp, or comparable. This refers to recently sold homes in your specific area that are comparable to your home. A comp will have similar statistics to your own home, like being of a similar age, having the same number of bedrooms and bathrooms, or the same square footage. Comps are mainly used to determine your home’s value at the moment, so it’s important to use comps that have sold recently. A few months can make a big difference, especially since seasonal buying is a very real trend; summer markets tend to be hotter than the winter months (no pun intended). '
                      }
                    </p>
                    <p>
                      {
                        'Naturally, since all homes have unique quirks and features, a comp can’t be the same as your home in every way, and comps can have different square footage or be in different conditions. What really matters is the proximity to your home and just how long ago it was sold. Comps can give you a realistic idea of what your home is worth in the current market. '
                      }
                    </p>
                  </div>
                </div>
              </div>
              <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                <h3 itemProp="name" id="how-does-realm-use-comps">
                  {'How does Realm use comps?'}
                </h3>
                <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                  <div itemProp="text">
                    <p>
                      {
                        'As mentioned earlier, there are a multitude of ways to figure out your home’s value. You can consult an appraiser or rely on the tax assessor, but either way you go, you’re facing different motivations and predispositions that can either artificially inflate or deflate the value. Rather than rely on a certain individual’s opinions, Realm uses an automated valuation model (AVM), which calculates your home value by aggregating large amounts of data. Because it relies on data, Realm’s AVM removes human biases and subjective opinions from the equation. '
                      }
                    </p>
                    <p>
                      {
                        'This doesn’t mean that comps are irrelevant with Realm’s AVM. On the contrary, Realm uses far more data than a traditional appraiser would, gathering over 600 data points per property to determine your home’s up-to-date value. Realm’s AVM takes recent sales and adjacent property values into account when determining your home’s value. Your free Realm dashboard can also show you comparable homes and their value. '
                      }
                    </p>
                  </div>
                </div>
              </div>
            </StyledText>
          </section>
        </div>
        <Footer />
        {unsupportedResponse && (
          <Modal title="Property not supported" buttons={modalButtons} onClose={handleModalClose}>
            <p>
              {"We're currently working on supporting all our properties to compare. Check back as we continue to add!"}
            </p>
          </Modal>
        )}
      </div>
    </OverlayMount>
  )
}

export default HomeValuesNearMe
