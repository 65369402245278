import React, { FC } from 'react'

import { SliceBackers } from 'pages/_serverRendered/CmsPage/types'
import Card from 'pages/_serverRendered/CmsPage/Section/Components/Card'
import cx from 'classnames'
import { hasTextPresent } from 'pages/_serverRendered/CmsPage/Section/Components/PresentRichText'
import styles from './styles.module.scss'
import { thumbnailUrl } from 'pages/_serverRendered/CmsPage/utils'

interface BackersProps {
  className?: string
  id?: string
  slice: SliceBackers
}

const Backers: FC<BackersProps> = ({ className, id, slice }) => {
  return (
    <Card id={id} backgroundColor={slice.primary.background_color} className={cx(styles.backers, className)}>
      {hasTextPresent(slice.primary.title1) && <h2 className={styles.h2}>{slice.primary.title1[0].text}</h2>}
      <div className={styles.backersBackers}>
        {slice.items.map((e) =>
          e.link.link_type === 'Web' && e.logo.url ? (
            <a key={e.logo.url} href={e.link.url} target={e.link.target}>
              <img src={thumbnailUrl(e.logo.url)} alt={e.logo.alt || ''} />
            </a>
          ) : e.logo.url ? (
            <img key={e.logo.url} src={thumbnailUrl(e.logo.url)} alt={e.logo.alt || ''} />
          ) : null
        )}
      </div>
    </Card>
  )
}

export default Backers
