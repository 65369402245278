import React, { forwardRef, ForwardRefRenderFunction } from 'react'
import cx from 'classnames'

import OldInput, { InputProps } from 'components/Input'

import styles from './styles.module.scss'

/* This was the input created for the new CamV2 onboarding flow.
   As anticipated, and since it has now shown up in a separate location
   I'm going to assume this is a slow migration to this as the new design.
   Therefore it has been advanced to '/components', and I'm giving it a
   memorable name, with the idea that eventually it will completely replace
   our current Input.
*/
export type { InputProps }

const Input: ForwardRefRenderFunction<HTMLInputElement, InputProps> = ({ className, ...props }, ref) => (
  <OldInput ref={ref} className={cx(styles.input, className)} {...props} />
)

export default forwardRef(Input)
