import React, { FC } from 'react'

import { SlicePhotoCard } from 'pages/_serverRendered/CmsPage/types'
import PresentRichText from 'pages/_serverRendered/CmsPage/Section/Components/PresentRichText'
import styles from './styles.module.scss'
import { thumbnailUrl } from 'pages/_serverRendered/CmsPage/utils'

const PhotoCard: FC<SlicePhotoCard> = ({ items }) => {
  return (
    <>
      {items.map((e, i) => (
        <div className={styles.item} key={i}>
          {e.image.url && <img alt={e.image.alt || ''} src={thumbnailUrl(e.image.url)} />}
          <div>
            <h3>{e.title[0].text}</h3>
            <PresentRichText render={e.description} />
          </div>
        </div>
      ))}
    </>
  )
}

export default PhotoCard
