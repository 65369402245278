import * as React from 'react'

import AverageHomeValue from 'pages/_serverRendered/PublicNeighborhood/Statistics/AverageHomeValue'
import AverageHomeValuePotentialIncrease from 'pages/_serverRendered/PublicNeighborhood/Statistics/AverageHomeValuePotentialIncrease'
import AverageBuildableSquareFeet from 'pages/_serverRendered/PublicNeighborhood/Statistics/AverageBuildableSquareFeet'
import AverageSalePrice from 'pages/_serverRendered/PublicNeighborhood/Statistics/AverageSalePrice'
import ChartContainer from 'pages/_serverRendered/PublicNeighborhood/ChartContainer'

import styles from './styles.module.scss'
import { AggregateStats } from 'recoil/publicNeighborhood'

interface StatisticsProps {
  name: string
  stats: AggregateStats
}

const Statistics: React.FC<StatisticsProps> = ({ name, stats }) => {
  return (
    <div className={styles.container}>
      <h2 className={styles.title}>{`${name} Homes By The Numbers`}</h2>
      <ChartContainer className={styles.centerStatsContainer}>
        <div className={styles.centerStats}>
          <AverageHomeValue values={[stats.avm_25th, stats.avm_avg, stats.avm_75th]} />
          <AverageHomeValuePotentialIncrease
            values={[stats.potential_value_25th, stats.potential_value_avg, stats.potential_value_75th]}
          />
        </div>
      </ChartContainer>
      <div className={styles.footerStats}>
        <AverageBuildableSquareFeet value={stats.buildable_sqft_avg} />
        <AverageSalePrice value={stats.sale_price_avg} />
      </div>
    </div>
  )
}
export default Statistics
