import React, { FC } from 'react'
import cx from 'classnames'

import { PreviewItem } from '../../../types'

import PresentRichText from 'pages/_serverRendered/CmsPage/Section/Components/PresentRichText'

import Image from '../../../Components/Image'
import CallToAction from '../../../Components/CallToAction'

import styles from './styles.module.scss'

interface Props {
  className?: string
  items: Array<PreviewItem>
}
const Previews: FC<Props> = ({ className, items }) => {
  return (
    <ul className={cx(styles.previews, className)}>
      {items.map((item, i) => {
        const rightToLeft = item.image_placement.indexOf('Right') != -1
        const imgTop = item.image_placement.indexOf('Top') != -1

        return (
          <li key={i} className={cx(styles.preview, { [styles.rightToLeft]: rightToLeft, [styles.dockTop]: imgTop })}>
            <div className={styles.imgHolder}>
              <Image className={styles.image} image={item.image} />
            </div>
            <div className={styles.information}>
              <PresentRichText className={styles.description} render={item.description} />
              <CallToAction
                className={styles.cta}
                url={item.cta_url}
                url_relative={item.cta_relative_url}
                trackingCode={item.cta_tracking_code}
                trackingSection={item.cta_tracking_section}
                trackingFeature={item.cta_tracking_feature}
                trackingFamily={item.cta_tracking_family}
              >
                {item.cta_text}
              </CallToAction>
            </div>
          </li>
        )
      })}
    </ul>
  )
}

export default Previews
