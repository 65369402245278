import React, {
  FC,
  PropsWithChildren,
  FormEvent,
  useState,
  useCallback,
  useEffect,
  ReactNode,
  SetStateAction,
  useMemo,
} from 'react'
import Check from '@mui/icons-material/Check'
import Close from '@mui/icons-material/Close'
import { SignUpResponse, UserStub } from 'recoil/user'
import { identify, track } from 'utils/analytics'
import { patchFormData, postFormData } from 'utils/fetch'
import { searchGooglePlaces } from 'apis/googlePlaces'
import Alert from 'pages/_serverRendered/EventSignup/Alert'
import CoreButton from 'components/Core/CoreButton'
import CoreInput from 'components/Core/CoreInput'
import TermsConsentMessage from 'components/TermsConsentMessage'
import Spinner from 'components/Spinner'

export interface SignupFormUserStub {
  id?: number
  full_name: string
  phone_number: string
  email: string
}
class SignupError extends Error {
  errors: { [key: string]: string }

  constructor(errors: { [key: string]: string }) {
    super('Signup error')
    this.name = this.constructor.name
    this.errors = errors

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor)
    } else {
      this.stack = new Error('Signup error').stack
    }
  }
}

interface SignupFormProps extends PropsWithChildren {
  className?: string
  header?: ReactNode
  onSuccess?: (user: SignupFormUserStub, creatingPropertyToken?: string) => void
  emailOptional?: boolean
  addressOptional?: boolean
  alert: { type: 'error' | 'success'; message: string } | null
  setAlert: React.Dispatch<SetStateAction<{ type: 'error' | 'success'; message: string } | null>>
  hideNotes?: boolean
  testMarketplace?: boolean
  sandbox?: boolean
}

const SignupForm: FC<SignupFormProps> = ({
  className,
  header,
  onSuccess,
  children,
  emailOptional,
  addressOptional,
  alert,
  setAlert,
  hideNotes,
  testMarketplace,
  sandbox,
}) => {
  const [formData, setFormData] = useState<FormData | null>(null)
  const [errors, setErrors] = useState<{ [key: string]: string }>({})
  const [fetching, setFetching] = useState(false)
  const [formKey, setFormKey] = useState(Date.now())
  const [placeId, setPlaceId] = useState<string>()
  const [loadingPlace, setLoadingPlace] = useState(false)
  const [marketplace, setMarketplace] = useState<boolean | null>(null)

  const handleSubmit = useCallback((e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setFormData(new FormData(e.target as HTMLFormElement))
  }, [])

  const handleAddressChange = useCallback((prediction: { address: string; place_id: string }) => {
    setPlaceId(prediction?.place_id)
  }, [])

  useEffect(() => {
    if (!placeId) return

    const _ = async () => {
      setLoadingPlace(true)
      const place = await searchGooglePlaces({ placeId })
      setMarketplace(place.marketplace)
      setLoadingPlace(false)
    }
    _()
  }, [placeId])

  const trackNewUser = useCallback(
    async (user: UserStub) => {
      // call identify before tracking the signup event so that we have Realm's User Id already in place.
      await identify(user)

      await track(
        'signup',
        {
          $email: user.email,
          $name: user.full_name,
          address: user.entered_address,
          via: 'form',
          signupDate: user.created_at,
          is_marketplace: marketplace,
        },
        {
          id: 24230,
          args: {
            orderId: `signup-${user.id}`,
            customerId: user.id,
            customerEmail: user.sha1_email,
          },
        }
      )
    },
    [marketplace]
  )

  const simulateNewUser = useCallback(
    (formData: FormData) => {
      if (!onSuccess) return

      const email = formData.get('user[email]')?.toString()
      const full_name = formData.get('user[full_name]')?.toString()
      const phone_number = formData.get('user[phone_number]')?.toString()

      if (!email || !full_name || !phone_number) {
        if (!email) setErrors({ email: 'missing' })
        if (!full_name) setErrors({ full_name: 'missing' })
        if (!phone_number) setErrors({ phone_number: 'missing' })
        return
      }

      const user = { email, full_name, phone_number }
      onSuccess(user)
    },

    [onSuccess]
  )

  const registerNewLead = useCallback(
    async (formData: FormData) => {
      const response = await postFormData('/users/leads', formData)
      if (response.isError) {
        const rawErrors = response.jsonBody as { [key: string]: string[] }
        const newErrors = Object.keys(rawErrors).reduce(
          (memo, key) => {
            memo[key] = rawErrors[key].join(', ')
            return memo
          },
          {} as { [key: string]: string }
        )
        throw new SignupError(newErrors)
      }

      const user = response.jsonBody.user as SignupFormUserStub
      track('event-lead', { $email: user.email, $name: user.full_name })
      if (onSuccess) onSuccess(user, response.jsonBody.pollable_job_token)
    },
    [onSuccess]
  )

  const registerNewUser = useCallback(
    async (formData) => {
      const response = await postFormData('/users', formData)
      if (response.isError) {
        const rawErrors = response.jsonBody as { [key: string]: string[] }
        const newErrors = Object.keys(rawErrors).reduce(
          (memo, key) => {
            memo[key] = rawErrors[key].join(', ')
            return memo
          },
          {} as { [key: string]: string }
        )
        throw new SignupError(newErrors)
      }

      const user = (response.jsonBody as SignUpResponse).user
      trackNewUser(user)
      if (onSuccess) onSuccess(user, response.jsonBody.pollable_job_token)
    },
    [trackNewUser, onSuccess]
  )

  const updateUser = useCallback(
    async (formData) => {
      const response = await patchFormData('/api/v1/users/signup_details/', formData)
      if (response.isError) {
        const rawErrors = response.jsonBody as { [key: string]: string[] }
        const newErrors = Object.keys(rawErrors).reduce(
          (memo, key) => {
            memo[key] = rawErrors[key].join(', ')
            return memo
          },
          {} as { [key: string]: string }
        )
        throw new SignupError(newErrors)
      }

      const user = {
        email: formData.get('user[email]'),
        full_name: formData.get('user[full_name]'),
        phone_number: formData.get('user[phone_number]'),
      }
      if (onSuccess) onSuccess(user)
    },
    [onSuccess]
  )

  useEffect(() => {
    const _ = async () => {
      if (!formData) return
      if (fetching) return

      const email = formData.get('user[email]')?.toString()
      const mode = !email?.length && emailOptional ? 'lead' : 'user'

      try {
        setErrors({})
        setFetching(true)

        await (sandbox
          ? simulateNewUser(formData)
          : mode == 'lead'
            ? registerNewLead(formData)
            : registerNewUser(formData))

        setFormData(null)
        setFetching(false)
        setAlert({ type: 'success', message: 'User added!' })
        setFormKey(Date.now())
      } catch (e) {
        if (e instanceof SignupError) {
          if (e.errors.email == 'has already been taken') {
            sandbox ? simulateNewUser(formData) : updateUser(formData)
          } else {
            setErrors(e.errors)
          }
        } else {
          console.error(e)
          setAlert({ type: 'error', message: 'An error occurred' })
        }
        setFormData(null)
        setFetching(false)
      }
    }
    _()
  }, [
    emailOptional,
    fetching,
    formData,
    registerNewLead,
    registerNewUser,
    setAlert,
    updateUser,
    simulateNewUser,
    sandbox,
  ])

  useEffect(() => {
    let timeout
    if (alert) timeout = setTimeout(() => setAlert(null), 5000)

    return () => clearTimeout(timeout)
  }, [alert, setAlert])

  const addressIcon = useMemo(() => {
    if (!testMarketplace) return

    return loadingPlace ? (
      <Spinner size="sm" />
    ) : marketplace ? (
      <Check className="tw-text-green-500 !tw-text-base" titleAccess="In marketplace" />
    ) : marketplace == false ? (
      <Close className="tw-text-red-500 !tw-text-base" titleAccess="Not in marketplace" />
    ) : undefined
  }, [testMarketplace, loadingPlace, marketplace])

  return (
    <form onSubmit={handleSubmit} className={className} key={formKey}>
      {header}
      <Alert alert={alert} />
      <CoreInput.Address
        label="Address"
        name="user[entered_address]"
        hint={errors.entered_address}
        kind={
          errors.entered_address
            ? 'alert'
            : testMarketplace
              ? marketplace
                ? 'positive'
                : marketplace == false
                  ? 'alert'
                  : 'enabled'
              : 'enabled'
        }
        onChangePrediction={handleAddressChange}
        required={!addressOptional}
        right={addressIcon}
      />
      <CoreInput.Text
        label="Full name"
        name="user[full_name]"
        hint={errors.full_name}
        kind={errors.full_name ? 'alert' : 'enabled'}
        required
      ></CoreInput.Text>
      <CoreInput.Text
        label="Email"
        type="email"
        name="user[email]"
        hint={errors.email}
        kind={errors.email ? 'alert' : 'enabled'}
        required={!emailOptional}
      ></CoreInput.Text>
      <CoreInput.Text
        type="tel"
        label="Phone"
        name="user[phone_number]"
        hint={errors.phone_number || 'We will never share this with third parties.'}
        kind={errors.phone_number ? 'alert' : 'enabled'}
      ></CoreInput.Text>
      {!hideNotes && <CoreInput.TextArea name="user[signup_message]" label="Anything else you want to share?" />}
      <input type="hidden" name="user[completed_profile]" value="true" />
      {children}
      <TermsConsentMessage className="tw-text-disabled-black tw-text-sm" />
      <CoreButton
        className="tw-w-full"
        text={fetching ? 'Submitting...' : 'Submit'}
        type="submit"
        disabled={fetching}
      />
    </form>
  )
}

export default SignupForm
