import React, { FC } from 'react'

interface Props {
  className?: string
}

const Svg: FC<Props> = ({ className }) => (
  <svg className={className} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g fill="currentColor" fillRule="evenodd">
      <path d="M9.37831 15C6.70831 15 1.37831 16.34 1.37831 19V20C1.37831 20.55 1.82831 21 2.37831 21H16.3783C16.9283 21 17.3783 20.55 17.3783 20V19C17.3783 16.34 12.0483 15 9.37831 15Z" />
      <path d="M5.11831 9H13.6483C13.9183 9 14.1383 8.78 14.1383 8.51V8.49C14.1383 8.22 13.9183 8 13.6483 8H13.3783C13.3783 6.52 12.5683 5.25 11.3783 4.55V5.5C11.3783 5.78 11.1583 6 10.8783 6C10.5983 6 10.3783 5.78 10.3783 5.5V4.14C10.0583 4.06 9.72831 4 9.37831 4C9.02831 4 8.69831 4.06 8.37831 4.14V5.5C8.37831 5.78 8.15831 6 7.87831 6C7.59831 6 7.37831 5.78 7.37831 5.5V4.55C6.18831 5.25 5.37831 6.52 5.37831 8H5.11831C4.84831 8 4.62831 8.22 4.62831 8.49V8.52C4.62831 8.78 4.84831 9 5.11831 9Z" />
      <path d="M9.37831 13C11.2383 13 12.7883 11.72 13.2383 10H5.51831C5.96831 11.72 7.51831 13 9.37831 13Z" />
      <path d="M22.3583 6.23L23.2883 5.4L22.5383 4.1L21.3483 4.49C21.2083 4.38 21.0483 4.29 20.8783 4.22L20.6283 3H19.1283L18.8783 4.22C18.7083 4.29 18.5483 4.38 18.3983 4.49L17.2183 4.1L16.4683 5.4L17.3983 6.23C17.3783 6.4 17.3783 6.58 17.3983 6.75L16.4683 7.6L17.2183 8.9L18.4183 8.52C18.5483 8.62 18.6983 8.7 18.8483 8.77L19.1283 10H20.6283L20.8983 8.78C21.0583 8.71 21.1983 8.63 21.3383 8.53L22.5283 8.91L23.2783 7.61L22.3483 6.76C22.3783 6.57 22.3683 6.4 22.3583 6.23ZM19.8783 7.75C19.1883 7.75 18.6283 7.19 18.6283 6.5C18.6283 5.81 19.1883 5.25 19.8783 5.25C20.5683 5.25 21.1283 5.81 21.1283 6.5C21.1283 7.19 20.5683 7.75 19.8783 7.75Z" />
      <path d="M19.7783 10.79L18.9283 11.07C18.8283 10.99 18.7183 10.93 18.5983 10.88L18.4183 10H17.3483L17.1683 10.87C17.0483 10.92 16.9283 10.99 16.8283 11.06L15.9883 10.78L15.4483 11.71L16.1083 12.3C16.0983 12.43 16.0983 12.55 16.1083 12.67L15.4483 13.28L15.9883 14.21L16.8483 13.94C16.9483 14.01 17.0483 14.07 17.1583 14.12L17.3383 15H18.4083L18.5983 14.13C18.7083 14.08 18.8183 14.02 18.9183 13.95L19.7683 14.22L20.3083 13.29L19.6483 12.68C19.6583 12.55 19.6583 12.43 19.6483 12.31L20.3083 11.72L19.7783 10.79ZM17.8783 13.39C17.3883 13.39 16.9883 12.99 16.9883 12.5C16.9883 12.01 17.3883 11.61 17.8783 11.61C18.3683 11.61 18.7683 12.01 18.7683 12.5C18.7683 12.99 18.3683 13.39 17.8783 13.39Z" />
    </g>
  </svg>
)

export default Svg
