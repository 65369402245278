export const HIGHLIGHT_RULES = [
  'zone',
  'ground_floor_buildable_area',
  'ground_floor_remaining',
  'total_buildable_area',
  'stories_remaining',
  'area_for_adu',
  'area_for_shed',
  'area_for_pool',
  'detached_adu_permitted',
]

export const ADU_RULES = [
  'total_adu_per_lot',
  'detached_adu_per_lot',
  'attached_adu_per_lot',
  'accessory_building_height',
  'accessory_building_stories',
  'detached_adu_permitted',
  'detached_max_adu_size',
  'attached_adu_permitted',
  'attached_max_adu_size',
  'adu_rental_permitted',
  'adu_front_yard_setback',
  'adu_side_yard_setback',
  'adu_back_yard_setback',
  'adu_building_setback',
]

export const PRIMARY_RULES = [
  'floor_area_ratio',
  'front_yard_setback',
  'side_yard_interior_setback',
  'side_yard_setback',
  'side_yard_street_setback',
  'back_yard_setback',
  'max_front_yard_setback',
  'max_side_yard_setback',
  'max_side_yard_interior_setback',
  'max_side_yard_street_setback',
  'max_back_yard_setback',
  'combined_side_yard_setback',
  'lot_coverage',
  'height',
  'stories',
  'dwelling_unit_per_acre',
  'min_dwelling_unit_per_acre',
  'second_story_setback',
  'front_garage_setback',
  'side_garage_setback',
  'back_garage_setback',
  'combined_garage_setback',
  'interior_side_garage_setback',
  'street_side_garage_setback',
  'num_dwelling_units',
  'max_dwelling_size',
  'building_coverage',
  'max_impervious_area',
]

export const PERMITTED_RULES = [
  'pool_setback',
  'front_pool_setback',
  'back_pool_setback',
  'side_pool_setback',
  'side_street_pool_setback',
  'pool_building_setback',
  'accessory_lot_coverage',
  'accessory_building_coverage',
  'shed_permitted',
  'deck_permitted',
  'patio_permitted',
  'porch_permitted',
  'accessory_permitted',
  'pool_permitted',
  'garage_conversion_permitted',
  'detached_garage_permitted',
]

export const OTHER_RULES = [
  'date_updated',
  'municode_link',
  'ADU_municode_link',
  'multifamily_front_yard_setback',
  'multifamily_side_yard_setback',
  'multifamily_back_yard_setback',
  'multifamily_lot_coverage',
  'multifamily_floor_area_ratio',
  'multifamily_height',
  'multifamily_combined_side_yard_setback',
  'multifamily_stories',
  'multifamily_side_yard_interior_setback',
  'multifamily_dwelling_unit_per_acre',
  'multifamily_side_yard_street_setback',
  'multifamily_pool_setback',
  'multifamily_building_coverage',
  'multifamily_num_dwelling_units',
]
