import React, { FC } from 'react'
import cx from 'classnames'

import { Footer as FooterType } from '../types'

import LinkCategory from './LinkCategory'
import DownloadCategory from './DownloadCategory'

import Instagram from 'svgs/social/instagram'
import Twitter from 'svgs/social/twitter'
import Facebook from 'svgs/social/facebook'
import LinkedIn from 'svgs/social/linkedin'

import styles from './styles.module.scss'

interface Props extends FooterType {
  className?: string
}
const Footer: FC<Props> = ({ className, footer_body, footer }) => {
  const { show_instagram, show_twitter, show_facebook, show_linkedin } = footer[0]
  return (
    <footer className={cx(styles.footer, className)}>
      <ul className={styles.categories}>
        {footer_body.map((slice, i) =>
          slice.slice_type === 'link_category' ? (
            <LinkCategory key={i} {...slice} />
          ) : slice.slice_type === 'download_category' ? (
            <DownloadCategory key={i} {...slice} />
          ) : null
        )}
      </ul>
      <div className={styles.subfooter}>
        <div className={styles.legal}>
          {`© ${new Date().getFullYear()} Realm. All Rights reserved. `}
          <a href="/privacy">{'Privacy Policy'}</a>
          {' | '}
          <a href="/terms">{'Terms'}</a>
        </div>
        <div className={styles.social}>
          {show_instagram && (
            <a href="https://www.instagram.com/realm/">
              <Instagram />
            </a>
          )}
          {show_twitter && (
            <a href="https://twitter.com/realmhome">
              <Twitter />
            </a>
          )}
          {show_facebook && (
            <a href="https://facebook.com/realmhome">
              <Facebook />
            </a>
          )}
          {show_linkedin && (
            <a href="https://linkedin.com/company/realmhome/">
              <LinkedIn />
            </a>
          )}
        </div>
      </div>
    </footer>
  )
}

export default Footer
