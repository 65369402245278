import React, { FC, useState, useCallback, PropsWithChildren } from 'react'
import cx from 'classnames'

import styles from './styles.module.scss'

interface Props {
  text: string
}
const SubNavigation: FC<PropsWithChildren<Props>> = ({ text, children }) => {
  const [open, setOpen] = useState(false)

  const handleToggleOpen = useCallback(() => setOpen((prev) => !prev), [])

  return (
    <>
      <button type="button" onClick={handleToggleOpen}>
        {text}
      </button>
      <ul className={cx(styles.subNavigation, { [styles.open]: open })}>{children}</ul>
    </>
  )
}

export default SubNavigation
