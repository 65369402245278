import cx from 'classnames'
import React, { FC, PropsWithChildren, ReactNode } from 'react'

import styles from './styles.module.scss'

interface Props {
  className?: string
  barClassName?: string
  labelClassName?: string
  valueLabelClassName?: string
  totalLabelClassName?: string
  value: number
  total: number
  valueLabel?: ReactNode
  totalLabel?: ReactNode
}

const BarChartSimple: FC<Props> = ({
  className,
  barClassName,
  labelClassName,
  valueLabelClassName,
  totalLabelClassName,
  valueLabel,
  totalLabel,
  value,
  total,
}) => {
  // Don't show the fill if we have no width (no calculated value)
  // Additionally (below) we will ensure the fill % always at least fills the
  // border-radius amount; otherwise it looks glitchy.
  const width = Math.min((value / total) * 100, 100)
  return (
    <div className={cx(styles.container, className)}>
      {width > 0 ? (
        <div className={cx(styles.bar, barClassName)} style={{ width: `${Math.max(22.505, width)}%` }}></div>
      ) : null}
      <div className={cx(styles.labels, labelClassName)}>
        <div className={cx(styles.valueLabel, valueLabelClassName)}>{valueLabel ?? value}</div>
        <div className={cx(styles.totalLabel, totalLabelClassName)}>{totalLabel ?? total}</div>
      </div>
    </div>
  )
}

export const EmptyState: FC<PropsWithChildren<{ className?: string; labelClassName?: string }>> = ({
  children,
  className,
  labelClassName,
}) => (
  <div className={cx(styles.container, className)}>
    <div className={cx(styles.labels, labelClassName)}>
      <div className={cx(styles.valueLabel, styles.emptyState)}>{children}</div>
    </div>
  </div>
)

export default BarChartSimple
