import React, { FC } from 'react'

interface Props {
  className?: string
}

const Svg: FC<Props> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49 48" height="48" width="49" className={className}>
    <path
      d="M7.45072 7.65003C6.67072 8.43003 6.67072 9.69003 7.45072 10.47L12.3907 15.41C12.1707 16.43 12.0307 17.47 12.0307 18.53C12.0307 26.87 20.8707 38.37 24.4907 42.75C25.2907 43.71 26.7507 43.71 27.5507 42.75C28.8507 41.19 30.7907 38.73 32.7707 35.83L38.0707 41.13C38.8507 41.91 40.1107 41.91 40.8907 41.13C41.6707 40.35 41.6707 39.09 40.8907 38.31L10.2707 7.65003C9.49072 6.87003 8.23072 6.87003 7.45072 7.65003ZM26.0307 4.53003C22.3507 4.53003 19.0307 5.95003 16.5307 8.25003L22.9107 14.63C23.7707 13.95 24.8507 13.53 26.0307 13.53C28.7907 13.53 31.0307 15.77 31.0307 18.53C31.0307 19.71 30.6107 20.79 29.9107 21.65L37.0107 28.75C38.7707 25.25 40.0307 21.67 40.0307 18.53C40.0307 10.79 33.7707 4.53003 26.0307 4.53003Z"
      fill="currentColor"
    />
  </svg>
)

export default Svg
