import React, { FC } from 'react'
import QRCode from 'react-qr-code'

import Instagram from 'svgs/social/instagram'
import Twitter from 'svgs/social/twitter'
import Facebook from 'svgs/social/facebook'
import LinkedIn from 'svgs/social/linkedin'

import { RealmLogoSmall } from 'components/MarketingSite/Header/RealmLogo'

import styles from './styles.module.scss'

const Footer: FC = () => (
  <footer className={styles.footer}>
    <div className={styles.container}>
      <div className={styles.brand}>
        <RealmLogoSmall className={styles.logo} />
      </div>
      <div className={styles.links}>
        <div className={styles.linkGroup}>
          <h5>{'Learn More'}</h5>
          <a href="/how-it-works">{'How It Works'}</a>
          <a href="/why-realm">{'Why Realm'}</a>
          <a href="/resources/library" target="_blank" rel="noreferrer">
            {'Resources'}
          </a>
        </div>
        <div className={styles.linkGroup}>
          <h5>{'Company'}</h5>
          <a href="/about-us">{'About Realm'}</a>
          <a href="/vendor-signup">{'Join Our Vendor Network'}</a>
          <a href="/about-us/careers">{'Careers'}</a>
        </div>
        <div className={styles.linkGroup}>
          <h5>{'Download The App'}</h5>
          <p>{'Get project price estimates, track your home value, and see neighborhood insights'}</p>

          <a
            className={styles.callToAction}
            href={
              'https://app.adjust.com/qr4wgfa?redirect_macos=https://apps.apple.com/us/app/realm-home/id1589251076&redirect_windows=https://apps.apple.com/us/app/realm-home/id1589251076'
            }
          >
            {'Download The App'}
          </a>
          <div className={styles.qrCode}>
            <QRCode
              value={`https://app.adjust.com/qr4wgfa?redirect_macos=https://apps.apple.com/us/app/realm-home/id1589251076&redirect_windows=https://apps.apple.com/us/app/realm-home/id1589251076`}
            />
          </div>
        </div>
      </div>
    </div>
    <div className={styles.final}>
      <ul className={styles.social}>
        <li>
          <a href="https://www.instagram.com/realm/" target="_blank" rel="noreferrer">
            <Instagram />
          </a>
        </li>
        <li>
          <a href="https://twitter.com/realmhome" target="_blank" rel="noreferrer">
            <Twitter />
          </a>
        </li>
        <li>
          <a href="https://www.facebook.com/realmhome" target="_blank" rel="noreferrer">
            <Facebook />
          </a>
        </li>
        <li>
          <a href="https://www.linkedin.com/company/realmhome/" target="_blank" rel="noreferrer">
            <LinkedIn />
          </a>
        </li>
      </ul>
      <div className={styles.legal}>
        <div className={styles.copyright}>{`© ${new Date().getFullYear()} Realm · All rights reserved`}</div>
        <div className={styles.links}>
          <a href="/privacy">{'Privacy Policy'}</a>
          {' · '}
          <a href="/terms">{'Terms of Use'}</a>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
