import React, { FC } from 'react'
import { PopularCity } from 'recoil/publicNeighborhood'

import styles from './styles.module.scss'

const PopularCities: FC<{ title: string; cities: PopularCity[]; stateCode: string }> = ({
  cities,
  title,
  stateCode,
}) => (
  <>
    <h2>{title}</h2>
    <ul className={styles.popularCities}>
      {cities.map((popularCity) => (
        <li key={popularCity.city_param}>
          <a href={`/${stateCode.toLowerCase()}/${popularCity.city_param}`}>{popularCity.city}</a>
        </li>
      ))}
      <li>
        <a href={`/${stateCode.toLowerCase()}/cities/1`}>{'View all'}</a>
      </li>
    </ul>
  </>
)

export default PopularCities
