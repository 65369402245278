import React, { FC } from 'react'

import { ContentList } from 'components/MarketingSite/PageContent/types'

import styles from './styles.module.scss'

const List: FC<ContentList> = ({ items, footer }) => (
  <>
    <ul className={styles.list}>
      {items.map((item) => (
        <li key={item}>{item}</li>
      ))}
    </ul>
    <h3 className={styles.title}>{footer.title}</h3>
    <p className={styles.body}>{footer.body}</p>
  </>
)

export default List
