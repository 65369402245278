import React, { FC, useState, useCallback, useEffect, useMemo } from 'react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

import { prepare, trackClick as baseTrackClick, prepareName, TrackBase } from 'utils/analyticsV2'

import { Calendar, Event } from './types'
import { schedulerPatchJSON } from './utils'

import EventAvailableIcon from 'svgs/event-available'
import DateIcon from 'svgs/calendar'
import TimeIcon from 'svgs/clock'
import Spinner from 'svgs/spinner'

import CoreText from 'components/Core/CoreText'
import CoreButton from 'components/Core/CoreButton'
import CoreDivider from 'components/Core/CoreDivider'

import Message from './components/Message'
import FailureModal from './FailureModal'
import PointOfContact from './PointOfContact'
import HouseholdDetails from './HouseholdDetails'
import InfoList, { InfoListItem } from './components/InfoList'
import CancelModal from './CancelModal'

import Reschedule from './Reschedule'

import styles from './Scheduled.module.scss'

dayjs.extend(utc)
dayjs.extend(timezone)

const trackClick = prepare(
  {
    family: 'vendor-site-visit',
    screen: 'scheduled',
  },
  baseTrackClick
)
const trackError = prepareName<TrackBase>('state-event', {
  code: 'error',
  family: 'vendor-site-visit',
  screen: 'scheduled',
  feature: 'canceling',
})

interface Props {
  calendar: Calendar
  onCalendarChange: React.Dispatch<React.SetStateAction<Calendar>>
}

const Scheduled: FC<Props> = ({ calendar, onCalendarChange }) => {
  const [timer, setTimer] = useState(0)
  const event = useMemo(() => calendar.event as Event, [calendar]) // We know it will exist.
  const [cancelModal, setCancelModal] = useState(false)
  const [cancelling, setCancelling] = useState(false)
  const [cancelError, setCancelError] = useState(false)
  const [rescheduling, setRescheduling] = useState(false)
  const [rescheduleError, setRescheduleError] = useState(false)

  useEffect(() => {
    if (!window) return
    const params = new URLSearchParams(window.location.search)
    if (params.has('reschedule')) setRescheduling(true)
  }, [])

  useEffect(() => {
    // Update our view every 30 seconds.
    const interval = setInterval(() => setTimer((prev) => prev + 1), 30000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  const handleCancelClick = useCallback(() => {
    trackClick({ code: 'open', section: 'cancel' })
    setCancelModal(true)
  }, [])

  const handleCancelModalConfirm = useCallback(async () => {
    trackClick({ code: 'confirm', section: 'cancel' })

    setCancelling(true)

    const body = { canceled: true }
    try {
      const response = await schedulerPatchJSON(
        `/vendor_site_visits/${calendar.id}/events/${event.id}`,
        body as unknown as Record<string, unknown>,
        calendar.id,
        onCalendarChange
      )

      onCalendarChange((prev) => ({ ...prev, event: { ...(response.jsonBody as Event) } }))
    } catch (e) {
      trackError({
        flexfield_1: {
          errorType: 'exception',
          message: e.toString(),
          canceled: true,
        },
      })
      setCancelError(true)
    }

    setCancelError(true)
    setCancelModal(false)
    setCancelling(false)
  }, [calendar, event, onCalendarChange])

  const handleCancelErrorClose = useCallback(() => {
    trackClick({ code: 'close', section: 'cancel-error' })
    setCancelError(false)
  }, [])
  const handleCancelModalClose = useCallback(() => {
    trackClick({ code: 'close', section: 'cancel' })
    setCancelModal(false)
  }, [])

  const handleRescheduleClick = useCallback(() => {
    trackClick({ code: 'open', section: 'reschedule' })
    setRescheduling(true)
  }, [])

  const handleRescheduleErrorClose = useCallback(() => {
    trackClick({ code: 'close', section: 'reschedule-error' })
    setRescheduleError(false)
  }, [])

  const handleRescheduleBack = useCallback(() => {
    setRescheduling(false)
  }, [])

  const status = useMemo(() => {
    const now = Date.now()
    const startTime = new Date(event.start_time || '')
    const endTime = new Date(event.end_time || '')
    if (endTime.getTime() <= now) return 'completed'
    else if (startTime.getTime() <= now) return 'occurring'
    return 'scheduled'

    // We want this to recalculate each time timer changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event, timer])

  const startTime = dayjs(event.start_time).tz(calendar.property.time_zone)
  const endTime = dayjs(event.end_time).tz(calendar.property.time_zone)

  if (rescheduling) {
    return (
      <Reschedule calendar={calendar} event={event} onCalendarChange={onCalendarChange} onBack={handleRescheduleBack} />
    )
  }

  return (
    <div className={styles.scheduled}>
      <Message
        title={status == 'completed' ? 'Completed Site Visit' : 'Scheduled Site Visit'}
        icon={<EventAvailableIcon />}
        type="success"
      >
        <div className={styles.messageContent}>
          <CoreText.Paragraph size="xl" weight="heavy" color="primaryBlack">
            {status == 'completed' ? 'Site visit completed' : 'Site visit scheduled'}
          </CoreText.Paragraph>
          {status == 'scheduled' ? (
            <>
              <CoreDivider.Full />
              <InfoList className={styles.bookingInfo}>
                <InfoListItem icon={<DateIcon />}>
                  <CoreText.Paragraph size="m" color="primaryBlack">
                    {startTime.format('ddd, MMM D')}
                  </CoreText.Paragraph>
                </InfoListItem>
                <InfoListItem icon={<TimeIcon />}>
                  <CoreText.Paragraph size="m" color="primaryBlack">
                    {`${startTime.format('h:mm A')} - ${endTime.format('h:mm A')}`}
                  </CoreText.Paragraph>
                  <CoreText.Paragraph size="m" weight="light" color="primaryBlack">
                    {startTime.format('zzz')}
                  </CoreText.Paragraph>
                </InfoListItem>
              </InfoList>
              <CoreDivider.Full />
              <footer>
                <CoreText.Paragraph weight="light" color="tertiaryBlack">
                  {'If this time no longer works for you, you can reschedule the appointment.'}
                </CoreText.Paragraph>
                <CoreButton
                  type="submit"
                  kind="secondary"
                  size="s"
                  text={'Reschedule site visit'}
                  onClick={handleRescheduleClick}
                  disabled={rescheduling}
                  icon={rescheduling ? <Spinner strokeWidth={16} /> : undefined}
                />
                <CoreText.Paragraph weight="light" color="tertiaryBlack">
                  {'If you no longer wish to persue this opportunity, you can cancel your appointment.'}
                </CoreText.Paragraph>
                <CoreButton
                  type="submit"
                  kind="negative"
                  size="s"
                  text={'Cancel site visit'}
                  onClick={handleCancelClick}
                  disabled={cancelling}
                  icon={cancelling ? <Spinner strokeWidth={16} /> : undefined}
                />
              </footer>
            </>
          ) : (
            <>
              <CoreText.Paragraph size="m" weight="light" color="primaryBlack">
                {
                  {
                    ['completed']: `Thank you for visiting with ${
                      calendar.property.full_name
                    } on ${startTime.format('M/D/YYYY')}. Please let us know how everything went!`,
                    ['occurring']: `Your site visit should be currently under way. Good luck!`,
                  }[status]
                }
              </CoreText.Paragraph>
              <CoreDivider.Full />
              <footer>
                <CoreText.Paragraph weight="light" color="tertiaryBlack">
                  {'Missed the meeting? You can try rescheduling your site visit.'}
                </CoreText.Paragraph>
                <CoreButton
                  type="submit"
                  kind="secondary"
                  size="s"
                  text={'Reschedule site visit'}
                  onClick={handleRescheduleClick}
                  disabled={rescheduling}
                  icon={rescheduling ? <Spinner strokeWidth={16} /> : undefined}
                />
              </footer>
            </>
          )}
        </div>
      </Message>
      <HouseholdDetails property={calendar.property} screen="scheduled" />
      <PointOfContact
        advisor={calendar.advisor}
        message={`If you have any questions${status == 'scheduled' ? ' or need to reschedule' : ''}, contact us.`}
        screen="scheduled"
      />
      {cancelModal && (
        <CancelModal submitting={cancelling} onClose={handleCancelModalClose} onConfirm={handleCancelModalConfirm} />
      )}
      {cancelError && (
        <FailureModal title="Failed to Cancel Site Visit" onClose={handleCancelErrorClose}>
          <p>
            {`An error occurred while attempting to cancel your site visit. Please try again later, or contact your advisor for more help.`}
          </p>
        </FailureModal>
      )}
      {rescheduleError && (
        <FailureModal title="Failed to Reschedule Site Visit" onClose={handleRescheduleErrorClose}>
          <p>
            {`An error occurred while attempting to reschedule your site visit. Please try again later, or contact your advisor for more help.`}
          </p>
        </FailureModal>
      )}
    </div>
  )
}

export default Scheduled
