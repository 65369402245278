import React, { FC, useCallback, useEffect, useRef, useState } from 'react'

import { AddressAutocompleteField } from '../AddressAutocompleteField'
import Cta from 'components/MarketingAndPropertyPages/Cta'
import { default as HeroContainer } from 'pages/_serverRendered/Homepage/Hero'
import { HeroData, AutocompleteRedirectTargetTypes } from 'pages/_serverRendered/CmsPage/types'
import { ConvertAutocompleteRedirectTarget } from 'pages/_serverRendered/CmsPage/utils'
import Markdown from 'components/Markdown'
import classnames from 'classnames'
import styles from './styles.module.scss'
import { hasTextPresent } from 'pages/_serverRendered/CmsPage/Section/Components/PresentRichText'

interface Props extends HeroData {
  onInputVisibleChange?: (boolean) => void
  autocompleteRedirectTarget: AutocompleteRedirectTargetTypes
  onRequestHeaderHeight: () => number | undefined
}

const Hero: FC<Props> = (data) => {
  switch (data.type) {
    case 'bullets':
      return <Bullets {...data} />
    case 'homepage':
      return <Homepage {...data} />
    default:
      return <Default {...data} />
  }
}

export const Default: FC<Props> = ({
  onInputVisibleChange,
  autocompleteRedirectTarget,
  title,
  description,
  include_cta,
  media,
  override_button_label_long,
  override_button_label_short,
  onRequestHeaderHeight,
}) => {
  return (
    <HeroContainer className={styles.hero} media={media}>
      {hasTextPresent(title) && <h2 className={styles.title}>{title[0].text}</h2>}
      {hasTextPresent(description) && <p className={styles.description}>{description[0].text}</p>}
      <AddressAutocompleteField
        showButton
        buttonLabelLong={override_button_label_long}
        buttonLabelShort={override_button_label_short}
        onInputVisibleChange={onInputVisibleChange}
        variant={ConvertAutocompleteRedirectTarget(autocompleteRedirectTarget)}
        positionLabel="hero"
        onRequestHeaderHeight={onRequestHeaderHeight}
        scrollToFocus
      />
      {include_cta && (
        <>
          <p className={styles.ctaOr}>{'or'}</p>
          <Cta
            type="primary"
            className={styles.contact}
            href="https://calendly.com/kerry-44/realm-project-review/"
            target="_blank"
            cta="landing-page-hero"
          >
            {'Talk to an Advisor'}
          </Cta>
        </>
      )}
    </HeroContainer>
  )
}

export const Homepage: FC<Props> = ({
  onInputVisibleChange,
  autocompleteRedirectTarget,
  title,
  description,
  media,
  override_button_label_long,
  override_button_label_short,
}) => {
  return (
    <HeroContainer className={styles.hero} media={media}>
      {description?.[0].text != null && <p className={styles.titleIntro}>{description[0].text}</p>}
      <h2 className={styles.title}>
        <Markdown>{title[0].text || ''}</Markdown>
      </h2>
      <AddressAutocompleteField
        buttonLabelLong={override_button_label_long}
        buttonLabelShort={override_button_label_short}
        onInputVisibleChange={onInputVisibleChange}
        variant={ConvertAutocompleteRedirectTarget(autocompleteRedirectTarget)}
        positionLabel="hero"
      />
    </HeroContainer>
  )
}

// Wait 5 seconds before rotating through options
const rotateInterval = 3000
const strategies = ['Increase home value', 'Add more living space', 'Earn rental income', 'Improve my backyard']

export const Bullets: FC<Props> = ({
  onInputVisibleChange,
  autocompleteRedirectTarget,
  title,
  description,
  media,
  override_button_label_long,
  override_button_label_short,
}) => {
  const [selectedStrategy, setSelectedStrategy] = useState<string>()

  const onStrategyClick = useCallback(
    (id) => {
      setSelectedStrategy(selectedStrategy === id ? undefined : id)
      setShouldRotateStrategies(false)
    },
    [selectedStrategy]
  )

  const [shouldRotateStrategies, setShouldRotateStrategies] = useState(true)
  const rotateIndexRef = useRef(-1)

  const rotateStrategies = useCallback(() => {
    if (shouldRotateStrategies) {
      rotateIndexRef.current = rotateIndexRef.current + 1

      // Once we've rotated through all the options once, stop rotating
      if (rotateIndexRef.current < strategies.length) {
        setSelectedStrategy(strategies[rotateIndexRef.current])
      } else {
        setSelectedStrategy(undefined)
        setShouldRotateStrategies(false)
      }
    }
  }, [shouldRotateStrategies])

  useEffect(() => {
    if (rotateIndexRef.current < 0) {
      rotateStrategies()
    }

    const interval = setInterval(rotateStrategies, rotateInterval)
    return () => clearInterval(interval)
  }, [rotateStrategies])

  return (
    <HeroContainer
      className={classnames(styles.hero, styles[`strategy${strategies.findIndex((s) => s === selectedStrategy)}`])}
      media={media}
    >
      <h2 className={styles.title}>{title[0].text}</h2>
      <div className={styles.strategies}>
        {strategies.map((strategy) => (
          <Strategy
            key={strategy}
            active={selectedStrategy === strategy}
            strategy={strategy}
            onClick={onStrategyClick}
          />
        ))}
      </div>
      <AddressAutocompleteField
        buttonLabelLong={override_button_label_long}
        buttonLabelShort={override_button_label_short}
        onInputVisibleChange={onInputVisibleChange}
        variant={ConvertAutocompleteRedirectTarget(autocompleteRedirectTarget)}
        positionLabel="hero"
      />
      {description?.[0].text != null && <p className={styles.descriptionBullets}>{description[0].text}</p>}
    </HeroContainer>
  )
}

interface StrategyProps {
  active: boolean
  strategy: string
  onClick: (string) => void
}

const Strategy: FC<StrategyProps> = ({ active, strategy, onClick }) => {
  const onStrategyClick = useCallback(() => onClick(strategy), [onClick, strategy])
  const onStrategyKeyPress = useCallback(
    (event) => {
      if (event.keyCode === 13) onStrategyClick()
    },
    [onStrategyClick]
  )

  return (
    <div
      className={classnames(styles.strategy, { [styles.strategyActive]: active })}
      onClick={onStrategyClick}
      onKeyPress={onStrategyKeyPress}
      role="button"
      tabIndex={-1}
    >
      <div className={styles.strategyBullet}>{'→'}</div>
      <div className={styles.strategyText}>{strategy}</div>
    </div>
  )
}

export default Hero
