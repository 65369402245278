import React, { FC } from 'react'
import classnames from 'classnames'

import { SliceFlippingCards } from 'pages/_serverRendered/CmsPage/types'
import { colorsToStyle } from 'pages/_serverRendered/CmsPage/Section/Components/Card'
import styles from './styles.module.scss'

const IMG_WIDTH = 512

const computeImgHeight = (dimensions?: { width: number; height: number } | null) => {
  if (!dimensions) return undefined

  return Math.round((dimensions.height * IMG_WIDTH) / dimensions.width)
}

const FlippingCards: FC<SliceFlippingCards> = ({ items, primary }) => (
  <div className={classnames(styles.container)}>
    {items.map((item) => (
      <div className={styles.item} key={item.title[0]?.text}>
        <div className={styles.front}>
          {item.image.url && (
            <img
              className={styles.frontImage}
              alt={item.image.alt || ''}
              src={`${item.image.url}&w=${IMG_WIDTH}`}
              srcSet={`${item.image.url}&w=${IMG_WIDTH}&dpr=1 1x, ${item.image.url}&w=${IMG_WIDTH}&dpr=2 2x`}
              width={IMG_WIDTH}
              height={computeImgHeight(item.image.dimensions)}
              loading="lazy"
            />
          )}
          <div className={styles.arrow}>{'→'}</div>
        </div>
        <div className={styles.back} style={colorsToStyle[primary?.card_color || '']}>
          {item.title[0] != null && <p className={styles.title}>{item.title[0].text}</p>}
          {item.body1[0] != null && <p className={styles.body}>{item.body1[0].text}</p>}
          {item.footer[0] != null && <p className={styles.footer}>{item.footer[0].text}</p>}
        </div>
      </div>
    ))}
  </div>
)

export default FlippingCards
