// The content of this page is copy/pasted - disabling some linter rules to avoid having
// to hand-edit everything
/* eslint-disable react/jsx-no-literals, react/jsx-no-bind, jsx-a11y/anchor-is-valid */
import cx from 'classnames'
import React, { FC, useEffect, useState } from 'react'

import Footer from 'components/MarketingSite/Footer'
import Header from 'components/MarketingSite/Header'
import FancyButton from 'components/FancyButton'
import { trackPage } from 'utils/analytics'

import legalStyles from 'components/MarketingSite/shared/legal.module.scss'
import styles from './styles.module.scss'

const Content: FC = () => (
  <div className={legalStyles.wrapper}>
    <div className={cx(styles.vendorContract, legalStyles.legal)}>
      <h1 className="c50">Realm Vendor Referral Fee Agreement</h1>
      <p className="c8">
        <span className="c4">I. The Parties</span>
        <span className="c5">
          . This Vendor Referral Fee Agreement (the &ldquo;Agreement&rdquo;) is entered into on
          &nbsp;______________(&ldquo;Effective Date&rdquo;) between:{' '}
        </span>
      </p>
      <p className="c2">
        <span className="c5"></span>
      </p>
      <p className="c8">
        <span className="c5">
          Realm Living, Inc. (&ldquo;Realm&rdquo; and &ldquo;Company&rdquo;) with a mailing address of 524 Broadway,
          Suite 11-117, New York, NY 10012
        </span>
      </p>
      <p className="c2">
        <span className="c5"></span>
      </p>
      <p className="c8">
        <span className="c5">AND </span>
      </p>
      <p className="c2">
        <span className="c5"></span>
      </p>
      <p className="c8">
        <span className="c5">
          &nbsp;___________________ (&ldquo;Vendor&rdquo;) with a mailing address of __________________________.
        </span>
      </p>
      <p className="c2">
        <span className="c5"></span>
      </p>
      <p className="c8">
        <span className="c5">
          The Company and Vendor are each referred to herein as a &ldquo;Party&rdquo; and, collectively, as the
          &ldquo;Parties.&rdquo;
        </span>
      </p>
      <p className="c2">
        <span className="c5"></span>
      </p>
      <p className="c8">
        <span className="c4">II. Acceptance of Realm&rsquo;s Terms of Use and Privacy Policy. </span>
        <span>
          By entering this Agreement, the &nbsp;Parties agree to the terms stated herein and the Vendor accepts the
          Company&rsquo;s{' '}
        </span>
        <span className="c30 c37">
          <a
            className="c39"
            href="https://www.google.com/url?q=https://realmhome.com/terms&amp;sa=D&amp;source=editors&amp;ust=1681221372533157&amp;usg=AOvVaw0XQyHzAIAe-rdNEDOHZhy4"
          >
            Terms of Use
          </a>
        </span>
        <span>&nbsp;and </span>
        <span className="c30 c37">
          <a
            className="c39"
            href="https://www.google.com/url?q=https://realmhome.com/privacy&amp;sa=D&amp;source=editors&amp;ust=1681221372533879&amp;usg=AOvVaw1ZPwCClj3ZCCeGVJ6S_JzB"
          >
            Privacy Policy
          </a>
        </span>
        <span>. </span>
      </p>
      <p className="c2">
        <span className="c5"></span>
      </p>
      <p className="c8">
        <span className="c4">III. Term and Termination.</span>
        <span className="c5">&nbsp;</span>
      </p>
      <ol className="c25 lst-kix_91wprrm5to9j-0 start" start={1}>
        <li className="c8 c20 c46 li-bullet-0">
          <span className="c5">
            This Agreement shall begin on the Effective Date and will remain in effect for a twelve month period.
          </span>
        </li>
        <li className="c8 c20 c46 li-bullet-0">
          <span className="c5">
            This Agreement will automatically renew on a month-to-month basis unless either Party provides written
            notice of termination which will be effective immediately.{' '}
          </span>
        </li>
        <li className="c8 c20 c46 li-bullet-0">
          <span className="c4">Payment After Termination. </span>
          <span>
            <a
              className="c39"
              href="https://www.google.com/url?q=https://www.lawinsider.com/clause/payment-after-termination&amp;sa=D&amp;source=editors&amp;ust=1681221372535732&amp;usg=AOvVaw3S7rUi_OalnzDx_qAh6RNy"
            >
              Termination of
            </a>
          </span>
          <span className="c5">
            &nbsp;this Agreement shall not affect the obligation of Vendor to pay the Company any amounts owed or to
            become owed as a result of a Referral provided by the Company on or before the date of such termination.
          </span>
        </li>
        <li className="c8 c20 c46 li-bullet-0">
          <span>
            If the Company and Vendor sign a new agreement, after the Effective Date, the new agreement shall supersede
            this Agreement.
          </span>
        </li>
      </ol>
      <p className="c2">
        <span className="c5"></span>
      </p>
      <p className="c8">
        <span className="c4">IV. Referral Description.</span>
        <span>
          &nbsp;Upon the execution of this Agreement, the Company may refer potential clients (&ldquo;Leads&rdquo;) to
          the Vendor. &nbsp;The Vendor shall pay the Company a referral fee for all{' '}
        </span>
        <span>current and future </span>
        <span className="c5">contracts derived from Leads provided by the Company.</span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>
      <p className="c8">
        <span className="c4">V. Referral Amount. </span>
        <span>
          The Vendor shall pay the Company a referral fee for Leads that result in a signed client contract
          &nbsp;(&ldquo;Project&rdquo;). See Attachment A for referral fee rate structure.
        </span>
      </p>
      <p className="c2">
        <span className="c5"></span>
      </p>
      <p className="c8">
        <span className="c4">VI. Payment.</span>
        <span>&nbsp;The Referral Amount, shall be paid by the Vendor to the Company within 30 days of</span>
        <span className="c5">
          &nbsp;the Project&rsquo;s contract has been signed. Payment shall be made in accordance with Section VII.{' '}
        </span>
      </p>
      <p className="c2">
        <span className="c5"></span>
      </p>
      <p className="c8">
        <span className="c4">VII. Payment Method</span>
        <span className="c5">
          . The Payment shall be made via electronic payment, following the invoice instructions sent from the Company
          to the Vendor via email.
        </span>
      </p>
      <p className="c2">
        <span className="c5"></span>
      </p>
      <p className="c8">
        <span className="c4">VIII. Exclusivity:</span>
        <span className="c5">
          &nbsp;This Agreement shall not be construed to be a commitment by either of the Parties to work exclusively
          with one another regarding referrals of potential new business or any other business activities.{' '}
        </span>
      </p>
      <p className="c2">
        <span className="c5"></span>
      </p>
      <p className="c8">
        <span className="c4">IX. Non-Circumvention.</span>
        <span className="c5">
          &nbsp;The Vendor agrees that all third (3rd) parties introduced to one another represent significant efforts
          and working relationships that are unique to, and part of, the work product and intellectual capital of the
          Company. Therefore, without prior written consent the Vendor agrees to refrain from conducting direct or
          indirect business dealings of any kind with any third (3rd) party so introduced, with the exception of third
          (3rd) parties the Vendor previously had a formal business relationship with prior to the execution of this
          agreement.
        </span>
      </p>
      <p className="c2">
        <span className="c5"></span>
      </p>
      <p className="c8">
        <span className="c4">X. Confidentiality.</span>
        <span className="c5">
          &nbsp;The Parties acknowledge that the existence and the terms of this Agreement, and any oral or written
          information exchanged between the Parties in connection with the preparation and performance of this
          Agreement, are regarded as confidential information. Each Party shall maintain confidentiality of all such
          confidential information, and without obtaining the written consent of the other Party, it shall not disclose
          any relevant confidential information to any third (3rd) parties, except for the information that: (a) is or
          will be in the public domain (other than through the receiving Party&rsquo;s unauthorized disclosure); (b) is
          under the obligation to be disclosed pursuant to the applicable laws or regulations, rules of any stock
          exchange, or orders of the court or other government authorities; or (c) is required to be disclosed by any
          Party to its shareholders, investors, legal counsels or financial advisors regarding the transaction
          contemplated hereunder, provided that such shareholders, investors, legal counsels or financial advisors shall
          be bound by the confidentiality obligations similar to those set forth in this Section. Disclosure of any
          confidential information by any employee, officer, staff, affiliates, or other agents by any Party shall be
          deemed disclosure of such confidential information by such Party, which Party shall be held liable for breach
          of this Agreement. This Section shall survive this Agreement for a period of the maximum allowed under State
          and Federal law.{' '}
        </span>
      </p>
      <p className="c2">
        <span className="c5"></span>
      </p>
      <p className="c8">
        <span className="c4">XI. </span>
        <span className="c4">Governing Law.</span>
        <span>&nbsp;</span>
        <span>
          This Agreement shall be governed by and construed in accordance with the laws in the State of New York.{' '}
        </span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>
      <p className="c8">
        <span className="c4">XII. Severability.</span>
        <span>
          &nbsp;In the event any provision or part of this Agreement is found to be invalid or unenforceable, only that
          particular provision or part so found, and not the entire Agreement, shall be considered{' '}
        </span>
        <span>invalid</span>
        <span>
          . <br />
          <br />
        </span>
        <span className="c4">XIII. Compliance.</span>
        <span className="c5">
          &nbsp;Vendors are required to comply with all applicable, local, state, and federal laws and are required to
          obtain necessary licenses pursuant to their business and the project.
        </span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>
      <p className="c2">
        <span className="c1"></span>
      </p>
      <p className="c13 c17">
        <span className="c1"></span>
      </p>
      <p className="c17">
        <span className="c4">Company&rsquo;s Signature</span>
        <span className="c5">&nbsp;_________________________________</span>
      </p>
      <p className="c17">
        <span className="c4">Date</span>
        <span className="c5">&nbsp;______________________ </span>
      </p>
      <p className="c17">
        <span className="c4">Print Name</span>
        <span className="c5">&nbsp;________________________________________</span>
      </p>
      <p className="c17">
        <span className="c4">Title </span>
        <span>&nbsp;_____________________________</span>
      </p>
      <p className="c2">
        <span className="c5"></span>
      </p>
      <p className="c2">
        <span className="c5"></span>
      </p>
      <p className="c2">
        <span className="c5"></span>
      </p>
      <p className="c2">
        <span className="c5"></span>
      </p>
      <p className="c17">
        <span className="c4">Vendor&rsquo;s Signature</span>
        <span className="c5">&nbsp;_________________________________</span>
      </p>
      <p className="c17">
        <span className="c4">Date</span>
        <span className="c5">&nbsp;______________________ </span>
      </p>
      <p className="c17">
        <span className="c4">Print Name</span>
        <span className="c5">&nbsp;________________________________________</span>
      </p>
      <p className="c17">
        <span className="c4">Title </span>
        <span className="c5">&nbsp;_____________________________</span>
      </p>
      <p className="c17 c13">
        <span className="c5"></span>
      </p>
      <hr style={{ pageBreakBefore: 'always', display: 'none' }} />
      <p className="c2">
        <span className="c49 c4 c28" />
      </p>
      <p className="c50">
        <span className="c4 c28 c49">Attachment A: Realm Project Referral Fee </span>
      </p>
      <p className="c2">
        <span className="c4 c26"></span>
      </p>
      <p className="c2">
        <span className="c26 c4"></span>
      </p>
      <p className="c8">
        <span className="c30 c4 c28 c44">HOW IT WORKS:</span>
        <span className="c16">&nbsp;</span>
      </p>
      <p className="c2">
        <span className="c7"></span>
      </p>
      <ul className="c25 lst-kix_un8fx8jkaul1-0 start">
        <li className="c8 c20 c46 li-bullet-0">
          <span className="c4 c28">Success Only: </span>
          <span className="c16">Only pay for leads that result in a signed contract.</span>
        </li>
      </ul>
      <p className="c2 c20">
        <span className="c7"></span>
      </p>
      <ul className="c25 lst-kix_yvy1w9rre0cr-0 start">
        <li className="c8 c20 c46 li-bullet-0">
          <span className="c4 c28">Payment Method: </span>
          <span className="c16">Securely pay via ACH or Credit Card through the Quickbooks platform.</span>
        </li>
      </ul>
      <p className="c2">
        <span className="c26 c38"></span>
      </p>
      <p className="c8">
        <span className="c30 c4 c28 c44">RATES:</span>
      </p>
      <p className="c2">
        <span className="c30 c4 c28 c44 c47"></span>
      </p>
      <p className="c8">
        <span className="c21 c30">For Construction and Renovation Contracts</span>
      </p>
      <p className="c2">
        <span className="c11"></span>
      </p>
      <p className="c2">
        <span className="c7"></span>
      </p>
      <table className="c10">
        <thead>
          <tr className="c31">
            <td className="c15 c27" colSpan={1} rowSpan={1}>
              <p className="c0">
                <span className="c19 c4">Contract Price </span>
              </p>
            </td>
            <td className="c34 c27" colSpan={1} rowSpan={1}>
              <p className="c0">
                <span className="c19 c4">Referral Fee</span>
              </p>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr className="c36">
            <td className="c15" colSpan={1} rowSpan={3}>
              <p className="c0 c13">
                <span className="c3"></span>
              </p>
              <p className="c0">
                <span className="c3">All Construction Contract Values</span>
              </p>
            </td>
            <td className="c34" colSpan={1} rowSpan={3}>
              <p className="c0 c13">
                <span className="c3"></span>
              </p>
              <p className="c0">
                <span className="c3">4.5%</span>
              </p>
            </td>
          </tr>
          <tr className="c36"></tr>
          <tr className="c31"></tr>
        </tbody>
      </table>
      <p className="c2">
        <span className="c16"></span>
      </p>
      <p className="c8">
        <span className="c21 c30">For Design and Architecture Contracts</span>
      </p>
      <p className="c2">
        <span className="c11"></span>
      </p>
      <p className="c2">
        <span className="c7"></span>
      </p>
      <table className="c10">
        <thead>
          <tr className="c31">
            <td className="c15 c27" colSpan={1} rowSpan={1}>
              <p className="c0">
                <span className="c19 c4">Total Design Price </span>
              </p>
            </td>
            <td className="c34 c27" colSpan={1} rowSpan={1}>
              <p className="c0">
                <span className="c19 c4">Referral Fee</span>
              </p>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr className="c36">
            <td className="c15" colSpan={1} rowSpan={3}>
              <p className="c0 c13">
                <span className="c3"></span>
              </p>
              <p className="c0">
                <span className="c3">All Design Contract Values</span>
              </p>
            </td>
            <td className="c34" colSpan={1} rowSpan={3}>
              <p className="c0 c13">
                <span className="c3"></span>
              </p>
              <p className="c0">
                <span className="c3">10%</span>
              </p>
            </td>
          </tr>
          <tr className="c36"></tr>
          <tr className="c31"></tr>
        </tbody>
      </table>
      <p className="c2">
        <span className="c16"></span>
      </p>
      <p className="c8">
        <span className="c21">
          <br />
        </span>
        <span className="c21">
          Referral fees are due within 30 days of contract signing between a client and vendor. Payment schedule will be
          determined by the contract value. Refer to Table A below{' '}
        </span>
        <span className="c21">for tiered</span>
        <span className="c21">&nbsp;payment schedule based on contract value. </span>
      </p>
      <p className="c2">
        <span className="c38"></span>
      </p>
      <p className="c8">
        <span className="c21">
          If a project has two contracts (ex: design contract, build contract), each contract is billed separately at
          the time of contract signing.
          <br />
          <br />
        </span>
        <span className="c11">Table A:</span>
      </p>
      <p className="c2">
        <span className="c7"></span>
      </p>
      <table className="c10">
        <thead>
          <tr className="c31">
            <td className="c23 c27" colSpan={1} rowSpan={1}>
              <p className="c0">
                <span className="c19 c4">Contract Value </span>
              </p>
            </td>
            <td className="c9 c27" colSpan={1} rowSpan={1}>
              <p className="c0">
                <span className="c19 c4">Due Within</span>
              </p>
              <p className="c0">
                <span className="c19 c4">30 Days</span>
              </p>
              <p className="c0 c13">
                <span className="c19 c4"></span>
              </p>
            </td>
            <td className="c9 c27" colSpan={1} rowSpan={1}>
              <p className="c0">
                <span className="c19 c4">Due Within </span>
              </p>
              <p className="c0">
                <span className="c19 c4">60 Days</span>
              </p>
              <p className="c0 c13">
                <span className="c19 c4"></span>
              </p>
            </td>
            <td className="c6 c27" colSpan={1} rowSpan={1}>
              <p className="c0">
                <span className="c19 c4">Due Within </span>
              </p>
              <p className="c0">
                <span className="c19 c4">90 Days</span>
              </p>
              <p className="c29 c13">
                <span className="c4 c19"></span>
              </p>
            </td>
            <td className="c14" colSpan={1} rowSpan={1}>
              <p className="c0">
                <span className="c19 c4">Due Within 120 Days</span>
              </p>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr className="c35">
            <td className="c23" colSpan={1} rowSpan={1}>
              <p className="c0">
                <span className="c3">&lt;$299,999</span>
              </p>
            </td>
            <td className="c9" colSpan={1} rowSpan={1}>
              <p className="c0">
                <span className="c3">100% of fee</span>
              </p>
            </td>
            <td className="c9" colSpan={1} rowSpan={1}>
              <p className="c0 c13">
                <span className="c3"></span>
              </p>
            </td>
            <td className="c6" colSpan={1} rowSpan={1}>
              <p className="c13 c29">
                <span className="c3"></span>
              </p>
            </td>
            <td className="c22" colSpan={1} rowSpan={1}>
              <p className="c29 c13">
                <span className="c3"></span>
              </p>
            </td>
          </tr>
          <tr className="c35">
            <td className="c23" colSpan={1} rowSpan={1}>
              <p className="c0">
                <span className="c21 c32">$300,000</span>
                <span className="c3">- $499,999</span>
              </p>
            </td>
            <td className="c9" colSpan={1} rowSpan={1}>
              <p className="c0">
                <span className="c3">$10,500</span>
              </p>
            </td>
            <td className="c9" colSpan={1} rowSpan={1}>
              <p className="c0">
                <span className="c3">100% of outstanding</span>
              </p>
            </td>
            <td className="c6" colSpan={1} rowSpan={1}>
              <p className="c0 c13">
                <span className="c3"></span>
              </p>
            </td>
            <td className="c22" colSpan={1} rowSpan={1}>
              <p className="c0 c13">
                <span className="c3"></span>
              </p>
            </td>
          </tr>
          <tr className="c35">
            <td className="c23" colSpan={1} rowSpan={1}>
              <p className="c0">
                <span className="c3">$500,000- $1,499,999</span>
              </p>
            </td>
            <td className="c9" colSpan={1} rowSpan={1}>
              <p className="c0">
                <span className="c3">$10,500</span>
              </p>
            </td>
            <td className="c9" colSpan={1} rowSpan={1}>
              <p className="c0">
                <span className="c3">50% of outstanding</span>
              </p>
            </td>
            <td className="c6" colSpan={1} rowSpan={1}>
              <p className="c0">
                <span className="c3">100% of outstanding</span>
              </p>
            </td>
            <td className="c22" colSpan={1} rowSpan={1}>
              <p className="c0 c13">
                <span className="c3"></span>
              </p>
            </td>
          </tr>
          <tr className="c45">
            <td className="c23" colSpan={1} rowSpan={1}>
              <p className="c0">
                <span className="c3">$1,500,000+</span>
              </p>
            </td>
            <td className="c9" colSpan={1} rowSpan={1}>
              <p className="c0">
                <span className="c3">$10,500</span>
              </p>
            </td>
            <td className="c9" colSpan={1} rowSpan={1}>
              <p className="c0">
                <span className="c3">33% of outstanding</span>
              </p>
            </td>
            <td className="c6" colSpan={1} rowSpan={1}>
              <p className="c0">
                <span className="c3">66% of outstanding</span>
              </p>
            </td>
            <td className="c22" colSpan={1} rowSpan={1}>
              <p className="c0">
                <span className="c3">100% of outstanding</span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <p className="c2">
        <span className="c11"></span>
      </p>
      <p className="c2">
        <span className="c11"></span>
      </p>
      <p className="c2">
        <span className="c11"></span>
      </p>
      <p className="c8">
        <span className="c30 c21">Example Payment</span>
      </p>
      <p className="c2">
        <span className="c7"></span>
      </p>
      <table className="c10">
        <thead>
          <tr className="c31">
            <td className="c33 c27" colSpan={1} rowSpan={1}>
              <p className="c0">
                <span className="c19 c4">Contract Price </span>
              </p>
            </td>
            <td className="c43 c27" colSpan={1} rowSpan={1}>
              <p className="c0">
                <span className="c19 c4">Referral Fee</span>
              </p>
            </td>
            <td className="c12" colSpan={1} rowSpan={1}>
              <p className="c0">
                <span className="c4 c32 c28">Referral Fee</span>
                <span className="c19 c4">&nbsp;</span>
              </p>
            </td>
            <td className="c12" colSpan={1} rowSpan={1}>
              <p className="c0">
                <span className="c19 c4">First Payment Due</span>
              </p>
            </td>
            <td className="c12" colSpan={1} rowSpan={1}>
              <p className="c0">
                <span className="c19 c4">Final Payment Due</span>
              </p>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr className="c35">
            <td className="c33" colSpan={1} rowSpan={1}>
              <p className="c0">
                <span className="c3">$400,000</span>
              </p>
            </td>
            <td className="c43" colSpan={1} rowSpan={1}>
              <p className="c0">
                <span className="c3">4.5%</span>
              </p>
            </td>
            <td className="c18" colSpan={1} rowSpan={1}>
              <p className="c0">
                <span className="c3">$18,000</span>
              </p>
            </td>
            <td className="c18" colSpan={1} rowSpan={1}>
              <p className="c0">
                <span className="c21 c32">$</span>
                <span className="c3">10,500</span>
              </p>
            </td>
            <td className="c18" colSpan={1} rowSpan={1}>
              <p className="c0">
                <span className="c3">$7,500</span>
              </p>
            </td>
          </tr>
          <tr className="c35">
            <td className="c33" colSpan={1} rowSpan={1}>
              <p className="c0 c13">
                <span className="c3"></span>
              </p>
            </td>
            <td className="c43" colSpan={1} rowSpan={1}>
              <p className="c0 c13">
                <span className="c3"></span>
              </p>
            </td>
            <td className="c18" colSpan={1} rowSpan={1}>
              <p className="c0 c13">
                <span className="c3"></span>
              </p>
            </td>
            <td className="c18" colSpan={1} rowSpan={1}>
              <p className="c0">
                <span className="c3">30 Days</span>
              </p>
            </td>
            <td className="c18" colSpan={1} rowSpan={1}>
              <p className="c0">
                <span className="c3">60 Days</span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <p className="c2">
        <span className="c11"></span>
      </p>
      <p className="c2">
        <span className="c11"></span>
      </p>
      <p className="c2">
        <span className="c11"></span>
      </p>
      <p className="c8">
        <span className="c30 c21">
          Refunds
          <br />
        </span>
        <span className="c38 c48">
          <br />
        </span>
        <span className="c16">All Realm referral fees are non-refundable, except in case of the following:</span>
      </p>
      <p className="c2">
        <span className="c16"></span>
      </p>
      <p className="c8">
        <span className="c21">
          Refunds shall only be issued in the event the Vendor is unable to fulfill their obligations due to a client
          cancellation from circumstances beyond the client&rsquo;s control, such as a natural disaster, personal
          tragedy, or inability to secure sufficient financing. Satisfactory evidence must be provided of such
          circumstances to Realm, including documentation of the event and its impact on their ability to fulfill their
          obligations.{' '}
        </span>
        <span className="c16">
          &nbsp;A referral fee will not be refunded if the contract is canceled due to failure to deliver on the agreed
          upon contract by the Vendor. &nbsp;Determination of refunds will be at the sole discretion of Realm
        </span>
      </p>
      <p className="c2">
        <span className="c16"></span>
      </p>
      <p className="c8">
        <span className="c21">
          Under no circumstances shall a referral fee be refunded to Vendor if Vendor is found to have caused the
          cancellation of a contract due to its or its employees negligence, poor performance, or failure to meet the
          terms of their contract with the client.
        </span>
      </p>
      <div>
        <p className="c2">
          <span className="c5"></span>
        </p>
      </div>
    </div>
  </div>
)

interface Props {
  webview: boolean
}

const VendorContract: FC<Props> = ({ webview }) => {
  const [smallButton, setSmallButton] = useState(false)
  useEffect(() => {
    trackPage('VendorContract')
  }, [])

  useEffect(() => {
    if (!window) return
    const watchScroll = () => {
      setSmallButton(window.scrollY >= 72)
    }
    window.addEventListener('scroll', watchScroll)
    return () => {
      window.removeEventListener('scroll', watchScroll)
    }
  }, [])

  let docusignBaseUrl: URL | null = null
  if (process.env.DOCUSIGN_VENDOR_CONTRACT_URL) {
    docusignBaseUrl = new URL(process.env.DOCUSIGN_VENDOR_CONTRACT_URL)
    const fields = {
      ['Effective Date']: new Date().toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }),
    }
    Object.entries(fields).forEach(([key, value]) => {
      docusignBaseUrl?.searchParams.append(key, value)
    })
  }

  return (
    <div className={legalStyles.page}>
      {!webview && <Header hideSignupButton />}
      {docusignBaseUrl && (
        <div className={styles.hero}>
          <div className={cx(styles.buttonBox, { [styles.small]: smallButton })}>
            <FancyButton className={styles.startSigning} href={docusignBaseUrl.toString()} type="button">
              {'Start Signing'}
            </FancyButton>
          </div>
        </div>
      )}
      <Content />
      {!webview && <Footer />}
    </div>
  )
}

export default VendorContract
