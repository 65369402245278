import React, { FC, useMemo, useCallback, MouseEvent } from 'react'

import { Link as PrismicLink } from 'prismic-reactjs'

import { useTrackClick } from '../..'

import styles from './styles.module.scss'

interface Props {
  className?: string
  button_style?: 'primary' | 'significant' | 'secondary' | 'tertiary' | 'disabled' | 'ghost' | 'negative' | 'inverted'
  link_text?: string
  link_url?: PrismicLink
  link_relative_url?: string

  link_tracking_code?: string
  link_tracking_section?: string
  link_tracking_feature?: string
  link_tracking_family?: string
}

const NavLink: FC<Props> = ({
  link_text: text,
  link_url: url,
  link_relative_url: url_relative,
  link_tracking_code: trackingCode,
  link_tracking_section: trackingSection,
  link_tracking_feature: trackingFeature,
  link_tracking_family: trackingFamily,
  button_style: buttonStyle = 'primary',
}) => {
  const trackClick = useTrackClick()
  const href = useMemo(() => (url?.url ? url.url : url_relative || ''), [url, url_relative])
  const target = useMemo(() => (url?.url ? url.target : ''), [url])

  const handleClick = useCallback(
    async (e: MouseEvent<HTMLAnchorElement>) => {
      if (!trackingCode) return
      e.preventDefault()

      await trackClick({
        code: trackingCode,
        section: trackingSection,
        feature: trackingFeature,
        family: trackingFamily,
      })

      if (target == '_blank') {
        window.open(href, '_blank')?.focus()
      } else {
        window.location.href = href
      }
    },
    [trackClick, trackingCode, trackingSection, trackingFeature, trackingFamily, href, target]
  )

  return (
    <li className={styles.navButton}>
      <a className={styles[buttonStyle]} href={href} target={target} onClick={handleClick}>
        {text}
      </a>
    </li>
  )
}

export default NavLink
