import React, { FC } from 'react'

interface Props {
  className?: string
}

const EventBusy: FC<Props> = ({ className }) => (
  <svg className={className} viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.14 34.94L23.96 31.12L27.78 34.94C28.36 35.52 29.32 35.52 29.9 34.94C30.48 34.36 30.48 33.4 29.9 32.82L26.08 29L29.9 25.18C30.48 24.6 30.48 23.64 29.9 23.06C29.32 22.48 28.36 22.48 27.78 23.06L23.96 26.88L20.14 23.06C19.56 22.48 18.6 22.48 18.02 23.06C17.44 23.64 17.44 24.6 18.02 25.18L21.84 29L18.02 32.82C17.44 33.4 17.44 34.36 18.02 34.94C18.6 35.52 19.56 35.52 20.14 34.94ZM38.46 8H36.46V6C36.46 4.9 35.56 4 34.46 4C33.36 4 32.46 4.9 32.46 6V8H16.46V6C16.46 4.9 15.56 4 14.46 4C13.36 4 12.46 4.9 12.46 6V8H10.46C8.23996 8 6.47996 9.8 6.47996 12L6.45996 40C6.45996 42.2 8.23996 44 10.46 44H38.46C40.66 44 42.46 42.2 42.46 40V12C42.46 9.8 40.66 8 38.46 8ZM36.46 40H12.46C11.36 40 10.46 39.1 10.46 38V18H38.46V38C38.46 39.1 37.56 40 36.46 40Z"
      fill="currentColor"
    />
  </svg>
)

export default EventBusy
