import React, { FC } from 'react'
import cx from 'classnames'

import PresentRichText, { hasTextPresent } from 'pages/_serverRendered/CmsPage/Section/Components/PresentRichText'
import { RichTextBlock } from 'prismic-reactjs'

import styles from './styles.module.scss'

interface Props {
  className?: string
  pre_title?: RichTextBlock[]
  title?: RichTextBlock[]
  post_title?: RichTextBlock[]
  reference_id?: string
}
const TitleHeader: FC<Props> = ({ className, pre_title, title, post_title, reference_id }) => {
  // no render if no titles.
  if (!hasTextPresent(pre_title) && !hasTextPresent(title) && !hasTextPresent(post_title)) {
    return null
  }
  return (
    <header className={cx(styles.titleHeader, className)} id={reference_id}>
      <PresentRichText className={styles.preTitle} render={pre_title} />
      <PresentRichText className={styles.title} render={title} />
      <PresentRichText className={styles.postTitle} render={post_title} />
    </header>
  )
}

export default TitleHeader
