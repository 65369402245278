import React, { FC } from 'react'

interface Props {
  className?: string
}

const Svg: FC<Props> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 16" height="17" width="16" className={className}>
    <path
      d="M14.9593 12.4459L11.4259 8.9126H10.7659L9.07258 10.6059V11.2659L12.6059 14.7993C12.8659 15.0593 13.2859 15.0593 13.5459 14.7993L14.9593 13.3859C15.2193 13.1326 15.2193 12.7059 14.9593 12.4459Z"
      fill="currentColor"
    />
    <path
      d="M12.0726 7.12593L13.0126 6.18593L14.4259 7.59927C15.2059 6.81927 15.2059 5.5526 14.4259 4.7726L12.0659 2.4126L11.1259 3.3526V1.4726L10.6593 0.999268L8.29925 3.35927L8.77258 3.8326H10.6593L9.71925 4.7726L10.4259 5.47927L8.49925 7.40593L5.74592 4.6526V3.70593L3.73258 1.6926L1.84592 3.57927L3.86592 5.59927H4.80592L7.55925 8.3526L6.99258 8.91927H5.57925L2.04592 12.4526C1.78592 12.7126 1.78592 13.1326 2.04592 13.3926L3.45925 14.8059C3.71925 15.0659 4.13925 15.0659 4.39925 14.8059L7.93258 11.2726V9.85927L11.3659 6.42593L12.0726 7.12593Z"
      fill="currentColor"
    />
  </svg>
)

export default Svg
