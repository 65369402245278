import cx from 'classnames'
import CoreText from './CoreText'
import CoreButton, { CoreButtonKind } from './CoreButton'
import CoreLightbox from './CoreLightbox'
import React, { FC, PropsWithChildren, ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import type { RedirectProps } from 'react-router-dom'

import styles from './CoreModal.module.scss'

/* A new modal that utilizes our Overlay system */

export type { CoreButtonKind } from './CoreButton'

export interface CoreModalButton {
  name: string
  kind?: CoreButtonKind
  disabled?: boolean
  type?: 'button' | 'submit' | 'reset'
  form?: string
  onClick?: () => void
  to?:
    | string
    | {
        pathname?: string
        search?: string
        state?: any
        hash?: string
        key?: string
      }
  href?: string
  push?: boolean
  from?: string
  path?: string
  exact?: boolean
  strict?: boolean
}

interface Props {
  className?: string
  icon?: ReactNode | (() => ReactNode)
  title?: string | (() => ReactNode)
  buttons?: string | CoreModalButton | Array<string | CoreModalButton> | (() => ReactNode | Array<ReactNode>)
  onClose?: (() => void) | RedirectProps
  kind?: 'info' | 'warning' | 'success' | 'danger'
}

function normalizeButton(button: string | CoreModalButton, onClose?: (() => void) | RedirectProps): CoreModalButton {
  if (typeof button === 'string') button = { name: button }
  // If button has no 'to', 'onClick', or 'form', then try to give it a way to function.
  if (!button.onClick && !button.to && !button.form) {
    button = Object.assign(button, typeof onClose === 'function' ? { onClick: onClose } : { ...onClose })
  }
  return button
}

const Modal: FC<PropsWithChildren<Props>> = ({ children, className, icon, title, buttons, onClose, kind = 'info' }) => {
  const buttonsToUse: Array<CoreModalButton> | (() => ReactNode | Array<ReactNode>) = !buttons
    ? []
    : typeof buttons === 'function'
      ? buttons
      : buttons instanceof Array
        ? buttons.map((button) => normalizeButton(button, onClose))
        : [normalizeButton(buttons, onClose)]

  const [isShadowNeeded, setIsShadowNeeded] = useState<boolean>(false)
  const sectionRef = useRef<HTMLElement | null>(null)

  const computeNeedForShadow = useCallback(() => {
    const section = sectionRef.current
    if (!section) return

    setIsShadowNeeded(section.scrollHeight > section.clientHeight)
  }, [])

  useEffect(() => {
    window.addEventListener('resize', computeNeedForShadow)
    return () => {
      window.removeEventListener('resize', computeNeedForShadow)
    }
  }, [computeNeedForShadow])

  useEffect(() => {
    const section = sectionRef.current
    if (!section) return

    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList' || mutation.type === 'attributes') {
          computeNeedForShadow()
        }
      }
    })
    observer.observe(section, { childList: true, subtree: true })
    return () => {
      observer.disconnect()
    }
  }, [computeNeedForShadow])

  return (
    <CoreLightbox
      className={cx(styles.modal, styles[kind], className)}
      placementControlClassName={styles.placementControl}
      onClose={onClose}
      position="bottom"
    >
      {icon ? <header>{typeof icon === 'function' ? icon() : icon}</header> : null}
      <section ref={sectionRef}>
        {title ? (
          <CoreText.Paragraph size="xl" weight="heavy" className={styles.title}>
            {typeof title === 'string' ? title : title()}
          </CoreText.Paragraph>
        ) : null}
        <div className={styles.content}>{children}</div>
      </section>
      {typeof buttonsToUse === 'function' || buttonsToUse.length > 0 ? (
        <footer className={cx({ [styles.withTopShadow]: isShadowNeeded })}>
          {typeof buttonsToUse === 'function'
            ? buttonsToUse()
            : buttonsToUse.map((button) => {
                const { name, ...others } = button
                return <CoreButton key={name} {...others} size="l" text={name} />
              })}
        </footer>
      ) : null}
    </CoreLightbox>
  )
}

export default Modal
