import React, { FC } from 'react'

interface Props {
  className?: string
}

const Svg: FC<Props> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 73 72" height="72" width="73" className={className}>
    <path
      d="M48.5 46.86C50.3557 46.86 51.86 45.3557 51.86 43.5C51.86 41.6443 50.3557 40.14 48.5 40.14C46.6443 40.14 45.14 41.6443 45.14 43.5C45.14 45.3557 46.6443 46.86 48.5 46.86Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M48.5 49.5C46.31 49.5 41.93 50.58 41.78 52.74C43.28 54.87 45.74 56.25 48.5 56.25C51.26 56.25 53.72 54.87 55.22 52.74C55.07 50.58 50.69 49.5 48.5 49.5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51.5 30.27V15.81L29 6L6.5 15.81V30.54C6.5 44.16 16.1 56.91 29 60C30.65 59.61 32.24 59.04 33.8 58.35C37.04 62.97 42.41 66 48.5 66C58.43 66 66.5 57.93 66.5 48C66.5 39.09 60.02 31.71 51.5 30.27ZM30.5 48C30.5 49.68 30.74 51.33 31.19 52.86C30.47 53.19 29.75 53.52 29 53.76C19.49 50.76 12.5 41.04 12.5 30.54V19.74L29 12.54L45.5 19.74V30.27C36.98 31.71 30.5 39.09 30.5 48ZM48.5 60C41.87 60 36.5 54.63 36.5 48C36.5 41.37 41.87 36 48.5 36C55.13 36 60.5 41.37 60.5 48C60.5 54.63 55.13 60 48.5 60Z"
      fill="currentColor"
    />
  </svg>
)

export default Svg
