import React from 'react'
import classNames from 'classnames'

import styles from './styles.module.scss'

interface Props {
  className?: string
  lowValue: string
  value: string
  highValue: string
}

const PercentileBarGraph: React.FC<Props> = ({ className, lowValue, value, highValue }) => {
  return (
    <div className={classNames(styles.graph, className)}>
      <div className={styles.bar}>
        <div className={styles.low}>
          <span className={styles.value}>{lowValue}</span>
          <span className={styles.label}>{'25th percentile'}</span>
        </div>
        <div className={styles.mid}>
          <span className={styles.value}>{value}</span>
          <span className={styles.label} />
        </div>
        <div className={styles.high}>
          <span className={styles.value}>{highValue}</span>
          <span className={styles.label}>{'75th percentile'}</span>
        </div>
      </div>
      <div className={styles.mobile}>
        <div className={styles.low}>
          <span className={styles.value}>{lowValue}</span>
          <span className={styles.label}>{'25th percentile'}</span>
        </div>
        <div className={styles.high}>
          <span className={styles.value}>{highValue}</span>
          <span className={styles.label}>{'75th percentile'}</span>
        </div>
      </div>
    </div>
  )
}

export default PercentileBarGraph
