import React, { FC } from 'react'
import cx from 'classnames'

import { SliceSocialProof } from '../../types'

import PresentRichText from 'pages/_serverRendered/CmsPage/Section/Components/PresentRichText'

import styles from './styles.module.scss'

interface Props {
  className?: string
  slice: SliceSocialProof
}
const SocialProof: FC<Props> = ({ className, slice }) => {
  return (
    <section className={cx(styles.socialProof, className)}>
      <div className={styles.scroll}>
        <div className={styles.quotes}>
          {slice.items.map((quote, i) => (
            <div className={styles.item} key={i}>
              {quote.logo.url && <img className={styles.logo} alt={quote.logo.alt || ''} src={quote.logo.url} />}
              <PresentRichText render={quote.quote} className={styles.quote} />
              {quote.cta_url && quote.cta_url.url && (
                <a className={styles.cta} href={quote.cta_url.url} target={quote.cta_url.target}>
                  {quote.cta_text}
                </a>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default SocialProof
