import { getJSON, postJSON, patchJSON, FetchResponse } from 'utils/fetch'
import { Property, Calendar } from './types'

export function formatDate(date: Date): string {
  const now = new Date()
  const dateDiff = date.getDate() - now.getDate()
  if (dateDiff == 0) return 'today'
  else if (dateDiff == 1) return 'tomorrow'
  else if (date.getFullYear() - now.getFullYear()) {
    return `on ${date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    })}`
  }

  return `on ${date.toLocaleDateString('en-US', {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
  })}`
}

export function formatProperty(property: Property): string {
  return `${property.delivery_line_1}, ${property.delivery_line_2 ? `${property.delivery_line_2}, ` : ''}${
    property.city
  } ${property.state} ${property.zip5}`
}

async function schedulerMethodJSON(
  method: typeof patchJSON | typeof postJSON,
  path: string,
  body: Record<string, unknown>,
  calendarId: string,
  onCalendarChange: React.Dispatch<React.SetStateAction<Calendar>>,
  attempt
): Promise<FetchResponse | false> {
  // If we're on our third attempt, get out with a failure
  if (attempt > 3) {
    return false
  }

  const response = await method(path, body)

  let retry = false
  if (response.code == 422) {
    // Their session changed in some way. Grab new token and try again.
    try {
      // Refresh our calendar info.
      const cResponse = await getJSON<Calendar>(`/vendor_site_visits/${calendarId}`)
      onCalendarChange((prev) => ({ ...prev, ...cResponse }))
      // CSRF needs to be updated immediately to have an impact on the
      // next attempt
      document.querySelector('meta[name=csrf-param]')?.setAttribute('content', cResponse.csrf_param)
      document.querySelector('meta[name=csrf-token]')?.setAttribute('content', cResponse.csrf_token)

      retry = true
    } catch {
      // We'll return the 422 errored response in this case.
    }

    if (retry) {
      const newResponse = await schedulerMethodJSON(method, path, body, calendarId, onCalendarChange, attempt + 1)
      // If our last attempt had a non-false response, use that.
      // Otherwise use the response from this scope's attempt even if it is bad.
      if (newResponse) return newResponse
    }
  }

  return response
}

export async function schedulerPostJSON(
  path: string,
  body: Record<string, unknown>,
  calendarId: string,
  onCalendarChange: React.Dispatch<React.SetStateAction<Calendar>>,
  attempt = 1
): Promise<FetchResponse> {
  const response = await schedulerMethodJSON(postJSON, path, body, calendarId, onCalendarChange, attempt)
  return response as FetchResponse
}

export async function schedulerPatchJSON(
  path: string,
  body: Record<string, unknown>,
  calendarId: string,
  onCalendarChange: React.Dispatch<React.SetStateAction<Calendar>>,
  attempt = 1
): Promise<FetchResponse> {
  const response = await schedulerMethodJSON(patchJSON, path, body, calendarId, onCalendarChange, attempt)
  return response as FetchResponse
}
