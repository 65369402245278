import React, { FC, PropsWithChildren } from 'react'
import cx from 'classnames'

import { Calendar } from './types'

import Message from './components/Message'
import PointOfContact from './PointOfContact'
import HouseholdDetails from './HouseholdDetails'

import styles from './CompletedLayout.module.scss'

interface Props {
  className?: string
  calendar: Calendar
  title: string
  description?: string
  helpMessage: string
  icon: React.ReactNode
  type: 'danger' | 'success'
  screen: string
}
const CompletedLayout: FC<PropsWithChildren<Props>> = ({
  className,
  calendar,
  title,
  description,
  helpMessage,
  icon,
  type,
  screen,
  children,
}) => {
  return (
    <div className={cx(styles.completedLayout, className)}>
      <Message title={title} description={description} icon={icon} type={type}>
        {children}
      </Message>
      <PointOfContact advisor={calendar.advisor} message={helpMessage} screen={screen} />
      <HouseholdDetails property={calendar.property} screen={screen} />
    </div>
  )
}

export default CompletedLayout
