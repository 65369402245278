import cx from 'classnames'
import Lightbox from '../Lightbox'
import React, { FC, PropsWithChildren } from 'react'
import type { RedirectProps } from 'react-router-dom'

import styles from './CoreLightbox.module.scss'

/* A new modal that utilizes our Overlay system */

interface Props {
  className?: string
  placementControlClassName?: string
  position?: 'center' | 'top' | 'bottom'
  onClose?: (() => void) | RedirectProps
}

const CoreLightbox: FC<PropsWithChildren<Props>> = ({
  children,
  className,
  placementControlClassName,
  position = 'bottom',
  onClose,
}) => {
  return (
    <Lightbox
      className={cx(styles.lightbox, className)}
      placementControlClassName={placementControlClassName}
      onClose={onClose}
      position={position}
      blurredBackground
    >
      {children}
    </Lightbox>
  )
}

export default CoreLightbox
