import React, { FC } from 'react'
import cx from 'classnames'
import { RichTextBlock } from 'prismic-reactjs'

import { Image } from '../../types'

import styles from './styles.module.scss'

interface Props {
  className?: string
  image: RichTextBlock & { desktop_2x: RichTextBlock }
}

const Image: FC<Props> = ({ className, image }) => {
  const srcset = [
    { img: image, size: '1x' },
    { img: image?.desktop_2x, size: '2x' },
  ]
    .filter((img) => !!img.img)
    .map((img) => `${img.img?.url} ${img.size}`)
    .join(',')

  return (
    <div key={image.url} className={cx(className, styles.imgSlot)}>
      <img src={image.url} srcSet={srcset} alt={image?.alt || ''} />
    </div>
  )
}

export default Image
