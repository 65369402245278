import React, { FC } from 'react'

import { Calendar } from './types'

import UnavailableIcon from 'svgs/alarm-off'

import CompletedLayout from 'pages/_serverRendered/VendorSiteVisit/CompletedLayout'

interface Props {
  calendar: Calendar
}
const Unavailable: FC<Props> = ({ calendar }) => {
  return (
    <CompletedLayout
      calendar={calendar}
      title="No remaining time available for this site visit"
      description="Unfortunately there are no longer any times available to schedule a site visit for this project."
      type="danger"
      icon={<UnavailableIcon />}
      helpMessage="If you believe there is an error, or would still like to schedule a site visit for this project, contact us."
      screen="unavailable"
    />
  )
}

export default Unavailable
