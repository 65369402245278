import React, { FC, useMemo } from 'react'
import { Link as PrismicLinkData } from 'prismic-reactjs'
import cx from 'classnames'

import WistiaVideo from 'components/WistiaVideo'

import styles from './styles.module.scss'

const extractWistiaVideoIdFromUrl = (url: string) => {
  const match = url.match(/myrealm.wistia.com\/medias\/(.+)/)
  if (match && match.length > 1) {
    return match[1]
  }

  return null
}

interface Props {
  media: PrismicLinkData
  isInfoGraphic: boolean
}
const Media: FC<Props> = ({ media, isInfoGraphic }) => {
  const videoId = useMemo(() => {
    if (media && media.link_type == 'Web') {
      return extractWistiaVideoIdFromUrl(media.url || '')
    }
  }, [media])

  return (
    <div className={styles.mediaContainer}>
      {media && media.link_type === 'Media' && (
        <img alt="" src={media.url} className={cx(styles.image, { [styles.infoGraphic]: isInfoGraphic })} />
      )}
      {media && media.link_type === 'Web' && videoId && (
        <div className={cx(styles.videoSubContainer, { [styles.infoGraphic]: isInfoGraphic })}>
          <WistiaVideo videoId={videoId} />
        </div>
      )}
    </div>
  )
}

export default Media
