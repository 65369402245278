import React, { FC } from 'react'

import styles from './styles.module.scss'

const Background: FC = () => (
  <div className={styles.background}>
    <div className={styles.bgContainer}>
      <span className={styles.ball7} />
      <span className={styles.ball6} />
      <span className={styles.ball5} />
      <span className={styles.ball4} />
      <span className={styles.ball3} />
      <span className={styles.ball2} />
      <span className={styles.ball1} />
    </div>
  </div>
)

export default Background
