import React, { FC } from 'react'

import RealmLogo, {
  RealmLogoSmall,
  RealmLogoPride,
  RealmLogoSmallPride,
} from 'components/MarketingSite/Header/RealmLogo'

import styles from './styles.module.scss'

const Logo: FC = () => {
  if (process.env.PRIDE_MODE == 'true') {
    return (
      <>
        <RealmLogoPride className={styles.logo} />
        <RealmLogoSmallPride className={styles.logoMobile} />
      </>
    )
  }
  return (
    <>
      <RealmLogo className={styles.logo} />
      <RealmLogoSmall className={styles.logoMobile} />
    </>
  )
}

export default Logo
