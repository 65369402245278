import React, { FC, useCallback, useMemo, useState } from 'react'
import { RichTextBlock } from 'prismic-reactjs'
import cx from 'classnames'

import { track } from 'utils/analytics'
import { SliceListCard } from '../../types'

import Card from 'pages/_serverRendered/CmsPage/Section/Components/Card'
import PresentRichText, { hasTextPresent } from 'pages/_serverRendered/CmsPage/Section/Components/PresentRichText'
import { thumbnailUrl } from 'pages/_serverRendered/CmsPage/utils'

import styles from './styles.module.scss'

interface ListCardItemProps {
  name?: string
  description?: RichTextBlock[]
}

const ListCardItem: FC<ListCardItemProps> = ({ name, description }) => {
  const [open, setOpen] = useState(false)
  const handleToggleOpen = useCallback(() => {
    setOpen((prev) => !prev)
  }, [])

  return (
    <li className={cx({ [styles.open]: open })}>
      <button type="button" onClick={handleToggleOpen}>
        {name}
      </button>
      <div className={styles.bulletDescription}>
        <PresentRichText render={description} />
      </div>
    </li>
  )
}

interface ListCardProps {
  className?: string
  slice: SliceListCard
}

const ListCard: FC<ListCardProps> = ({ className, slice }) => {
  const url = useMemo(() => {
    return slice.primary?.cta_url?.url || slice.primary?.cta_relative_url || null
  }, [slice])
  const target = useMemo(() => {
    return slice.primary?.cta_url?.target || null
  }, [slice])
  const handleClick = useCallback(
    async (event: React.MouseEvent<HTMLAnchorElement>) => {
      if (!url) return

      event.preventDefault()
      await track('click cta', {
        cta: `list-card${slice.primary.nav_id ? `-${slice.primary.nav_id}` : ''}`,
        module: 'cms-marketing',
      })
      if (target === '_blank') {
        window.open(url, '_blank')?.focus()
      } else {
        window.location.href = url
      }
    },
    [slice, url, target]
  )

  return (
    <section className={cx(styles.listCardOuter, className)}>
      <Card id={slice.primary.nav_id} backgroundColor={slice.primary.background_color} className={styles.listCard}>
        {hasTextPresent(slice.primary.title) && <PresentRichText className={styles.h2} render={slice.primary.title} />}
        {slice.primary.image.url && (
          <img
            className={styles.image}
            src={thumbnailUrl(slice.primary.image.url)}
            alt={slice.primary.image.alt || ''}
          />
        )}
        {hasTextPresent(slice.primary.subheader) && <h3 className={styles.h3}>{slice.primary.subheader[0].text}</h3>}
        {hasTextPresent(slice.primary.description) && (
          <div className={styles.description}>
            <PresentRichText render={slice.primary.description} />
          </div>
        )}
        {slice.items.length > 0 && (
          <div className={cx(styles.bulletsContainer, styles[slice.primary.bullets_style])}>
            <ul>
              {slice.items.map((bullet, i) => (
                <ListCardItem key={i} name={bullet.bullet_name} description={bullet.bullet_description} />
              ))}
            </ul>
          </div>
        )}
        {hasTextPresent(slice.primary.closing_text) && (
          <div className={styles.closingText}>
            <PresentRichText render={slice.primary.closing_text} />
          </div>
        )}
        {url ? (
          <a className={styles.cta} href={url} target={target || undefined} onClick={handleClick}>
            {slice.primary.cta_text}
          </a>
        ) : null}
      </Card>
    </section>
  )
}

export default ListCard
