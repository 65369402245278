import dayjs from 'dayjs'
import { DefaultValue } from 'recoil'

export const sessionStorageEffect =
  (key, expirationInHours) =>
  ({ setSelf, onSet }) => {
    // Don't do this if we're SSR
    if (typeof window === 'undefined') return

    const savedValue = sessionStorage.getItem(key)

    const expKey = `${key}-exp`
    const timestamp = sessionStorage.getItem(expKey)
    const expired = expirationInHours && (!timestamp || dayjs() >= dayjs(timestamp).add(expirationInHours, 'hours'))

    if (expired) {
      setSelf(new DefaultValue())
    } else if (savedValue != null) {
      setSelf(JSON.parse(savedValue))
    }

    onSet((newValue) => {
      if (newValue instanceof DefaultValue) {
        sessionStorage.removeItem(key)
        sessionStorage.removeItem(expKey)
      } else {
        sessionStorage.setItem(key, JSON.stringify(newValue))
        sessionStorage.setItem(expKey, dayjs().toISOString())
      }
    })
  }

export const localStorageEffect =
  (key, expirationInHours?) =>
  ({ setSelf, onSet }) => {
    // Don't do this if we're SSR
    if (typeof window === 'undefined') return

    const savedValue = localStorage.getItem(key)

    const expKey = `${key}-exp`
    const timestamp = localStorage.getItem(expKey)
    const expired = expirationInHours && (!timestamp || dayjs() >= dayjs(timestamp).add(expirationInHours, 'hours'))

    if (expired) {
      setSelf(new DefaultValue())
    } else if (savedValue != null) {
      setSelf(JSON.parse(savedValue))
    }

    onSet((newValue) => {
      if (newValue instanceof DefaultValue) {
        localStorage.removeItem(key)
        localStorage.removeItem(expKey)
      } else {
        localStorage.setItem(key, JSON.stringify(newValue))
        localStorage.setItem(expKey, dayjs().toISOString())
      }
    })
  }
