import React, { FC } from 'react'
import { colors, colorsToStyle } from 'pages/_serverRendered/CmsPage/Section/Components/Card'

import { BrandColor } from 'pages/_serverRendered/CmsPage/types'
import { RichTextBlock } from 'prismic-reactjs'
import classnames from 'classnames'
import styles from './styles.module.scss'

interface ScrollingCardsProps {
  backgroundColor?: BrandColor
  primary?: { card_color?: BrandColor }
  items: {
    body1: RichTextBlock[]
    footer: RichTextBlock[]
    title: RichTextBlock[]
  }[]
}

const ScrollingCards: FC<ScrollingCardsProps> = ({ backgroundColor, items, primary }) => {
  return (
    <div className={classnames(styles.container)}>
      {items.map((item) => (
        <div
          className={styles.item}
          key={item.title[0]?.text}
          style={
            backgroundColor ?? primary?.card_color
              ? colorsToStyle[backgroundColor ?? (primary?.card_color || '')]
              : { backgroundColor: colors['lapis-regular'], color: 'white' }
          }
        >
          {item.title[0] != null && <p className={styles.title}>{item.title[0].text}</p>}
          {item.body1[0] != null && (
            <div className={styles.body}>
              <p>{item.body1[0].text}</p>
            </div>
          )}
          {item.footer[0] != null && <p className={styles.footer}>{item.footer[0].text}</p>}
        </div>
      ))}
    </div>
  )
}

export default ScrollingCards
