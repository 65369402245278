import React, { FC } from 'react'
import styles from './InternalLinks.module.scss'
import cx from 'classnames'

export interface InternalLink {
  label: string
  destination_url: string
  source_url: string
}

export interface Props {
  links: InternalLink[]
  className?: string
}

const InternalLinks: FC<Props> = ({ links, className }) => {
  return (
    <div className={cx(styles.internalLinks, className)}>
      {links.map((link) => {
        return (
          <ul key={link.destination_url}>
            <li>
              <a key={link.destination_url} href={link.destination_url}>
                {link.label}
              </a>
            </li>
          </ul>
        )
      })}
    </div>
  )
}

export default InternalLinks
