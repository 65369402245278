import React from 'react'

import Bath from 'svgs/bath'
import Bed from 'svgs/bed'
import cx from 'classnames'
import { ProjectItem } from 'pages/_serverRendered/landing/ContractorsNearMe'
import styles from './styles.module.scss'
import { formatCurrency } from 'utils/formatting'
import ImageWithBackup from 'components/ImageWithBackup'
import DefaultAvatar from 'components/DefaultAvatar'

interface Props {
  label: string
  projects: ProjectItem[]
  loading: boolean
  columns?: number
}

const ProjectListings: React.FC<Props> = ({ projects = [], loading, columns }) => {
  if (projects.length === 0 && !loading) return null

  return (
    <div className={cx(styles.resultItems, styles[`columns${columns}`], { [styles.isLoading]: loading })}>
      {projects.map((result, i) => {
        if (!result) {
          return <div key={`i_${i}`} className={cx(styles.resultItem, styles.placeholder)} />
        }
        return (
          <div key={result.id} className={styles.resultItem}>
            <a itemProp="url" href={result.url}>
              <ImageWithBackup
                primary={result.image_url}
                backup={
                  <ImageWithBackup
                    primary={result.backup_image_url}
                    backup={<DefaultAvatar className={styles.avatar} />}
                  />
                }
              />
              <div itemProp="address">{result.display_address}</div>
            </a>
            <div>
              {'Home value: '}
              <span className={styles.price}>{formatCurrency(result.value)}</span>
            </div>
            <div className={styles.details}>
              <div>
                <div itemProp="numberOfBedrooms">{result.bedroomscount}</div>
                <Bed />
              </div>
              <div>
                <div itemProp="numberOfBathroomsTotal">{result.bathcount}</div>
                <Bath />
              </div>
              <div>{`${result.areabuilding} sqft`}</div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default ProjectListings
