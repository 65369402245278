import React, { FC } from 'react'

import { ContentTable } from 'components/MarketingSite/PageContent/types'

import styles from './styles.module.scss'

const Table: FC<ContentTable> = ({ items }) => (
  <>
    <ul className={styles.table}>
      {items.map((item) => (
        <li key={item.title}>
          <h4 className={styles.title}>{item.title}</h4>
          {item.body != null && <p>{item.body}</p>}
        </li>
      ))}
    </ul>
  </>
)

export default Table
