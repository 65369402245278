import React, { FC } from 'react'

interface Props {
  className?: string
}

const Svg: FC<Props> = ({ className }) => (
  <svg className={className} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g fill="currentColor" fillRule="evenodd">
      <path d="M21.6742 18.4075L16.7342 13.4675L14.6142 15.5875L19.5542 20.5275C20.1442 21.1175 21.0942 21.1175 21.6742 20.5275C22.2542 19.9375 22.2542 18.9875 21.6742 18.4075Z" />
      <path d="M18.3342 10.4175C20.2642 10.4175 21.8342 8.84753 21.8342 6.91753C21.8342 6.33753 21.6742 5.79753 21.4242 5.31753L18.7242 8.01753L17.2342 6.52753L19.9342 3.82753C19.4542 3.57753 18.9142 3.41753 18.3342 3.41753C16.4042 3.41753 14.8342 4.98753 14.8342 6.91753C14.8342 7.32753 14.9142 7.71753 15.0442 8.07753L13.1942 9.92753L11.4142 8.14753C11.8042 7.75753 11.8042 7.12753 11.4142 6.73753L10.7042 6.02753L12.8242 3.90753C11.6542 2.73753 9.75415 2.73753 8.58415 3.90753L5.76415 6.73753C5.37415 7.12753 5.37415 7.75753 5.76415 8.14753L6.47415 8.85753H3.93415C3.74415 8.85753 3.56415 8.92753 3.43415 9.06753C3.15415 9.34753 3.15415 9.78753 3.43415 10.0675L5.97415 12.6075C6.25415 12.8875 6.69415 12.8875 6.97415 12.6075C7.10415 12.4775 7.18415 12.2975 7.18415 12.1075V9.56753L7.88415 10.2675C8.27415 10.6575 8.90415 10.6575 9.29415 10.2675L11.0742 12.0475L4.72415 18.3975C4.13415 18.9875 4.13415 19.9375 4.72415 20.5175C5.31415 21.1075 6.26415 21.1075 6.84415 20.5175L17.1642 10.2075C17.5342 10.3375 17.9242 10.4175 18.3342 10.4175Z" />
    </g>
  </svg>
)

export default Svg
