import React from 'react'

import { formatCurrency } from 'utils/formatting'

import PercentialBarGraph from '../PercentialBarGraph'
import Tooltip from 'components/PropertyPages/ToolTip'

import styles from './styles.module.scss'

interface Props {
  values: [number, number, number]
}
const AverageHomeValuePotentialIncrease: React.FC<Props> = ({ values }) => {
  return (
    <div className={styles.container}>
      <Tooltip className={styles.tooltip}>
        {
          "How much each home's value could increase with additional investment. We've analyzed what upgrades and changes are possible on each property in this neighborhood."
        }
      </Tooltip>
      <h3>{'Average Untapped Potential Value'}</h3>
      <PercentialBarGraph
        lowValue={formatCurrency(values[0])}
        value={formatCurrency(values[1])}
        highValue={formatCurrency(values[2])}
      />
    </div>
  )
}

export default AverageHomeValuePotentialIncrease
