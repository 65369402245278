import React, { FC, useEffect } from 'react'

import AddressAutocomplete from 'components/MarketingSite/Header/AddressAutocomplete'
import Footer from 'components/MarketingSite/Footer'
import Header, { calculateNavItems } from 'components/MarketingSite/Header'
import GetStarted from 'pages/_serverRendered/Homepage/GetStarted'
import PageContent from 'components/MarketingSite/PageContent'
import meetContent from 'pages/_serverRendered/Meet/content'
import { trackPage } from 'utils/analytics'

import styles from './styles.module.scss'

const Meet: FC = () => {
  useEffect(() => {
    trackPage('Meet')
  }, [])

  return (
    <div>
      <Header activeNavItem={calculateNavItems().Meet}>
        <AddressAutocomplete positionLabel="header" />
      </Header>
      <PageContent content={meetContent} />
      <GetStarted className={styles.getStarted} />
      <Footer />
    </div>
  )
}

export default Meet
