import React, { FC, useMemo } from 'react'
import cx from 'classnames'

import { PhotoCard as PhotoCardType } from '../../types'
import { thumbnailUrl } from 'pages/_serverRendered/CmsPage/utils'

import ClipboardIcon from 'svgs/clipboard'
import ToolsIcon from 'svgs/tools'
import ContractorIcon from 'svgs/contractor-2'
import PhoneIcon from 'svgs/phone'
import DollarSignIcon from 'svgs/dollar-sign'
import MoneyIcon from 'svgs/money'
import EmailIcon from 'svgs/email'

import PresentRichText from 'pages/_serverRendered/CmsPage/Section/Components/PresentRichText'

import styles from './styles.module.scss'

interface Props {
  card: PhotoCardType
}
const Card: FC<Props> = ({ card }) => {
  const icon = useMemo(() => {
    if (card.icon == 'Clipboard') return <ClipboardIcon />
    if (card.icon == 'Tools') return <ToolsIcon />
    if (card.icon == 'Contractor') return <ContractorIcon />
    if (card.icon == 'Phone') return <PhoneIcon />
    if (card.icon == 'DollarSign') return <DollarSignIcon />
    if (card.icon == 'Money') return <MoneyIcon />
    if (card.icon == 'Email') return <EmailIcon />

    return <span />
  }, [card.icon])
  const color = card.image_background_color ? { backgroundColor: card.image_background_color } : {}
  return (
    <div className={styles.card}>
      {card.image.url ? (
        <img
          className={cx(styles.image, { [styles.centered]: card.image_position == 'Centered' })}
          alt={card.image.alt || ''}
          src={thumbnailUrl(card.image.url)}
          style={{ ...color }}
        />
      ) : (
        <div className={styles.image} style={{ ...color }} />
      )}
      <div className={styles.info}>
        {card.icon != 'NONE' ? <div className={styles.icon}>{icon}</div> : null}
        <PresentRichText render={card.title} className={styles.cardTitle} />
        <PresentRichText render={card.description} className={styles.description} />
        <PresentRichText render={card.description_alt} className={styles.descriptionAlt} />
      </div>
    </div>
  )
}

export default Card
