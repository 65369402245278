import React, { FC } from 'react'
import cx from 'classnames'

import { SliceCustomerSocialProof } from '../../types'

import PresentRichText, { hasTextPresent } from 'pages/_serverRendered/CmsPage/Section/Components/PresentRichText'

import SectionTitle from '../../Components/SectionTitle'
import CallToAction from '../../Components/CallToAction'
import Testimonials from './Testimonials'

import styles from './styles.module.scss'

interface Props {
  className?: string
  slice: SliceCustomerSocialProof
}
const CustomerSocialProof: FC<Props> = ({ className, slice }) => {
  const {
    nav_id,
    background_color,
    title,
    subtitle,
    cta_text,
    cta_relative_url,
    cta_url,

    cta_tracking_code,
    cta_tracking_section,
    cta_tracking_feature,
    cta_tracking_family,

    scroll_tracking_code,
    scroll_tracking_section,
    scroll_tracking_family,
  } = slice.primary
  return (
    <section
      id={nav_id}
      className={cx(styles.customerSocialProof, { [styles.gray]: background_color == 'Gray' }, className)}
    >
      <div className={styles.titleBox}>
        <SectionTitle className={styles.title} title={title} />
        {(cta_text || hasTextPresent(subtitle)) && (
          <div className={styles.subtitleContent}>
            {hasTextPresent(subtitle) && <PresentRichText className={styles.subtitleText} render={subtitle} />}
            {cta_text && (
              <CallToAction
                className={styles.cta}
                url={cta_url}
                url_relative={cta_relative_url}
                trackingCode={cta_tracking_code}
                trackingSection={cta_tracking_section}
                trackingFeature={cta_tracking_feature}
                trackingFamily={cta_tracking_family}
              >
                {cta_text}
              </CallToAction>
            )}
          </div>
        )}
      </div>
      <Testimonials
        className={styles.testimonials}
        testimonials={slice.items}
        trackingCode={scroll_tracking_code}
        trackingSection={scroll_tracking_section}
        trackingFamily={scroll_tracking_family}
      />
    </section>
  )
}

export default CustomerSocialProof
