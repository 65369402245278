import classnames from 'classnames'
import React, { FC } from 'react'

import Cta from 'components/MarketingAndPropertyPages/Cta'
import { ContentType, SectionType } from 'components/MarketingSite/PageContent/types'
import MarketingAccordion from 'components/MarketingAndPropertyPages/Accordion/MarketingAccordion'
import Blog from 'components/MarketingSite/PageContent/Section/Blog'
import Cards from 'components/MarketingSite/PageContent/Section/Cards'
import Faq from 'components/MarketingSite/PageContent/Section/Faq'
import List from 'components/MarketingSite/PageContent/Section/List'
import Markdown from 'components/Markdown'
import Menu from 'components/MarketingSite/PageContent/Section/Menu'
import ScrollingCards from 'components/MarketingSite/PageContent/Section/ScrollingCards'
import Table from 'components/MarketingSite/PageContent/Section/Table'
import Text from 'components/MarketingSite/PageContent/Section/Text'

import styles from './styles.module.scss'

type Props = SectionType & {
  className?: string
}

const Section: FC<Props> = ({ className, navItem, title, body, cardBackgroundColor, contentType, content, cta }) => (
  <section
    className={classnames(
      styles.section,
      { [styles.overflow]: contentType === 'scrollingCards', [styles[cardBackgroundColor || '']]: cardBackgroundColor },
      className
    )}
    id={navItem?.slug}
  >
    {title != null && (
      <Markdown options={{ wrapper: 'h2' }} className={styles.title}>
        {title}
      </Markdown>
    )}
    {body != null && <p className={styles.body}>{body}</p>}
    {contentType && <SectionContent contentType={contentType} content={content} />}
    {cta != null && (
      <Cta className={styles.cta} href={cta.href}>
        {cta.title}
      </Cta>
    )}
  </section>
)

const SectionContent: FC<{ contentType: ContentType; content: any }> = ({ contentType, content }) => {
  switch (contentType) {
    case 'accordion':
      return <MarketingAccordion {...content} />
    case 'blog':
      return <Blog {...content} />
    case 'cards':
      return <Cards {...content} />
    case 'faq':
      return <Faq {...content} />
    case 'list':
      return <List {...content} />
    case 'menu':
      return <Menu {...content} />
    case 'scrollingCards':
      return <ScrollingCards {...content} />
    case 'table':
      return <Table {...content} />
    case 'text':
      return <Text {...content} />
    default:
      return null
  }
}

export default Section
