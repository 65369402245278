import React, { FC, useEffect, useState, useRef, useCallback } from 'react'
import cx from 'classnames'

import { track, trackPage } from 'utils/analytics'

import { CmsPageData, Slice as SliceType } from './types'
import { ConvertAutocompleteRedirectTarget } from 'pages/_serverRendered/CmsPage/utils'

import ShowOrHideAdjustBanner from 'non-rendering/ShowOrHideAdjustBanner'
import CTAAdjustment, { useCTAAdjustment } from 'pages/_serverRendered/CmsPageV2/CtaAdjustment'
import { PlaceholderReplacements } from 'pages/_serverRendered/CmsPage/Section/Components/PresentRichText/processPlaceholders'

import AddressAutocompleteField from './AddressAutocompleteField'
import Header from 'components/MarketingSite/Header'
import Button from 'components/NikhilButton'

import Hero from './Hero'
import GetStarted from './GetStarted'
import BrowseStates from './BrowseStates'
import Footer from './Footer'

import CustomEmbed from './Sections/CustomEmbed'
import InfoPoints from './Sections/InfoPoints'
import ImageQuotes from './Sections/ImageQuotes'
import PhotoCards from './Sections/PhotoCards'
import ListCards from './Sections/ListCards'
import SocialProof from './Sections/SocialProof'
import TableOfContents from './Sections/TableOfContents'
import FoldingItems from './Sections/FoldingItems'
import InfoTable from './Sections/InfoTable'

import styles from './styles.module.scss'

const Slice: FC<SliceType> = (props) => {
  const { ...slice } = props

  if (
    [
      'custom_embed',
      'info_points_v2',
      'image_quotes_v2',
      'photo_cards_v2',
      'list_cards_v2',
      'social_proof_v2',
      'table_of_contents_v2',
      'folding_items_v2',
      'info_table_v2',
    ].includes(slice.slice_type || '')
  ) {
    return (
      <>
        {slice.slice_type == 'custom_embed' ? (
          <CustomEmbed slice={slice} className={styles.section} />
        ) : slice.slice_type === 'info_points_v2' ? (
          <InfoPoints slice={slice} className={cx(styles.section, styles.infoPoints)} />
        ) : slice.slice_type === 'image_quotes_v2' ? (
          <ImageQuotes slice={slice} className={styles.section} />
        ) : slice.slice_type === 'photo_cards_v2' ? (
          <PhotoCards slice={slice} className={styles.section} />
        ) : slice.slice_type === 'list_cards_v2' ? (
          <ListCards slice={slice} className={styles.section} />
        ) : slice.slice_type === 'social_proof_v2' ? (
          <SocialProof slice={slice} className={styles.section} />
        ) : slice.slice_type === 'table_of_contents_v2' ? (
          <TableOfContents slice={slice} className={styles.section} />
        ) : slice.slice_type === 'folding_items_v2' ? (
          <FoldingItems slice={slice} className={styles.section} />
        ) : slice.slice_type === 'info_table_v2' ? (
          <InfoTable slice={slice} className={styles.section} />
        ) : null}
      </>
    )
  }
  return null
}

const CmsPage: FC<{
  data: CmsPageData
  pageName: string
  webview: boolean
  slug?: string
  placeholder_replacements?: Record<string, string>
}> = ({
  data: {
    body,
    hero,
    get_started,
    browse_states,
    nav_how_it_works,
    nav_why_realm,
    nav_resources,
    nav_about,
    nav_dashboard,
    nav_sign_in,
    nav_get_started,
    autocomplete_redirect_target,
    page_id_for_tracking,
    address_autocomplete_visible,
    address_label,
    show_cta,
    cta_forward_utm_params,
    cta_referral_utm_params,
    cta_text,
    cta_url,
    cta_relative_url,
  },
  pageName,
  webview,
  slug,
  placeholder_replacements,
}) => {
  const headerRef = useRef<HTMLElement>(null)
  const [heroAddressInputVisible, setHeroAddressInputVisible] = useState(
    hero?.[0] != null && hero[0].address_autocomplete_visible
  )
  const [heroCtaVisible, setHeroCtaVisible] = useState(hero?.[0] != null && hero[0].show_cta)
  const [campaignId, setCampaignId] = useState<string>()
  const { url } = useCTAAdjustment(cta_url?.url, cta_referral_utm_params, cta_forward_utm_params, slug)

  useEffect(() => {
    trackPage(pageName || page_id_for_tracking)
  }, [pageName, page_id_for_tracking])

  // Ideally, the marketing campaign Id also comes from prismic.
  // Since we only have one campaign right now, we'll fake it here.
  useEffect(() => {
    // Set the campaignId only after the client loads, so we can detect what
    // page we're on and if we should do it. This whole useEffect block
    // won't be necessary if/when we push the campaignId from prismic
    if (window.location.pathname == '/l/giveaway') {
      const url = new (URL || window.URL)(window.location.href)
      if (!url.searchParams.has('mc')) {
        const campaignId = encodeURIComponent(btoa('giveaway-2021-10-18'))
        url.searchParams.append('mc', campaignId)
        window.history.replaceState(null, '', url.toString())
        setCampaignId(campaignId)
      } else {
        setCampaignId(url.searchParams.get('mc') as string)
      }
    }
  }, [])

  const handleRequestHeaderHeight = useCallback(() => {
    if (headerRef.current) return headerRef.current.offsetHeight
  }, [])

  const handleCtaClick = useCallback(async () => {
    await track('click cta', { cta: 'landing-page-header', module: 'header' })

    if (!cta_url && !cta_relative_url) return
    if (!url && !cta_relative_url) return

    if (url && cta_url && cta_url.target === '_blank') {
      window.open(url, '_blank')?.focus()
    } else {
      window.location.href = url || cta_relative_url || ''
    }
  }, [cta_url, url, cta_relative_url])

  return (
    <CTAAdjustment slug={slug}>
      <PlaceholderReplacements replacements={placeholder_replacements}>
        <div className={styles.cmsPage}>
          {!webview && (
            <Header
              ref={headerRef}
              className={styles.header}
              hideHowItWorksButton={nav_how_it_works === false}
              hideWhyRealmButton={nav_why_realm === false}
              hideResourcesButton={nav_resources === false}
              hideAboutButton={nav_about === false}
              hideDashboardButton={nav_dashboard === false}
              hideSignInButton={nav_sign_in === false}
              hideSignupButton={nav_get_started === false}
              campaignId={campaignId}
            >
              {address_autocomplete_visible != false ? (
                <div className={cx(styles.headerContainer, styles.addressContainer)}>
                  <AddressAutocompleteField
                    containerClassName={cx(styles.search, { [styles.hide]: heroAddressInputVisible })}
                    positionLabel="header"
                    variant={ConvertAutocompleteRedirectTarget(autocomplete_redirect_target)}
                    placeholder={address_label}
                  />
                </div>
              ) : show_cta ? (
                <div className={cx(styles.headerContainer)}>
                  <Button
                    className={cx(styles.cta, { [styles.hide]: heroCtaVisible })}
                    type="button"
                    onClick={handleCtaClick}
                  >
                    {cta_text || 'Get Started'}
                  </Button>
                </div>
              ) : (
                <div className={styles.headerContainer} />
              )}
            </Header>
          )}
          {hero?.[0] != null && (
            <Hero
              {...hero[0]}
              className={styles.hero}
              onAddressInputVisibleChange={setHeroAddressInputVisible}
              onCtaVisibleChange={setHeroCtaVisible}
              autocompleteRedirectTarget={autocomplete_redirect_target}
              onRequestHeaderHeight={handleRequestHeaderHeight}
            />
          )}
          {body.map((slice, index) => (
            <Slice {...slice} key={index} />
          ))}
          {!webview && (
            <>
              {get_started?.[0]?.show_get_started != false && (
                <GetStarted
                  className={cx(styles.section, styles.getStarted)}
                  {...get_started?.[0]}
                  variant={ConvertAutocompleteRedirectTarget(autocomplete_redirect_target)}
                  onRequestHeaderHeight={handleRequestHeaderHeight}
                />
              )}
              <BrowseStates {...browse_states?.[0]} />
              <Footer />
              <ShowOrHideAdjustBanner pathname={global.window && global.window.location.pathname} />
            </>
          )}
        </div>
      </PlaceholderReplacements>
    </CTAAdjustment>
  )
}

export default CmsPage
