import React, { FC } from 'react'

import Card from 'pages/_serverRendered/CmsPage/Section/Components/Card'
import Cta from 'components/MarketingAndPropertyPages/Cta'
import { SliceTeam } from 'pages/_serverRendered/CmsPage/types'
import cx from 'classnames'
import { hasTextPresent } from 'pages/_serverRendered/CmsPage/Section/Components/PresentRichText'
import styles from './styles.module.scss'
import { thumbnailUrl } from 'pages/_serverRendered/CmsPage/utils'

interface TeamProps {
  className?: string
  id?: string
  slice: SliceTeam
}

const Team: FC<TeamProps> = ({ className, id, slice }) => {
  return (
    <Card backgroundColor={slice.primary.background_color} id={id} className={cx(styles.team, className)}>
      {hasTextPresent(slice.primary.title1) && <h2 className={styles.h2}>{slice.primary.title1[0].text}</h2>}
      <div className={styles.items}>
        {slice.items.map((e, i) => (
          <div key={i}>
            {e.picture.url && <img src={thumbnailUrl(e.picture.url)} alt={e.picture.alt || ''} />}
            <div>{e.name}</div>
            <div>{e.role}</div>
          </div>
        ))}
      </div>
      <Cta href={slice.primary.cta_url?.url || ''} cta={`team${id ? `-${id}` : ''}`}>
        {slice.primary.cta_title}
      </Cta>
    </Card>
  )
}

export default Team
