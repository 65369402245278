import React, { FC, useState, useCallback, useMemo, useEffect } from 'react'
import cx from 'classnames'
import dayjs, { Dayjs } from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import advanced from 'dayjs/plugin/advancedFormat'

import { CalendarMarks } from './types'

import ArrowIcon from 'svgs/arrow-forward-ios'
import ExpandIcon from 'svgs/expand-more'

import CoreButton from 'components/Core/CoreButton'
import CoreText from 'components/Core/CoreText'

import Month from './Month'
import Week from './Week'

import styles from './styles.module.scss'

export type { CalendarMarks, MarkTypes } from './types'
export { setMark } from './types'

dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.extend(advanced)

type View = 'week' | 'twoWeek' | 'month'

interface Props {
  className?: string
  selected?: Date
  marks: CalendarMarks
  initialView?: View
  timezone?: string

  onSelectionChange: React.Dispatch<React.SetStateAction<Date>>
  onTrackAttempt?: (code: string, flexfield_1?: any) => void
}

const Calendar: FC<Props> = ({
  className,
  selected,
  marks,
  initialView = 'week',
  timezone,
  onSelectionChange,
  onTrackAttempt,
}) => {
  const [view, setView] = useState<View>(initialView)

  const track = useCallback(
    (code: string, flexfield_1?: any) => {
      if (!onTrackAttempt) return
      onTrackAttempt(code, flexfield_1)
    },
    [onTrackAttempt]
  )

  const handlePrevClicked = useCallback(() => {
    track('previous', { view: view })
    if (view == 'month') {
      onSelectionChange((prev) => {
        let date = dayjs(prev)
        if (timezone) date = date.tz(timezone)
        return date.add(-1, 'month').toDate()
      })
    } else {
      onSelectionChange((prev) => {
        let date = dayjs(prev)
        if (timezone) date = date.tz(timezone)
        return date.add(-1, 'week').toDate()
      })
    }
  }, [view, onSelectionChange, track, timezone])
  const handleNextClicked = useCallback(() => {
    track('next', { view: view })
    if (view == 'month') {
      onSelectionChange((prev) => {
        let date = dayjs(prev)
        if (timezone) date = date.tz(timezone)
        return date.add(1, 'month').toDate()
      })
    } else {
      onSelectionChange((prev) => {
        let date = dayjs(prev)
        if (timezone) date = date.tz(timezone)
        return date.add(1, 'week').toDate()
      })
    }
  }, [view, onSelectionChange, track, timezone])

  const handleTodayClicked = useCallback(() => {
    const date = new Date()
    track('select-date', { date: date, option: 'today' })
    onSelectionChange(new Date())
  }, [onSelectionChange, track])

  const handleDateSelected = useCallback(
    (date: Dayjs) => {
      track('select-date', { date: date.toDate() })
      onSelectionChange(date.toDate())
    },
    [onSelectionChange, track]
  )

  const handleShowMoreClicked = useCallback(() => {
    track('show-more')
    setView('twoWeek')
  }, [track])
  const handleShowMonthClicked = useCallback(() => {
    track('show-month')
    setView('month')
  }, [track])

  useEffect(() => {
    if (!selected) onSelectionChange(new Date())
  }, [selected, onSelectionChange])

  const djsSelected = useMemo(() => {
    let date = dayjs(selected)
    if (timezone) date = date.tz(timezone)

    return date
  }, [selected, timezone])

  return (
    <div className={cx(styles.calendar, className)}>
      <header>
        <CoreText.Paragraph className={styles.title} size="xl" weight="regular">
          {djsSelected.format('MMMM YYYY')}
        </CoreText.Paragraph>
        <div className={styles.controls}>
          <CoreButton
            className={styles.prev}
            icon={<ArrowIcon />}
            size="s"
            kind="secondary"
            type="button"
            onClick={handlePrevClicked}
          />
          <CoreButton text="Today" size="s" kind="secondary" type="button" onClick={handleTodayClicked} />
          <CoreButton
            className={styles.next}
            icon={<ArrowIcon />}
            size="s"
            kind="secondary"
            type="button"
            onClick={handleNextClicked}
          />
        </div>
      </header>
      {view == 'week' ? (
        <Week selected={djsSelected} marks={marks} onSelected={handleDateSelected} />
      ) : (
        <Month
          selected={djsSelected}
          marks={marks}
          onSelected={handleDateSelected}
          visibleWeeks={view == 'twoWeek' ? 2 : 6}
        />
      )}
      {view != 'month' && (
        <footer>
          {view == 'twoWeek' ? (
            <CoreButton
              text="Show entire month availability"
              size="s"
              kind="ghost"
              type="button"
              icon={<ExpandIcon className={styles.expandMore} />}
              onClick={handleShowMonthClicked}
            />
          ) : (
            <CoreButton
              text="Show more dates"
              size="s"
              kind="ghost"
              type="button"
              icon={<ExpandIcon className={styles.expandMore} />}
              onClick={handleShowMoreClicked}
            />
          )}
        </footer>
      )}
    </div>
  )
}

export default Calendar
