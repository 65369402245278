import React, { FC, useEffect } from 'react'

import Footer from 'components/MarketingSite/Footer'
import Header, { calculateNavItems } from 'components/MarketingSite/Header'
import AddressAutocomplete from 'components/MarketingSite/Header/AddressAutocomplete'
import GetStarted from 'pages/_serverRendered/Homepage/GetStarted'
import PageContent from 'components/MarketingSite/PageContent'
import learnContent from 'pages/_serverRendered/Learn/content'
import { trackPage } from 'utils/analytics'

import styles from './styles.module.scss'

const Hero = () => (
  <div className={styles.hero}>
    <div className={styles.chart}>
      <div className={styles.potentialValue}>
        <div className={styles.label}>
          <div className={styles.amount}>{'$580,000'}</div>
        </div>
      </div>
      <div className={styles.currentValue}>
        <div className={styles.label}>
          <div className={styles.amount}>{'$417,000'}</div>
          <div className={styles.delta}>{'+$35,500 since purchase'}</div>
        </div>
      </div>
    </div>
    <ul className={styles.legend}>
      <li>{'Estimated current value'}</li>
      <li>{'Estimated potential value'}</li>
    </ul>
  </div>
)

const Learn: FC = () => {
  useEffect(() => {
    trackPage('Learn')
  }, [])

  return (
    <div>
      <Header activeNavItem={calculateNavItems().Learn}>
        <AddressAutocomplete positionLabel="header" />
      </Header>
      <PageContent content={learnContent} hero={<Hero />} />
      <GetStarted className={styles.getStarted} />
      <Footer />
    </div>
  )
}

export default Learn
