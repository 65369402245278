import React, { FC } from 'react'

interface Props {
  className?: string
}

const Svg: FC<Props> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" height="16" width="16" className={className}>
    <path
      d="M4.66543 14.0101C4.99209 14.3368 5.51876 14.3368 5.84543 14.0101L11.3854 8.47012C11.6454 8.21012 11.6454 7.79012 11.3854 7.53012L5.84543 1.99012C5.51876 1.66345 4.99209 1.66345 4.66543 1.99012C4.33876 2.31678 4.33876 2.84345 4.66543 3.17012L9.49209 8.00345L4.65876 12.8368C4.33876 13.1568 4.33876 13.6901 4.66543 14.0101Z"
      fill="currentColor"
    />
  </svg>
)

export default Svg
