import React, { FC, useState, useCallback, useMemo } from 'react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)

import { prepare, trackClick as baseTrackClick } from 'utils/analyticsV2'
import { Calendar } from './types'

import Availabilities, { Block } from './Availabilities'
import ConfirmBookingModal from './ConfirmBookingModal'
import EarlierDaysAvailableModal from './EarlierDaysAvailableModal'

import styles from './Unscheduled.module.scss'

const trackClick = prepare(
  {
    family: 'vendor-site-visit',
    screen: 'unscheduled',
  },
  baseTrackClick
)

interface Props {
  calendar: Calendar
  onTimeSelected: (time: Block) => Promise<void>
  submitting: boolean
  timer: number
}

const AvailabilitySelection: FC<Props> = ({ calendar, onTimeSelected, submitting, timer }) => {
  const [time, setTime] = useState<Block | null>(null)
  const [timeError, setTimeError] = useState<string | null>(null)
  const [confirmBookingModal, setConfirmBookingModal] = useState(false)
  const [ignoreEarlierTimesModal, setIgnoreEarlierTimesModal] = useState(false)

  const handleTimeChanged = useCallback((block: Block) => {
    trackClick({ code: 'open', section: 'confirm-booking', flexfield_1: { start: block.start, end: block.end } })
    setTime(() => ({ ...block }))
    setConfirmBookingModal(true)
  }, [])

  const handleConfirmBookingConfirm = useCallback(async () => {
    trackClick({ code: 'confirm', section: 'confirm-booking' })

    if (!time) {
      setTimeError('You must select an available time')
      setConfirmBookingModal(false)
      return true
    }

    setTimeError(null)
    await onTimeSelected(time)
    setConfirmBookingModal(false)
  }, [time, onTimeSelected])

  const handleConfirmBookingClose = useCallback(() => {
    trackClick({ code: 'close', section: 'confirm-booking' })
    setConfirmBookingModal(false)
  }, [])

  const handleEarlierDaysAvailableClose = useCallback(() => {
    trackClick({ code: 'close', section: 'earlier-days-available' })
    setIgnoreEarlierTimesModal(true)
    setConfirmBookingModal(false)
  }, [])

  const handleEarlierDaysAvailableConfirm = useCallback(() => {
    trackClick({ code: 'confirm', section: 'earlier-days-available' })
    setIgnoreEarlierTimesModal(true)
  }, [])

  const availabilities = useMemo(
    () => {
      // eventDurationSize
      const eventDurationSize = calendar.event_duration * 60 * 1000
      // Increment size will be 30m, or if our event duration is smaller, that.
      const incrementSize = Math.max(30 * 60 * 1000, calendar.event_duration)
      // Get the time of our cutoff, if one exists.
      const cutOff =
        calendar.vendor_forward_availability && !calendar.impersonated
          ? dayjs().tz(calendar.property.time_zone).add(calendar.vendor_forward_availability, 'days').toDate()
          : null
      // Split into even length time blocks.
      // Only allow availabilities to start and end at the top of the increment size.
      const blocks = calendar.availabilities.reduce((newAvail, block) => {
        const origStart = dayjs(block.time.start).tz(calendar.property.time_zone).toDate()
        const origEnd = dayjs(block.time.end).tz(calendar.property.time_zone).toDate()

        const start = Math.ceil(origStart.getTime() / incrementSize) * incrementSize
        const lastStart = origEnd.getTime() - eventDurationSize
        for (let x = start; x <= lastStart; x += incrementSize) {
          newAvail.push({
            start: dayjs(x).tz(calendar.property.time_zone).toDate(),
            end: dayjs(x + eventDurationSize)
              .tz(calendar.property.time_zone)
              .toDate(),
          })
        }
        return newAvail
      }, [] as Array<Block>)

      // If no cutoff, return them all.
      if (cutOff == null) return blocks

      return blocks.filter((block) => {
        return block.end <= cutOff
      })
    },
    // We want this to recalculate each time timer changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [calendar, timer]
  )

  const earlierTimeDays = useMemo(() => {
    if (ignoreEarlierTimesModal) return 0
    if (!time) return 0

    const timeDay = dayjs(time.start).tz(calendar.property.time_zone).startOf('day').toDate().getTime()

    // Get a count of how many individual days earlier availabilities exist for.
    const counts = availabilities.reduce(
      (counts, availability) => {
        // Grab day of this time.
        // Ignore it if it isn't before our time's day
        const availabilityDay = dayjs(availability.start)
          .tz(calendar.property.time_zone)
          .startOf('day')
          .toDate()
          .getTime()
        if (availabilityDay >= timeDay) return counts

        return { ...counts, [availabilityDay]: (counts[availabilityDay] || 0) + 1 }
      },
      {} as Record<number, number>
    )

    return Object.entries(counts).length
  }, [ignoreEarlierTimesModal, availabilities, time, calendar])

  return (
    <div className={styles.availabilitySelection}>
      <Availabilities
        availabilities={availabilities}
        selected={time || undefined}
        eventDuration={calendar.event_duration}
        onChange={handleTimeChanged}
        disabled={submitting}
        timezone={calendar.property.time_zone}
      />
      {timeError && <p className="tw-pl-2 tw-mt-2 tw-text-xs">{timeError}</p>}
      {confirmBookingModal && time && earlierTimeDays && (
        <EarlierDaysAvailableModal
          earlierDays={earlierTimeDays}
          onClose={handleEarlierDaysAvailableClose}
          onConfirm={handleEarlierDaysAvailableConfirm}
        />
      )}
      {confirmBookingModal && time && !earlierTimeDays && (
        <ConfirmBookingModal
          startTime={dayjs(time?.start).tz(calendar.property.time_zone)}
          endTime={dayjs(time?.end).tz(calendar.property.time_zone)}
          submitting={submitting}
          onClose={handleConfirmBookingClose}
          onConfirm={handleConfirmBookingConfirm}
        />
      )}
    </div>
  )
}

export default AvailabilitySelection
