import { useEffect, useState, useMemo } from 'react'
import { Link } from 'prismic-reactjs'

import { UTM_PARAMS_SESSION_KEY } from 'utils/analytics'

interface UtmParams {
  utm_medium?: string
  utm_source?: string
  utm_campaign?: string
  utm_content?: string
}

export default function useAdjustLink(link?: Link): Link | undefined {
  const [utmParams, setUtmParams] = useState<UtmParams | null>(null)

  useEffect(() => {
    const sUtmParams = sessionStorage?.getItem(UTM_PARAMS_SESSION_KEY)
    if (sUtmParams) {
      const utmParams = JSON.parse(sUtmParams)
      if (utmParams) {
        setUtmParams(utmParams)
      }
    }
  }, [])

  return useMemo(() => {
    if (!link?.url) return link

    let redirectUrl = link?.url
    if (utmParams) {
      if (utmParams.utm_source) redirectUrl += `&adgroup=${encodeURIComponent(utmParams.utm_source)}`
      if (utmParams.utm_campaign) redirectUrl += `&campaign=${encodeURIComponent(utmParams.utm_campaign)}`
      if (utmParams.utm_content) redirectUrl += `&creative=${encodeURIComponent(utmParams.utm_content)}`
    }

    return {
      ...link,
      url: redirectUrl,
    }
  }, [utmParams, link])
}
