import React, { FC, useState, useCallback, PropsWithChildren } from 'react'
import cx from 'classnames'

import { useTrackClick } from '../../..'
import { CustomerTestimonial } from '../../../types'

import PlayIcon from 'svgs/play'

import PresentRichText from 'pages/_serverRendered/CmsPage/Section/Components/PresentRichText'

import Image from '../../../Components/Image'
import CaseStudyViewer from './CaseStudyViewer'

import styles from './styles.module.scss'

export interface TestimonialItem extends CustomerTestimonial {
  id: string
}

interface ContainerProps {
  onClick?: (e: any) => void
}
const Container: FC<PropsWithChildren<ContainerProps>> = ({ children, onClick }) => {
  return onClick ? (
    <button type="button" onClick={onClick}>
      {children}
    </button>
  ) : (
    <div>{children}</div>
  )
}

interface Props extends TestimonialItem {
  className?: string
}
const Testimonial: FC<Props> = ({
  className,
  image,
  quote,
  author,
  click_action,
  case_study_uid,
  click_tracking_code,
  click_tracking_section,
  click_tracking_feature,
  click_tracking_family,
}) => {
  const trackClick = useTrackClick()
  const [showModal, setShowModal] = useState(false)
  const handleClick = useCallback(
    (e) => {
      trackClick({
        code: click_tracking_code,
        section: click_tracking_section,
        feature: click_tracking_feature,
        family: click_tracking_family,
      })

      e.preventDefault()
      e.stopPropagation()
      setShowModal(true)
    },
    [trackClick, click_tracking_code, click_tracking_section, click_tracking_feature, click_tracking_family]
  )
  const handleOnClose = useCallback(() => {
    setShowModal(false)
  }, [])

  return (
    <li className={cx(styles.testimonial, className)}>
      <Container onClick={click_action != 'No Action' ? handleClick : undefined}>
        <Image className={styles.image} image={image} />
        <div className={styles.quoteContainer}>
          <PresentRichText className={styles.quote} render={quote} />
          <span className={styles.author}>{author}</span>
        </div>
        {click_action != 'No Action' && <PlayIcon className={styles.play} />}
      </Container>
      {showModal && case_study_uid && <CaseStudyViewer onClose={handleOnClose} id={case_study_uid} showCta />}
    </li>
  )
}

export default Testimonial
