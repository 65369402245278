import React from 'react'

import { formatCurrency } from 'utils/formatting'

import PercentialBarGraph from '../PercentialBarGraph'
import Tooltip from 'components/PropertyPages/ToolTip'

import styles from './styles.module.scss'

interface Props {
  values: [number, number, number]
}

const AverageHomeValue: React.FC<Props> = ({ values }) => {
  return (
    <div className={styles.container}>
      <Tooltip className={styles.tooltip}>
        {
          'Our assessment of the average current home value. We use several data sources including tax assessments, listing history, building permits, and zoning regulations.'
        }
      </Tooltip>
      <h3>{'Average Home Value'}</h3>
      <PercentialBarGraph
        lowValue={formatCurrency(values[0])}
        value={formatCurrency(values[1])}
        highValue={formatCurrency(values[2])}
      />
    </div>
  )
}

export default AverageHomeValue
