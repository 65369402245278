import React from 'react'

import { formatCurrency } from 'utils/formatting'

import StatPill from '../StatPill'
import ChartContainer from '../../ChartContainer'
import Tooltip from 'components/PropertyPages/ToolTip'

import styles from './styles.module.scss'

interface Props {
  value: number
}

const AverageSalePrice: React.FC<Props> = ({ value }) => {
  return (
    <ChartContainer className={styles.averageSalePrice}>
      <Tooltip className={styles.tooltip}>{'How much homes have been sold for over the last year.'}</Tooltip>
      <h3>{'Average Sale Price (Last 12 months)'}</h3>
      <StatPill>{formatCurrency(value)}</StatPill>
    </ChartContainer>
  )
}

export default AverageSalePrice
