import React, { FC } from 'react'

import Accordion from './index'
import AccordionItem from './AccordionItem'
import Cta from 'components/MarketingAndPropertyPages/Cta'
import { RichText } from 'prismic-reactjs'
import { SliceAccordion } from 'pages/_serverRendered/CmsPage/types'
import { hasTextPresent } from 'pages/_serverRendered/CmsPage/Section/Components/PresentRichText'
import styles from './styles.module.scss'

const CmsAccordion: FC<SliceAccordion> = ({ items, primary }) => {
  return (
    <>
      {hasTextPresent(primary?.subtitle) && (
        <div className={styles.subtitle}>
          <RichText render={primary?.subtitle} />
        </div>
      )}
      <Accordion preopen={primary?.preopen}>
        {({ selectedIndices, toggleIndex }) =>
          items.map((item, index) => (
            <AccordionItem
              index={index}
              key={index}
              selectedIndices={selectedIndices}
              title={(item.title || [])[0]?.text || ''}
              toggleIndex={toggleIndex}
            >
              {item.image?.url != null && (
                <img className={styles.image} src={item.image.url} alt={item.image.alt || ''} />
              )}
              <div className={styles.content}>
                {hasTextPresent(item.header) && (
                  <div className={styles.header}>
                    <RichText render={item.header} />
                  </div>
                )}
                {hasTextPresent(item.body1) && (
                  <div className={styles.body1}>
                    <RichText render={item.body1} />
                  </div>
                )}
                {item.cta_title != null && (
                  <Cta className={styles.cta} href={item.cta_url || ''}>
                    {item.cta_title}
                  </Cta>
                )}
              </div>
            </AccordionItem>
          ))
        }
      </Accordion>
    </>
  )
}

export default CmsAccordion
