import React, { FC } from 'react'

interface Props {
  className?: string
}

const Svg: FC<Props> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 73 72" height="72" width="73" className={className}>
    <path
      d="M57.335 12H54.335V6H48.335V12H24.335V6H18.335V12H15.335C12.035 12 9.33496 14.7 9.33496 18V60C9.33496 63.3 12.035 66 15.335 66H57.335C60.635 66 63.335 63.3 63.335 60V18C63.335 14.7 60.635 12 57.335 12ZM57.335 60H15.335V30H57.335V60ZM15.335 24V18H57.335V24H15.335ZM32.015 55.38L49.805 37.59L46.625 34.41L32.015 49.02L25.685 42.69L22.505 45.87L32.015 55.38Z"
      fill="currentColor"
    />
  </svg>
)

export default Svg
