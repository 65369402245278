import React, { FC } from 'react'
import cx from 'classnames'

import { FooterLinkSlice } from '../types'

import Link from './Link'

import styles from './styles.module.scss'

type Props = FooterLinkSlice

const LinkCategory: FC<Props> = ({ primary: { header }, items }) => {
  return (
    <li className={cx(styles.category, styles.linkCategory)}>
      <ul className={styles.links}>
        <li className={styles.header}>{header}</li>
        {items.map((item, i) => (
          <Link key={i} {...item} />
        ))}
      </ul>
    </li>
  )
}

export default LinkCategory
