import React, { FC, PropsWithChildren, useCallback } from 'react'
import cx from 'classnames'

import { track } from 'utils/analytics'

import ImgAppPreview from 'images/onboarding/iPhone11pro-projects@1x.png'

import Button from 'components/NikhilButton'

import styles from './styles.module.scss'

interface Props {
  className?: string
  redirectUrl: string
  redirectQR: () => React.ReactNode
}

const CtaSection: FC<PropsWithChildren<Props>> = ({ className, redirectUrl, redirectQR, children }) => {
  const handleDownloadClick = useCallback(async () => {
    // Simply redirect if our variant is 'Download'.
    await track('download app click')
    window.location.href = redirectUrl
  }, [redirectUrl])

  return (
    <div className={cx(styles.ctaSection, className)}>
      <div className={styles.container}>
        <div className={styles.content}>{children}</div>
        <div className={styles.qrGroup}>
          {redirectQR()}
          <span>{'Scan to download'}</span>
        </div>
        <div className={styles.preview}>
          <img src={ImgAppPreview} srcSet={`${ImgAppPreview} 1x`} alt="App Preview" />
        </div>
        <Button className={styles.download} onClick={handleDownloadClick}>
          {'Download'}
        </Button>
      </div>
    </div>
  )
}

export default CtaSection
