import React from 'react'
import classNames from 'classnames'

import styles from './styles.module.scss'

interface Props {
  className?: string
}

const StatPill: React.FC<React.PropsWithChildren<Props>> = ({ className, children }) => (
  <div className={classNames(styles.statPill, className)}>{children}</div>
)

export default StatPill
