import React, { FC } from 'react'
import cx from 'classnames'

import { CallToActionProperties, HeroImageItem } from '../types'

import PresentRichText, { hasTextPresent } from 'pages/_serverRendered/CmsPage/Section/Components/PresentRichText'

import SectionTitle from '../Components/SectionTitle'
import CallToActionButton from '../Components/CallToAction'

import Background from '../Hero/Background'

import styles from './styles.module.scss'

// Parse int that returns undefined if we get NaN
function pInt(val?: string | null): number | undefined {
  const ret = parseInt(val as string)
  if (Number.isNaN(ret)) return undefined
  return ret
}

interface Props extends CallToActionProperties {
  className?: string
  background_images: Array<HeroImageItem>
}
const CallToAction: FC<Props> = ({
  className,
  nav_id,
  title,
  subtitle,
  cta_text,
  cta_url,
  cta_relative_url,

  cta_tracking_code,
  cta_tracking_section,
  cta_tracking_feature,
  cta_tracking_family,

  background_images_rotation_velocity,
  background_images_rotation_drag,
  background_images,
}) => {
  return (
    <section id={nav_id} className={cx(styles.callToAction, className)}>
      <Background
        className={styles.background}
        images={background_images}
        minSpeed={pInt(background_images_rotation_velocity)}
        drag={pInt(background_images_rotation_drag)}
        minWidth={700}
        minHeight={550}
      />
      <div className={styles.titleBox}>
        <SectionTitle className={styles.title} title={title} />
        {(cta_text || hasTextPresent(subtitle)) && (
          <div className={styles.subtitleContent}>
            {hasTextPresent(subtitle) && <PresentRichText className={styles.subtitleText} render={subtitle} />}
            {cta_text && (
              <CallToActionButton
                className={styles.cta}
                url={cta_url}
                url_relative={cta_relative_url}
                trackingCode={cta_tracking_code}
                trackingSection={cta_tracking_section}
                trackingFeature={cta_tracking_feature}
                trackingFamily={cta_tracking_family}
              >
                {cta_text}
              </CallToActionButton>
            )}
          </div>
        )}
      </div>
    </section>
  )
}

export default CallToAction
