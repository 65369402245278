import React, { FC } from 'react'
import styles from './styles.module.scss'
import ImgPlant from 'images/projectPlan/plant.png'
import { TopProject } from 'recoil/publicNeighborhood'

interface ProjectsProps {
  projects: TopProject[]
}

const Projects: FC<ProjectsProps> = ({ projects }) => (
  <div className={styles.projects}>
    {projects.map(({ project_template: { description, kind, name }, icon_url }) => (
      <div className={styles.project} key={description}>
        <div className={styles.image}>
          <img src={icon_url ?? ImgPlant} alt={description} />
        </div>
        <div className={styles.details}>
          <h3>{name}</h3>
        </div>
        <a id={`project-${kind}`} className={styles.button} href="/users/sign_up">
          {'Sign Up for cost'}
        </a>
      </div>
    ))}
  </div>
)

export default Projects
