import React, { FC } from 'react'

interface ThankYouProps {
  className?: string
}

const ThankYou: FC<ThankYouProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="78"
      height="78"
      viewBox="0 0 78 78"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_32_521)">
        <path
          d="M37.7628 0H15.6388L0 15.7072V37.9279L15.6388 53.6352H37.7628L53.4016 37.9279V15.7072L37.7628 0Z"
          fill="#146B4B"
        />
        <path
          d="M26.7009 43.0771C36.6331 43.0771 44.6847 34.9903 44.6847 25.0146C44.6847 15.039 36.6331 6.95215 26.7009 6.95215C16.7687 6.95215 8.71704 15.039 8.71704 25.0146C8.71704 34.9903 16.7687 43.0771 26.7009 43.0771Z"
          fill="#689B82"
        />
        <path
          d="M26.7008 40.137C35.0163 40.137 41.7574 33.3664 41.7574 25.0145C41.7574 16.6626 35.0163 9.89209 26.7008 9.89209C18.3852 9.89209 11.6442 16.6626 11.6442 25.0145C11.6442 33.3664 18.3852 40.137 26.7008 40.137Z"
          fill="#0D4531"
        />
        <path
          d="M19.5203 34.3058C19.5203 35.8002 19.7467 37.2458 20.151 38.6103C22.124 39.5686 24.3397 40.1371 26.7009 40.1371C35.0135 40.1371 41.7575 33.3637 41.7575 25.0147C41.7575 23.5203 41.531 22.0746 41.1267 20.7102C39.1537 19.7519 36.9381 19.1833 34.5769 19.1833C26.2642 19.1833 19.5203 25.9568 19.5203 34.3058Z"
          fill="white"
        />
        <path
          d="M48.3396 10.4769C46.8517 8.98248 44.442 8.98248 42.9541 10.4769L24.9703 28.5393L18.1455 21.6847C16.6576 20.1903 14.2479 20.1903 12.7601 21.6847C11.2722 23.1791 11.2722 25.5993 12.7601 27.0937L24.9703 39.3573L48.3396 15.8859C49.8274 14.3915 49.8274 11.9712 48.3396 10.4769Z"
          fill="#0D4531"
        />
        <path
          d="M11.6442 19.9793V23.8452L25.0673 36.1738L49.407 12.5887V8.21924L42.9541 10.477L24.9541 30.8948L11.6442 19.9793Z"
          fill="#0D4531"
        />
        <path
          d="M48.3396 6.09136C46.8517 4.59699 44.442 4.59699 42.9541 6.09136L24.9703 24.1538L18.1455 17.2992C16.6576 15.8048 14.2479 15.8048 12.7601 17.2992C11.2722 18.7936 11.2722 21.2138 12.7601 22.7082L24.9703 34.9718L48.3396 11.5004C49.8274 10.006 49.8274 7.58574 48.3396 6.09136Z"
          fill="#AFC100"
        />
        <path d="M53.4016 15.7073L77.8059 40.0721V62.2929L53.4016 37.928V15.7073Z" fill="#689B82" />
        <path d="M15.639 53.6353L40.0433 78.0001H62.1673L37.763 53.6353H15.639Z" fill="#0D4531" />
        <path d="M37.7628 53.6352L53.4016 37.928L77.8059 62.2928L62.1671 78L37.7628 53.6352Z" fill="#AFC100" />
        <path
          d="M75.3476 15.1874L75.4285 15.2686V15.8696C75.4285 17.5426 75.6711 17.7863 77.3368 17.7863H77.9352L78.0161 17.8675V18.2898L77.9352 18.3711H77.3368C75.6711 18.3711 75.4285 18.6147 75.4285 20.2878V20.8888L75.3476 20.97H74.9271L74.8463 20.8888V20.2878C74.8463 18.6147 74.6037 18.3711 72.9379 18.3711H72.3395L72.2587 18.2898V17.8675L72.3395 17.7863H72.9379C74.6037 17.7863 74.8463 17.5426 74.8463 15.8696V15.2686L74.9271 15.1874H75.3476Z"
          fill="#146B4B"
        />
        <path
          d="M66.2103 20.158H67.5526L67.7952 20.4016V22.3021C67.7952 27.5974 68.5715 28.377 73.8437 28.377H75.7197L75.9623 28.6369V29.9851L75.7197 30.2288H73.8437C68.5715 30.2288 67.7952 31.0084 67.7952 36.3037V38.1879L67.5364 38.4316H66.1941L65.9515 38.1879V36.2875C65.9515 30.9922 65.1753 30.2125 59.903 30.2125H58.027L57.7844 29.9526V28.6044L58.027 28.3608H59.9192C65.1914 28.3608 65.9677 27.5811 65.9677 22.2858V20.4016L66.2265 20.158H66.2103Z"
          fill="#146B4B"
        />
      </g>
      <defs>
        <clipPath id="clip0_32_521">
          <rect width="78" height="78" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ThankYou
