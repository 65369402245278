import React, { FC } from 'react'

import { Advisor } from './types'

import EventBusyIcon from 'svgs/chat-bubble'

import CoreModal from 'components/Core/CoreModal'
import CoreText from 'components/Core/CoreText'

import ContactInfo from './PointOfContact/ContactInfo'

import styles from './MismatchedModal.module.scss'

interface Props {
  advisor: Advisor
  onClose?: () => void
}

const MismatchedModal: FC<Props> = ({ advisor, onClose }) => (
  <CoreModal
    className={styles.mismatchedModal}
    title={"Can't match the homeowner's schedule? Please reach out to us"}
    onClose={onClose}
    buttons={{ name: 'Close', onClick: onClose }}
    icon={<EventBusyIcon />}
    kind="success"
  >
    <CoreText.Paragraph weight="light" color="tertiaryBlack">
      {"Ensure you've reviewed the homeowner's availability for the weeks ahead."}
    </CoreText.Paragraph>
    <ContactInfo
      className={styles.contactInfo}
      advisor={advisor}
      screen="unscheduled"
      section="schedule-mismatch-point-of-contact-details"
    />
  </CoreModal>
)

export default MismatchedModal
