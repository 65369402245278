import React from 'react'

import Bath from 'svgs/bath'
import Bed from 'svgs/bed'
import cx from 'classnames'

import styles from './styles.module.scss'
import ImageWithBackup from 'components/ImageWithBackup'
import DefaultAvatar from 'components/DefaultAvatar'

export interface HomeSearchResultItem {
  id: string
  url: string
  image_url: string
  backup_image_url: string
  display_address: string
  bedroomscount: number
  bathcount: number
  arealotsf: number
  value: number
}

interface Props {
  results: HomeSearchResultItem[]
  loading: boolean
  columns?: number
}

const HomeSearchListings: React.FC<Props> = ({ results, loading, columns }) => {
  if (!results || !results.length) return null

  return (
    <div className={cx(styles.resultItems, { [styles.isLoading]: loading, [styles.columns4]: columns === 4 })}>
      {results.map((result, i) => {
        if (!result) {
          return <div key={`i_${i}`} className={cx(styles.resultItem, styles.placeholder)} />
        }
        return (
          <div
            key={result.id}
            className={styles.resultItem}
            itemScope
            itemType="https://schema.org/SingleFamilyResidence"
          >
            <a itemProp="url" href={result.url}>
              <ImageWithBackup
                className={styles.avatar}
                primary={result.image_url}
                backup={
                  result.backup_image_url ? (
                    <ImageWithBackup
                      className={styles.avatar}
                      primary={result.backup_image_url}
                      backup={<DefaultAvatar className={styles.avatar} />}
                    />
                  ) : (
                    <DefaultAvatar className={styles.avatar} />
                  )
                }
              />
              <div className={styles.address} itemProp="address">
                {result.display_address}
              </div>
            </a>
            <div className={styles.price}>{result.value}</div>
            <div className={styles.details}>
              <div>
                <div itemProp="numberOfBedrooms">{result.bedroomscount}</div>
                <Bed />
              </div>
              <div>
                <div itemProp="numberOfBathroomsTotal">{result.bathcount}</div>
                <Bath />
              </div>
              <div>{`${result.arealotsf} sqft`}</div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default HomeSearchListings
