import React, { FC } from 'react'

interface Props {
  className?: string
}

const Svg: FC<Props> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 16" height="17" width="16" className={className}>
    <path
      d="M7.04917 13.5729V11.2195C7.04917 10.4191 7.69803 9.77022 8.49844 9.77022C9.29885 9.77022 9.9477 10.4191 9.9477 11.2195V13.5729C9.9477 13.9912 10.2738 14.3334 10.6723 14.3334H12.3028C12.7013 14.3334 13.0274 13.9912 13.0274 13.5729L13.5709 8.24916H14.8027C15.1361 8.24916 15.2955 7.81565 15.0419 7.58749L8.98394 1.86068C8.70858 1.6021 8.2883 1.6021 8.01294 1.86068L1.95501 7.58749C1.70864 7.81565 1.86081 8.24916 2.19414 8.24916H3.42602L3.96949 13.5729C3.96949 13.9912 4.29557 14.3334 4.69412 14.3334H6.32454C6.72309 14.3334 7.04917 13.9912 7.04917 13.5729Z"
      fill="currentColor"
    />
  </svg>
)

export default Svg
