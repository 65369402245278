import React, { FC, useState, useCallback } from 'react'
import cx from 'classnames'

import { Header as HeaderType } from '../../types'

import HamburgerIcon from 'svgs/hamburger'
import CloseIcon from 'svgs/close'

import SubNavigation from './SubNavigation'
import NavLink from './NavLink'
import Link from './Link'
import DownloadLink from './Link'

import styles from './styles.module.scss'

interface Props extends HeaderType {
  className?: string
}
const MobileNavigation: FC<Props> = ({
  className,

  header_body,
}) => {
  const [open, setOpen] = useState(false)
  const [closing, setClosing] = useState(false)

  const handleOpen = useCallback(() => {
    setClosing(false)
    setOpen(true)
  }, [])
  const handleClose = useCallback(() => {
    setClosing(true)
    setTimeout(() => setOpen(false), 400) // This must match our animation
  }, [])

  const headerItems = header_body.filter((slice) => ['Header', 'Both'].includes(slice.primary.mobile_display_location))
  const hamburgerItems = header_body.filter((slice) =>
    ['Hamburger', 'Both'].includes(slice.primary.mobile_display_location)
  )

  return (
    <>
      <ul className={cx(styles.navigation, styles.mobile, className)}>
        {headerItems.map((slice, i) =>
          slice.slice_type === 'link' ? (
            <NavLink key={i} {...slice.primary} />
          ) : slice.slice_type === 'link_category' ? (
            <li key={i} className={styles.navButton}>
              <button type="button">{slice.primary.header}</button>
              <ul className={styles.subNavigation}>
                {slice.items.map((item, l) => (
                  <NavLink key={l} {...item} />
                ))}
              </ul>
            </li>
          ) : slice.slice_type === 'download_link' ? (
            <DownloadLink
              key={i}
              link_text={slice.primary.download_text}
              link_url={slice.primary.download_url}
              link_tracking_code={slice.primary.download_tracking_code}
              link_tracking_section={slice.primary.download_tracking_section}
              link_tracking_feature={slice.primary.download_tracking_feature}
              link_tracking_family={slice.primary.download_tracking_family}
            />
          ) : null
        )}
        {hamburgerItems.length > 0 && (
          <li className={cx(styles.navButton, styles.hamburger)}>
            <button type="button" onClick={handleOpen}>
              <HamburgerIcon className="tw-w-4 tw-h-4" />
            </button>
          </li>
        )}
      </ul>
      {open && (
        <div className={cx(styles.openNavigation, { [styles.closing]: closing })}>
          <button
            type="button"
            className={styles.close}
            onClick={handleClose}
            style={{ animationDelay: `${closing ? 0 : hamburgerItems.length * 100 + 200}ms` }}
          >
            <CloseIcon />
          </button>
          <ul className={cx(styles.mobileNavigation)}>
            {hamburgerItems.map((slice, i) =>
              slice.slice_type === 'link' ? (
                <Link key={i} {...slice.primary} delay={closing ? 0 : i * 100 + 200} />
              ) : slice.slice_type === 'link_category' ? (
                <li key={i} className={styles.navButton} style={{ animationDelay: `${closing ? 0 : i * 100 + 200}ms` }}>
                  <SubNavigation text={slice.primary.header}>
                    {slice.items.map((item, l) => (
                      <Link key={l} {...item} />
                    ))}
                  </SubNavigation>
                </li>
              ) : slice.slice_type === 'download_link' ? (
                <DownloadLink
                  key={i}
                  link_text={slice.primary.download_text}
                  link_url={slice.primary.download_url}
                  link_tracking_code={slice.primary.download_tracking_code}
                  link_tracking_section={slice.primary.download_tracking_section}
                  link_tracking_feature={slice.primary.download_tracking_feature}
                  link_tracking_family={slice.primary.download_tracking_family}
                  delay={closing ? 0 : i * 100 + 200}
                />
              ) : null
            )}
          </ul>
        </div>
      )}
    </>
  )
}

export default MobileNavigation
