import React, { FC } from 'react'
import cx from 'classnames'

import SectionTitle from '../../Components/SectionTitle'
import Rotator from './Rotator'

import { RichTextBlock } from 'prismic-reactjs'

import styles from './styles.module.scss'

interface Props {
  className?: string
  headline: Array<RichTextBlock>
  headlineRotationOptions: Array<string>
  headlineRotationSpeed?: number
}
const Headline: FC<Props> = ({ className, headline, headlineRotationOptions, headlineRotationSpeed }) => {
  return (
    <div className={cx(styles.headline, className)}>
      <SectionTitle className={styles.title} title={headline} />
      {headlineRotationOptions.length > 0 ? (
        <Rotator options={headlineRotationOptions} interval={1000 / (headlineRotationSpeed || 0.3)} />
      ) : null}
    </div>
  )
}

export default Headline
