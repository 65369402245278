import React, { CSSProperties, FC } from 'react'
import cx from 'classnames'

import { Header as HeaderType } from '../../types'

import QRCode from './QRCode'
import Link from './Link'

import styles from './styles.module.scss'

interface Props extends HeaderType {
  className?: string
}
const DesktopNavigation: FC<Props> = ({
  className,

  header_body,
}) => {
  return (
    <ul className={cx(styles.navigation, className)}>
      {header_body.map((slice, i) =>
        slice.slice_type === 'link' ? (
          <Link key={i} {...slice.primary} />
        ) : slice.slice_type === 'link_category' ? (
          <li key={i} className={styles.navButton}>
            <button type="button">{slice.primary.header}</button>
            <ul className={styles.subNavigation} style={{ '--n': slice.items.length } as CSSProperties}>
              {slice.items.map((item, l) => (
                <Link key={l} {...item} style={{ '--i': l + 1 } as CSSProperties} />
              ))}
            </ul>
          </li>
        ) : slice.slice_type === 'download_link' ? (
          <li key={i} className={styles.downloadOption}>
            <span>{slice.primary.header}</span>
            <QRCode image={slice.primary.download_qr_code} />
          </li>
        ) : null
      )}
    </ul>
  )
}

export default DesktopNavigation
