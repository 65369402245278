import React, { FC } from 'react'
import cx from 'classnames'

import { SliceImageQuotes } from '../../types'

import PresentRichText, { hasTextPresent } from 'pages/_serverRendered/CmsPage/Section/Components/PresentRichText'
import { thumbnailUrl } from 'pages/_serverRendered/CmsPage/utils'
import SectionTitle from '../../Components/SectionTitle'

import styles from './styles.module.scss'

interface ImageQuotesProps {
  className?: string
  slice: SliceImageQuotes
}

const ImageQuotes: FC<ImageQuotesProps> = ({ className, slice }) => {
  return (
    <section id={slice.primary.nav_id} className={cx(styles.imageQuotes, className)}>
      {hasTextPresent(slice.primary?.title) && (
        <div className={styles.titleBox}>
          <SectionTitle className={styles.title} title={slice.primary?.title} />
        </div>
      )}
      <div className={styles.container}>
        {slice.items.map((item, i) =>
          item.link?.url ? (
            <a href={item.link.url} target={item.link.target} key={i} className={styles.item}>
              {item.image.url && <img alt={item.image.alt || ''} src={thumbnailUrl(item.image.url)} />}
              <div className={styles.description}>
                <div>
                  <span className={styles.name}>{item.name}</span>
                  <span className={styles.location}>{` - ${item.location}`}</span>
                </div>
                <PresentRichText render={item.quote} />
              </div>
            </a>
          ) : (
            <div key={i} className={styles.item}>
              {item.image.url && <img alt={item.image.alt || ''} src={thumbnailUrl(item.image.url)} />}
              <div className={styles.description}>
                <div>
                  <span className={styles.name}>{item.name}</span>
                  <span className={styles.location}>{` - ${item.location}`}</span>
                </div>
                <PresentRichText render={item.quote} />
              </div>
            </div>
          )
        )}
      </div>
    </section>
  )
}

export default ImageQuotes
