import RealmLogo from 'components/MarketingSite/Header/RealmLogo'
import React, { FC, PropsWithChildren } from 'react'

const Container: FC<PropsWithChildren> = ({ children }) => {
  return (
    <>
      <div className="tw-flex tw-py-6 sm:tw-y-8 tw-justify-center tw-items-center">
        <RealmLogo className="tw-w-20 sm:tw-w-24 tw-text-white" />
      </div>
      <div className="tw-bg-neutrals-100 tw-mb-16 tw-space-y-6 tw-mx-2 sm:tw-mx-auto tw-p-4 sm:tw-p-8 tw-rounded-3xl sm:tw-rounded-5xl sm:tw-w-sm">
        {children}
      </div>
    </>
  )
}

export default Container
