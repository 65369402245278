import Hero from 'pages/_serverRendered/Homepage/Hero'
import AddressAutocompleteField from 'pages/_serverRendered/CmsPage/AddressAutocompleteField'
import React, { FC, useEffect, useState } from 'react'

import AddressAutocomplete from 'components/MarketingSite/Header/AddressAutocomplete'
import AtlantaImg from 'images/marketing/property-potential/atlanta.jpg'
import AustinImg from 'images/marketing/property-potential/austin.jpg'
import Footer from 'components/MarketingSite/Footer'
import GetStarted from 'pages/_serverRendered/Homepage/GetStarted'
import Header from 'components/MarketingSite/Header'
import LosAngelesImg from 'images/marketing/property-potential/los-angeles.jpg'
import SeattleImg from 'images/marketing/property-potential/seattle.jpg'
import { formatCurrency } from 'utils/formatting'
import styles from './styles.module.scss'
import { trackPage } from 'utils/analytics'

const highlightedProperties = [
  {
    city: 'Seattle',
    value: 659542,
    potential: 1119653,
    img: SeattleImg,
    href: '/wa/seattle/915-n-109th-st-seattle-wa-98133/47814848/preview',
  },
  {
    city: 'Austin',
    value: 477757,
    potential: 594859,
    img: AustinImg,
    href: '/tx/austin/11718-big-trl-austin-tx-78759/167944644/preview',
  },
  {
    city: 'Atlanta',
    value: 486984,
    potential: 729378,
    img: AtlantaImg,
    href: '/ga/atlanta/1534-berkeley-ln-ne-atlanta-ga-30329/151478759/preview',
  },
  {
    city: 'Los Angeles',
    value: 763579,
    potential: 1138483,
    img: LosAngelesImg,
    href: '/ca/inglewood/2429-w-78th-pl-inglewood-ca-90305/24392023/preview',
  },
]

const PropertyPotential: FC = () => {
  const [heroInputVisible, setHeroInputVisible] = useState(false)

  useEffect(() => {
    trackPage('PropertyPotential')
  }, [])

  return (
    <div>
      <Header className={styles.nav}>
        {heroInputVisible ? <div /> : <AddressAutocomplete className={styles.search} positionLabel="header" />}
      </Header>
      <div className={styles.pageContent}>
        <Hero
          className={styles.hero}
          disclaimer="*We will never share your information with any third parties without your consent."
        >
          <h2 className={styles.title}>
            {'Look up your address to see what your home '}
            <strong>{'could'}</strong>
            {' be worth.'}
          </h2>
          <AddressAutocompleteField
            buttonLabelLong="View my property analysis"
            buttonLabelShort="View property"
            onInputVisibleChange={setHeroInputVisible}
            placeholder="Enter an address*"
            positionLabel="hero"
          />
        </Hero>
        <div className={styles.properties}>
          {highlightedProperties.map((highlightedProperty) => (
            <a
              className={styles.property}
              style={{ backgroundImage: `url('${highlightedProperty.img}')` }}
              key={highlightedProperty.href}
              href={highlightedProperty.href}
            >
              <div className={styles.propertyInner}>
                <div className={styles.propertyCity}>{highlightedProperty.city}</div>
                <div className={styles.propertySpacer} />
                <div className={styles.propertyChart}>
                  <div
                    className={styles.propertyBar}
                    style={{ width: `${(100 * highlightedProperty.value) / highlightedProperty.potential}%` }}
                  />
                  <div className={styles.propertyLabels}>
                    <div>{formatCurrency(highlightedProperty.value)}</div>
                    <div>{formatCurrency(highlightedProperty.potential)}</div>
                  </div>
                </div>
              </div>
            </a>
          ))}
        </div>
      </div>
      <GetStarted
        className={styles.getStarted}
        title="Get started"
        description="Enter your address to see your potential value."
      />
      <Footer />
    </div>
  )
}

export default PropertyPotential
