import React, { FC } from 'react'

import Card from 'pages/_serverRendered/CmsPage/Section/Components/Card'
import Cta from 'components/MarketingAndPropertyPages/Cta'
import { SliceListCard } from 'pages/_serverRendered/CmsPage/types'
import { RichText } from 'prismic-reactjs'
import cx from 'classnames'
import { hasTextPresent } from 'pages/_serverRendered/CmsPage/Section/Components/PresentRichText'
import styles from './styles.module.scss'
import { thumbnailUrl } from 'pages/_serverRendered/CmsPage/utils'

interface ListCardProps {
  id?: string
  className?: string
  slice: SliceListCard
}

const ListCard: FC<ListCardProps> = ({ id, className, slice }) => {
  return (
    <Card id={id} backgroundColor={slice.primary.background_color} className={cx(styles.listCard, className)}>
      {hasTextPresent(slice.primary.title1) && <h2 className={styles.h2}>{slice.primary.title1[0].text}</h2>}
      {slice.primary.image.url && (
        <img className={styles.image} src={thumbnailUrl(slice.primary.image.url)} alt={slice.primary.image.alt || ''} />
      )}
      {hasTextPresent(slice.primary.subheader) && <h3 className={styles.h3}>{slice.primary.subheader[0].text}</h3>}
      {hasTextPresent(slice.primary.description) && (
        <div className={styles.description}>
          <RichText render={slice.primary.description} />
        </div>
      )}
      {hasTextPresent(slice.primary.bullets) && (
        <div className={cx(styles.bulletsContainer, styles[slice.primary.bullets_style])}>
          <RichText render={slice.primary.bullets} />
        </div>
      )}
      {hasTextPresent(slice.primary.closing_text) && (
        <div className={styles.closingText}>
          <RichText render={slice.primary.closing_text} />
        </div>
      )}
      <Cta href={slice.primary.cta_url?.url || ''} cta={`list-card${id ? `-${id}` : ''}`}>
        {slice.primary.cta_text}
      </Cta>
    </Card>
  )
}

export default ListCard
