import React, { FC } from 'react'

import Markdown from 'components/Markdown'

import { ContentText } from 'components/MarketingSite/PageContent/types'
import Cta from 'components/MarketingAndPropertyPages/Cta'

import ImgCaseStudy1 from 'images/learn/case-study-1.jpg'

import styles from './styles.module.scss'

const Text: FC<ContentText> = ({ body, cta, footer, image, title }) => (
  <>
    {image != null && <img className={styles.image} alt={image.alt} src={imageSrc(image.key)} />}
    {title != null && <h3 className={styles.title}>{title}</h3>}
    {body != null && <Markdown className={styles.text}>{body}</Markdown>}
    {footer != null && <Markdown className={styles.footer}>{footer}</Markdown>}
    {cta != null && <Cta href={cta.href}>{cta.title}</Cta>}
  </>
)

const imageSrc = (image) => {
  switch (image) {
    case 'case-study-1':
      return ImgCaseStudy1
  }
}

export default Text
