import React, { FC, useCallback, useMemo } from 'react'

import ZoningGroup from './ZoningGroup'
import { HIGHLIGHT_RULES, ADU_RULES, PRIMARY_RULES, PERMITTED_RULES, OTHER_RULES } from './RuleCategories'

import styles from './styles.module.scss'

interface Props {
  zoningRules: { string: { value: string; caveats: string } }
}

const DataLayout: FC<Props> = ({ zoningRules }) => {
  const filterZoningRules = useCallback(
    (rule) => {
      return zoningRules[rule] && (zoningRules[rule].value || zoningRules[rule].caveats)
    },
    [zoningRules]
  )

  const formattedZoningRules = useCallback(
    (rule) => {
      return {
        ruleType: rule,
        value: zoningRules[rule].value && zoningRules[rule].value !== '-1' ? zoningRules[rule].value : null,
        caveats: zoningRules[rule].caveats ? zoningRules[rule].caveats.split('. ') : [],
      }
    },
    [zoningRules]
  )

  // We want the rule groups to change when zoningRules changes
  const highlightRules = useMemo(() => {
    return HIGHLIGHT_RULES.filter(filterZoningRules).map(formattedZoningRules)
  }, [filterZoningRules, formattedZoningRules])

  const aduRules = useMemo(() => {
    return ADU_RULES.filter(filterZoningRules).map(formattedZoningRules)
  }, [filterZoningRules, formattedZoningRules])

  const primaryRules = useMemo(() => {
    return PRIMARY_RULES.filter(filterZoningRules).map(formattedZoningRules)
  }, [filterZoningRules, formattedZoningRules])

  const permittedRules = useMemo(() => {
    return PERMITTED_RULES.filter(filterZoningRules).map(formattedZoningRules)
  }, [filterZoningRules, formattedZoningRules])

  const otherRules = useMemo(() => {
    return OTHER_RULES.filter(filterZoningRules).map(formattedZoningRules)
  }, [filterZoningRules, formattedZoningRules])

  const match = useMemo(
    () =>
      highlightRules.length !== 0 ||
      aduRules.length !== 0 ||
      primaryRules.length !== 0 ||
      permittedRules.length !== 0 ||
      otherRules.length !== 0,
    [aduRules, highlightRules, otherRules, permittedRules, primaryRules]
  )

  if (!match) {
    return (
      <div className={styles.missing}>
        {"We're still working on collecting zoning for this property. Check back soon!"}
      </div>
    )
  }

  return (
    <div className={styles.dataLayout}>
      <ZoningGroup sectionTitle={'Highlights'} zoningRules={highlightRules} />
      <ZoningGroup sectionTitle={'Primary'} zoningRules={primaryRules} />
      <ZoningGroup sectionTitle={'Accessory'} zoningRules={aduRules} />
      <ZoningGroup sectionTitle={'Permitted'} zoningRules={permittedRules} />
      <ZoningGroup sectionTitle={'Other'} zoningRules={otherRules} />
    </div>
  )
}

export default DataLayout
