import classnames from 'classnames'
import React, { FC } from 'react'

import { ContentScrollingCards } from 'components/MarketingSite/PageContent/types'

import styles from './styles.module.scss'

const ScrollingCards: FC<ContentScrollingCards> = ({ items }) => (
  <div className={classnames(styles.container)}>
    {items.map((item) => (
      <div className={styles.item} key={item.footer}>
        {item.title != null && <p className={styles.title}>{item.title}</p>}
        <p className={styles.body}>{item.body}</p>
        {item.footer != null && <p className={styles.footer}>{item.footer}</p>}
      </div>
    ))}
  </div>
)

export default ScrollingCards
