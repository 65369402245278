import React, { FC } from 'react'

import { ContentMenu } from 'components/MarketingSite/PageContent/types'

import styles from './styles.module.scss'

const Menu: FC<ContentMenu> = ({ items }) => (
  <>
    <ul className={styles.menu}>
      {items.map((item) => (
        <li key={item.href}>
          <a className={styles.link} href={item.href}>
            {item.title}
          </a>
        </li>
      ))}
    </ul>
  </>
)

export default Menu
