import React from 'react'

import { formatNumber } from 'utils/formatting'

import StatPill from '../StatPill'
import ChartContainer from '../../ChartContainer'
import Tooltip from 'components/PropertyPages/ToolTip'

import styles from './styles.module.scss'

interface Props {
  value: number
}

const AverageBuildableSquareFeet: React.FC<Props> = ({ value }) => {
  return (
    <ChartContainer className={styles.averageBuildableSquareFeet}>
      <Tooltip className={styles.tooltip}>
        {
          'The additional square feet each lot has available to develop. We compare the current footprint of the home to the maximum footprint allowed by local zoning rules.'
        }
      </Tooltip>
      <h3>{'Average Buildable Square Feet'}</h3>
      <StatPill className={styles.stat}>{`${formatNumber(value)} sq ft`}</StatPill>
    </ChartContainer>
  )
}

export default AverageBuildableSquareFeet
