import React, { FC, PropsWithChildren } from 'react'

import EventBusyIcon from 'svgs/event-busy'

import CoreModal from 'components/Core/CoreModal'

interface Props {
  title?: string
  onClose?: () => void
}

const FailureModal: FC<PropsWithChildren<Props>> = ({ title, onClose, children }) => (
  <CoreModal
    title={title}
    onClose={onClose}
    buttons={{ name: 'Close', onClick: onClose }}
    icon={<EventBusyIcon />}
    kind="danger"
  >
    {children}
  </CoreModal>
)

export default FailureModal
