import React, { FC, PropsWithChildren, createContext, useContext, useMemo } from 'react'
import { useUtmParams } from 'utils/analytics'

interface Context {
  slug?: string | null
}

const CTAAdjustmentContext = createContext<Context>({
  slug: null,
})

export function useCTAAdjustment(
  url?: string | null,
  isReferralCtaUrl?: boolean,
  isUtmForwardCtaUrl?: boolean,
  slug?: string | null // Let them override slug.
): {
  url: string | null | undefined
  referralCtaUrl: string | null | undefined
  utmForwardCtaUrl: string | null | undefined
} {
  const context = useContext(CTAAdjustmentContext)
  const utmParams = useUtmParams()

  const slugToUse = useMemo(() => {
    return slug || context.slug
  }, [context, slug])

  const referralCtaUrl = useMemo(() => {
    if (!isReferralCtaUrl) return url
    if (!url) return url
    if (!slugToUse) return url

    const newUrl = new URL(url)
    newUrl.searchParams.set('utm_medium', 'partnership')
    newUrl.searchParams.set('utm_source', 'referral')
    // utm_campaign holds a list of data here.
    // key:value|key2:value2|etc.
    newUrl.searchParams.set('utm_campaign', `slug:${slugToUse}`)
    return newUrl.toString()
  }, [isReferralCtaUrl, url, slugToUse])

  const utmForwardCtaUrl = useMemo(() => {
    if (!isUtmForwardCtaUrl) return url
    if (!url) return url
    if (!utmParams) return url

    const newUrl = new URL(url)
    Object.keys(utmParams).forEach((key) => {
      const value = utmParams[key]
      if (typeof value !== 'undefined' && value != null) {
        newUrl.searchParams.set(key, value)
      }
    })

    return newUrl.toString()
  }, [isUtmForwardCtaUrl, url, utmParams])

  const newUrl = useMemo(() => {
    return (isReferralCtaUrl && referralCtaUrl) || (isUtmForwardCtaUrl && utmForwardCtaUrl) || url
  }, [isReferralCtaUrl, isUtmForwardCtaUrl, url, referralCtaUrl, utmForwardCtaUrl])

  return {
    url: newUrl,
    referralCtaUrl,
    utmForwardCtaUrl,
  }
}

interface Props {
  slug?: null | string
}

const CTAAdjustment: FC<PropsWithChildren<Props>> = ({ slug, children }) => {
  return <CTAAdjustmentContext.Provider value={{ slug: slug }}>{children}</CTAAdjustmentContext.Provider>
}

export default CTAAdjustment
