import React, { FC, useState, useCallback } from 'react'
import { RichTextBlock } from 'prismic-reactjs'
import cx from 'classnames'

import { SliceFoldingItems } from '../../types'

import PresentRichText from 'pages/_serverRendered/CmsPage/Section/Components/PresentRichText'
import TitleHeader from '../Components/TitleHeader'

import styles from './styles.module.scss'

interface ItemProps {
  title: RichTextBlock[]
  info: RichTextBlock[]
  referenceId?: string
  startExpanded: boolean
}
const FoldingItem: FC<ItemProps> = ({ title, info, referenceId, startExpanded }) => {
  const [expanded, setExpanded] = useState(startExpanded)
  const toggle = useCallback(() => {
    setExpanded((prev) => !prev)
  }, [])

  return (
    <>
      <button className={cx(styles.expandButton, { [styles.expanded]: expanded })} type="button" onClick={toggle}>
        <PresentRichText className={styles.title} render={title} id={referenceId} />
      </button>
      <PresentRichText className={styles.info} render={info} />
    </>
  )
}

interface Props {
  className?: string
  slice: SliceFoldingItems
}
const FoldingItems: FC<Props> = ({ className, slice }) => {
  return (
    <section className={cx(styles.foldingItems, className)}>
      <TitleHeader className={styles.titleBox} {...slice.primary} />
      <ul className={styles.items}>
        {slice.items.map((item, i) => (
          <li key={item.reference_id || `${i}`}>
            {slice.primary?.allow_collapsing ? (
              <FoldingItem
                startExpanded={!!slice.primary?.start_expanded}
                referenceId={item.reference_id}
                title={item.item_title}
                info={item.item_info}
              />
            ) : (
              <>
                <PresentRichText className={styles.title} render={item.item_title} id={item.reference_id} />
                <PresentRichText className={styles.info} render={item.item_info} />
              </>
            )}
          </li>
        ))}
      </ul>
    </section>
  )
}

export default FoldingItems
