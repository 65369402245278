import React, { FC } from 'react'
import cx from 'classnames'

import { SliceInfoPoints } from '../../types'

import TitleHeader from '../Components/TitleHeader'

import InfoPoint from './InfoPoint'

import styles from './styles.module.scss'

interface Props {
  className?: string
  slice: SliceInfoPoints
}
const InfoPoints: FC<Props> = ({ className, slice }) => {
  return (
    <section className={cx(styles.infoPoints, className)}>
      <TitleHeader className={styles.titleBox} {...slice.primary} />
      <ul className={styles.points} style={{ gridTemplateColumns: `repeat(${slice.primary?.columns || 3}, 1fr` }}>
        {slice.items.map((point, i) => (
          <InfoPoint key={i} infoPoint={point} />
        ))}
      </ul>
    </section>
  )
}

export default InfoPoints
