import React, { FC, useState, useCallback, useMemo } from 'react'

import { ProjectTemplate } from 'recoil/projectTemplates'

import Search from './Search'

import Project from './Project'

import styles from './styles.module.scss'

interface Props {
  projects: Array<ProjectTemplate>
  selectedProjects: Array<number>
  onChange: (projects: Array<number>) => void
}

const ProjectList: FC<Props> = ({ projects: allProjects, selectedProjects, onChange }) => {
  const [search, setSearch] = useState('')

  const handleChange = useCallback(
    (projectId, selected) => {
      const newProjects = selected
        ? selectedProjects.concat(projectId)
        : selectedProjects.filter((selProjId) => selProjId != projectId)
      onChange(newProjects)
    },
    [selectedProjects, onChange]
  )

  const projects = useMemo(() => {
    const matchFn = (project) => project.name.toLowerCase().indexOf(search.toLowerCase()) > -1

    return allProjects
      .filter((project) => matchFn(project))
      .sort((a, b) => {
        if (a.position == null) return 1
        if (b.position == null) return -1
        return a.position - b.position
      })
  }, [allProjects, search])
  return (
    <div className={styles.projectList}>
      <div className={styles.search}>
        <Search
          placeholder={`Search ${projects.length} project${projects.length == 1 ? '' : 's'}`}
          value={search}
          onChange={setSearch}
        />
      </div>
      <ul className={styles.projects}>
        {projects.map((project) => {
          const selected = selectedProjects.indexOf(project.id) > -1
          return (
            <li key={project.id}>
              <Project project={project} selected={selected} onChange={handleChange} />
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default ProjectList
