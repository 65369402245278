import React, { FC, useCallback, useState } from 'react'

import { getJSON } from 'utils/fetch'
import { Calendar } from './types'

import ExpiredIcon from 'svgs/event-busy'
import Spinner from 'svgs/spinner'

import CoreDivider from 'components/Core/CoreDivider'
import CoreText from 'components/Core/CoreText'
import CoreButton from 'components/Core/CoreButton'

import CompletedLayout from 'pages/_serverRendered/VendorSiteVisit/CompletedLayout'

import styles from './Canceled.module.scss'

interface Props {
  calendar: Calendar
  onCalendarChange: React.Dispatch<React.SetStateAction<Calendar>>
}

const Canceled: FC<Props> = ({ calendar, onCalendarChange }) => {
  const [submitting, setSubmitting] = useState(false)

  const handleNewSiteVisitClick = useCallback(async () => {
    setSubmitting(true)
    try {
      // Refresh our calendar info.
      const cResponse = await getJSON<Calendar>(`/vendor_site_visits/${calendar.id}`)
      onCalendarChange((prev) => ({ ...prev, event: undefined, ...cResponse }))
      setSubmitting(false)
    } catch {
      // force a page refresh... this is evil.
      window.location.reload()
    }
  }, [calendar, onCalendarChange])

  return (
    <CompletedLayout
      calendar={calendar}
      title="Site Visit Canceled"
      description={`Your site visit has been canceled.`}
      type="danger"
      icon={<ExpiredIcon />}
      helpMessage="If you believe there is an error, contact us."
      screen="canceled"
      className={styles.canceled}
    >
      <div className={styles.messageContent}>
        <CoreText.Paragraph size="xl" weight="heavy" color="primaryBlack">
          {'Site visit Canceled'}
        </CoreText.Paragraph>
        <CoreDivider.Full />
        <CoreText.Paragraph weight="light" color="tertiaryBlack">
          {
            'Your site visit has been canceled. If you would still like to schedule a site visit for this project, you may start the process again.'
          }
        </CoreText.Paragraph>
        <footer>
          <CoreButton
            type="submit"
            kind="secondary"
            size="s"
            text={'Schedule new site visit'}
            onClick={handleNewSiteVisitClick}
            disabled={submitting}
            icon={submitting ? <Spinner strokeWidth={16} /> : undefined}
          />
        </footer>
      </div>
    </CompletedLayout>
  )
}

export default Canceled
