import React, { FC } from 'react'
import cx from 'classnames'

import { Header as HeaderType } from '../types'

import Logo from './Logo'
import DesktopNavigation from './DesktopNavigation'
import MobileNavigation from './MobileNavigation'

import styles from './styles.module.scss'

interface Props extends HeaderType {
  className?: string
}
const Header: FC<Props> = ({ className, ...navOptions }) => {
  return (
    <header className={cx(styles.header, className)}>
      <Logo />
      <DesktopNavigation className={styles.desktopNav} {...navOptions} />
      <MobileNavigation className={styles.mobileNav} {...navOptions} />
    </header>
  )
}

export default Header
