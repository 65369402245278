import React, { FC, useMemo } from 'react'

import { InfoPoint as InfoPointType } from '../../types'

import { useCTAAdjustment } from '../../CtaAdjustment'

import ClipboardIcon from 'svgs/clipboard'
import ToolsIcon from 'svgs/tools'
import ContractorIcon from 'svgs/contractor-2'
import PhoneIcon from 'svgs/phone'
import DollarSignIcon from 'svgs/dollar-sign'
import MoneyIcon from 'svgs/money'
import EmailIcon from 'svgs/email'

import PresentRichText from 'pages/_serverRendered/CmsPage/Section/Components/PresentRichText'

import styles from './styles.module.scss'

interface Props {
  infoPoint: InfoPointType
  style?: React.CSSProperties
}

const HeroInfoPoint: FC<Props> = ({ infoPoint, style }) => {
  const { url } = useCTAAdjustment(
    infoPoint.cta_url?.url,
    infoPoint.cta_referral_utm_params,
    infoPoint.cta_forward_utm_params
  )
  const icon = useMemo(() => {
    if (infoPoint.icon == 'Clipboard') return <ClipboardIcon />
    if (infoPoint.icon == 'Tools') return <ToolsIcon />
    if (infoPoint.icon == 'Contractor') return <ContractorIcon />
    if (infoPoint.icon == 'Phone') return <PhoneIcon />
    if (infoPoint.icon == 'DollarSign') return <DollarSignIcon />
    if (infoPoint.icon == 'Money') return <MoneyIcon />
    if (infoPoint.icon == 'Email') return <EmailIcon />

    return <span />
  }, [infoPoint.icon])

  return (
    <li className={styles.infoPoint} style={style}>
      {infoPoint.icon != 'NONE' ? <div className={styles.icon}>{icon}</div> : null}
      <PresentRichText className={styles.title} render={infoPoint.title} />
      <PresentRichText className={styles.description} render={infoPoint.description} />
      {infoPoint.cta_url && infoPoint.cta_url.url && (
        <a className={styles.cta} href={url || infoPoint.cta_url?.url} target={infoPoint.cta_url.target}>
          {infoPoint.cta_text || 'Learn more'}
        </a>
      )}
    </li>
  )
}

export default HeroInfoPoint
