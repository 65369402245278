import React, { FC, useState, useEffect, useMemo } from 'react'
import cx from 'classnames'

import styles from './styles.module.scss'

// Circular increment
function inc(value, max) {
  return (value + 1) % max
}

interface Props {
  className?: string
  options: Array<string>
  interval: number
}
const Rotator: FC<Props> = ({ className, options, interval }) => {
  const [lastOption, setLastOption] = useState(0)
  const [curOption, setCurOption] = useState(0)

  // Begin rotation every interval
  useEffect(() => {
    // Don't bother if we only have one option.
    if (options.length <= 1) return

    const intId = setInterval(() => {
      setCurOption((prev) => inc(prev, options.length))
    }, interval)

    return () => {
      clearInterval(intId)
    }
  }, [interval, options])

  // Since we're always spinning 'up', we only need the current
  // and the next option showing.
  const renderOptions = useMemo(() => {
    const opts = [{ key: lastOption, value: options[lastOption] }]
    if (lastOption != curOption) opts.push({ key: curOption, value: options[curOption] })
    return opts
  }, [curOption, lastOption, options])

  // Each time our current option changes, start the rotation
  useEffect(() => {
    // Make them match.
    const tmId = setTimeout(() => setLastOption(curOption), 1000)
    return () => clearTimeout(tmId)
  }, [curOption])

  return (
    <div className={cx(styles.rotator, className)}>
      <ul className={cx(styles.options, { [styles.spin]: curOption != lastOption })}>
        {renderOptions.map((option) => (
          <li key={option.key} className={styles.optionText}>
            {option.value}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Rotator
