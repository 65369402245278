import cx from 'classnames'
import React, { FC, PropsWithChildren, useCallback } from 'react'
import { RichText, RichTextBlock } from 'prismic-reactjs'

import styles from './styles.module.scss'

interface Props {
  richText?: RichTextBlock[]
  dataReplacements?: { [key: string]: string | ((key: string) => string | null) } | ((key: string) => string | null)
}

const StyledRichText: FC<Props> = ({ richText, dataReplacements }) => {
  const htmlSerializer = useCallback(
    (type, element, content, children, key) => {
      // If this is a 'data' custom type, then we want to do a
      // data replacement.
      if (type == 'label' && element.data?.label == 'data') {
        let replaced = content
        if (dataReplacements) {
          if (typeof dataReplacements === 'function') {
            replaced = dataReplacements(content)
          } else if (typeof dataReplacements === 'object') {
            const value = dataReplacements[content]
            if (typeof value === 'function') {
              replaced = value(key)
            } else if (typeof value !== 'undefined') {
              replaced = value
            }
          }
        }
        return <span key={key}>{replaced}</span>
      }
      return null
    },
    [dataReplacements]
  )

  return (
    <div className={styles.styledRichText}>
      <RichText render={richText} htmlSerializer={htmlSerializer} />
    </div>
  )
}

export const StyledText: FC<PropsWithChildren<{ className?: string }>> = ({ children, className }) => (
  <div className={cx(styles.styledRichText, className)}>{children}</div>
)

export default StyledRichText
