import React, { FC } from 'react'

interface Props {
  className?: string
}

const Svg: FC<Props> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 16" height="17" width="16" className={className}>
    <path
      d="M8.50005 8.00008C9.97338 8.00008 11.1667 6.80675 11.1667 5.33341C11.1667 3.86008 9.97338 2.66675 8.50005 2.66675C7.02672 2.66675 5.83338 3.86008 5.83338 5.33341C5.83338 6.80675 7.02672 8.00008 8.50005 8.00008ZM8.50005 9.33342C6.72005 9.33342 3.16672 10.2267 3.16672 12.0001V13.3334H13.8334V12.0001C13.8334 10.2267 10.2801 9.33342 8.50005 9.33342Z"
      fill="currentColor"
    />
  </svg>
)

export default Svg
