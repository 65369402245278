import React from 'react'

import styles from './styles.module.scss'

const FAQ: React.FC = () => (
  <>
    <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
      <h3 className={styles.question} itemProp="name">
        {'What is the Realm Score and how can I get mine?'}
      </h3>
      <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
        <div itemProp="text">
          {
            'Your Realm Score helps you understand how much of your property’s potential you’ve already tapped into, both in terms of home value and livability. Look up any address at realmhome.com to find its Realm Score. It’s calculated on a standardized scale of 0-1,000 and is easy to use to compare properties as it only takes into account the things you are able to change about your property.'
          }
        </div>
      </div>
    </div>
    <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
      <h3 className={styles.question} itemProp="name">
        {'What types of home remodeling projects can Realm help me with?'}
      </h3>
      <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
        <div itemProp="text">
          {
            'Realm provides local project cost estimates as well as property-specific recoup estimates for home upgrades (kitchen remodel, bathroom remodel), adding space (home addition, garage conversion), and installing amenities (pool, deck, landscaping).'
          }
        </div>
      </div>
    </div>
    <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
      <h3 className={styles.question} itemProp="name">
        {'Does Realm cost anything to use?'}
      </h3>
      <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
        <div itemProp="text">
          {
            'Realm is free to use. You can access property-specific recoup calculations for a variety of projects in your free Realm Dashboard after creating an account. You can add as many properties as you’d like to your dashboard.'
          }
        </div>
      </div>
    </div>
    <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
      <h3 className={styles.question} itemProp="name">
        {'What data does Realm use for its home value estimator?'}
      </h3>
      <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
        <div itemProp="text">
          {
            'Our home value estimator uses both categorical and numeric data about your property to estimate its value. We’ve aggregated thousands of data points from a variety of sources including tax history, building permits, zoning regulations, listing price history, and listings photos.'
          }
        </div>
      </div>
    </div>
  </>
)

export default FAQ
