import React, { FC } from 'react'

interface Props {
  className?: string
}

const Svg: FC<Props> = ({ className }) => (
  <svg className={className} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <path
        d="M4.47186 6H6.47186V7C6.47186 8.1 7.37186 9 8.47186 9H14.4719C15.5719 9 16.4719 8.1 16.4719 7V6H18.4719V11H20.4719V6C20.4719 4.9 19.5719 4 18.4719 4H14.2919C13.8719 2.84 12.7719 2 11.4719 2C10.1719 2 9.07186 2.84 8.65186 4H4.47186C3.37186 4 2.47186 4.9 2.47186 6V20C2.47186 21.1 3.37186 22 4.47186 22H10.4719V20H4.47186V6ZM11.4719 4C12.0219 4 12.4719 4.45 12.4719 5C12.4719 5.55 12.0219 6 11.4719 6C10.9219 6 10.4719 5.55 10.4719 5C10.4719 4.45 10.9219 4 11.4719 4Z"
        fill="currentColor"
      />
      <path
        d="M21.2219 13.25C20.8119 12.84 20.1319 12.84 19.7219 13.25L14.9819 18L12.7219 15.75C12.3119 15.34 11.6419 15.34 11.2219 15.75C10.8119 16.16 10.8119 16.84 11.2219 17.25L14.2719 20.29C14.6619 20.68 15.2919 20.68 15.6819 20.29L21.2119 14.75C21.6319 14.34 21.6319 13.66 21.2219 13.25Z"
        fill="currentColor"
      />
    </g>
  </svg>
)

export default Svg
