import React, { FC } from 'react'
import cx from 'classnames'

import { SliceListCards } from '../../types'

import PresentRichText from 'pages/_serverRendered/CmsPage/Section/Components/PresentRichText'
import TitleHeader from '../Components/TitleHeader'

import styles from './styles.module.scss'

interface Props {
  className?: string
  slice: SliceListCards
}
const ListCards: FC<Props> = ({ className, slice }) => {
  return (
    <section className={cx(styles.listCards, className)}>
      <TitleHeader className={styles.titleBox} {...slice.primary} />
      <ul className={styles.cards}>
        {slice.items.map((card, i) => (
          <li key={i} className={styles.card}>
            <PresentRichText render={card?.text} />
          </li>
        ))}
      </ul>
    </section>
  )
}

export default ListCards
