import React, { FC } from 'react'

interface Props {
  className?: string
}

const Svg: FC<Props> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" height="25" width="24" className={className}>
    <path
      d="M12.9212 2C7.40115 2 2.93115 6.48 2.93115 12C2.93115 17.52 7.40115 22 12.9212 22C18.4512 22 22.9312 17.52 22.9312 12C22.9312 6.48 18.4512 2 12.9212 2ZM16.2212 16.71L11.9312 12.41V7H13.9312V11.59L17.6412 15.3L16.2212 16.71Z"
      fill="currentColor"
    />
  </svg>
)

export default Svg
