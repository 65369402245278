import React, { FC, PropsWithChildren } from 'react'
import cx from 'classnames'

import CoreText from 'components/Core/CoreText'
import LocalCard from '../LocalCard'

import styles from './styles.module.scss'

interface MessageProps {
  className?: string
  type: 'success' | 'danger'
  title?: string
  description?: string
  icon?: React.ReactNode
}

const Message: FC<PropsWithChildren<MessageProps>> = ({ className, type, title, description, icon, children }) => {
  return (
    <LocalCard className={cx(styles.message, className)}>
      {icon && <div className={cx(styles.iconWrapper, { [styles[type]]: true })}>{icon}</div>}
      <div className={cx(styles.content, { [styles.static]: !children })}>
        {children || (
          <>
            {title && (
              <CoreText.Paragraph size="xl" weight="heavy" color="primaryBlack">
                {title}
              </CoreText.Paragraph>
            )}
            {description && (
              <CoreText.Paragraph weight="light" color={'tertiaryBlack'}>
                {description}
              </CoreText.Paragraph>
            )}
          </>
        )}
      </div>
    </LocalCard>
  )
}

export default Message
