import React, { FC } from 'react'
import cx from 'classnames'

import { SlicePhotoCards } from '../../types'

import TitleHeader from '../Components/TitleHeader'

import Card from './Card'

import styles from './styles.module.scss'

interface Props {
  className?: string
  slice: SlicePhotoCards
}
const PhotoCards: FC<Props> = ({ className, slice }) => {
  return (
    <section className={cx(styles.photoCards, className)}>
      <TitleHeader className={styles.titleBox} {...slice.primary} />
      <div className={styles.cards}>
        {slice.items.map((card, i) => (
          <Card key={i} card={card} />
        ))}
      </div>
    </section>
  )
}

export default PhotoCards
