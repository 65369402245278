import PresentRichText, { hasTextPresent } from 'pages/_serverRendered/CmsPage/Section/Components/PresentRichText'
import React, { FC } from 'react'

import Card from 'pages/_serverRendered/CmsPage/Section/Components/Card'
import Cta from 'components/MarketingAndPropertyPages/Cta'
import { SlicePress } from 'pages/_serverRendered/CmsPage/types'
import cx from 'classnames'
import styles from './styles.module.scss'

interface PressProps {
  className?: string
  id?: string
  slice: SlicePress
}

const Press: FC<PressProps> = ({ className, id, slice }) => {
  return (
    <Card id={id} backgroundColor={slice.primary?.background_color} className={cx(styles.press, className)}>
      {hasTextPresent(slice.primary?.title1) && <h2 className={styles.h2}>{slice.primary?.title1[0].text}</h2>}
      <PresentRichText render={slice.primary?.description} />
      <div className={styles.container}>
        {slice.items.map((item, index) =>
          item.link ? (
            <a href={item.link.url} target={item.link.target} className={styles.item} key={index}>
              {item.quote[0] && <p className={styles.quote}>{item.quote[0].text}</p>}
              {item.source && <p className={styles.source}>{item.source}</p>}
            </a>
          ) : (
            <div className={styles.item} key={index}>
              {item.quote[0] && <p className={styles.quote}>{item.quote[0].text}</p>}
              {item.source && <p className={styles.source}>{item.source}</p>}
            </div>
          )
        )}
      </div>
      <Cta href={slice.primary?.cta_url?.url || ''} cta={`press${id ? `-${id}` : ''}`}>
        {slice.primary?.cta_title}
      </Cta>
    </Card>
  )
}

export default Press
