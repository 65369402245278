// The content of this page is copy/pasted - disabling some linter rules to avoid having
// to hand-edit everything
/* eslint-disable react/jsx-no-literals, react/jsx-no-bind, jsx-a11y/anchor-is-valid */
import React, { FC, useEffect } from 'react'

import Footer from 'components/MarketingSite/Footer'
import Header from 'components/MarketingSite/Header'
import { trackPage } from 'utils/analytics'

import styles from 'components/MarketingSite/shared/legal.module.scss'

const Content: FC = () => (
  <div className={styles.wrapper}>
    <div className={styles.legal}>
      <h1>Privacy Policy</h1>
      <span> Effective date: May 26th, 2020</span>
      <p></p>
      <p>
        <span>
          At Realm, we take your privacy seriously. Please read this Privacy Policy to learn how we treat your personal
          data.
        </span>{' '}
        <b>
          By using or accessing our Services in any manner, you acknowledge that you accept the practices and policies
          outlined below, and you hereby consent that we will collect, use and share your information as described in
          this Privacy Policy.
        </b>
        <span> &nbsp;</span>
      </p>
      <p>
        <span> Remember that your</span>
        <span> use of Realm’s Services is at all times subject to our </span>
        <span> Terms of Use</span>
        <span>
          , which incorporates this Privacy Policy. Any terms we use in this Policy without defining them have the
          definitions given to them in the <a href="/terms"> Terms of Use</a> .&nbsp;
        </span>
      </p>
      <p>
        <span>
          You may print a copy of this Privacy Policy by clicking{' '}
          <a
            href="#"
            onClick={() => {
              window.print()
              return false
            }}
          >
            {' '}
            here
          </a>
        </span>
        <span>
          . If you have a disability, you may access this Privacy Policy in an alternative format by contacting
          <a href="mailto:info@realmhome.com"> info@realmhome.com</a> .
        </span>
      </p>
      <h2>Privacy Policy Table of Contents</h2>
      <p style={{ paddingLeft: '30px' }}>
        <a href="#what"> What this Privacy Policy Covers</a>
        <a href="#what">
          <br />
        </a>
        <a href="#personal-data"> Personal Data</a>
        <br />
        <i>
          <a href="#personal-data1"> – Categories of Personal Data We Collect</a>
          <br />
        </i>
        <a href="#personal-data2"> – Categories of Sources of Personal Data</a>
        <br />
        <a href="#personal-data3"> – Our Commercial or Business Purposes for Collecting Personal Data</a>
        <br />
        <a href="#share-data"> How We Share Your Personal Data</a>
        <br />
        <a href="#tracking"> Tracking Tools, Advertising and Opt-Out</a>
        <br />
        <a href="#data-security"> Data Security and Retention</a>
        <br />
        <a href="#data-children"> Personal Data of Children</a>
        <br />
        <a href="#ca-residents"> California Resident Rights</a>
        <br />
        <a href="#states-rights"> Other State Law Privacy Rights</a>
        <br />
        <a href="#changes"> Changes to this Privacy Policy</a>
        <br />
        <a href="#contact"> Contact Information</a>
      </p>
      <p>
        <b> </b>
      </p>
      <h2 id="what">What this Privacy Policy Covers</h2>
      <p>
        <span>
          This Privacy Policy covers how we treat Personal Data that we gather when you access or use our Services.
          “Personal Data” means any information that identifies or relates to a particular individual and also includes
          information referred to as “personally identifiable information” or “personal information” under applicable
          data privacy laws, rules or regulations. This Privacy Policy does not cover the practices of companies we
          don’t own or control or people we don’t manage.
        </span>
      </p>
      <h2 id="personal-data">Personal Data</h2>
      <p id="personal-data1">
        <strong>
          <i> Categories of Personal Data We Collect</i>
        </strong>
      </p>
      <p>
        <span>
          This chart details the categories of Personal Data that we collect and have collected over the past 12 months:
        </span>
      </p>
      <table>
        <tbody>
          <tr>
            <td>
              <b> Category of Personal Data&nbsp;</b>
            </td>
            <td>
              <b> Examples of Personal Data We Collect</b>
            </td>
            <td>
              <b> Categories of Third Parties With Whom We Share this Personal Data:</b>
              <span> &nbsp;</span>
            </td>
          </tr>
          <tr>
            <td>
              <b> Profile or Contact Data</b>
            </td>
            <td>
              <ul>
                <li>
                  <span> First and last name</span>
                </li>
                <li>
                  <span> Email</span>
                </li>
                <li>
                  <span> Phone number</span>
                </li>
                <li>
                  <span> Address</span>
                </li>
                <li>
                  <span> Unique identifiers such as passwords</span>
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <span> Service Providers</span>
                </li>
                <li>
                  <span> Advertising Partners</span>
                </li>
                <li>
                  <span> Business Partners</span>
                </li>
                <li>
                  <span> Parties You Authorize, Access or Authenticate</span>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <b> Payment Data</b>
            </td>
            <td>
              <ul>
                <li>
                  <span> Payment card type</span>
                </li>
                <li>
                  <span> Last 4 digits of payment card</span>
                </li>
                <li>
                  <span> Billing address, phone number, and email</span>
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <span> Service Providers (specifically our payment processing partner, currently </span>
                  <span> [</span>
                  <span> Stripe, Inc</span>
                  <span> ][</span>
                  <span> Square, Inc. and Square Capital, LLC</span>
                  <span> ][</span>
                  <span> Braintree (offered by PayPal, Inc.)</span>
                  <span> ]</span>
                  <span> )</span>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <b> Commercial Data</b>
            </td>
            <td>
              <ul>
                <li>
                  <span> Purchase history</span>
                </li>
                <li>
                  <span> Consumer profiles</span>
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <span> Service Providers</span>
                </li>
                <li>
                  <span> Advertising Partners</span>
                </li>
                <li>
                  <span> Business Partners</span>
                </li>
                <li>
                  <span> Parties You Authorize, Access or Authenticate</span>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <b> Device/IP Data</b>
            </td>
            <td>
              <ul>
                <li>
                  <span> IP address</span>
                </li>
                <li>
                  <span> Device ID</span>
                </li>
                <li>
                  <span> Domain server</span>
                </li>
                <li>
                  <span> Type of device/operating system/browser used to access the Services</span>
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <span> Service Providers</span>
                </li>
                <li>
                  <span> Advertising Partners</span>
                </li>
                <li>
                  <span> Business Partners</span>
                </li>
                <li>
                  <span> Parties You Authorize, Access or Authenticate</span>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <b> Web Analytics</b>
            </td>
            <td>
              <ul>
                <li>
                  <span> Web page interactions</span>
                </li>
                <li>
                  <span> Referring webpage/source through which you accessed the Services</span>
                </li>
                <li>
                  <span> Non-identifiable request IDs</span>
                </li>
                <li>
                  <span>Statistics associated with the interaction between device or browser and the Services</span>
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <span> Service Providers</span>
                </li>
                <li>
                  <span> Advertising Partners</span>
                </li>
                <li>
                  <span> Business Partners</span>
                </li>
                <li>
                  <span> Parties You Authorize, Access or Authenticate</span>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <b> Social Network Data</b>
            </td>
            <td>
              <ul>
                <li>
                  <span> Email</span>
                </li>
                <li>
                  <span> Phone number</span>
                </li>
                <li>
                  <span> User name</span>
                </li>
                <li>
                  <span> IP address</span>
                </li>
                <li>
                  <span> Device ID</span>
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <span> Service Providers</span>
                </li>
                <li>
                  <span> Advertising Partners</span>
                </li>
                <li>
                  <span> Business Partners</span>
                </li>
                <li>
                  <span> Parties You Authorize, Access or Authenticate</span>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <b> Consumer Demographic Data</b>
            </td>
            <td>
              <ul>
                <li>
                  <span> Age / date of birth</span>
                </li>
                <li>
                  <span> Zip code</span>
                </li>
                <li>
                  <span> Gender</span>
                </li>
                <li>
                  <span> Race</span>
                </li>
                <li>
                  <span> Ethnicity</span>
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <span> Service Providers</span>
                </li>
                <li>
                  <span> Advertising Partners</span>
                </li>
                <li>
                  <span> Business Partners</span>
                </li>
                <li>
                  <span> Parties You Authorize, Access or Authenticate</span>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <b> Professional or Employment-Related Data</b>
            </td>
            <td>
              <ul>
                <li>
                  <span> Resume</span>
                </li>
                <li>
                  <span> Job title</span>
                </li>
                <li>
                  <span> Job history</span>
                </li>
                <li>
                  <span> Performance evaluations</span>
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <span> Service Providers</span>
                </li>
                <li>
                  <span> Advertising Partners</span>
                </li>
                <li>
                  <span> Business Partners</span>
                </li>
                <li>
                  <span> Parties You Authorize, Access or Authenticate</span>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <b> Non-Public Education Data</b>
            </td>
            <td>
              <ul>
                <li>
                  <span> Grades or transcripts</span>
                </li>
                <li>
                  <span> Student financial information</span>
                </li>
                <li>
                  <span> Student disciplinary records</span>
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <span> Service Providers</span>
                </li>
                <li>
                  <span> Advertising Partners</span>
                </li>
                <li>
                  <span> Business Partners</span>
                </li>
                <li>
                  <span> Parties You Authorize, Access or Authenticate</span>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <b> Geolocation Data</b>
            </td>
            <td>
              <ul>
                <li>
                  <span> IP-address-based location information</span>
                </li>
                <li>
                  <span> GPS data</span>
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <span> Service Providers</span>
                </li>
                <li>
                  <span> Advertising Partners</span>
                </li>
                <li>
                  <span> Business Partners</span>
                </li>
                <li>
                  <span> Parties You Authorize, Access or Authenticate</span>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <b> Biometric Data</b>
            </td>
            <td>
              <ul>
                <li>
                  <span> Fingerprints</span>
                </li>
                <li>
                  <span> Faceprints</span>
                </li>
                <li>
                  <span> Iris scans</span>
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <span> Service Providers</span>
                </li>
                <li>
                  <span> Advertising Partners</span>
                </li>
                <li>
                  <span> Business Partners</span>
                </li>
                <li>
                  <span> Parties You Authorize, Access or Authenticate</span>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <b> Sensory Data</b>
            </td>
            <td>
              <ul>
                <li>
                  <span> Photos, videos or recordings of your environment</span>
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <span> Service Providers</span>
                </li>
                <li>
                  <span> Advertising Partners</span>
                </li>
                <li>
                  <span> Business Partners</span>
                </li>
                <li>
                  <span> Parties You Authorize, Access or Authenticate</span>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <b> Health Data</b>
            </td>
            <td>
              <ul>
                <li>
                  <span> Medical conditions</span>
                </li>
                <li>
                  <span> Weight</span>
                </li>
                <li>
                  <span> Health or exercise activity monitoring</span>
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <span> Service Providers</span>
                </li>
                <li>
                  <span> Advertising Partners</span>
                </li>
                <li>
                  <span> Business Partners</span>
                </li>
                <li>
                  <span> Parties You Authorize, Access or Authenticate</span>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <b> Inferences Drawn From Other Personal Data Collected</b>
            </td>
            <td>
              <ul>
                <li>
                  <span> Profiles reflecting user attributes</span>
                </li>
                <li>
                  <span> Profiles reflecting user behavior</span>
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <span> Service Providers</span>
                </li>
                <li>
                  <span> Advertising Partners</span>
                </li>
                <li>
                  <span> Business Partners</span>
                </li>
                <li>
                  <span> Parties You Authorize, Access or Authenticate</span>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <b> Other Identifying Information that You Voluntarily Choose to Provide</b>
            </td>
            <td>
              <ul>
                <li>
                  <span> Identifying information in emails or letters you send us</span>
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <span> Service Providers</span>
                </li>
                <li>
                  <span> Advertising Partners</span>
                </li>
                <li>
                  <span> Business Partners</span>
                </li>
                <li>
                  <span> Parties You Authorize, Access or Authenticate</span>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <b> Financial Data You Chose to Provide</b>
            </td>
            <td>
              <ul>
                <li>
                  <span> Credit score</span>
                </li>
                <li>
                  <span> Mortgage provider&nbsp;</span>
                </li>
                <li>
                  <span> Mortgage interest rate</span>
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <span> Service Providers</span>
                </li>
                <li>
                  <span> Advertising Partners</span>
                </li>
                <li>
                  <span> Business Partners</span>
                </li>
                <li>
                  <span> Parties You Authorize, Access or Authenticate</span>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <b> Home Data You Chose to Provide</b>
            </td>
            <td>
              <ul>
                <li>
                  <span> Home inspections</span>
                </li>
                <li>
                  <span> Interior and exterior home photographs</span>
                </li>
                <li>
                  <span> Appliance brand and model numbers</span>
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <span> Service Providers</span>
                </li>
                <li>
                  <span> Advertising Partners</span>
                </li>
                <li>
                  <span> Business Partners</span>
                </li>
                <li>
                  <span> Parties You Authorize, Access or Authenticate</span>
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
      <p>&nbsp;</p>
      <p id="personal-data2">
        <strong>
          <i> Categories of Sources of Personal Data</i>
        </strong>
      </p>
      <p>
        <span> We collect Personal Data about you from the following categories of sources:</span>
      </p>
      <ul>
        <li>
          <b> You</b>
        </li>
      </ul>
      <ul>
        <li style={{ listStyleType: 'none' }}>
          <ul>
            <li style={{ listStyleType: 'none' }}>
              <ul>
                <li>
                  <span style={{ textDecoration: 'underline' }}>
                    <span> When you provide such information directly to us</span>
                    <span> .</span>
                  </span>
                  <ul>
                    <li>
                      <span> When you create an account or use our interactive tools and Services.</span>
                    </li>
                    <li>
                      <span>
                        When you voluntarily provide information in free-form text boxes through the Services or through
                        responses to surveys or questionnaires.
                      </span>
                    </li>
                    <li>
                      <span> When you send us an email or otherwise contact us.&nbsp;&nbsp;</span>
                    </li>
                  </ul>
                </li>
                <li>
                  <span style={{ textDecoration: 'underline' }}>
                    <span> When you use the Services and such information is collected automatically</span>
                    <span> .</span>
                  </span>
                  <ul>
                    <li>
                      <span>
                        Through Cookies (defined in the “Tracking Tools, Advertising and Opt-Out” section below).
                      </span>
                    </li>
                    <li>
                      <span>
                        If you use a location-enabled browser, we may receive information about your location.
                      </span>
                    </li>
                    <li>
                      <span>
                        If you download and install certain applications and software we make available, we may receive
                        and collect information transmitted from your computing device for the purpose of providing you
                        the relevant Services, such as information regarding when you are logged on and available to
                        receive updates or alert notices.
                      </span>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <ul>
        <li>
          <b> Public Records</b>
        </li>
      </ul>
      <ul>
        <li style={{ listStyleType: 'none' }}>
          <ul>
            <li style={{ listStyleType: 'none' }}>
              <ul>
                <li>
                  <span> From the government or other sources.</span>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <ul>
        <li>
          <b> Third Parties</b>
        </li>
      </ul>
      <ul>
        <li style={{ listStyleType: 'none' }}>
          <ul>
            <li>
              <span style={{ textDecoration: 'underline' }}>
                <span> Vendors</span>
              </span>
              <ul>
                <li>
                  <span>
                    We may use analytics providers to analyze how you interact and engage with the Services, or third
                    parties may help us provide you with customer support.
                  </span>
                </li>
                <li>
                  <span>We may use vendors to obtain information to generate leads and create user profiles.</span>
                </li>
              </ul>
            </li>
            <li>
              <span style={{ textDecoration: 'underline' }}>
                <span> Advertising Partners</span>
              </span>
              <ul>
                <li>
                  <span>
                    We receive information about you from some of our vendors who assist us with marketing or
                    promotional services related to how you interact with our websites, applications, products,
                    Services, advertisements or communications.
                  </span>
                </li>
              </ul>
            </li>
            <li>
              <span style={{ textDecoration: 'underline' }}>
                <span> Social Networks&nbsp;</span>
              </span>
              <ul>
                <li>
                  <span>
                    If you provide your social network account credentials to us or otherwise sign in to the Services
                    through a third-party site or service, some content and/or information in those accounts may be
                    transmitted into your account with us.
                  </span>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <p>
        <em> &nbsp;</em>
      </p>
      <p id="personal-data3">
        <i>
          <strong> Our Commercial or Business Purposes for Collecting Personal Data</strong>
        </i>
      </p>
      <ul>
        <li>
          <b> Providing, Customizing and Improving the Services</b>
        </li>
      </ul>
      <ul>
        <li style={{ listStyleType: 'none' }}>
          <ul>
            <li style={{ listStyleType: 'none' }}>
              <ul>
                <li>
                  <span> Creating and managing your account or other user profiles.</span>
                </li>
                <li>
                  <span> Processing orders or other transactions; billing.</span>
                </li>
                <li>
                  <span> Providing you with the products, services or information you request.</span>
                </li>
                <li>
                  <span> Meeting or fulfilling the reason you provided the information to us.</span>
                </li>
                <li>
                  <span> Providing support and assistance for the Services.</span>
                </li>
                <li>
                  <span>
                    Improving the Services, including testing, research, internal analytics and product development.
                  </span>
                </li>
                <li>
                  <span>Personalizing the Services, website content and communications based on your preferences.</span>
                </li>
                <li>
                  <span> Doing fraud protection, security and debugging.</span>
                </li>
                <li>
                  <span>
                    Carrying out other business purposes stated when collecting your Personal Data or as otherwise set
                    forth in applicable data privacy laws, such as the California Consumer Privacy Act (the “CCPA”).
                  </span>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <ul>
        <li>
          <b> Marketing the Services</b>
        </li>
      </ul>
      <ul>
        <li style={{ listStyleType: 'none' }}>
          <ul>
            <li style={{ listStyleType: 'none' }}>
              <ul>
                <li>
                  <span> Marketing and selling the Services.</span>
                </li>
                <li>
                  <span>Showing you advertisements, including interest-based or online behavioral advertising.</span>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <ul>
        <li>
          <b> Corresponding with You</b>
        </li>
      </ul>
      <ul>
        <li style={{ listStyleType: 'none' }}>
          <ul>
            <li style={{ listStyleType: 'none' }}>
              <ul>
                <li>
                  <span>
                    Responding to correspondence that we receive from you, contacting you when necessary or requested,
                    and sending you information about Realm or the Services.
                  </span>
                </li>
                <li>
                  <span>
                    Sending emails and other communications according to your preferences or that display content that
                    we think will interest you.
                  </span>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <ul>
        <li>
          <b> Meeting Legal Requirements and Enforcing Legal Terms</b>
        </li>
      </ul>
      <ul>
        <li style={{ listStyleType: 'none' }}>
          <ul>
            <li>
              <span>
                Fulfilling our legal obligations under applicable law, regulation, court order or other legal process,
                such as preventing, detecting and investigating security incidents and potentially illegal or prohibited
                activities.
              </span>
            </li>
            <li>
              <span> Protecting the rights, property or safety of you, Realm or another party.</span>
            </li>
            <li>
              <span> Enforcing any agreements with you.</span>
            </li>
            <li>
              <span>Responding to claims that any posting or other content violates third-party rights.</span>
            </li>
            <li>
              <span> Resolving disputes.</span>
            </li>
          </ul>
        </li>
      </ul>
      <p>
        <span>
          We will not collect additional categories of Personal Data or use the Personal Data we collected for
          materially different, unrelated or incompatible purposes without providing you notice.
        </span>
      </p>
      <p>&nbsp;</p>
      <h2 id="share-data">How We Share Your Personal Data</h2>
      <p>
        <span>
          We disclose your Personal Data to the categories of service providers and other parties listed in this
          section. Depending on state laws that may be applicable to you, some of these disclosures may constitute a
          “sale” of your Personal Data. For more information, please refer to the state-specific sections below.
        </span>
      </p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <b>
            Service Providers.
            <span>
              These parties help us provide the Services or perform business functions on our behalf. They include:
            </span>
          </b>
        </li>
      </ul>
      <ul>
        <li style={{ listStyleType: 'none' }}>
          <ul>
            <li style={{ listStyleType: 'none' }}>
              <ul>
                <li>
                  <span> Hosting, technology and communication providers.</span>
                </li>
                <li>
                  <span> Security and fraud prevention consultants.</span>
                </li>
                <li>
                  <span> Analytics providers.&nbsp;</span>
                </li>
                <li>
                  <span> Support and customer service vendors.</span>
                </li>
                <li>
                  <span> Product fulfillment and delivery providers.</span>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <ul>
        <li>
          <b>
            Business Partners.
            <span> These parties partner with us in offering various services. They include:</span>
          </b>
        </li>
      </ul>
      <ul>
        <li style={{ listStyleType: 'none' }}>
          <ul>
            <li style={{ listStyleType: 'none' }}>
              <ul>
                <li>
                  <span> Businesses that you have a relationship with.</span>
                </li>
                <li>
                  <span>Companies that we partner with to offer joint promotional offers or opportunities.</span>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <ul>
        <li>
          <b> Parties You Authorize, Access or Authenticate</b>
        </li>
      </ul>
      <ul>
        <li style={{ listStyleType: 'none' }}>
          <ul>
            <li>
              <span> Third parties you access through the services.</span>
            </li>
            <li>
              <span> Social media services.</span>
            </li>
            <li>
              <span> Other users.</span>
            </li>
          </ul>
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>
        <span style={{ textDecoration: 'underline' }}>
          <i>
            <span> Legal Obligations</span>
          </i>
        </span>
      </p>
      <p>
        <span>
          We may share any Personal Data that we collect with third parties in conjunction with any of the activities
          set forth under “Meeting Legal Requirements and Enforcing Legal Terms” in the “Our Commercial or Business
          Purposes for Collecting Personal Data” section above.&nbsp;
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ textDecoration: 'underline' }}>
          <i>
            <span> Business Transfers</span>
          </i>
        </span>
      </p>
      <p>
        <span>
          All of your Personal Data that we collect may be transferred to a third party if we undergo a merger,
          acquisition, bankruptcy or other transaction in which that third party assumes control of our business (in
          whole or in part). Should one of these events occur, we will make reasonable efforts to notify you before your
          information becomes subject to different privacy and security policies and practices.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ textDecoration: 'underline' }}>
          <i>
            <span> Data that is Not Personal Data</span>
          </i>
        </span>
      </p>
      <p>
        <span>
          We may create aggregated, de-identified or anonymized data from the Personal Data we collect, including by
          removing information that makes the data personally identifiable to a particular user. We may use such
          aggregated, de-identified or anonymized data and share it with third parties for our lawful business purposes,
          including to analyze, build and improve the Services and promote our business, provided that we will not share
          such data in a manner that could identify you.&nbsp;&nbsp;
        </span>
      </p>
      <p>&nbsp;</p>
      <h2 id="tracking">Tracking Tools, Advertising and Opt-Out</h2>
      <p>
        <span>
          The Services use cookies and similar technologies such as pixel tags, web beacons, clear GIFs and JavaScript
          (collectively, “Cookies”) to enable our servers to recognize your web browser, tell us how and when you visit
          and use our Services, analyze trends, learn about our user base and operate and improve our Services. Cookies
          are small pieces of data– usually text files – placed on your computer, tablet, phone or similar device when
          you use that device to access our Services. We may also supplement the information we collect from you with
          information received from third parties, including third parties that have placed their own Cookies on your
          device(s). Please note that because of our use of Cookies, the Services do not support “Do Not Track” requests
          sent from a browser at this time.
        </span>
      </p>
      <p>
        <span> We use the following types of Cookies:</span>
      </p>
      <ul>
        <li>
          <span> Essential Cookies</span>
          <span>
            . Essential Cookies are required for providing you with features or services that you have requested. For
            example, certain Cookies enable you to log into secure areas of our Services. Disabling these Cookies may
            make certain features and services unavailable.
          </span>
        </li>
        <li>
          <span> Functional Cookies</span>
          <span>
            . Functional Cookies are used to record your choices and settings regarding our Services, maintain your
            preferences over time and recognize you when you return to our Services. These Cookies help us to
            personalize our content for you, greet you by name and remember your preferences (for example, your choice
            of language or region).
          </span>
        </li>
        <li>
          <span> Performance/Analytical Cookies</span>
          <span>
            . Performance/Analytical Cookies allow us to understand how visitors use our Services. They do this by
            collecting information about the number of visitors to the Services, what pages visitors view on our
            Services and how long visitors are viewing pages on the Services. Performance/Analytical Cookies also help
            us measure the performance of our advertising campaigns in order to help us improve our campaigns and the
            Services’ content for those who engage with our advertising. For example, Google Inc. (“Google”) uses
            cookies in connection with its Google Analytics services. Google’s ability to use and share information
            collected by Google Analytics about your visits to the Services is subject to the Google Analytics Terms of
            Use and the Google Privacy Policy. You have the option to opt-out of Google’s use of Cookies by visiting the
            Google advertising opt-out page at www.google.com/privacy_ads.html or the Google Analytics Opt-out Browser
            Add-on at https://tools.google.com/dlpage/gaoptout/.
          </span>
        </li>
        <li>
          <span> Retargeting/Advertising Cookies</span>
          <span>
            . Retargeting/Advertising Cookies collect data about your online activity and identify your interests so
            that we can provide advertising that we believe is relevant to you. For more information about this, please
            see the section below titled “Information about Interest-Based Advertisements.”
          </span>
          <span> &nbsp;</span>
        </li>
      </ul>
      <p>
        <span>
          You can decide whether or not to accept Cookies through your internet browser’s settings. Most browsers have
          an option for turning off the Cookie feature, which will prevent your browser from accepting new Cookies, as
          well as (depending on the sophistication of your browser software) allow you to decide on acceptance of each
          new Cookie in a variety of ways. You can also delete all Cookies that are already on your device. If you do
          this, however, you may have to manually adjust some preferences every time you visit our website and some of
          the Services and functionalities may not work.&nbsp;
        </span>
      </p>
      <p>
        <span>
          To explore what Cookie settings are available to you, look in the “preferences” or “options” section of your
          browser’s menu. To find out more information about Cookies, including information about how to manage and
          delete Cookies, please visit
        </span>
        <a href="http://www.allaboutcookies.org/">
          <span> http://www.allaboutcookies.org/</span>
        </a>
        <span> .</span>
      </p>
      <p>&nbsp;</p>
      <h2>
        <span> Information about Interest-Based Advertisements:&nbsp;</span>
      </h2>
      <p>
        <span>
          We may serve advertisements, and also allow third-party ad networks, including third-party ad servers, ad
          agencies, ad technology vendors and research firms, to serve advertisements through the Services. These
          advertisements may be targeted to users who fit certain general profile categories or display certain
          preferences or behaviors (“Interest-Based Ads”). Information for Interest-Based Ads (including Personal Data)
          may be provided to us by you, or derived from the usage patterns of particular users on the Services and/or
          services of third parties. Such information may be gathered through tracking users’ activities across time and
          unaffiliated properties, including when you leave the Services. To accomplish this, we or our service
          providers may deliver Cookies, including a file (known as a “web beacon”) from an ad network to you through
          the Services. Web beacons allow ad networks to provide anonymized, aggregated auditing, research and reporting
          for us and for advertisers. Web beacons also enable ad networks to serve targeted advertisements to you when
          you visit other websites. Web beacons allow ad networks to view, edit or set their own Cookies on your
          browser, just as if you had requested a web page from their site.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span>
          We comply with the Digital Advertising Alliance (“DAA”) Self-Regulatory Principles for Online Behavioral
          Advertising. Through the DAA and Network Advertising Initiative (“NAI”), several media and marketing
          associations have developed an industry self-regulatory program to give consumers a better understanding of,
          and greater control over, ads that are customized based a consumer’s online behavior across different websites
          and properties. To make choices about Interest-Based Ads from participating third parties, including to
          opt-out of receiving behaviorally targeted advertisements from participating organizations, please visit the
          DAA’s or NAI’s consumer opt-out pages, which are located at
        </span>
        <a href="http://www.networkadvertising.org/choices/">
          <span> http://www.networkadvertising.org/choices/</span>
        </a>
        <span> or </span>
        <a href="http://www.aboutads.info/choices">
          <span> www.aboutads.info/choices</span>
        </a>
        <span> .&nbsp;</span>
      </p>
      <p>
        <b> </b>
      </p>
      <h2 id="data-security">Data Security and Retention</h2>
      <p>
        <span>
          We seek to protect your Personal Data from unauthorized access, use and disclosure using appropriate physical,
          technical, organizational and administrative security measures based on the type of Personal Data and how we
          are processing that data. You should also help protect your data by appropriately selecting and protecting
          your password and/or other sign-on mechanism; limiting access to your computer or device and browser; and
          signing off after you have finished accessing your account. Although we work to protect the security of your
          account and other data that we hold in our records, please be aware that no method of transmitting data over
          the internet or storing data is completely secure.
        </span>
      </p>
      <p>
        <span>
          We retain Personal Data about you for as long as you have an open account with us or as otherwise necessary to
          provide you with our Services. In some cases we retain Personal Data for longer, if doing so is necessary
        </span>
        <span>
          to comply with our legal obligations, resolve disputes or collect fees owed, or is otherwise permitted or
          required by applicable law, rule or regulation. W
        </span>
        <span>
          e may further retain information in an anonymous or aggregated form where that information would not identify
          you personally.
        </span>
      </p>
      <p>
        <b> </b>
      </p>
      <h2 id="data-children">Personal Data of Children</h2>
      <p>
        <span>
          As noted in the Terms of Use, we do not knowingly collect or solicit Personal Data about children under
        </span>
        <strong> 13 </strong>
        <span> &nbsp;years of age; if you are a child under the age of </span>
        <strong> 13</strong>
        <span>
          , please do not attempt to register for or otherwise use the Services or send us any Personal Data. If we
          learn we have collected Personal Data from a child under
        </span>
        <strong> 13</strong>
        <span>
          years of age, we will delete that information as quickly as possible. If you believe that a child under
        </span>
        <strong> 13</strong>
        <span>years of age may have provided Personal Data to us, please contact us at info@realmhome.com.</span>
      </p>
      <p>
        <b> </b>
      </p>
      <h2 id="ca-residents">California Resident Rights</h2>
      <p>
        <span>
          If you are a California resident, you have the rights set forth in this section. Please see the “Exercising
          Your Rights” section below for instructions regarding how to exercise these rights. Please note that we may
          process Personal Data of our customers’ end users or employees in connection with our provision of certain
          services to our customers. If we are processing your Personal Data as a service provider, you should contact
          the entity that collected your Personal Data in the first instance to address your rights with respect to such
          data.
        </span>
      </p>
      <p>
        <span>
          If there are any conflicts between this section and any other provision of this Privacy Policy and you are a
          California resident, the portion that is more protective of Personal Data shall control to the extent of such
          conflict. If you have any questions about this section or whether any of the following rights apply to you,
          please contact us at info@realmhome.com.
        </span>
      </p>
      <p>
        <span style={{ textDecoration: 'underline' }}>
          <i>
            <span> Access</span>
          </i>
        </span>
      </p>
      <p>
        <span>
          You have the right to request certain information about our collection and use of your Personal Data over the
          past 12 months. In response, we will provide you with the following information:
        </span>
      </p>
      <ul>
        <li>
          <span> The categories of Personal Data that we have collected about you.</span>
        </li>
        <li>
          <span> The categories of sources from which that Personal Data was collected.</span>
        </li>
        <li>
          <span> The business or commercial purpose for collecting or selling your Personal Data.</span>
        </li>
        <li>
          <span> The categories of third parties with whom we have shared your Personal Data.</span>
        </li>
        <li>
          <span> The specific pieces of Personal Data that we have collected about you.</span>
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>
        <span>
          If we have disclosed your Personal Data to any third parties for a business purpose over the past 12 months,
          we will identify the categories of Personal Data shared with each category of third party recipient. If we
          have sold your Personal Data over the past 12 months, we will identify the categories of Personal Data sold to
          each category of third party recipient.
        </span>
      </p>
      <p>
        <span style={{ textDecoration: 'underline' }}>
          <i>
            <span> Deletion</span>
          </i>
        </span>
      </p>
      <p>
        <span>
          You have the right to request that we delete the Personal Data that we have collected about you. Under the
          CCPA, this right is subject to certain exceptions: for example, we may need to retain your Personal Data to
          provide you with the Services or complete a transaction or other action you have requested. If your deletion
          request is subject to one of these exceptions, we may deny your deletion request.&nbsp;
        </span>
      </p>
      <p>
        <span style={{ textDecoration: 'underline' }}>
          <i>
            <span> Exercising Your Rights</span>
          </i>
        </span>
      </p>
      <p>
        <span>
          To exercise the rights described above, you or your Authorized Agent (defined below) must send us a request
          that (1) provides sufficient information to allow us to verify that you are the person about whom we have
          collected Personal Data, and (2) describes your request in sufficient detail to allow us to understand,
          evaluate and respond to it. Each request that meets both of these criteria will be considered a “Valid
          Request.” We may not respond to requests that do not meet these criteria. We will only use Personal Data
          provided in a Valid Request to verify your identity and complete your request. You do not need an account to
          submit a Valid Request.
        </span>
      </p>
      <p>
        <span>
          We will work to respond to your Valid Request within 45 days of receipt. We will not charge you a fee for
          making a Valid Request unless your Valid Request(s) is excessive, repetitive or manifestly unfounded. If we
          determine that your Valid Request warrants a fee, we will notify you of the fee and explain that decision
          before completing your request.
        </span>
      </p>
      <p>
        <span> You may submit a Valid Request using the following methods:</span>
      </p>
      <ul>
        <li>
          <span> Email us at: info@realmhome.com</span>
        </li>
      </ul>
      <p>
        <span>
          You may also authorize an agent (an “Authorized Agent”) to exercise your rights on your behalf. To do this,
          you must provide your Authorized Agent with written permission to exercise your rights on your behalf, and we
          may request a copy of this written permission from your Authorized Agent when they make a request on your
          behalf.
        </span>
      </p>
      <p>
        <span style={{ textDecoration: 'underline' }}>
          <i>
            <span> We Will Not Discriminate Against You for Exercising Your Rights Under the CCPA</span>
          </i>
        </span>
      </p>
      <p>
        <span>
          We will not discriminate against you for exercising your rights under the CCPA. We will not deny you our goods
          or services, charge you different prices or rates, or provide you a lower quality of goods and services if you
          exercise your rights under the CCPA. However, we may offer different tiers of our Services as allowed by
          applicable data privacy laws (including the CCPA) with varying prices, rates or levels of quality of the goods
          or services you receive related to the value of Personal Data that we receive from you.
        </span>
        <b> &nbsp;</b>
      </p>
      <p>
        <b> </b>
      </p>
      <h2 id="states-rights">Other State Law Privacy Rights</h2>
      <p>
        <span style={{ textDecoration: 'underline' }}>
          <i> California Resident Rights</i>
        </span>
      </p>
      <p>
        <span>
          Under California Civil Code Sections 1798.83-1798.84, California residents are entitled to contact us to
          prevent disclosure of Personal Data to third parties for such third parties’ direct marketing purposes; in
          order to submit such a request, please contact us at info@realmhome.com&nbsp;
        </span>
      </p>
      <p>
        <span style={{ textDecoration: 'underline' }}>
          <i>
            <span> Nevada Resident Rights</span>
          </i>
        </span>
      </p>
      <p>
        <span>
          If you are a resident of Nevada, you have the right to opt-out of the sale of certain Personal Data to third
          parties who intend to license or sell that Personal Data. You can exercise this right by contacting us at
        </span>
        <a href="mailto:info@realmhome.com">
          <span> info@realmhome.com </span>
        </a>
        <span>
          with the subject line “Nevada Do Not Sell Request” and providing us with your name and the email address
          associated with your account. Please note that we do not currently sell your Personal Data as sales are
          defined in Nevada Revised Statutes Chapter 603A.&nbsp;
        </span>
      </p>
      <p>
        <b> </b>
      </p>
      <h2 id="changes">Changes to this Privacy Policy</h2>
      <p>
        <span>
          We’re constantly trying to improve our Services, so we may need to change this Privacy Policy from time to
          time, but we will alert you to any such changes by placing a notice on the Realm website, by sending you an
          email and/or by some other means. Please note that if you’ve opted not to receive legal notice emails from us
          (or you haven’t provided us with your email address), those legal notices will still govern your use of the
          Services, and you are still responsible for reading and understanding them. If you use the Services after any
          changes to the Privacy Policy have been posted, that means you agree to all of the changes. Use of information
          we collect is subject to the Privacy Policy in effect at the time such information is collected.
        </span>
      </p>
      <p>
        <b> </b>
      </p>
      <h2 id="contact">Contact Information:</h2>
      <p>
        <span>
          If you have any questions or comments about this Privacy Policy, the ways in which we collect and use your
          Personal Data or your choices and rights regarding such collection and use, please do not hesitate to contact
          us at:
        </span>
      </p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <a href="https://realmhome.com/"> https://realmhome.com/</a>
        </li>
        <li>
          <a href="mailto:info@realmhome.com"> info@realmhome.com</a>
        </li>
        <li>333 Hudson Street Suite 201, New York, New York 10013</li>
      </ul>
    </div>
  </div>
)

interface PrivacyProps {
  webview: boolean
}

const Privacy: FC<PrivacyProps> = ({ webview }) => {
  useEffect(() => {
    trackPage('Privacy')
  }, [])

  return (
    <div className={styles.page}>
      {!webview && <Header />}
      <Content />
      {!webview && <Footer />}
    </div>
  )
}

export default Privacy
