import classNames from 'classnames'
import React, { FC, PropsWithChildren } from 'react'

import styles from './styles.module.scss'

interface Props {
  className?: string
}

const ChartContainer: FC<PropsWithChildren<Props>> = ({ children, className }) => (
  <div className={classNames(styles.container, className)}>{children}</div>
)

export default ChartContainer
