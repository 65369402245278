import React, { FC } from 'react'

import { SliceLandingCards } from 'pages/_serverRendered/CmsPage/types'
import cx from 'classnames'
import styles from './styles.module.scss'
import { thumbnailUrl } from 'pages/_serverRendered/CmsPage/utils'

const LandingCards: FC<SliceLandingCards> = ({ items }) => {
  return (
    <div className={cx(styles.container, { [styles.twoUp]: items.length % 2 === 0 })}>
      {items.map((card) => (
        <div className={styles.card} key={card.title?.[0]?.text}>
          {card.title?.[0]?.text != null && <h4 className={styles.title}>{card.title?.[0]?.text}</h4>}
          {card.image?.url != null && (
            <div className={styles.image}>
              <img src={thumbnailUrl(card.image.url)} alt={card.image.alt || ''} />
            </div>
          )}
          {card.body1?.[0]?.text && <p className={styles.body}>{card.body1?.[0]?.text}</p>}
        </div>
      ))}
    </div>
  )
}

export default LandingCards
