import React, { FC } from 'react'
import cx from 'classnames'

import { SliceCustomEmbed } from '../../types'

import CustomEmbed from 'pages/_serverRendered/Blog/shared/CustomEmbed'

import styles from './styles.module.scss'

interface Props {
  className?: string
  slice: SliceCustomEmbed
}
const CustomEmbedSlice: FC<Props> = ({ className, slice }) => {
  return (
    <section id={slice.primary?.nav_title} className={cx(styles.customEmbed, className)}>
      <CustomEmbed slice={slice} />
    </section>
  )
}

export default CustomEmbedSlice
