import React, { FC, useMemo, useCallback } from 'react'
import cx from 'classnames'

import { track } from 'utils/analytics'

import { PhotoCard as PhotoCardType } from '../../types'
import { thumbnailUrl } from 'pages/_serverRendered/CmsPage/utils'

import ClipboardIcon from 'svgs/clipboard'
import ToolsIcon from 'svgs/tools'
import ContractorIcon from 'svgs/contractor-2'
import PhoneIcon from 'svgs/phone'
import DollarSignIcon from 'svgs/dollar-sign'
import MoneyIcon from 'svgs/money'
import EmailIcon from 'svgs/email'

import PresentRichText from 'pages/_serverRendered/CmsPage/Section/Components/PresentRichText'

import styles from './styles.module.scss'

interface Props {
  card: PhotoCardType
}
const Card: FC<Props> = ({ card }) => {
  const url = useMemo(() => {
    return card.cta_url?.url || card.cta_relative_url || null
  }, [card])
  const target = useMemo(() => {
    return card.cta_url?.target || null
  }, [card])
  const handleClick = useCallback(
    async (event: React.MouseEvent<HTMLAnchorElement>) => {
      if (!url) return

      event.preventDefault()
      await track('click cta', {
        code: card.cta_tracking_code,
        section: card.cta_tracking_section,
        feature: card.cta_tracking_feature,
        family: card.cta_tracking_family,
      })
      if (target === '_blank') {
        window.open(url, '_blank')?.focus()
      } else {
        window.location.href = url
      }
    },
    [card, url, target]
  )

  const icon = useMemo(() => {
    if (card.icon == 'Clipboard') return <ClipboardIcon />
    if (card.icon == 'Tools') return <ToolsIcon />
    if (card.icon == 'Contractor') return <ContractorIcon />
    if (card.icon == 'Phone') return <PhoneIcon />
    if (card.icon == 'DollarSign') return <DollarSignIcon />
    if (card.icon == 'Money') return <MoneyIcon />
    if (card.icon == 'Email') return <EmailIcon />

    const number = parseInt(card.icon)
    if (number && !Number.isNaN(number)) {
      return <span>{number}</span>
    }

    return <span />
  }, [card.icon])
  const color = card.image_background_color ? { backgroundColor: card.image_background_color } : {}
  return (
    <div className={styles.card}>
      {card.image.url ? (
        <img
          className={cx(styles.image, { [styles.centered]: card.image_position == 'Centered' })}
          alt={card.image.alt || ''}
          src={thumbnailUrl(card.image.url)}
          style={{ ...color }}
        />
      ) : (
        <div className={styles.image} style={{ ...color }} />
      )}
      <div className={styles.info}>
        {card.icon != 'NONE' ? <div className={styles.icon}>{icon}</div> : null}
        <PresentRichText render={card.title} className={styles.cardTitle} />
        <PresentRichText render={card.description} className={styles.description} />
        <PresentRichText render={card.description_alt} className={styles.descriptionAlt} />
        {card.cta_text && url ? (
          <a className={styles.cta} onClick={handleClick} href={url} target={target || undefined}>
            {card.cta_text}
          </a>
        ) : null}
      </div>
    </div>
  )
}

export default Card
