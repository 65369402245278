import dayjs, { Dayjs } from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)
dayjs.extend(timezone)

export type MarkTypes = 'default' | 'dark'

export interface CalendarMarks {
  [date: number]: MarkTypes
}

export const setMark = function (marks: CalendarMarks, date: Dayjs, mark: MarkTypes): CalendarMarks {
  return { ...marks, [date.startOf('day').toDate().getTime()]: mark }
}
export const getMark = function (marks: CalendarMarks, date: Dayjs): undefined | MarkTypes {
  return marks[date.startOf('day').toDate().getTime()]
}
export const clearMark = function (marks: CalendarMarks, date: Dayjs): CalendarMarks {
  const remKey = date.startOf('day').toDate().getTime()
  const newMarks = { ...marks }
  delete newMarks[remKey]
  return newMarks
}
