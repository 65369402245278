import React, { FC } from 'react'
import cx from 'classnames'

import PresentRichText, { hasTextPresent } from 'pages/_serverRendered/CmsPage/Section/Components/PresentRichText'
import { RichTextBlock } from 'prismic-reactjs'

import styles from './styles.module.scss'

interface Props {
  className?: string
  subHeadline: Array<RichTextBlock>
}
const SubHeadline: FC<Props> = ({ className, subHeadline }) => {
  // no render if no subHeadline.
  if (!hasTextPresent(subHeadline)) {
    return null
  }

  return <PresentRichText className={cx(styles.subHeadline, className)} render={subHeadline} />
}

export default SubHeadline
