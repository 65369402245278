import React, { FC } from 'react'
import cx from 'classnames'

import PresentRichText, { hasTextPresent } from 'pages/_serverRendered/CmsPage/Section/Components/PresentRichText'
import { RichTextBlock } from 'prismic-reactjs'

import styles from './styles.module.scss'

interface Props {
  className?: string
  title?: RichTextBlock[]
}
const SectionTitle: FC<Props> = ({ className, title }) => {
  // no render if no titles.
  if (!hasTextPresent(title)) {
    return null
  }
  return (
    <div className={cx(styles.title, className)}>
      {title?.map((part, i) => {
        let cl = ''
        if (part.text && ['<', '>'].includes(part.text[0])) {
          cl = part.text[0] == '<' ? styles.leftJustify : styles.rightJustify
          part = {
            ...part,
            text: part.text.substring(1),
            spans: part.spans?.map((span) => ({
              start: span.start - 1,
              end: span.end - 1,
              type: span.type,
            })),
          }
        }
        return <PresentRichText key={i} className={cl} render={[part]} />
      })}
    </div>
  )
}

export default SectionTitle
