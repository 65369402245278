import Info from '@mui/icons-material/Info'
import CreatingProperty from 'components/Auth/CreatingProperty'
import CoreButton from 'components/Core/CoreButton'
import Loading from 'components/Loading'
import HandleURLQuery from 'non-rendering/HandleURLQuery'
import Container from 'pages/_serverRendered/AeEventSignup/components/Container'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import {
  eventsAddedUserState,
  eventsCreatingPropertyTokenState,
  eventsSelectedAeSelector,
  eventsSelectedPackagePriceInDollarsState,
  eventsSelectedPackageState,
  purchasePackage,
} from 'recoil/events'

const PayDeposit: FC = () => {
  const [loading, setLoading] = useState(false)
  const [propertyId, setPropertyId] = useState<number>()
  const [error, setError] = useState(false)
  const selectedPackage = useRecoilValue(eventsSelectedPackageState)
  const selectedPrice = useRecoilValue(eventsSelectedPackagePriceInDollarsState)
  const selectedAe = useRecoilValue(eventsSelectedAeSelector)
  const creatingPropertyToken = useRecoilValue(eventsCreatingPropertyTokenState)
  const user = useRecoilValue(eventsAddedUserState)
  const history = useHistory()

  const handleClickTryAgain = useCallback(() => {
    setError(false)
    setLoading(true)
  }, [])

  const handleCreatingPropertySuccess = useCallback(({ id: propertyId }) => {
    setPropertyId(propertyId)
    setLoading(true)
  }, [])

  useEffect(() => {
    if (!creatingPropertyToken) setLoading(true)
  }, [creatingPropertyToken])

  useEffect(() => {
    if (!loading) return
    if (!user) return
    if (!selectedAe) return

    const _ = async () => {
      if (selectedPackage && user.email) {
        try {
          setError(false)
          const response = await purchasePackage({
            user_email: user.email,
            package_id: selectedPackage.id,
            property_id: propertyId,
            custom_price_in_cents: (selectedPrice || selectedPackage.price_in_dollars) * 100,
            admin_user_id: selectedAe.admin_user_id,
          })

          if (response.isError) {
            setError(true)
            return
          }

          window.location.href = response.redirect_url
        } catch {
          setError(true)
        } finally {
          setLoading(false)
        }
      }
    }
    _()
  })

  const onReturnFromStripe = useCallback(
    async (key: string, value: Array<string>) => {
      if (!value || value.length == 0) return

      if (key == 'success') history.push('/')
      else setError(true)
    },
    [history]
  )

  return (
    <Container>
      <HandleURLQuery query={['success', 'cancel']} onHandle={onReturnFromStripe} preserve={false} />

      {error ? (
        <>
          <div className={'tw-p-3 tw-flex tw-gap-2 tw-bg-red-100 tw-text-red-600'}>
            <Info />
            <div>{'An error occured'}</div>
          </div>
          <CoreButton className="tw-w-full" text="Try again" onClick={handleClickTryAgain} />
        </>
      ) : creatingPropertyToken ? (
        <CreatingProperty
          className="tw-relative tw-h-40"
          token={creatingPropertyToken}
          onSuccess={handleCreatingPropertySuccess}
        >
          <Loading />
        </CreatingProperty>
      ) : (
        <div className="tw-relative tw-h-40">
          <Loading />
        </div>
      )}
    </Container>
  )
}

export default PayDeposit
