import React, { FC, useCallback } from 'react'

import EarlierDaysAvailableIcon from 'svgs/event-available'

import CoreModal from 'components/Core/CoreModal'
import CoreText from 'components/Core/CoreText'
import CoreButton from 'components/Core/CoreButton'

import styles from './EarlierDaysAvailableModal.module.scss'

interface Props {
  earlierDays: number
  onClose?: () => void
  onConfirm?: () => void
}

const EarlierDaysAvailableModal: FC<Props> = ({ earlierDays, onClose, onConfirm }) => {
  const renderButtons = useCallback(
    () => (
      <>
        <CoreButton text="I'm sure" kind="secondary" onClick={onConfirm} />
        <CoreButton text="Take me back to scheduling" kind="primary" onClick={onClose} />
      </>
    ),
    [onConfirm, onClose]
  )
  return (
    <CoreModal
      className={styles.earlierDaysAvailableModal}
      title="Earlier times available"
      onClose={onClose}
      buttons={renderButtons}
      icon={<EarlierDaysAvailableIcon />}
      kind="warning"
    >
      <CoreText.Paragraph className={styles.content} size="m" weight="light" color="primaryBlack">
        {`There ${earlierDays == 1 ? 'is' : 'are'} ${earlierDays} day${
          earlierDays == 1 ? '' : 's'
        } with availability open before the time you have selected. Are you sure you don't want to schedule sooner?`}
      </CoreText.Paragraph>
      <CoreText.Paragraph className={styles.content} size="m" weight="regular" color="primaryBlack">
        {`Being one of the first two contractors on site will increase your chances of winning the project by 2x!`}
      </CoreText.Paragraph>
    </CoreModal>
  )
}

export default EarlierDaysAvailableModal
