import React, { FC, useMemo } from 'react'
import cx from 'classnames'

import { formatCurrency } from 'utils/formatting'
import { ProjectTemplate } from 'recoil/projectTemplates'

import styles from './styles.module.scss'

interface Props {
  className?: string
  selectedProjects: Array<ProjectTemplate>
}

const Calculations: FC<Props> = ({ className, selectedProjects }) => {
  const totalMin = useMemo(() => selectedProjects.reduce((memo, project) => memo + project.cost_estimate_low, 0), [
    selectedProjects,
  ])
  const totalMax = useMemo(() => selectedProjects.reduce((memo, project) => memo + project.cost_estimate_high, 0), [
    selectedProjects,
  ])
  return (
    <div className={cx(styles.calculations, className)}>
      <div className={styles.total}>
        {'Total'}
        <span className={styles.values}>
          {!totalMin && !totalMax ? '$0' : `${formatCurrency(totalMin, true)} - ${formatCurrency(totalMax, true)}`}
        </span>
      </div>
    </div>
  )
}

export default Calculations
