import React, { FC } from 'react'

import CmsPage from 'pages/_serverRendered/CmsPage'
import { CmsPageData } from 'pages/_serverRendered/CmsPage/types'

const Homepage: FC<{ data: CmsPageData }> = ({ data }) => {
  return <CmsPage data={data} pageName="homepage" />
}

export default Homepage
