import React, { FC } from 'react'
import cx from 'classnames'

import { FooterDownloadSlice } from '../types'

import PresentRichText, { hasTextPresent } from 'pages/_serverRendered/CmsPage/Section/Components/PresentRichText'

import CallToAction from '../Components/CallToAction'
import QRCode from '../Components/QRCode'
import useAdjustLink from '../Components/useAdjustLink'

import styles from './styles.module.scss'

type Props = FooterDownloadSlice

const DownloadCategory: FC<Props> = ({
  primary: {
    header,
    description,
    download_text,
    download_url,
    download_qr_code,
    download_tracking_code,
    download_tracking_section,
    download_tracking_feature,
    download_tracking_family,
  },
}) => {
  const downloadLink = useAdjustLink(download_url)
  return (
    <li className={cx(styles.category, styles.downloadCategory)}>
      <div className={styles.header}>{header}</div>
      {hasTextPresent(description) && <PresentRichText className={styles.description} render={description} />}
      {download_text && (
        <CallToAction
          className={cx(styles.cta, styles.mobile)}
          url={downloadLink}
          trackingCode={download_tracking_code}
          trackingSection={download_tracking_section}
          trackingFeature={download_tracking_feature}
          trackingFamily={download_tracking_family}
        >
          {download_text}
        </CallToAction>
      )}
      {download_qr_code && <QRCode className={cx(styles.qrCode, styles.desktop)} image={download_qr_code} />}
    </li>
  )
}

export default DownloadCategory
