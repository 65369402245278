import React, { FC } from 'react'

import FullAddressInput from 'components/FullAddressInput'
import Spinner from 'components/Spinner'
import Input from 'components/Input'

import styles from './styles.module.scss'

interface Props {
  apiKey: string | undefined
  setApiKey: (apiKey: string) => void
  onPredictionSelected: (prediction: any) => void
  enteredAddress: string
  setEnteredAddress: (enteredAddress: string) => void
  loading: boolean
  error: boolean
}

const CoverPage: FC<Props> = ({
  apiKey,
  setApiKey,
  onPredictionSelected,
  enteredAddress,
  setEnteredAddress,
  loading,
  error,
}) => {
  return (
    <div className={styles.coverPage}>
      <h1>{'Want to see Zoning in your area?'}</h1>
      <p className={styles.enterKeys}>{'Enter your user key and an address to get started'}</p>
      <div className={styles.searchBars}>
        <Input
          placeholder="User Key"
          className={styles.search}
          value={apiKey}
          onChange={setApiKey}
          disabled={loading}
        />
        <FullAddressInput
          className={styles.search}
          placeholder="Enter an address"
          id="entered_address"
          required
          onPredictionSelected={onPredictionSelected}
          value={enteredAddress}
          onChange={setEnteredAddress}
          disabled={loading}
        />
      </div>
      {loading && <Spinner size={'lg'} color={'white'} />}
      {error && <div className={styles.error}>{'Error: Please Enter A Valid Key and Property Address'}</div>}
    </div>
  )
}

export default CoverPage
