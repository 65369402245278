import React, { FC } from 'react'

interface HamburgerIconProps {
  className?: string
}

const HamburgerIcon: FC<HamburgerIconProps> = ({ className }) => (
  <svg className={className} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3333 4H2.66667C2.3 4 2 4.3 2 4.66667V4.67333C2 5.04 2.3 5.34 2.66667 5.34H13.3333C13.7 5.34 14 5.04 14 4.67333V4.66667C14 4.3 13.7 4 13.3333 4ZM2.66667 7.33333H13.3333C13.7 7.33333 14 7.63333 14 8C14 8.36667 13.7 8.66667 13.3333 8.66667H2.66667C2.3 8.66667 2 8.36667 2 8C2 7.63333 2.3 7.33333 2.66667 7.33333ZM13.3333 12H6.66667C6.3 12 6 11.7 6 11.3333C6 10.9667 6.3 10.6667 6.66667 10.6667H13.3333C13.7 10.6667 14 10.9667 14 11.3333C14 11.7 13.7 12 13.3333 12Z"
      fill="currentColor"
    />
  </svg>
)

export default HamburgerIcon
