import React, { FC, ReactNode, useCallback, useState } from 'react'

import styles from './styles.module.scss'

interface AccordionProps {
  preselectedItems?: number[]
  preopen?: boolean
  children?: (args: { selectedIndices: number[]; toggleIndex: (index: number) => void }) => ReactNode
}

const Accordion: FC<AccordionProps> = ({ children, preopen, preselectedItems }) => {
  const [selectedIndices, setSelectedIndices] = useState<number[]>(
    preselectedItems ? preselectedItems : preopen ? [0] : []
  )
  const toggleIndex = useCallback(
    (index: number) => {
      if (selectedIndices.includes(index)) {
        setSelectedIndices(selectedIndices.filter((e) => e !== index))
      } else {
        setSelectedIndices([...selectedIndices, index])
      }
    },
    [selectedIndices, setSelectedIndices]
  )

  return (
    <div className={styles.accordion}>
      {children &&
        children({
          selectedIndices,
          toggleIndex,
        })}
    </div>
  )
}

export default Accordion
