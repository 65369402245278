import {
  eventsAddedUserState,
  eventsPropsState,
  eventsSelectedAeSelector,
  useResetEventOnboarding,
} from 'recoil/events'
import React, { FC, useCallback, useEffect, useMemo } from 'react'
import { InlineWidget, useCalendlyEventListener } from 'react-calendly'
import { useHistory } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { useWindowHeight } from 'utils/windowHeight'

const ScheduleMeeting: FC = () => {
  const history = useHistory()
  const windowHeight = useWindowHeight()
  const user = useRecoilValue(eventsAddedUserState)
  const ae = useRecoilValue(eventsSelectedAeSelector)
  const props = useRecoilValue(eventsPropsState)
  const reset = useResetEventOnboarding()

  const calendlyUrl = useMemo(() => ae?.calendly_link || props?.calendly_url, [ae?.calendly_link, props?.calendly_url])

  useEffect(() => {
    if (!calendlyUrl) history.push('/')
  }, [calendlyUrl, history])

  useCalendlyEventListener({
    onEventScheduled: useCallback(async () => {
      setTimeout(() => {
        reset()
        history.push('/')
      }, 2000)
    }, [history, reset]),
  })

  if (!calendlyUrl) return null

  return (
    <InlineWidget
      url={`${calendlyUrl}?hide_gdpr_banner=1`}
      styles={{ width: '100vw', height: `${windowHeight}px` }}
      prefill={{ email: user?.email, name: user?.full_name, customAnswers: { a1: user?.phone_number } }}
      utm={{ utmSource: 'event-signup' }}
    />
  )
}

export default ScheduleMeeting
