import React, { FC, useMemo, useCallback, MouseEvent, PropsWithChildren } from 'react'
import cx from 'classnames'

import { Link } from 'prismic-reactjs'

import { useTrackClick } from '../../'

import styles from './styles.module.scss'

interface Props {
  className?: string
  url?: Link
  url_relative?: string

  trackingCode?: string
  trackingSection?: string
  trackingFeature?: string
  trackingFamily?: string
}
const CallToAction: FC<PropsWithChildren<Props>> = ({
  className,
  children,
  url,
  url_relative,
  trackingCode,
  trackingSection,
  trackingFeature,
  trackingFamily,
}) => {
  const trackClick = useTrackClick()

  const href = useMemo(() => (url?.url ? url.url : url_relative || ''), [url, url_relative])
  const target = useMemo(() => (url?.url ? url.target : ''), [url])

  const handleClick = useCallback(
    async (e: MouseEvent<HTMLAnchorElement>) => {
      if (!trackingCode) return
      e.preventDefault()

      await trackClick({
        code: trackingCode,
        section: trackingSection,
        feature: trackingFeature,
        family: trackingFamily,
      })

      if (target == '_blank') {
        window.open(href, '_blank')?.focus()
      } else {
        window.location.href = href
      }
    },
    [trackClick, trackingCode, trackingSection, trackingFeature, trackingFamily, href, target]
  )

  return (
    <a className={cx(styles.callToAction, className)} href={href} target={target} onClick={handleClick}>
      {children}
    </a>
  )
}

export default CallToAction
