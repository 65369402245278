import React, { FC, useMemo } from 'react'

import { SliceCustomerLogos } from 'pages/_serverRendered/CmsPage/types'
import WistiaVideo from 'components/WistiaVideo'
import styles from './styles.module.scss'

const extractWistiaVideoIdFromUrl = (url: string) => {
  const match = url.match(/myrealm.wistia.com\/medias\/(.+)/)
  if (match && match.length > 1) {
    return match[1]
  }

  return null
}

const CustomerLogos: FC<SliceCustomerLogos> = ({ primary, items }) => {
  const videoId = useMemo(() => {
    if (primary.media && primary.media.link_type == 'Web') {
      return extractWistiaVideoIdFromUrl(primary.media.url || '')
    }
  }, [primary.media])

  return (
    <>
      <div className={styles.mediaContainer}>
        {primary.media && primary.media.link_type === 'Media' && (
          <img alt="" src={primary.media.url} className={styles.image} />
        )}
        {primary.media && primary.media.link_type === 'Web' && videoId && (
          <div className={styles.videoSubContainer}>
            <WistiaVideo videoId={videoId} />
          </div>
        )}
      </div>
      {items.length > 0 && (
        <div className={styles.customerLogosLogos}>
          {items.map((e) =>
            e.link.link_type === 'Web' && e.logo.url ? (
              <a key={e.logo.url} href={e.link.url} target={e.link.target}>
                <img src={e.logo.url} alt={e.logo.alt || ''} />
              </a>
            ) : e.logo.url ? (
              <img key={e.logo.url} src={e.logo.url} alt={e.logo.alt || ''} />
            ) : null
          )}
        </div>
      )}
    </>
  )
}

export default CustomerLogos
