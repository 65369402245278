import { AutocompleteRedirectTargetTypes } from 'pages/_serverRendered/CmsPage/types'

export const thumbnailUrl = (url: string, maxHeight = 640): string => {
  return `${url}&fit=clip&h=${maxHeight}`
}

export function ConvertAutocompleteRedirectTarget(target: AutocompleteRedirectTargetTypes): undefined | string {
  if (target == AutocompleteRedirectTargetTypes.ProjectPricing) return 'renovation-pricing'
  else if (target == AutocompleteRedirectTargetTypes.RenovationPricing) return 'renovation-pricing'
  else if (target == AutocompleteRedirectTargetTypes.HomeReport) return 'home-report'

  // Default should be the default, and is specifically left undefined to allow other possible redirect
  // options on the back-end (most will be a version of onboarding)
  return
}
