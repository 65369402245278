import React, { FC } from 'react'

interface Props {
  className?: string
}

const Svg: FC<Props> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" height="16" width="16" className={className}>
    <path
      d="M8.00001 1.33334C4.32001 1.33334 1.33334 4.32 1.33334 8C1.33334 11.68 4.32001 14.6667 8.00001 14.6667C11.68 14.6667 14.6667 11.68 14.6667 8C14.6667 4.32 11.68 1.33334 8.00001 1.33334ZM8.66668 11.3333H7.33334V7.33334H8.66668V11.3333ZM8.66668 6H7.33334V4.66667H8.66668V6Z"
      fill="currentColor"
    />
  </svg>
)

export default Svg
