import React, { FC, useEffect } from 'react'
import Header from 'components/MarketingSite/Header'
import { trackPage } from 'utils/analytics'

import styles from './styles.module.scss'

const RateTable: FC = () => {
  useEffect(() => {
    trackPage('RateTable')
  }, [])

  return (
    <div className={styles.rateTable}>
      <Header />
      <iframe
        className={styles.rateTableIframe}
        title="rate table"
        src="https://widgets.icanbuy.com/c/standard/us/en/mortgage/tables/Mortgage.aspx?siteid=224b1d932f859694&redirect_no_results=1&redirect_to_mortgage_funnel=1&searchbtnbgcolor=364af9&listingbtnbgcolor=4a957a"
      />
    </div>
  )
}

export default RateTable
