import React, { FC } from 'react'
import cx from 'classnames'

import { HeroImage } from '../../types'

import styles from './styles.module.scss'

interface Props {
  className?: string
  image: HeroImage
  orientation: 'square' | 'landscape' | 'portrait'
}

const Image: FC<Props> = ({ className, image, orientation }) => {
  const isLandscape = orientation == 'landscape'
  const isPortrait = orientation == 'portrait'
  const srcset = [
    { img: isLandscape ? image.landscape : isPortrait ? image.portrait : image, size: '1x' },
    { img: isLandscape ? image.landscape_2x : isPortrait ? image.portrait_2x : image.square_2x, size: '2x' },
  ]
    .filter((img) => !!img.img)
    .map((img) => `${img.img?.url} ${img.size}`)
    .join(',')

  return (
    <img
      className={cx(styles.image, { [styles.landscape]: isLandscape, [styles.portrait]: isPortrait }, className)}
      src={image.url}
      srcSet={srcset}
      alt={image?.alt || ''}
    />
  )
}

export default Image
