import React, { FC, useEffect } from 'react'

import { useAdjustBanner } from 'non-rendering/ShowOrHideAdjustBanner'
import { BrowserRouter, Route, StaticRouter, Switch } from 'react-router-dom'
import PickTask from 'pages/_serverRendered/AeEventSignup/screens/PickTask'
import UserInfo from 'pages/_serverRendered/AeEventSignup/screens/UserInfo'
import { RecoilRoot, useSetRecoilState } from 'recoil'
import { EventsProps, eventsPropsState } from 'recoil/events'
import ScheduleMeeting from 'pages/_serverRendered/AeEventSignup/screens/ScheduleMeeting'
import PayDeposit from 'pages/_serverRendered/AeEventSignup/screens/PayDeposit'

const Inner: FC<EventsProps> = (props) => {
  const setProps = useSetRecoilState(eventsPropsState)

  const { hideAdjustBanner } = useAdjustBanner()
  useEffect(() => hideAdjustBanner(), [hideAdjustBanner])

  useEffect(() => {
    setProps(props)
  }, [props, setProps])

  return (
    <div className="tw-flex tw-flex-col tw-min-h-screen tw-bg-gradient-to-b tw-from-green-900 tw-to-neutrals-1000">
      <Switch>
        <Route exact path="/" component={PickTask} />
        <Route exact path="/user-info" component={UserInfo} />
        <Route exact path="/schedule-meeting" component={ScheduleMeeting} />
        <Route exact path="/pay-deposit" component={PayDeposit} />
      </Switch>
    </div>
  )
}

const AeEventSignup: FC<EventsProps> = (props) => {
  let Router = StaticRouter
  if (typeof window !== 'undefined') Router = BrowserRouter

  return (
    <RecoilRoot>
      <Router basename="ae-event-signup">
        <Inner {...props} />
      </Router>
    </RecoilRoot>
  )
}

export default AeEventSignup
