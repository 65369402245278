import PresentRichText, { hasTextPresent } from 'pages/_serverRendered/CmsPage/Section/Components/PresentRichText'
import React, { FC } from 'react'

import { SliceBlogHighlights } from 'pages/_serverRendered/CmsPage/types'
import Cta from 'components/MarketingAndPropertyPages/Cta'
import styles from './styles.module.scss'
import { thumbnailUrl } from 'pages/_serverRendered/CmsPage/utils'

const BlogHighlights: FC<SliceBlogHighlights> = ({ items }) => {
  return (
    <div className={styles.blogHighlights}>
      {items.map((e, i) => (
        <div className={styles.item} key={i}>
          {e.blog_post_image.url && (
            <img
              className={styles.blogPostImage}
              src={thumbnailUrl(e.blog_post_image.url)}
              alt={e.blog_post_image.alt || ''}
            />
          )}
          <div className={styles.itemInner}>
            <div className={styles.authorInfo}>
              {e.author_picture.url && (
                <img
                  className={styles.authorPicture}
                  src={thumbnailUrl(e.author_picture.url)}
                  alt={e.author_picture.alt || ''}
                />
              )}
              <div className={styles.authorDetails}>
                <div>{`${e.author_name || 'Realm team'}${e.author_role ? ',' : ''}`}</div>
                {e.author_role && <div>{e.author_role}</div>}
              </div>
            </div>
            {hasTextPresent(e.blog_post_title) && <h3 className={styles.blogPostTitle}>{e.blog_post_title[0].text}</h3>}
            <PresentRichText render={e.blog_post_snippet} />
            <Cta href={e.blog_post_url.url || ''} target={e.blog_post_url.target} cta={`blog-highlight-${i + 1}`}>
              {'Read'}
            </Cta>
          </div>
        </div>
      ))}
    </div>
  )
}

export default BlogHighlights
