import React, { FC } from 'react'
import cx from 'classnames'

import { HeroData, AutocompleteRedirectTargetTypes } from '../types'
import { ConvertAutocompleteRedirectTarget } from 'pages/_serverRendered/CmsPage/utils'

import PresentRichText from 'pages/_serverRendered/CmsPage/Section/Components/PresentRichText'
import Cta from 'components/MarketingAndPropertyPages/Cta'

import AddressAutocompleteField from '../AddressAutocompleteField'
import Media from './Media'
import CtaButton from './CtaButton'

import styles from './styles.module.scss'

interface Props extends HeroData {
  className?: string
  onAddressInputVisibleChange?: (boolean) => void
  onCtaVisibleChange?: (boolean) => void
  autocompleteRedirectTarget: AutocompleteRedirectTargetTypes
  onRequestHeaderHeight: () => number | undefined
}

// Hero has 3 different `data.type`: 'bullets', 'homepage', and 'default'
// Currently, it appears we only use 'default' or we don't have a hero.
// So for now I'll only implement 'default'

const Hero: FC<Props> = ({
  className,
  onAddressInputVisibleChange,
  onCtaVisibleChange,
  autocompleteRedirectTarget,
  title,
  subtitle,
  description,
  include_cta,
  media,
  is_media_infographic,
  address_autocomplete_visible,
  override_button_label_long,
  override_button_label_short,
  show_autocomplete_button,
  address_label,
  onRequestHeaderHeight,

  show_cta,
  cta_text,
  cta_forward_utm_params,
  cta_referral_utm_params,
  cta_url,
  cta_relative_url,
}) => {
  return (
    <section className={cx(styles.hero, className)}>
      <div className={styles.mainHero}>
        <div className={styles.content}>
          <div className={styles.titleBox}>
            <PresentRichText className={styles.title} render={title} />
            <PresentRichText className={styles.subtitle} render={subtitle} />
            <PresentRichText className={styles.description} render={description} />
          </div>
          {address_autocomplete_visible != false && (
            <AddressAutocompleteField
              containerClassName={styles.addressField}
              className={styles.input}
              buttonLabelLong={override_button_label_long}
              buttonLabelShort={override_button_label_short}
              showButton={show_autocomplete_button}
              onInputVisibleChange={onAddressInputVisibleChange}
              variant={ConvertAutocompleteRedirectTarget(autocompleteRedirectTarget)}
              positionLabel="hero"
              placeholder={address_label}
              onRequestHeaderHeight={onRequestHeaderHeight}
              scrollToFocus
            />
          )}
          {/* Different CTA than the one below. */}
          {show_cta && (
            <CtaButton
              cta_text={cta_text}
              cta_url={cta_url}
              cta_forward_utm_params={cta_forward_utm_params}
              cta_referral_utm_params={cta_referral_utm_params}
              cta_relative_url={cta_relative_url}
              onVisibleChange={onCtaVisibleChange}
            />
          )}
          {include_cta && (
            <>
              <p className={styles.ctaOr}>{'or'}</p>
              <Cta
                type="primary"
                className={styles.contact}
                href="https://calendly.com/kerry-44/realm-project-review/"
                target="_blank"
                cta="landing-page-hero"
              >
                {'Talk to an Advisor'}
              </Cta>
            </>
          )}
        </div>
        <Media media={media} isInfoGraphic={is_media_infographic} />
      </div>
    </section>
  )
}

export default Hero
