import React, { FC } from 'react'
import cx from 'classnames'

import { SliceImageQuotes } from '../../types'

import TitleHeader from '../Components/TitleHeader'

import ImageQuote from './ImageQuote'

import styles from './styles.module.scss'

interface Props {
  className?: string
  slice: SliceImageQuotes
}
const ImageQuotes: FC<Props> = ({ className, slice }) => {
  return (
    <section className={cx(styles.imageQuotes, className)}>
      <TitleHeader className={styles.titleBox} {...slice.primary} />
      <div className={styles.scroll}>
        <div className={styles.quotes}>
          {slice.items.map((quote, i) => (
            <ImageQuote className={styles.item} key={i} {...quote} />
          ))}
        </div>
      </div>
    </section>
  )
}

export default ImageQuotes
