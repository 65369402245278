import React, { FC, useCallback, useMemo } from 'react'
import cx from 'classnames'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

import CoreButton from 'components/Core/CoreButton'

import styles from './Period.module.scss'

dayjs.extend(utc)
dayjs.extend(timezone)

export interface Block {
  start: Date
  end: Date
}

interface TimeProps {
  block: Block
  selected?: Block
  disabled: boolean
  timezone?: string
  onClick: (block: Block) => void
}

const Time: FC<TimeProps> = ({ block, selected, disabled, timezone, onClick }) => {
  const handleTimeClicked = useCallback(() => {
    onClick(block)
  }, [onClick, block])

  const isSelected = block.start.getTime() == selected?.start?.getTime()

  const djsStart = useMemo(() => {
    let date = dayjs(block.start)
    if (timezone) date = date.tz(timezone)
    return date
  }, [block.start, timezone])

  return (
    <CoreButton
      size="m"
      text={djsStart.format('h:mm A')}
      onClick={handleTimeClicked}
      disabled={disabled}
      className={cx(styles.time, { [styles.selected]: isSelected })}
    />
  )
}

export default Time
