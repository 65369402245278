import React, { FC } from 'react'
import cx from 'classnames'
import Info from '@mui/icons-material/Info'

interface AlertProps {
  alert: { type: 'error' | 'success'; message: string } | null
}

const Alert: FC<AlertProps> = ({ alert }) => {
  if (!alert) return null

  return (
    <div
      className={cx('tw-p-3 tw-flex tw-gap-2', {
        'tw-bg-red-100 tw-text-red-600': alert.type == 'error',
        'tw-bg-blue-100 tw-text-blue-600': alert.type == 'success',
      })}
    >
      <Info />
      <div>{alert.message}</div>
    </div>
  )
}

export default Alert
