import PresentRichText, { hasTextPresent } from 'pages/_serverRendered/CmsPage/Section/Components/PresentRichText'
import React, { FC } from 'react'

import Cta from 'components/MarketingAndPropertyPages/Cta'
import { SliceQuotesCard } from 'pages/_serverRendered/CmsPage/types'
import ScrollingCards from 'pages/_serverRendered/CmsPage/Section/ScrollingCards'
import cx from 'classnames'
import styles from './styles.module.scss'

interface QuotesCardProps {
  className?: string
  id?: string
  slice: SliceQuotesCard
}

const QuotesCard: FC<QuotesCardProps> = ({ className, id, slice }) => {
  return (
    <section id={id} className={cx(styles.quotes, className)}>
      {hasTextPresent(slice.primary?.title) && <h2 className={styles.h2}>{(slice.primary?.title || [])[0]?.text}</h2>}
      <PresentRichText render={slice.primary?.description} />
      <ScrollingCards items={slice.items} backgroundColor={slice.primary?.background_color} />
      <Cta href={slice.primary?.cta_url || ''} cta={`quotes-card${id ? `-${id}` : ''}`}>
        {slice.primary?.cta_title}
      </Cta>
    </section>
  )
}

export default QuotesCard
