import React, { FC, useMemo, useCallback } from 'react'
import cx from 'classnames'
import dayjs, { Dayjs } from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

import { CalendarMarks, getMark } from './types'

import CoreText from 'components/Core/CoreText'

import styles from './styles.module.scss'

dayjs.extend(utc)
dayjs.extend(timezone)

interface DayProps {
  date: Dayjs
  selected: boolean
  marked?: string
  onSelected: (day: Dayjs) => void
}

const Day: FC<DayProps> = ({ date, selected, marked, onSelected }) => {
  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      onSelected(date)
    },
    [onSelected, date]
  )

  return (
    <li className={cx(styles.day, { [styles.selected]: selected, [styles[marked as string]]: !!marked })}>
      <button onClick={handleClick} type="button">
        <CoreText.Paragraph element={'span'} size="l" weight="light">
          {date.format('dd')[0]}
        </CoreText.Paragraph>
        <CoreText.Paragraph className={styles.date} element={'span'} size="l" weight="regular">
          {date.format('D')}
        </CoreText.Paragraph>
      </button>
    </li>
  )
}

interface Props {
  selected: Dayjs
  marks: CalendarMarks
  onSelected: (date: Dayjs) => void
}

const Week: FC<Props> = ({ selected, marks, onSelected }) => {
  const days = useMemo(() => {
    const theWeek: Array<Dayjs> = []
    const start = selected.startOf('w')
    for (let x = 0; x < 7; x++) {
      theWeek.push(start.add(x, 'd'))
    }
    return theWeek
  }, [selected])
  return (
    <ol className={cx(styles.week)}>
      {days.map((day) => (
        <Day
          key={`${day.toDate().getTime()}`}
          date={day}
          selected={day.isSame(selected, 'day')}
          marked={getMark(marks, day)}
          onSelected={onSelected}
        />
      ))}
    </ol>
  )
}

export default Week
