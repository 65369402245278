import React, { FC } from 'react'

import { ImageQuote } from '../../types'
import { thumbnailUrl } from 'pages/_serverRendered/CmsPage/utils'

import { useCTAAdjustment } from '../../CtaAdjustment'

import PresentRichText from 'pages/_serverRendered/CmsPage/Section/Components/PresentRichText'

import styles from './styles.module.scss'

interface Props extends ImageQuote {
  className?: string
}
const ImageQuotes: FC<Props> = ({
  className,
  image,
  name,
  location,
  quote,
  cta_url,
  cta_forward_utm_params,
  cta_referral_utm_params,
  cta_text,
}) => {
  const { url } = useCTAAdjustment(cta_url?.url, cta_referral_utm_params, cta_forward_utm_params)
  return (
    <div className={className}>
      {image.url && <img className={styles.image} alt={image.alt || ''} src={thumbnailUrl(image.url)} />}
      <div className={styles.name}>{name}</div>
      <div className={styles.location}>{location}</div>
      <PresentRichText render={quote} className={styles.quote} />
      {cta_url && cta_url.url && (
        <a className={styles.cta} href={url || cta_url.url} target={cta_url.target}>
          {cta_text}
        </a>
      )}
    </div>
  )
}

export default ImageQuotes
