import React, { FC, useState, useCallback, useEffect, useMemo } from 'react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

import { getJSON } from 'utils/fetch'
import { prepare, trackClick as baseTrackClick, prepareName, TrackBase } from 'utils/analyticsV2'
import { VendorContactUser } from 'recoil/vendors'

import { Calendar, Event, CEvent } from './types'
import { schedulerPostJSON } from './utils'

import Spinner from 'svgs/spinner'

import CoreText from 'components/Core/CoreText'
import CoreButton from 'components/Core/CoreButton'

import Expired from './Expired'
import Unavailable from './Unavailable'
import { Block } from './Availabilities'
import FailureModal from './FailureModal'
import HouseholdDetails from './HouseholdDetails'
import PickContactModal from './PickContactModal'
import MismatchedModal from './MismatchedModal'
import DeclineModal from './DeclineModal'

import AvailabilitySelection from './AvailabilitySelection'

import styles from './Unscheduled.module.scss'

dayjs.extend(utc)
dayjs.extend(timezone)

const trackClick = prepare(
  {
    family: 'vendor-site-visit',
    screen: 'unscheduled',
  },
  baseTrackClick
)
const trackError = prepareName<TrackBase>('state-event', {
  code: 'error',
  family: 'vendor-site-visit',
  screen: 'unscheduled',
  feature: 'scheduling',
})

const trackEmailError = prepareName<TrackBase>('state-event', {
  code: 'error',
  family: 'vendor-site-visit',
  screen: 'unscheduled',
  feature: 'pick-contact',
})

interface UnscheduledProps {
  calendar: Calendar
  onCalendarChange: React.Dispatch<React.SetStateAction<Calendar>>
}

const Unscheduled: FC<UnscheduledProps> = ({ calendar, onCalendarChange }) => {
  const [submitting, setSubmitting] = useState(false)
  const [vendorContact, setVendorContact] = useState<null | VendorContactUser>(null)
  const [timer, setTimer] = useState(0)
  const [time, setTime] = useState<Block | null>(null)
  const [decline, setDecline] = useState(false)
  const [scheduleError, setScheduleError] = useState(false)
  const [pickContactModalOpen, setPickContactModalOpen] = useState(false)
  const [pickContactError, setPickContactError] = useState(false)
  const [mismatchedModal, setMismatchedModal] = useState(false)
  const [declineModal, setDeclineModal] = useState(false)
  const [timeUnavailableModal, setTimeUnavailableModal] = useState(false)

  useEffect(() => {
    // Update our view every 30 seconds.
    const interval = setInterval(() => setTimer((prev) => prev + 1), 30000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  const processSubmission = useCallback(
    async (time: null | Block, canceled: boolean) => {
      if (vendorContact == null) {
        setPickContactModalOpen(true)
        return false
      }

      setSubmitting(true)
      const body: CEvent = { email: vendorContact.email, canceled: canceled }
      if (!canceled && time) {
        body.start_time = time.start.toISOString()
        body.end_time = time.end.toISOString()
      }

      try {
        const response = await schedulerPostJSON(
          `/vendor_site_visits/${calendar.id}/events`,
          body as unknown as Record<string, unknown>,
          calendar.id,
          onCalendarChange
        )
        if (response.code != 200) {
          trackError({
            flexfield_1: {
              errorType: 'http',
              status: response.code,
              code: response.jsonBody?.code,
              time: time,
              canceled: canceled,
              email: vendorContact.email,
            },
          })
        }
        if (response.code == 400 && response.jsonBody?.code == 'invalid_email') {
          setPickContactModalOpen(true)
          setSubmitting(false)
          return false
        } else if (response.code == 400 && response.jsonBody?.code == 'time_unavailable') {
          // Best effort only. Goal here is to receive updated calendar times
          // to display the most accurate info.
          try {
            // Refresh our calendar info.
            const cResponse = await getJSON<Calendar>(`/vendor_site_visits/${calendar.id}`)
            onCalendarChange((prev) => ({ ...prev, ...cResponse }))
          } catch {
            /* Ignore */
          }
          setTimeUnavailableModal(true)
        } else if (response.code != 200) {
          setScheduleError(true)
        } else {
          const event: Event = response.jsonBody
          onCalendarChange((prev) => ({ ...prev, event: { ...event } }))

          // Only bother refreshing the entire calendar if we aren't
          // declining/canceled.
          if (!canceled) {
            // Best effort only. Goal here is to receive updated customer contact
            // info.
            try {
              // Refresh our calendar info.
              const cResponse = await getJSON<Calendar>(`/vendor_site_visits/${calendar.id}?event_id=${event.id}`)
              onCalendarChange((prev) => ({ ...prev, ...cResponse }))
            } catch {
              /* Ignore */
            }
          }
        }
      } catch (e) {
        trackError({
          flexfield_1: {
            errorType: 'exception',
            message: e.toString(),
            time: time,
            canceled: canceled,
            email: vendorContact.email,
          },
        })
        setScheduleError(true)
      }

      setSubmitting(false)
      return true
    },
    [vendorContact, calendar.id, onCalendarChange]
  )

  const handleTimeSelected = useCallback(
    async (time: Block) => {
      setTime(time)
      await processSubmission(time, false)
    },
    [processSubmission]
  )

  const handleMismatchedClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    trackClick({ code: 'open', section: 'schedule-mismatch' })
    e.preventDefault()
    setMismatchedModal(true)
  }, [])

  const handleDeclineClick = useCallback(() => {
    trackClick({ code: 'open', section: 'decline' })
    setDeclineModal(true)
  }, [])

  const handleDeclineModalConfirm = useCallback(async () => {
    trackClick({ code: 'confirm', section: 'decline' })
    setTime(null)
    setDecline(true)
    await processSubmission(null, true)
    setDeclineModal(false)
  }, [processSubmission])

  const handlePickContactModalClose = useCallback(async () => {
    if (time || decline) {
      trackClick({ code: 'confirm', section: 'pick-contact', feature: 'submit' })
      const result = await processSubmission(time, decline)
      if (result) {
        setPickContactModalOpen(false)
      }
    } else {
      trackClick({ code: 'confirm', section: 'pick-contact', feature: 'initial' })

      if (vendorContact == null) {
        setPickContactModalOpen(true)
        return false
      }

      setSubmitting(true)

      try {
        // We actually don't care what gets returned here, as long as it isn't a 404.
        const event = await getJSON<Event>(
          `/vendor_site_visits/${calendar.id}/events/${encodeURIComponent(vendorContact.email)}`
        )
        onCalendarChange((prev) => ({ ...prev, event: { ...event } }))

        // If we succeeded we want to try and refresh our calendar. The event won't
        // provide us info we want such as customer info.
        try {
          // Refresh our calendar info.
          const cResponse = await getJSON<Calendar>(`/vendor_site_visits/${calendar.id}?event_id=${event.id}`)
          onCalendarChange((prev) => ({ ...prev, ...cResponse }))
        } catch {
          /* Ignore */
        }
      } catch (e) {
        if (e.code) {
          trackEmailError({
            flexfield_1: {
              errorType: 'http',
              status: e.code,
              email: vendorContact.email,
            },
          })
        } else {
          trackEmailError({
            flexfield_1: {
              errorType: 'exception',
              message: e.toString(),
              email: vendorContact.email,
            },
          })
        }
        if (e.code == 404) {
          setPickContactModalOpen(true)
          setSubmitting(false)
          return false
        } else {
          setPickContactError(true)
          setSubmitting(false)
          return false
        }
      }
      setSubmitting(false)
      setPickContactModalOpen(false)
    }
  }, [time, decline, processSubmission, vendorContact, calendar.id, onCalendarChange])

  const handleScheduleErrorClose = useCallback(() => {
    trackClick({ code: 'close', section: 'schedule-error' })
    setScheduleError(false)
  }, [])
  const handleMismatchedModalClose = useCallback(() => {
    trackClick({ code: 'close', section: 'schedule-mismatch' })
    setMismatchedModal(false)
  }, [])
  const handleDeclineModalClose = useCallback(() => {
    trackClick({ code: 'close', section: 'decline' })
    setDeclineModal(false)
  }, [])
  const handleTimeUnavailableModalClose = useCallback(() => {
    trackClick({ code: 'close', section: 'time-unavailable-error' })
    setTimeUnavailableModal(false)
  }, [])
  const handlePickContactErrorClose = useCallback(() => {
    trackClick({ code: 'close', section: 'pick-contact-error' })
    setPickContactError(false)
  }, [])

  const availabilities = useMemo(() => {
    // eventDurationSize
    const eventDurationSize = calendar.event_duration * 60 * 1000
    // Increment size will be 30m, or if our event duration is smaller, that.
    const incrementSize = Math.max(30 * 60 * 1000, calendar.event_duration)
    // Split into even length time blocks.
    // Only allow availabilities to start and end at the top of the increment size.
    return calendar.availabilities.reduce((newAvail, block) => {
      const origStart = dayjs(block.time.start).tz(calendar.property.time_zone).toDate()
      const origEnd = dayjs(block.time.end).tz(calendar.property.time_zone).toDate()

      const start = Math.ceil(origStart.getTime() / incrementSize) * incrementSize
      const lastStart = origEnd.getTime() - eventDurationSize
      for (let x = start; x <= lastStart; x += incrementSize) {
        newAvail.push({
          start: dayjs(x).tz(calendar.property.time_zone).toDate(),
          end: dayjs(x + eventDurationSize)
            .tz(calendar.property.time_zone)
            .toDate(),
        })
      }

      return newAvail
    }, [] as Array<Block>)
  }, [calendar])

  const status = useMemo(() => {
    const now = Date.now()
    const booking_due_date = new Date(calendar.booking_due_date || 0).getTime()
    if (calendar.booking_due_date && booking_due_date <= now) return 'expired'
    else if (availabilities.length == 0) return 'unavailable'
    return 'unscheduled'

    // We want this to recalculate each time timer changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendar, availabilities, timer])

  if (status == 'expired') return <Expired calendar={calendar} />
  else if (status == 'unavailable') return <Unavailable calendar={calendar} />

  return (
    <div className={styles.unscheduled}>
      <header>
        <CoreText.Headline size="xs" weight="heavy">
          {'Schedule Site Visit'}
        </CoreText.Headline>
        <CoreText.Paragraph weight="light">
          {"Select from the homeowner's availability below to schedule a site visit for this project."}
        </CoreText.Paragraph>
      </header>
      <HouseholdDetails className={styles.householdDetails} property={calendar.property} screen="unscheduled" />
      <CoreText.Paragraph className={styles.availabilityTitle} color="disabledBlack">
        {'Homeowner availability'}
      </CoreText.Paragraph>
      <div className={styles.availabilityContainer}>
        <AvailabilitySelection
          calendar={calendar}
          onTimeSelected={handleTimeSelected}
          submitting={submitting}
          timer={timer}
        />
        <div className={styles.buttons}>
          <CoreButton
            className=""
            text="None of these times work for me"
            kind="secondary"
            icon={submitting ? <Spinner strokeWidth={16} /> : undefined}
            disabled={submitting}
            onClick={handleMismatchedClick}
          />
          <CoreButton
            className=""
            text="Decline"
            kind="negative"
            disabled={submitting}
            icon={submitting ? <Spinner strokeWidth={16} /> : undefined}
            onClick={handleDeclineClick}
          />
        </div>
      </div>

      {pickContactModalOpen && pickContactError ? (
        <FailureModal title="Failed to pick contact" onClose={handlePickContactErrorClose}>
          <p>
            {
              'An error occurred while attempting to pick a contact. Please try again later, or contact your advisor for more help.'
            }
          </p>
        </FailureModal>
      ) : pickContactModalOpen ? (
        <PickContactModal
          vendorContacts={calendar.vendor_contacts}
          vendorContact={vendorContact}
          setVendorContact={setVendorContact}
          submitting={submitting}
          onClose={handlePickContactModalClose}
        />
      ) : null}
      {declineModal && (
        <DeclineModal submitting={submitting} onConfirm={handleDeclineModalConfirm} onClose={handleDeclineModalClose} />
      )}
      {mismatchedModal && <MismatchedModal advisor={calendar.advisor} onClose={handleMismatchedModalClose} />}
      {scheduleError && (
        <FailureModal
          title={`Failed to ${time ? 'Schedule' : 'Decline'} Site Visit`}
          onClose={handleScheduleErrorClose}
        >
          <p>
            {`An error occurred while attempting to ${
              time ? 'schedule' : 'decline'
            } your site visit. Please try again later, or contact your advisor for more help.`}
          </p>
        </FailureModal>
      )}
      {timeUnavailableModal && (
        <FailureModal title="Time slot no longer available" onClose={handleTimeUnavailableModalClose}>
          <p>{`The time you have selected is no longer available. Please pick a different time.`}</p>
        </FailureModal>
      )}
    </div>
  )
}

export default Unscheduled
