import React, { FC, PropsWithChildren } from 'react'
import cx from 'classnames'

import styles from './LocalCard.module.scss'

interface Props {
  className?: string
}

const LocalCard: FC<PropsWithChildren<Props>> = ({ className, children }) => {
  return <div className={cx(styles.localCard, className)}>{children}</div>
}

export default LocalCard
