import React, { FC, useCallback } from 'react'
import cx from 'classnames'

import { formatPhoneNumber } from 'utils/formatting'
import { prepare, trackClick as baseTrackClick } from 'utils/analyticsV2'
import { Advisor } from '../types'

import PersonIcon from 'svgs/person'
import EmailIcon from 'svgs/email-2'
import PhoneIcon from 'svgs/phone'

import CoreText from 'components/Core/CoreText'
import InfoList, { InfoListItem } from '../components/InfoList'

import styles from './ContactInfo.module.scss'

const trackClick = prepare(
  {
    family: 'vendor-site-visit',
  },
  baseTrackClick
)

interface Props {
  className?: string
  advisor: Advisor
  screen: string
  section: string
}
const ContactInfo: FC<Props> = ({ className, advisor, screen, section }) => {
  const handleEmailClick = useCallback(() => {
    trackClick({ code: 'open', screen, section, feature: 'email' })
    window.open(`mailto:${advisor.email}`)
  }, [advisor, screen, section])

  const handlePhoneClick = useCallback(() => {
    trackClick({ code: 'open', screen, section, feature: 'phone' })
    let number = advisor.phone_number as string
    if (!number.startsWith('+')) number = `+${number}`
    window.open(`tel:${number}`)
  }, [advisor, screen, section])
  return (
    <InfoList className={cx(styles.contactInfo, className)}>
      <li className={styles.advisor}>
        {advisor.profile_pic_url ? (
          <img src={advisor.profile_pic_url} alt="Your Advisor" />
        ) : (
          <PersonIcon className={styles.advisorPlaceholder} />
        )}
        <div className={styles.details}>
          <CoreText.Paragraph size="l" color="primaryBlack">
            {advisor.name}
          </CoreText.Paragraph>
          <CoreText.Paragraph size="m" weight="light" color="tertiaryBlack">
            {advisor.title}
          </CoreText.Paragraph>
        </div>
      </li>
      <InfoListItem icon={<EmailIcon />} onClick={handleEmailClick}>
        <CoreText.Paragraph size="m" weight="light" color="primaryBlack">
          {advisor.email}
        </CoreText.Paragraph>
      </InfoListItem>
      {advisor.phone_number && (
        <InfoListItem icon={<PhoneIcon />} onClick={handlePhoneClick}>
          <CoreText.Paragraph size="m" weight="light" color="primaryBlack">
            {formatPhoneNumber(advisor.phone_number)}
          </CoreText.Paragraph>
        </InfoListItem>
      )}
    </InfoList>
  )
}

export default ContactInfo
