const slugify = (title?: string): string | undefined => {
  return title
    ? title
        .toLowerCase()
        .replace(/[^\w ]+/g, '')
        .replace(/ +/g, '-')
    : undefined
}

export default slugify
