import React, { FC, PropsWithChildren, useCallback } from 'react'

interface SlideButtonProps {
  slide: number
  onClick: (slide: number) => void
}

const SlideButton: FC<PropsWithChildren<SlideButtonProps>> = ({ children, slide, onClick }) => {
  const handleClick = useCallback(() => {
    onClick(slide)
  }, [onClick, slide])

  return (
    <button
      onClick={handleClick}
      type="button"
      className="tw-h-20 tw-w-full tw-text-left tw-py-4 tw-flex tw-items-center focus:tw-outline-none"
    >
      {children}
    </button>
  )
}

export default SlideButton
