import React, { FC, useCallback } from 'react'
import { VendorContactUser } from 'recoil/vendors'
import CoreModal from 'components/Core/CoreModal'
import CoreInput from 'components/Core/CoreInput'
import CoreButton from 'components/Core/CoreButton'
import SecurityIcon from 'svgs/admin-panel-settings'
import Spinner from 'svgs/spinner'

interface PickContactModalProps {
  vendorContacts: VendorContactUser[]
  vendorContact: VendorContactUser | null
  setVendorContact: (vendorContact: VendorContactUser | null) => void
  submitting: boolean
  onClose?: () => void
}

const PickContactModal: FC<PickContactModalProps> = ({
  vendorContacts,
  vendorContact,
  setVendorContact,
  submitting,
  onClose,
}) => {
  const handleChangeText = useCallback(
    (email: string) => {
      const newVendorContact = vendorContacts.find((e) => e.email == email) || null
      setVendorContact(newVendorContact)
    },
    [setVendorContact, vendorContacts]
  )

  const handleOnSubmit = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault()

      onClose?.()
    },
    [onClose]
  )

  const renderButtons = useCallback(
    () => (
      <CoreButton
        text="Submit"
        type="submit"
        form="frmPickContact"
        disabled={submitting}
        icon={submitting ? <Spinner strokeWidth={16} /> : undefined}
      />
    ),
    [submitting]
  )

  return (
    <CoreModal title="Pick a contact to perform the site visit" buttons={renderButtons} icon={<SecurityIcon />}>
      <form id="frmPickContact" onSubmit={handleOnSubmit}>
        <CoreInput.Select
          autoFocus
          required
          disabled={submitting}
          name="event[email]"
          className="tw-mt-2"
          value={vendorContact?.email}
          onChangeText={handleChangeText}
        >
          <option>{'Please pick one...'}</option>
          {vendorContacts.map((e) => (
            <option key={e.id} value={e.email}>
              {`${e.full_name} <${e.email}>`}
            </option>
          ))}
        </CoreInput.Select>
      </form>
    </CoreModal>
  )
}

export default PickContactModal
