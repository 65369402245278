import React, { FC, useMemo } from 'react'
import cx from 'classnames'

import { HeroImageItem } from '../../types'

import Image from './Image'

import styles from './styles.module.scss'

interface RenderImage {
  original: HeroImageItem
  depth: number
  x: number
  y: number
}

const RNG_SEED = 1527

// This is a very light seeded RNG (mulberry32) that will give us just
// enough consistant randomness for initialization
function seeded_random(a) {
  return function () {
    let t = (a += 0x6d2b79f5)
    t = Math.imul(t ^ (t >>> 15), t | 1)
    t ^= t + Math.imul(t ^ (t >>> 7), t | 61)
    return ((t ^ (t >>> 14)) >>> 0) / 4294967296
  }
}

function int(value: string | undefined | null, def: number): number {
  if (typeof value === 'undefined' || value == null) return def
  const ret = parseInt(value)
  if (Number.isNaN(ret)) return def
  return ret
}

const MAX_ANIMATION_TIME = 120000

interface Props {
  className?: string
  images: Array<HeroImageItem>
  minSpeed?: number
  drag?: number
  minWidth?: number
  minHeight?: number
}

const Background: FC<Props> = ({ className, images, minSpeed }) => {
  const imgs: Array<RenderImage> = useMemo(() => {
    const rand = seeded_random(RNG_SEED)

    const unitSize = 100 / images.length

    // We want to make a relatively even distribution, and avoid clumps (initially)
    // in our x, y and z.
    // x is taken care of because we start out putting one per unit (with some
    // minor dirt)
    const startYs = images
      .map((_, i) => ({ value: unitSize * i, sort: rand() }))
      .sort((a, b) => a.sort - b.sort)
      .map((item) => item.value)
    const startZ = images
      .map((_, i) => ({ value: unitSize * i, sort: rand() }))
      .sort((a, b) => a.sort - b.sort)
      .map((item) => item.value)

    return images.map((image, i) => ({
      original: image,
      depth: int(image.depth, startZ[i]),
      x: unitSize * i + int(image.distance, (rand() - 0.5) * unitSize),
      y: (int(image.angle, startYs[i] * 3.6) / 360) * -1,
    }))
  }, [images])

  const animationTime = !minSpeed ? MAX_ANIMATION_TIME : MAX_ANIMATION_TIME / minSpeed
  const animationPlayState = minSpeed == 0 ? 'paused' : 'running'

  return (
    <div className={cx(styles.background, className)}>
      <ul>
        {imgs.map((image, i) => {
          const className = cx({
            [styles.landscape]: image.original.orientation == 'landscape',
            [styles.portrait]: image.original.orientation == 'portrait',
            [styles.large]: image.original.size == 'large',
            [styles.medium]: image.original.size == 'medium',
          })
          return (
            <li
              key={i}
              className={className}
              style={{
                translate: `${image.x}% 0`,
                zIndex: -Math.floor(image.depth),
                filter: `brightness(${100 - image.depth}%)`,
                animationDelay: `${image.y * animationTime}ms`,
                animationDuration: `${animationTime + (animationTime / 100) * image.depth}ms`,
                animationPlayState: animationPlayState,
              }}
            >
              <Image image={image.original.image} orientation={image.original.orientation || 'square'} />
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default Background
