import React, { FC } from 'react'

import { DesktopImage } from '../types'

interface Props {
  className?: string
  image?: DesktopImage
}

const QRCode: FC<Props> = ({ className, image }) => {
  const srcset = [
    { img: image, size: '1x' },
    { img: image?.desktop_2x, size: '2x' },
  ]
    .filter((img) => !!img.img)
    .map((img) => `${img.img?.url} ${img.size}`)
    .join(',')

  return (
    <div className={className}>
      <img src={image?.url} srcSet={srcset} alt={image?.alt || ''} />
    </div>
  )
}

export default QRCode
