import React, { FC, ReactNode } from 'react'

import styles from './styles.module.scss'

interface Props {
  children: ReactNode
  href: string
}

const CtaSmall: FC<Props> = ({ children, href }) => (
  <a className={styles.ctaSmall} href={href}>
    {children}
  </a>
)

export default CtaSmall
