import React, { FC, useState, useCallback } from 'react'
import Header from 'components/MarketingSite/Header'

import { titleCase } from 'utils/formatting'
import { getJSON } from 'utils/fetch'

import Spinner from 'components/Spinner'
import FullAddressInput, { Prediction } from 'components/FullAddressInput'

import DataLayout from './DataLayout'
import CoverPage from './CoverPage'

import styles from './styles.module.scss'

interface Data {
  property: {
    address: string
    city: string
    state: string
    zip: string
    depth: string
    width: string
    lot_size: string
    stories: string
  }
  zoning: { string: { value: string; caveats: string } }
}

const ZoningDisplay: FC = () => {
  const [enteredAddress, setEnteredAddress] = useState<string>('')
  const [data, setData] = useState<Data | null>(null)
  const [apiKey, setApiKey] = useState<string | undefined>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)

  const onPredictionSelected = useCallback(
    async (prediction: Prediction | null) => {
      if (!prediction) return
      if (!apiKey) return
      setLoading(true)
      setError(false)
      const queryString = new URLSearchParams({
        address: prediction.description,
        partner_key: apiKey,
      }).toString()
      try {
        const response = await getJSON(`/api/p1/zoning?${queryString}`)
        setData(response as Data)
      } catch (error) {
        setError(true)
      } finally {
        setLoading(false)
        setEnteredAddress('')
      }
    },
    [apiKey]
  )

  if (!data) {
    return (
      <div>
        <Header />
        <CoverPage
          apiKey={apiKey}
          setApiKey={setApiKey}
          onPredictionSelected={onPredictionSelected}
          enteredAddress={enteredAddress}
          setEnteredAddress={setEnteredAddress}
          loading={loading}
          error={error}
        />
      </div>
    )
  }

  return (
    <div>
      <Header />
      <div className={styles.projectPricing}>
        <section className={styles.primary}>
          <div className={styles.content}>
            <h1>{'Want to see Zoning in your area?'}</h1>
            <p className={styles.subheader}>{`Here are the zoning regulations for ${titleCase(
              data.property.address
            )}`}</p>
            <DataLayout zoningRules={data.zoning} />
          </div>
        </section>
        <section className={styles.calculations}>
          <div className={styles.searchProperties}>
            <h1 className={styles.searchHeader}>{'Search Other Properties'}</h1>
            <FullAddressInput
              className={styles.search}
              placeholder="Enter an address"
              id="entered_address"
              required
              onPredictionSelected={onPredictionSelected}
              value={enteredAddress}
              onChange={setEnteredAddress}
              disabled={loading}
            />
            {loading && <Spinner size={'md'} color={'white'} />}
            {error && <div className={styles.error}>{'Error: Please Enter A Valid Address'}</div>}
          </div>
          <div className={styles.propertyInfo}>
            <div className={styles.lotData}>
              <div className={styles.lotHeader}>{'Data Used in Zoning Calculations'}</div>
              <ul>
                <li>{`Lot Depth: ${parseInt(data.property.depth) > 0 ? data.property.depth + ' ft' : '  Missing'}`}</li>
                <li>{`Lot Width: ${parseInt(data.property.width) > 0 ? data.property.width + ' ft' : '  Missing'}`}</li>
                <li>{`Lot Size: ${
                  parseInt(data.property.lot_size) > 0 ? data.property.lot_size + ' sq ft' : '  Missing'
                }`}</li>
                <li>{`Primary Dwelling Stories: ${
                  parseInt(data.property.stories) > 0 ? data.property.stories : 'Missing'
                }`}</li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default ZoningDisplay
