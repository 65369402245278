import React, { FC } from 'react'
import { titleCase } from 'utils/formatting'

import cx from 'classnames'

import styles from './ZoningRuleStyles.module.scss'

interface Props {
  name: string
  value: string
  message: string[]
}

const ZoningRule: FC<Props> = ({ name, value, message }) => {
  return (
    <div className={cx(styles.rule)}>
      <div className={styles.info}>
        <div className={styles.name}>
          {titleCase(name?.replace(/_/g, ' '))}
          {value && ': ' + value}
        </div>
        <ul>
          {message
            ? message.map((sentence) => {
                return (
                  <li key={sentence} className={styles.details}>
                    {sentence.trim()}
                  </li>
                )
              })
            : null}
        </ul>
      </div>
    </div>
  )
}

export default ZoningRule
