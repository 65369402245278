import React, { FC } from 'react'

interface Props {
  className?: string
}

const Svg: FC<Props> = ({ className }) => (
  <svg className={className} height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.025 21Q11.6 21 11.312 20.712Q11.025 20.425 11.025 20V18.85Q9.9 18.6 9.05 17.975Q8.2 17.35 7.675 16.225Q7.5 15.875 7.662 15.488Q7.825 15.1 8.25 14.925Q8.6 14.775 8.975 14.938Q9.35 15.1 9.55 15.475Q9.975 16.225 10.625 16.613Q11.275 17 12.225 17Q13.25 17 13.963 16.538Q14.675 16.075 14.675 15.1Q14.675 14.225 14.125 13.712Q13.575 13.2 11.575 12.55Q9.425 11.875 8.625 10.938Q7.825 10 7.825 8.65Q7.825 7.025 8.875 6.125Q9.925 5.225 11.025 5.1V4Q11.025 3.575 11.312 3.287Q11.6 3 12.025 3Q12.45 3 12.738 3.287Q13.025 3.575 13.025 4V5.1Q13.975 5.25 14.675 5.713Q15.375 6.175 15.825 6.85Q16.05 7.175 15.913 7.575Q15.775 7.975 15.35 8.15Q15 8.3 14.625 8.162Q14.25 8.025 13.925 7.675Q13.6 7.325 13.163 7.137Q12.725 6.95 12.075 6.95Q10.975 6.95 10.4 7.438Q9.825 7.925 9.825 8.65Q9.825 9.475 10.575 9.95Q11.325 10.425 13.175 10.95Q14.9 11.45 15.788 12.537Q16.675 13.625 16.675 15.05Q16.675 16.825 15.625 17.75Q14.575 18.675 13.025 18.9V20Q13.025 20.425 12.738 20.712Q12.45 21 12.025 21Z"
      fill="currentColor"
    />
  </svg>
)

export default Svg
