import React, { FC } from 'react'
import cx from 'classnames'

interface PageHeaderProps {
  headlineText: string
  subheadText: string
  className?: string
}

import styles from './PageHeader.module.scss'
import CoreText from 'components/Core/CoreText'

const PageHeader: FC<PageHeaderProps> = ({ headlineText, subheadText, className }) => {
  return (
    <div className={cx(styles.pageHeader, className)}>
      <CoreText.Headline size="xl" weight="heavy" className={cx(styles.headline, styles.desktop)}>
        {headlineText}
      </CoreText.Headline>
      <CoreText.Headline size="s" weight="heavy" className={cx(styles.headline, styles.mobile)}>
        {headlineText}
      </CoreText.Headline>
      <CoreText.Paragraph size="m" className={styles.subhead}>
        {subheadText}
      </CoreText.Paragraph>
    </div>
  )
}

export default PageHeader
