import React, { FC, useCallback } from 'react'

import EventBusyIcon from 'svgs/event-busy'
import Spinner from 'svgs/spinner'

import CoreModal from 'components/Core/CoreModal'
import CoreButton from 'components/Core/CoreButton'

interface Props {
  submitting: boolean
  onClose?: () => void
  onConfirm?: () => void
}

const CancelModal: FC<Props> = ({ submitting, onClose, onConfirm }) => {
  const renderButtons = useCallback(
    () => (
      <>
        <CoreButton
          text="Cancel site visit"
          kind="negative"
          disabled={submitting}
          icon={submitting ? <Spinner strokeWidth={16} /> : undefined}
          onClick={onConfirm}
        />
        <CoreButton text="Close" kind="primary" disabled={submitting} onClick={onClose} />
      </>
    ),
    [submitting, onConfirm, onClose]
  )
  return (
    <CoreModal
      title="Are you sure you want to cancel this site visit?"
      onClose={onClose}
      buttons={renderButtons}
      icon={<EventBusyIcon />}
      kind="danger"
    />
  )
}

export default CancelModal
