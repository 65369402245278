import React, { FC } from 'react'

interface Props {
  className?: string
}

const Svg: FC<Props> = ({ className }) => (
  <svg className={className} viewBox="0 0 17 16" fill="none">
    <path
      d="M3.8335 13.6666H13.1668V12.3333H3.8335V13.6666ZM13.1668 6.33331H10.5002V2.33331H6.50016V6.33331H3.8335L8.50016 11L13.1668 6.33331Z"
      fill="currentColor"
    />
  </svg>
)

export default Svg
