import React, { FC } from 'react'
import cx from 'classnames'

import { SliceTableOfContents, TableOfContentsItem } from '../../types'

import TitleHeader from '../Components/TitleHeader'

import styles from './styles.module.scss'

interface ToCGroup {
  reference_text?: string
  reference_id?: string
  section_id?: string
  children: Array<TableOfContentsItem>
}

interface Props {
  className?: string
  slice: SliceTableOfContents
}
const TableOfContents: FC<Props> = ({ className, slice }) => {
  // First group all the items
  const groups = slice.items.reduce((acc, item) => {
    let group = acc.find((group) => group.section_id == item.section_id)
    if (!group) {
      group = { section_id: item.section_id, children: [] }
      acc.push(group)
    }
    if (!item.is_section_header) group.children.push(item)
    else {
      group.reference_text = item.reference_text
      group.reference_id = item.reference_id
    }
    return acc
  }, [] as Array<ToCGroup>)

  return (
    <section className={cx(styles.tableOfContents, className)}>
      <TitleHeader className={styles.titleBox} {...slice.primary} />
      <ul className={styles.groups}>
        {groups.map((group) => (
          <li key={group.reference_id} className={styles.group}>
            <a href={`#${group.reference_id}`}>{group.reference_text}</a>
            <ul className={styles.items}>
              {group.children.map((item) => (
                <li key={item.reference_id} className={styles.item}>
                  <a href={`#${item.reference_id}`}>{item.reference_text}</a>
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </section>
  )
}

export default TableOfContents
