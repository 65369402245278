import React, { FC } from 'react'

import Accordion from 'components/MarketingAndPropertyPages/Accordion'
import AccordionItem from 'components/MarketingAndPropertyPages/Accordion/AccordionItem'
import { ContentAccordion } from 'components/MarketingSite/PageContent/types'
import Cta from 'components/MarketingAndPropertyPages/Cta'

import styles from './styles.module.scss'

import ImgBackyardHome from 'images/learn/projects/backyard-home.jpg'
import ImgBackyardHome2x from 'images/learn/projects/backyard-home@2x.jpg'
import ImgBathroom from 'images/learn/projects/bathroom.jpg'
import ImgBathroom2x from 'images/learn/projects/bathroom@2x.jpg'
import ImgConvertExisting from 'images/learn/projects/convert-existing.jpg'
import ImgConvertExisting2x from 'images/learn/projects/convert-existing@2x.jpg'
import ImgKitchen from 'images/learn/projects/kitchen.jpg'
import ImgKitchen2x from 'images/learn/projects/kitchen@2x.jpg'
import ImgMoreSpace from 'images/learn/projects/more-space.jpg'
import ImgMoreSpace2x from 'images/learn/projects/more-space@2x.jpg'
import ImgPool from 'images/learn/projects/pool.jpg'
import ImgPool2x from 'images/learn/projects/pool@2x.jpg'

const imageSrc = (image) => {
  switch (image) {
    case 'backyard-home':
      return [ImgBackyardHome, ImgBackyardHome2x]
    case 'bathroom':
      return [ImgBathroom, ImgBathroom2x]
    case 'convert-existing':
      return [ImgConvertExisting, ImgConvertExisting2x]
    case 'kitchen':
      return [ImgKitchen, ImgKitchen2x]
    case 'more-space':
      return [ImgMoreSpace, ImgMoreSpace2x]
    case 'pool':
      return [ImgPool, ImgPool2x]
    default:
      return [ImgPool, ImgPool2x]
  }
}

const MarketingAccordion: FC<ContentAccordion> = ({ items, preselectedItem }) => {
  return (
    <Accordion preselectedItems={preselectedItem ? [preselectedItem] : undefined}>
      {({ selectedIndices, toggleIndex }) =>
        items.map((item, index) => (
          <AccordionItem
            index={index}
            key={index}
            selectedIndices={selectedIndices}
            title={item.title}
            toggleIndex={toggleIndex}
          >
            {item.image != null && (
              <img
                className={styles.image}
                src={imageSrc(item.image.key)[0]}
                alt={item.image.alt}
                srcSet={`${imageSrc(item.image.key)[1]} 2x`}
              />
            )}
            <div className={styles.content}>
              {item.header != null && <p className={styles.header}>{item.header}</p>}
              {item.body != null && <p className={styles.body}>{item.body}</p>}
              {item.cta != null && (
                <Cta className={styles.cta} href={item.cta.href}>
                  {item.cta.title}
                </Cta>
              )}
            </div>
          </AccordionItem>
        ))
      }
    </Accordion>
  )
}

export default MarketingAccordion
