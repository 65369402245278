import React, { FC, useCallback } from 'react'
import cx from 'classnames'

import { formatCurrency } from 'utils/formatting'
import { ProjectTemplate } from 'recoil/projectTemplates'

import FallbackIcon from 'svgs/property-plan/fallback'
import CloseIcon from 'svgs/close'

import ExternalSvg from 'components/ExternalSvg'
import Checkbox from 'components/Checkbox'

import styles from './styles.module.scss'

interface Props {
  project: ProjectTemplate
  selected?: boolean
  small?: boolean

  onChange?: (projectId: number, selected: boolean) => void
}

const Project: FC<Props> = ({ project, selected, small, onChange }) => {
  const handleChange = useCallback(() => {
    if (onChange) onChange(project.id, !selected)
  }, [project, selected, onChange])

  return (
    <label
      className={cx(styles.project, {
        [styles.small]: small,
        [styles.selected]: selected,
      })}
      htmlFor={`${project.id}`}
    >
      <Checkbox className={styles.checkbox} checked={selected} id={`${project.id}`} onChange={handleChange} />
      <div className={styles.info}>
        <span className={styles.name}>{project.name}</span>
        <span className={styles.cost}>
          {formatCurrency(project.cost_estimate_low, true)}
          {' - '}
          {formatCurrency(project.cost_estimate_high, true)}
        </span>
      </div>
      <div className={styles.icon}>
        <ExternalSvg uri={project.icon_svg} className={styles.img}>
          <FallbackIcon className={styles.img} />
        </ExternalSvg>
        <CloseIcon className={styles.close} />
      </div>
    </label>
  )
}

export default Project
