import PresentRichText, { hasTextPresent } from 'pages/_serverRendered/CmsPage/Section/Components/PresentRichText'
import React, { FC } from 'react'

import Card from 'pages/_serverRendered/CmsPage/Section/Components/Card'
import Cta from 'components/MarketingAndPropertyPages/Cta'
import { SliceOpenRoles } from 'pages/_serverRendered/CmsPage/types'
import cx from 'classnames'
import styles from './styles.module.scss'

interface OpenRolesProps {
  className?: string
  id?: string
  slice: SliceOpenRoles
}

const OpenRoles: FC<OpenRolesProps> = ({ className, id, slice }) => {
  return (
    <Card backgroundColor={slice.primary.background_color} id={id} className={cx(styles.openRoles, className)}>
      {hasTextPresent(slice.primary.title1) && <h2 className={styles.h2}>{slice.primary.title1[0].text}</h2>}
      <PresentRichText render={slice.primary.description} />
      <div className={styles.items}>
        {slice.items.map((e, i) => (
          <a className={styles.item} key={i} href={e.role_url.url} target={e.role_url.target}>
            {e.role_name}
          </a>
        ))}
      </div>
      <Cta
        href={slice.primary.cta_url?.url || ''}
        cta={`open-roles${id ? `-${id}` : ''}`}
        target={slice.primary.cta_url?.target}
      >
        {slice.primary.cta_title}
      </Cta>
    </Card>
  )
}

export default OpenRoles
