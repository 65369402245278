import classnames from 'classnames'
import React, { FC, MouseEvent, ReactNode, useCallback } from 'react'
import { track } from 'utils/analytics'

import styles from './styles.module.scss'

interface Props {
  children: ReactNode
  className?: string
  cta?: string
  id?: string
  href: string
  target?: string
  type?: 'primary'
}

const Cta: FC<Props> = ({ children, className, cta, id, href, target, type }) => {
  const handleClick = useCallback(
    async (event: MouseEvent<HTMLAnchorElement>) => {
      if (!cta) return
      event.preventDefault()
      await track('click cta', { cta, module: 'cms-marketing' })
      if (target === '_blank') {
        window.open(href, '_blank')?.focus()
      } else {
        window.location.href = href
      }
    },
    [cta, href, target]
  )

  if (!href) return null

  return (
    <a
      className={classnames(styles.cta, { [styles.primary]: type === 'primary' }, className)}
      id={id}
      onClick={handleClick}
      href={href}
      target={target}
    >
      <span>{children}</span>
    </a>
  )
}

export default Cta
