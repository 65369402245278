import React, { FC, useCallback } from 'react'
import { Dayjs } from 'dayjs'

import EventAvailableIcon from 'svgs/event-available'
import DateIcon from 'svgs/calendar'
import TimeIcon from 'svgs/clock'
import Spinner from 'svgs/spinner'

import CoreModal from 'components/Core/CoreModal'
import CoreText from 'components/Core/CoreText'
import CoreButton from 'components/Core/CoreButton'

import InfoList, { InfoListItem } from './components/InfoList'

import styles from './ConfirmBookingModal.module.scss'

interface Props {
  startTime: Dayjs
  endTime: Dayjs
  submitting: boolean
  onClose?: () => void
  onConfirm?: () => void
}

const ConfirmBookingModal: FC<Props> = ({ startTime, endTime, submitting, onClose, onConfirm }) => {
  const renderButtons = useCallback(
    () => (
      <>
        <CoreButton
          text="Book site visit"
          kind="primary"
          disabled={submitting}
          icon={submitting ? <Spinner strokeWidth={16} /> : undefined}
          onClick={onConfirm}
        />
        <CoreButton text="Cancel" kind="secondary" disabled={submitting} onClick={onClose} />
      </>
    ),
    [submitting, onConfirm, onClose]
  )
  return (
    <CoreModal
      className={styles.confirmBookingModal}
      title="Confirm Site Visit Booking"
      onClose={onClose}
      buttons={renderButtons}
      icon={<EventAvailableIcon />}
      kind="success"
    >
      <InfoList className={styles.bookingInfo}>
        <InfoListItem icon={<DateIcon />}>
          <CoreText.Paragraph size="m" color="primaryBlack">
            {startTime.format('ddd, MMM D')}
          </CoreText.Paragraph>
        </InfoListItem>
        <InfoListItem icon={<TimeIcon />}>
          <CoreText.Paragraph size="m" color="primaryBlack">
            {`${startTime.format('h:mm A')} - ${endTime.format('h:mm A')}`}
          </CoreText.Paragraph>
          <CoreText.Paragraph size="m" weight="light" color="primaryBlack">
            {startTime.format('zzz')}
          </CoreText.Paragraph>
        </InfoListItem>
      </InfoList>
    </CoreModal>
  )
}

export default ConfirmBookingModal
