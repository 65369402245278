import React, { ForwardRefRenderFunction, forwardRef, PropsWithChildren } from 'react'
import cx from 'classnames'

import OldButton, { ButtonProps } from 'components/Button2'

import styles from './styles.module.scss'

/* This was the button created for the new CamV2 onboarding flow.
   As anticipated, and since it has now shown up in a separate location
   I'm going to assume this is a slow migration to this as the new design.
   Therefore it has been advanced to '/components', and I'm giving it a
   memorable name, with the idea that eventually it will completely replace
   our current Button2.
*/
export type { ButtonProps }

const Button: ForwardRefRenderFunction<HTMLButtonElement, PropsWithChildren<ButtonProps>> = (
  { className, disabled, ...props },
  ref
) => (
  <OldButton
    className={cx(styles.button, { [styles.disabled]: disabled }, className)}
    {...props}
    disabled={disabled}
    ref={ref}
  />
)

export default forwardRef(Button)
