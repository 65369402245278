import React, { FC } from 'react'

interface CalendarProps {
  className?: string
}

const Calendar: FC<CalendarProps> = ({ className }) => (
  <svg className={className} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 3.54517H20C21.1 3.54517 22 4.44517 22 5.54517V21.5452C22 22.6452 21.1 23.5452 20 23.5452H4C2.9 23.5452 2 22.6452 2 21.5452V5.54517C2 4.44517 2.9 3.54517 4 3.54517H5V2.54517C5 1.99517 5.45 1.54517 6 1.54517C6.55 1.54517 7 1.99517 7 2.54517V3.54517H17V2.54517C17 1.99517 17.45 1.54517 18 1.54517C18.55 1.54517 19 1.99517 19 2.54517V3.54517ZM5 21.5452H19C19.55 21.5452 20 21.0952 20 20.5452V8.54517H4V20.5452C4 21.0952 4.45 21.5452 5 21.5452Z"
      fill="currentColor"
    />
  </svg>
)

export default Calendar
