import React, { FC } from 'react'
import cx from 'classnames'

import { SliceAppPreview } from '../../types'

import PresentRichText, { hasTextPresent } from 'pages/_serverRendered/CmsPage/Section/Components/PresentRichText'

import CallToAction from '../../Components/CallToAction'
import SectionTitle from '../../Components/SectionTitle'
import Image from '../../Components/Image'
import useAdjustLink from '../../Components/useAdjustLink'

import QRCode from '../../Components/QRCode'
import Previews from './Previews'

import styles from './styles.module.scss'

interface Props {
  className?: string
  slice: SliceAppPreview
}
const AppPreview: FC<Props> = ({ className, slice }) => {
  const {
    nav_id,
    title,
    subtitle_desktop,
    subtitle_mobile,
    qr_code,
    download_text,
    download_url,
    download_tracking_code,
    download_tracking_section,
    download_tracking_feature,
    download_tracking_family,

    big_phone,
  } = slice.primary
  const downloadLink = useAdjustLink(download_url)
  return (
    <section
      id={nav_id}
      className={cx(styles.appPreview, { [styles.gray]: slice?.primary.background_color == 'Gray' }, className)}
    >
      <div className={styles.titleBox}>
        <SectionTitle className={styles.title} title={title} />
        {(download_text || qr_code || hasTextPresent(subtitle_desktop) || hasTextPresent(subtitle_mobile)) && (
          <div className={styles.subtitleContent}>
            {hasTextPresent(subtitle_desktop) && (
              <PresentRichText className={cx(styles.subtitleText, styles.desktop)} render={subtitle_desktop} />
            )}
            {hasTextPresent(subtitle_mobile) && (
              <PresentRichText className={cx(styles.subtitleText, styles.mobile)} render={subtitle_mobile} />
            )}
            {download_text && (
              <CallToAction
                className={cx(styles.cta, styles.mobile)}
                url={downloadLink}
                trackingCode={download_tracking_code}
                trackingSection={download_tracking_section}
                trackingFeature={download_tracking_feature}
                trackingFamily={download_tracking_family}
              >
                {download_text}
              </CallToAction>
            )}
            {qr_code && <QRCode className={cx(styles.qrCode, styles.desktop)} image={qr_code} />}
          </div>
        )}
      </div>
      <Image className={styles.bigPhone} image={big_phone} />
      <Previews className={styles.previews} items={slice.items} />
    </section>
  )
}

export default AppPreview
