import { MarketingSitePage } from 'components/MarketingSite/PageContent/types'

export const testimonialsItems = [
  {
    title: 'Goal: Evaluate potential homes',
    body: 'When I was buying my house, I had so many questions that I couldn’t get answered. If I had been armed with this info earlier, it would have taken a lot of stress and uncertainty out of the process.',
    footer: 'Aldo, San Francisco',
  },
  {
    title: 'Goal: Add space for grandparents to stay',
    body: 'We love our home and have a laundry list of things we want to do, especially as our kids grow up...The first thing we wanted to do was adding more private space for grandparents and rental income. Realm helped us decide what to do and how much to spend. Next up is building a fence and fixing the funky bathroom—it’s entirely made of mirrors.',
    footer: 'Julie & Rob, Fullerton',
  },
  {
    title: 'Goal: Earn rental income',
    body: 'We knew we wanted an ADU but it was such a daunting task. Without Realm, we would have pushed off the project forever but they made it so easy for us to get started. Realm’s detailed report helped us hone in on exactly what type of ADU we wanted and taught us how we could refinance the ADU into our existing mortgage payment.',
    footer: 'Robyn & Lukas, Los Angeles',
  },
]

const meetContent: MarketingSitePage = {
  intro: {
    title: 'Real stories from real homeowners',
    body: 'Read about how Realm is helping property owners tap into property potential',
  },
  sections: [
    {
      navItem: {
        title: 'Case studies',
        slug: 'case-studies',
      },
      cardBackgroundColor: 'moss-fine',
      cta: { title: 'Read more on the blog', href: '/resources/library' },
      contentType: 'text',
      content: {
        image: { key: 'case-study-1', alt: 'Joel & Louise' },
        title: `“Realm did a great job, considering all the facts and weighing them objectively, which led them to propose a plan that's better than anything we could have thought of by ourselves!”`,
        body: `Joel & Louise purchased their home in Paso Robles several years ago as a vacation rental and investment property. During the pandemic, they relocated from their apartment in Los Angeles to take advantage of the beautiful outdoor space, backyard, and pool.

With a long list of potential projects and no idea where to start, Joel & Louise reached out to Realm for a holistic plan to transform their vacation property into their permanent dream home. Based on Realm’s analysis, they chose to start by renovating their garage and working with a local team to plan a home addition. They plan to continue using Realm as a resource for years to come.`,
        footer: `As Louise says: “We have no shortage of projects and will stick with Realm for most of them. Really, any improvement around the house that goes beyond a plumber, our contact at Realm is likely to hear from us.”`,
      },
    },
    {
      intro: {
        title: 'Hear what real customers are saying about Realm.',
      },
      navItem: {
        title: 'Testimonials',
        slug: 'testimonials',
      },
      cardBackgroundColor: 'transparent',
      contentType: 'scrollingCards',
      content: {
        items: testimonialsItems,
      },
    },
    {
      intro: {
        title: 'Learn More',
        body: 'Visit our blog to stay up to date with the industry, get valuable insights from our experts, and more.',
        cta: {
          title: 'Take me there',
          href: '/resources/library',
        },
      },
      navItem: {
        title: 'Blog',
        slug: 'blog',
      },
      cardBackgroundColor: 'transparent',
      contentType: 'blog',
      content: {
        items: [
          {
            author: {
              key: 'liz',
              name: 'Liz Young',
              title: 'Founder',
            },
            title: 'What is the Realm Score?',
            body: 'The Realm Score helps you understand how much of a property’s potential has been realized. Unlike home value, which can be impacted by a number of factors outside a homeowner’s control such as school districts, lot size, and nearby highways, a Realm Score only takes into account the things that you can change.',
            href: '/resources/library/what-is-the-realm-score/',
          },
          {
            author: {
              key: 'liz',
              name: 'Liz Young',
              title: 'Founder',
            },
            title: 'Why I Founded Realm',
            body: 'I founded Realm because once I started investing in real estate, I thought it was crazy that homeowners didn’t have access to the same information as investors.',
            href: '/resources/library/founder-story/',
          },
          {
            author: {
              key: 'liz',
              name: 'Liz Young',
              title: 'Founder',
            },
            title: 'Vanessa: Garage Conversion to ADU',
            body: 'Meet Vanessa – Medical professional, outdoors enthusiast, proud owner of an English bulldog. Goal: Earn enough rental income to buy a second home.',
            href: '/resources/library/vanessa-garage-conversion-to-adu/',
          },
        ],
      },
    },
  ],
}

export default meetContent
